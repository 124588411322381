import { useTranslation } from 'react-i18next';
import { CloseIcon, WarningIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  CircularProgress,
  Input,
  InputRightElement,
  Button,
  FormErrorMessage,
} from '@chakra-ui/react';

import { BiTrash } from 'react-icons/bi';
import { FaCheck } from 'react-icons/fa';

export type CryptoWalletFieldProps = {
  cryptoWalletAddress: string;
  isCryptoWalletAddressValid: boolean;
  isValidatingCryptoWalletAddress: boolean;
  handleCryptoWalletAddressChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleCleanWalletAddress: () => void;
};

const CryptoWalletField = ({
  cryptoWalletAddress,
  isCryptoWalletAddressValid,
  isValidatingCryptoWalletAddress,
  handleCryptoWalletAddressChange,
  handleCleanWalletAddress,
}: CryptoWalletFieldProps) => {
  const { t } = useTranslation('buy-crypto');
  return (
    <FormControl
      isInvalid={
        !!cryptoWalletAddress &&
        !isCryptoWalletAddressValid &&
        !isValidatingCryptoWalletAddress
      }
    >
      <FormLabel>{t('Enter your wallet address')}</FormLabel>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          {!isValidatingCryptoWalletAddress && isCryptoWalletAddressValid && (
            <FaCheck color="green" />
          )}
          {!isValidatingCryptoWalletAddress &&
            !isCryptoWalletAddressValid &&
            !!cryptoWalletAddress && <CloseIcon color="red.500" />}
          {isValidatingCryptoWalletAddress && (
            <CircularProgress
              size="20px"
              isIndeterminate={true}
              color="primary.500"
            />
          )}
          {cryptoWalletAddress.length <= 0 && (
            <WarningIcon color="yellow.500" />
          )}
        </InputLeftElement>
        <Input
          value={cryptoWalletAddress}
          onChange={handleCryptoWalletAddressChange}
          variant={isCryptoWalletAddressValid ? 'filled' : undefined}
        />
        {!!cryptoWalletAddress && (
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              colorScheme="gray"
              variant="outline"
              onClick={handleCleanWalletAddress}
            >
              <BiTrash />
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      {!!cryptoWalletAddress &&
        !isCryptoWalletAddressValid &&
        !isValidatingCryptoWalletAddress && (
          <FormErrorMessage>{t('Invalid wallet address')}</FormErrorMessage>
        )}
    </FormControl>
  );
};

export default CryptoWalletField;
