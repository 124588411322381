import { useTranslation } from 'react-i18next';
import {
  Text,
  Flex,
  Box,
  Divider,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { BsCurrencyExchange } from 'react-icons/bs';
import './../../Dashboard.language';
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { colors } from '../../../../common/configuration/ChakraColors';
import useDashboardExchangeRate from './hooks/useDashboardExchangeRate';

const DashboardExchangeRate = () => {
  const { t } = useTranslation('dashboard');
  const { data, maxDomain, minDomain, currentPV, setCurrentPV } =
    useDashboardExchangeRate();

  return (
    <Box
      rounded={'lg'}
      bg={useColorModeValue('white', 'gray.700')}
      boxShadow={'lg'}
      p={8}
      minW={{ base: '400px', md: '550px' }}
      height={'450px'}
    >
      <Heading size="small" as="h1" mb="4">
        <Flex flexDir="row" gap="10px" alignItems="center">
          <BsCurrencyExchange />
          {t('Exchange rate')}
        </Flex>
      </Heading>
      <Divider />
      <Box w="100%" h="85%" padding="20px">
        <Flex flexDir="row" marginBottom="15px">
          <Text fontWeight="bold">1 USD = {currentPV}</Text>
        </Flex>
        <ResponsiveContainer width="100%" minHeight="100%">
          <LineChart data={data}>
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={false}
            />
            <YAxis
              orientation="right"
              axisLine={false}
              tickLine={false}
              domain={[minDomain, maxDomain]}
            />
            <Tooltip
              content={({ active, payload, label }) => {
                if (
                  active &&
                  payload &&
                  payload.length &&
                  payload[0].value !== undefined
                ) {
                  setCurrentPV(payload[0].value as number);
                  return (
                    <Text fontSize="small" color="gray.500">
                      {label}
                    </Text>
                  );
                }
                return null;
              }}
            />
            <Line
              type="monotone"
              dataKey="pv"
              stroke={colors.primary['500']}
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
        <Divider />
        <Text fontSize="small" color="gray.300">
          {t('A month ago')}
        </Text>
      </Box>
    </Box>
  );
};

export default DashboardExchangeRate;
