import React from 'react';
import { Input, InputProps } from '@chakra-ui/react';
import { Currencies } from '@waygee/shared-types';

export type CurrencyInputFieldProps = {
  currency: Currencies;
  props: InputProps;
  onValueChange?: (value: string) => void;
};

const CurrencyInputField = ({
  currency,
  props,
  onValueChange,
}: CurrencyInputFieldProps) => {
  const [value, setValue] = React.useState<string | undefined>('');

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, '');
    const decimalValue = numericValue ? parseInt(numericValue, 10) / 100 : 0;
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(decimalValue);
    setValue(formattedValue);
    if (onValueChange) onValueChange(formattedValue);
  };

  const prefix =
    currency === Currencies.BRL
      ? 'R$'
      : currency === Currencies.EUR
      ? '€'
      : '$';

  return (
    <Input
      onChange={handleOnChange}
      value={value}
      {...props}
      placeholder={`${prefix}0,00`}
    />
  );
};

export default CurrencyInputField;
