var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Olá "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":24}}}) : helper)))
    + ",</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      ><br />\n      Esperamos que este e-mail o encontre bem. Agradecemos por fornecer seus\n      dados pessoais.<br />\n      <br />\n      Durante a revisão, identificamos algumas pendências nas informações\n      fornecidas. Para garantir que seu cadastro seja concluído com precisão,\n      solicitamos que faça as seguintes correções:<br />\n      <br />\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"PENDING_LIST") || (depth0 != null ? lookupProperty(depth0,"PENDING_LIST") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PENDING_LIST","hash":{},"data":data,"loc":{"start":{"line":15,"column":6},"end":{"line":15,"column":26}}}) : helper))) != null ? stack1 : "")
    + "<br />\n      <br />\n      Pedimos que efetue essas correções o mais rápido possível para evitar\n      quaisquer inconvenientes futuros. Se precisar de assistência ou tiver\n      alguma dúvida, não hesite em responder a este e-mail.<br />\n      <br />\n      Agradecemos sua colaboração e compreensão. Estamos aqui para ajudar e\n      garantir que sua experiência conosco seja a melhor possível.<br />\n      <br />\n    </span>\n  </div>\n</div>\n";
},"useData":true});