import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  IAuthenticatedUserDataDto,
  IOrderDto,
  OnboardingStepStatus,
  OnboardingSteps,
  OrderStatus,
} from '@waygee/shared-types';
import axios from 'axios';
import { ApiNames, getRequestConfig } from '../../common/apis/ApiRoutes';
import appConfig from '../../common/configuration/AppConfig';
import { axiosCustomInstance } from '../../common/network';
import { SignUpStepResult } from '../../common/types';
import { AuthToken } from '../../common/types/auth';
import { registerAndIdentifyGuest } from '../../common/utility/chat/chat.js';
import {
  addAuthCookieListener,
  getAuthTokenCookie,
  removeAuthToken,
  setAuthToken as setAuthTokenCookie,
} from '../../common/utility/cookies/authTokenStorage';

const publicUrls = [
  '/secure/login',
  '/secure/password-reset',
  '/secure/signup',
  '/secure/about',
  '/secure/contact',
  '/home',
];

const useAuthContext = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState<IAuthenticatedUserDataDto>();
  const [orderAvailableCount, setOrderAvailableCount] = useState<number>(0);
  const [onboardingStep, setOnboardingStep] = useState<OnboardingSteps>(
    OnboardingSteps.ACCOUNT_DATA
  );
  const [onboardingStepResults, setOnboardingStepResults] = useState<
    SignUpStepResult[]
  >([]);
  const [onboardingStatus, setOnboardingStatus] =
    useState<OnboardingStepStatus>(OnboardingStepStatus.PENDING);
  const navigate = useNavigate();
  const invalidateUserAccess = useCallback(() => {
    removeAuthToken();
    setIsLoggedIn(false);
    setUser(undefined);
    sessionStorage.removeItem(appConfig.lasRequestDateSessionId);
    if (!publicUrls.includes(window.location.pathname))
      navigate('/secure/login', { replace: true });
  }, [navigate]);

  const validateAuthCookie = () => {
    const localAuthToken = getAuthTokenCookie();
    if (localAuthToken) return localAuthToken;
    throw new Error('Auth cookie is missing:');
  };

  const checkUserProposalStatus = useCallback(async () => {
    const response = await axiosCustomInstance.request<SignUpStepResult[]>(
      getRequestConfig({
        endpoint: ApiNames.CHECK_ONBOARDING_STEPS,
      })
    );

    if (response.data) {
      setOnboardingStepResults(response.data);
      const step = response.data.find(
        (step) => step.status === OnboardingStepStatus.PENDING
      );
      const reviewStep = response.data.find(
        (step) => step.step === OnboardingSteps.REVIEW
      );
      if (step) {
        setOnboardingStep(step.step);
        setOnboardingStatus(step.status);
      } else {
        setOnboardingStep(OnboardingSteps.REVIEW);
        setOnboardingStatus(
          reviewStep?.status ?? OnboardingStepStatus.IN_PROGRESS
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const checkOrders = useCallback(async () => {
    const { data } = await axiosCustomInstance.request<IOrderDto[]>(
      getRequestConfig({
        endpoint: ApiNames.GET_ALL_ORDERS,
      })
    );
    setOrderAvailableCount(
      data?.filter((order) => order?.status === OrderStatus.AVAILABLE)
        ?.length ?? 0
    );
  }, []);

  const authorizeUser = useCallback(
    async (newAuthToken: AuthToken): Promise<void> => {
      try {
        const response =
          await axiosCustomInstance.request<IAuthenticatedUserDataDto>(
            getRequestConfig({
              endpoint: ApiNames.ME,
              token: newAuthToken,
            })
          );

        setIsLoggedIn(true);
        await checkUserProposalStatus();
        if (window.location.pathname === '/secure/login')
          navigate('/secure/dashboard', { replace: true });
        setUser(response.data);
        checkOrders();
        const {
          user: { id, email },
          ownerIndividualContact: { legalName },
        } = response.data;
        registerAndIdentifyGuest(id, legalName, email);
        return await Promise.resolve();
      } catch (error: unknown) {
        invalidateUserAccess();
        return await Promise.reject(error);
      }
    },
    [checkUserProposalStatus, invalidateUserAccess, navigate, checkOrders]
  );

  const updateAuthToken = useCallback((newAuthToken: AuthToken) => {
    setAuthTokenCookie(newAuthToken);
  }, []);

  const fetchRefreshToken = async (newAuthToken: AuthToken) =>
    axios
      .request<AuthToken>(
        getRequestConfig({
          endpoint: ApiNames.REFRESH_TOKEN,
          token: {
            accessToken: newAuthToken?.refreshToken,
          },
        })
      )
      .then((response) => response.data);

  const refreshAuthToken = useCallback(
    async (newAuthToken: AuthToken) => {
      if (newAuthToken)
        try {
          const updatedAuthToken = await fetchRefreshToken(newAuthToken);
          if (updatedAuthToken) {
            const silentToken: AuthToken = {
              ...updatedAuthToken,
              silent: true,
            };
            updateAuthToken(silentToken);
            return true;
          }
          return false;
        } catch {
          return false;
        }
    },
    [updateAuthToken]
  );

  const validateUserAccess = useMemo(
    () => async () => {
      try {
        addAuthCookieListener(authorizeUser, () => invalidateUserAccess());
        const localAuthToken = validateAuthCookie();
        await authorizeUser(localAuthToken);
      } catch (error) {
        const cookie = getAuthTokenCookie();
        if (!cookie) return invalidateUserAccess();
        const didUpdateToken = await refreshAuthToken(cookie);
        if (!didUpdateToken) {
          invalidateUserAccess();
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const authToken = getAuthTokenCookie();

  return {
    onboardingStep,
    onboardingStatus,
    user,
    isLoggedIn,
    authToken,
    validateUserAccess,
    refreshAuthToken,
    onboardingStepResults,
    orderAvailableCount,
  };
};

export default useAuthContext;
