import i18n from '../../common/configuration/i18n';

i18n.addResourceBundle('en', 'invoice', {
  Invoice: 'Invoice',
  'Your invoices': 'Your invoices overview',
  Configure: 'Configure',
  'Configure your invoice': 'Configure your invoice',
  History: 'History',
  'See your recent invoices': 'See your recent invoices',
  Create: 'Create',
  'Create new invoice': 'Create new invoice',
  Title: 'Title',
  'Sub Title': 'Sub Title',
  Address: 'Address',
  'Footer label': 'Footer label',
  'Bill to': 'Bill to',
  Save: 'Save',
  'Address 2': 'Address 2',
  Phone: 'Phone',
  'Send e-mail to payer': 'Send e-mail to payer',
  'Add new Configuration': 'Add new Configuration',
  Currency: 'Currency',
  Actions: 'Actions',
  'Are you sure?': 'Are you sure?',
  'Delete this invoice configuration?': 'Delete this invoice configuration?',
  Cancel: 'Cancel',
  Delete: 'Delete',
  Payers: 'Payers',
  'Configure your invoice payers': 'Configure your invoice payers',
  Name: 'Name',
  'Add new payer': 'Add new payer',
  'Create Invoice': 'Create Invoice',
  'Create and send invoice to your payers':
    'Create and send invoice to your payers',
  'You are not ready to create invoice': 'You are not ready to create invoice',
  'You need to have at least one of each:':
    'You need to have at least one of each:',
  'invoice configuration': 'invoice configuration',
  payer: 'payer',
  'If you need help, reach out our team at':
    'If you need help, reach out our team at',
  Amount: 'Amount',
  Number: 'Number',
  'Service Description': 'Service Description',
  Configuration: 'Configuration',
  'Invoice Paragraph Colors': 'Invoice Paragraph Colors',
  'Configure invoice': 'Configure invoice',
  'Invoice Preview': 'Invoice Preview',
  'Creating invoice...': 'Creating invoice...',
  Failed: 'Failed',
  'Something wrong': 'Something wrong',
  'Invoice created': 'Invoice created',
  'Payer is required': 'Payer is required',
  'Invoice config is required': 'Invoice config is required',
  'Invoice number is required': 'Invoice number is required',
  'Amount is required': 'Amount is required',
  'Service description is required': 'Service description is required',
  'Date is required': 'Date is required',
  'Due date is required': 'Due date is required',
  'Invoice History': 'Invoice History',
  Date: 'Date',
  'Due Date': 'Due Date',
  'Payer Name': 'Payer Name',
  'Invoice downloaded': 'Invoice downloaded',
  'Invoice downloaded successfully': 'Invoice downloaded successfully',
  'Downloading invoice': 'Downloading invoice',
  'Please wait...': 'Please wait...',
  'Look how it is easy to start!': 'Look how it is easy to start!',
  'Start by configuring your invoice, here you will define the invoice structure to be used as template.':
    'Start by configuring your invoice, here you will define the invoice structure to be used as template.',
  'Then you need to configure your payers, here you will define the payers that will receive the invoice.':
    'Then you need to configure your payers, here you will define the payers that will receive the invoice.',
  'Finally you can create your invoice, here you will select the configuration and payer and set the invoice data.':
    'Finally you can create your invoice, here you will select the configuration and payer and set the invoice data.',
  'You can access your invoice history to see your recent invoices and download them.':
    'You can access your invoice history to see your recent invoices and download them.',
  'Invoice deleted successful': 'Invoice deleted successful',
  'Invoice deletion failed': 'Invoice deletion failed',
  'Deleting Invoice': 'Deleting Invoice',
  'Are you sure you want to delete this invoice?':
    'Are you sure you want to delete this invoice?',
  Preview: 'Preview',
  'Select a currency': 'Select a currency',
  'Your data': 'Your data',
  'Your Payer Data': 'Your Payer Data',
  'Save for use again later': 'Save for use again later',
  'Currency is required': 'Currency is required',
  'Title is required': 'Title is required',
  'Invalid email address': 'Invalid email address',
  'Name is required': 'Name is required',
  New: 'New',
  'Select existing': 'Select existing',
  'Sign-in to create invoice': 'Sign-in to create invoice',
});

i18n.addResourceBundle('ptbr', 'invoice', {
  Invoice: 'Invoice',
  'Your invoices': 'Visão geral das suas Invoices',
  Configure: 'Configurar',
  'Configure your invoice': 'Configurar sua invoice',
  History: 'Histórico',
  'See your recent invoices': 'Veja suas invoices recentes',
  Create: 'Criar',
  'Create new invoice': 'Criar nova invoice',
  Title: 'Título',
  'Sub Title': 'Sub Título',
  Address: 'Endereço',
  'Footer label': 'Rodapé',
  'Bill to': 'Cobrar de',
  Save: 'Salvar',
  'Address 2': 'Endereço 2',
  Phone: 'Telefone',
  'Send e-mail to payer': 'Enviar e-mail para o pagador',
  'Add new Configuration': 'Adicionar nova configuração',
  Currency: 'Moeda',
  Actions: 'Ações',
  'Are you sure?': 'Você tem certeza?',
  'Delete this invoice configuration?': 'Excluir esta configuração de invoice?',
  Cancel: 'Cancelar',
  Payers: 'Pagadores',
  'Configure your invoice payers': 'Configure seus pagadores',
  Name: 'Nome',
  'Add new payer': 'Adicionar novo pagador',
  'Create Invoice': 'Criar Invoice',
  'Create and send invoice to your payers':
    'Criar e enviar invoice para seus pagadores',
  'You are not ready to create invoice':
    'Você não está pronto para criar Invoices',
  'You need to have at least one of each:':
    'Você precisa ter pelo menos um de cada:',
  'invoice configuration': 'Configuração de Invoice',
  payer: 'Pagador',
  'If you need help, reach out our team at':
    'Se precisar de ajuda, entre em contato com nossa equipe em',
  Amount: 'Valor',
  Number: 'Número',
  'Service Description': 'Descrição do serviço',
  Configuration: 'Configuração',
  'Invoice Paragraph Colors': 'Cor do tema da sua invoice',
  'Configure invoice': 'Configurações de invoices',
  'Invoice Preview': 'Prévia da invoice',
  'Creating invoice...': 'Criando invoice...',
  Failed: 'Falhou',
  'Something wrong': 'Algo deu errado',
  'Invoice created': 'Invoice criada',
  'Payer is required': 'Pagador é obrigatório',
  'Invoice config is required': 'Configuração de invoice é obrigatório',
  'Invoice number is required': 'Número da invoice é obrigatório',
  'Amount is required': 'Valor é obrigatório',
  'Service description is required': 'Descrição do serviço é obrigatório',
  'Date is required': 'Data é obrigatório',
  'Due date is required': 'Data de vencimento é obrigatório',
  'Invoice History': 'Histórico de invoices',
  Date: 'Data',
  'Due Date': 'Data de vencimento',
  'Payer Name': 'Nome do pagador',
  'Invoice downloaded': 'Invoice baixada',
  'Invoice downloaded successfully': 'Invoice baixada com sucesso',
  'Downloading invoice': 'Baixando invoice',
  'Please wait...': 'Por favor aguarde...',
  'Look how it is easy to start!': 'Veja como é fácil começar!',
  'Start by configuring your invoice, here you will define the invoice structure to be used as template':
    'Comece configurando sua invoice, aqui você definirá a estrutura da invoice a ser usada como modelo',
  'Then you need to configure your payers, here you will define the payers that will receive the invoice':
    'Em seguida, você precisa configurar seus pagadores, aqui você definirá os pagadores que receberão a invoice',
  'Finally you can create your invoice, here you will select the configuration and payer and set the invoice data':
    'Finalmente você pode criar sua invoice, aqui você selecionará a configuração e o pagador e definirá os dados da invoice',
  'You can access your invoice history to see your recent invoices and download them':
    'Você pode acessar o histórico de suas invoices para ver suas invoices recentes e baixá-las.',
  'Invoice deleted successful': 'Invoice deletada com sucesso',
  'Invoice deletion failed': 'Falha ao deletar invoice',
  'Deleting Invoice': 'Deletando Invoice',
  Delete: 'Deletar',
  'Are you sure you want to delete this invoice?':
    'Você tem certeza que deseja excluir essa invoice?',
  Preview: 'Prévia',
  'Select a currency': 'Selecione a moeda',
  'Your data': 'Seus dados',
  'Your Payer Data': 'Dados do seu pagador',
  'Save for use again later': 'Salvar para usar novamente',
  'Currency is required': 'Moeda é obrigatório',
  'Title is required': 'Título é obrigatório',
  'Invalid email address': 'E-mail inválido',
  'Name is required': 'Nome é obrigatório',
  New: 'Novo',
  'Select existing': 'Selecionar existente',
  'Sign-in to create invoice': 'Entre para criar invoice',
});
