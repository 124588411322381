import { useTranslation } from 'react-i18next';
import { CopyIcon } from '@chakra-ui/icons';
import { useToast } from '@chakra-ui/react';
import './CopyToClipboard.language';

type CopyToClipboardProps = {
  text: string;
};
const CopyToClipboard = ({ text }: CopyToClipboardProps) => {
  const handleCopyToClipboard = (text: string) =>
    navigator.clipboard.writeText(text);

  const toast = useToast();
  const { t } = useTranslation('copy-to-clipboard');

  return (
    <CopyIcon
      marginLeft="10px"
      _hover={{ cursor: 'pointer', color: 'primary.500' }}
      onClick={() => {
        handleCopyToClipboard(text);
        toast({
          title: t('Success!'),
          description: t('Copied to clipboard.'),
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      }}
    />
  );
};

export default CopyToClipboard;
