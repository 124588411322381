import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';
import {
  IMfaCompletedTokenDto,
  IMfaRequestTokenDto,
} from '@waygee/shared-types';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ApiNames, ApiRequestConfig, getRequestConfig } from '../../../apis';
import { MfaFormProps } from '../MfaForm';
import './../Mfa.language';
const MFA_SEND_INTERVAL = 60;

export type RequestToast = {
  title: string;
  description: string;
  isClosable?: boolean;
};

export type RequestToastOptions = {
  success: RequestToast;
  error?: RequestToast;
  loading?: RequestToast;
};

export type UseWithMfaFormProps = {
  firstRequest: ApiRequestConfig;
  firstRequestToastOptions: RequestToastOptions;
  firstCallback: (response: AxiosResponse) => void;
  finalRequest: ApiRequestConfig;
  finalRequestToastOptions: RequestToastOptions;
  finalCallback: (response: AxiosResponse) => void;
  finalCallbackError?: (response: AxiosError<{ message: string }>) => void;
  parentFormSubmit?: () => void;
  parentFormIsValid?: boolean;
  initialMfaRequestToken?: string;
  disclaimer?: string;
};

const useWithMfaForm = ({
  firstRequest,
  firstCallback,
  finalRequest,
  finalCallback,
  finalCallbackError,
  parentFormSubmit,
  parentFormIsValid,
  finalRequestToastOptions,
  firstRequestToastOptions,
  initialMfaRequestToken,
  disclaimer,
}: UseWithMfaFormProps) => {
  const [countdown, setCountdown] = useState(MFA_SEND_INTERVAL);
  const [isCounting, setIsCounting] = useState(false);
  const [mfaEmailSent, setMfaEmailSent] = useState<boolean>(false);
  const [mfaRequestToken, setMfaToken] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation('mfa');
  const toast = useToast();

  const validationSchema = Yup.object({
    mfaCode: Yup.string()
      .max(999999, t('Must be 6 digits'))
      .test(
        'len',
        t('6 digit code is required'),
        (val) => (mfaEmailSent && val?.toString().length === 6) || !mfaEmailSent
      ),
  });

  const sendMfaEmailWithCode = (mfaToken: string) => {
    return axios
      .request<IMfaRequestTokenDto>(
        getRequestConfig({
          endpoint: ApiNames.MFA_SEND_CODE,
          token: {
            accessToken: mfaToken,
            refreshToken: '',
          },
        })
      )
      .then(() => setMfaEmailSent(true));
  };

  const formik = useFormik({
    initialValues: {
      mfaCode: '',
    },
    onSubmit: (values) => {
      if (parentFormIsValid && parentFormSubmit) {
        parentFormSubmit();
      }
      if (parentFormIsValid && !isLoading) {
        setIsLoading(true);
        const promise = axios
          .request<IMfaRequestTokenDto>(
            getRequestConfig({
              ...firstRequest,
              token: { accessToken: initialMfaRequestToken },
            })
          )
          .then(async (response) => {
            firstCallback(response);
            setMfaToken(response.data?.mfaRequestToken ?? '');
            await sendMfaEmailWithCode(response.data?.mfaRequestToken ?? '');
          })
          .finally(() => setIsLoading(false));

        const { error, loading, success } = firstRequestToastOptions;

        toast.promise(promise, {
          success: {
            ...success,
            isClosable: true,
          },
          error: {
            ...error,
            isClosable: true,
          },
          loading: {
            ...loading,
            isClosable: true,
          },
        });
      }
    },
    validationSchema,
  });

  const {
    setFieldValue,
    touched,
    errors,
    values,
    handleSubmit,
    setFieldError,
  } = formik;

  const handleResendMfaCode = () => {
    setCountdown(MFA_SEND_INTERVAL);
    setIsCounting(true);
    axios
      .request<IMfaRequestTokenDto>(
        getRequestConfig({
          endpoint: ApiNames.MFA_SEND_CODE,
          token: {
            accessToken: mfaRequestToken,
            refreshToken: '',
          },
        })
      )
      .then(() => setMfaEmailSent(true))
      .finally(() => setIsLoading(false));
  };

  const handleMfaCodeValidation = (
    e?: FormEvent<HTMLFormElement> | undefined
  ) => {
    e?.preventDefault();
    if (values.mfaCode && errors.mfaCode === undefined) {
      setIsLoading(true);
      const promise = axios
        .request<IMfaCompletedTokenDto>(
          getRequestConfig({
            endpoint: ApiNames.MFA_VERIFY_CODE,
            token: {
              accessToken: mfaRequestToken ?? '',
              refreshToken: '',
            },
            params: values.mfaCode,
          })
        )
        .then(async (response) => {
          try {
            const responseFinal = await axios.request(
              getRequestConfig({
                ...finalRequest,
                token: {
                  accessToken: response.data?.mfaCompletedToken ?? '',
                  refreshToken: '',
                },
              })
            );
            finalCallback(responseFinal);
          } catch (error) {
            if (finalCallbackError)
              finalCallbackError(error as AxiosError<{ message: string }>);
            throw error;
          }
        })
        .catch(() => {
          setFieldError('mfaCode', t('Invalid code'));
          throw new Error('Invalid code');
        })
        .finally(() => setIsLoading(false));

      const { success } = finalRequestToastOptions;
      toast.promise(promise, {
        success: {
          ...success,
          isClosable: true,
        },
        error: {
          description: t('Invalid code'),
          title: t('Error'),
          isClosable: true,
        },
        loading: {
          description: t('Checking if code is valid...'),
          title: t('Validating'),
          isClosable: true,
        },
      });
    } else {
      setFieldError('mfaCode', t('Invalid code'));
    }
  };

  const mfaFormProps: MfaFormProps = {
    handleMfaCodeValidation,
    handleResendMfaCode,
    countdown,
    isCounting,
    isLoading,
    setFieldValue,
    touched,
    errors,
    disclaimer,
  };

  useEffect(() => {
    if (isCounting && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      setIsCounting(false);
    }
  }, [countdown, isCounting]);

  return {
    mfaFormProps,
    handleResendMfaCode,
    mfaEmailSent,
    isLoading,
    handleMfaSubmit: handleSubmit,
  };
};

export default useWithMfaForm;
