import './../ChangePassword.language';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';
import {
  IChangePasswordDto,
  IMfaCompletedTokenDto,
  IMfaRequestTokenDto,
} from '@waygee/shared-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ApiNames, getRequestConfig } from '../../../../../common/apis';
import { axiosCustomInstance } from '../../../../../common/network';
import { getAuthTokenCookie } from '../../../../../common/utility/cookies/authTokenStorage';

const MFA_SEND_INTERVAL = 60;
const useChangePassword = () => {
  const { t } = useTranslation('change-password');
  const [mfaRequestToken, setMfaToken] = useState<string | undefined>(
    undefined
  );
  const [countdown, setCountdown] = useState(MFA_SEND_INTERVAL);
  const [isCounting, setIsCounting] = useState(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [mfaEmailSent, setMfaEmailSent] = useState<boolean>(false);
  const authToken = getAuthTokenCookie();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const validationSchema = Yup.object({
    oldPassword: Yup.string().required(t('Current Password is required')),
    newPassword: Yup.string().required(t('New Password is required')),
    confirmNewPassword: Yup.string()
      .required(t('Confirm New Password is required'))
      .oneOf(
        [
          Yup.ref('newPassword'),
          null as unknown as string | Yup.Reference<unknown>,
        ],
        t('Passwords must match')
      ),
    mfaCode: Yup.string()
      .max(999999, t('Must be 6 digits'))
      .test(
        'len',
        t('6 digit code is required'),
        (val) => (mfaEmailSent && val?.toString().length === 6) || !mfaEmailSent
      ),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      mfaCode: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      const promise = axiosCustomInstance
        .request<IMfaRequestTokenDto>(
          getRequestConfig({
            endpoint: ApiNames.CHANGE_PASSWORD,
            data: {
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
            },
            token: authToken,
          })
        )
        .then((response) => {
          setMfaToken(response.data?.mfaRequestToken ?? '');
          axiosCustomInstance
            .request<IMfaRequestTokenDto>(
              getRequestConfig({
                endpoint: ApiNames.MFA_SEND_CODE,
                token: {
                  accessToken: response.data?.mfaRequestToken ?? '',
                  refreshToken: '',
                },
              })
            )
            .then(() => setMfaEmailSent(true));
        })
        .finally(() => {
          setIsLoading(false);
        });

      toast.promise(promise, {
        success: {
          title: t('Email sent'),
          description: t('Check your inbox to get the confirmation code'),
          isClosable: true,
        },
        error: {
          title: t('Password change failed'),
          description: t('Something wrong'),
          isClosable: true,
        },
        loading: {
          title: t('Changing password'),
          description: t('Please wait'),
          isClosable: true,
        },
      });
    },
  });

  const handleMfaCodeValidation = () => {
    setIsLoading(true);
    if (values.mfaCode) {
      const promise = axiosCustomInstance
        .request<IMfaCompletedTokenDto>(
          getRequestConfig({
            endpoint: ApiNames.MFA_VERIFY_CODE,
            token: {
              accessToken: mfaRequestToken ?? '',
              refreshToken: '',
            },
            params: values.mfaCode,
          })
        )
        .then((response) => {
          axiosCustomInstance
            .request<IChangePasswordDto>(
              getRequestConfig({
                endpoint: ApiNames.CHANGE_PASSWORD,
                data: {
                  oldPassword: values.oldPassword,
                  newPassword: values.newPassword,
                },
                token: {
                  accessToken: response.data?.mfaCompletedToken ?? '',
                  refreshToken: '',
                },
              })
            )
            .then(() => {
              setIsCompleted(true);
            });
        })
        .catch(() => setIsLoading(false));
      toast.promise(promise, {
        success: {
          title: t('Password changed'),
          description: t('Your password has been changed'),
          isClosable: true,
        },
        error: {
          title: t('Password change failed'),
          description: t('Something wrong'),
          isClosable: true,
        },
        loading: {
          title: t('Changing password'),
          description: t('Please wait'),
          isClosable: true,
        },
      });
    }
  };

  const handleResendMfaCode = () => {
    setCountdown(MFA_SEND_INTERVAL);
    setIsCounting(true);
    axiosCustomInstance
      .request<IMfaRequestTokenDto>(
        getRequestConfig({
          endpoint: ApiNames.MFA_SEND_CODE,
          token: {
            accessToken: mfaRequestToken,
            refreshToken: '',
          },
        })
      )
      .then(() => setMfaEmailSent(true))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isCounting && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      setIsCounting(false);
    }
  }, [countdown, isCounting]);

  const {
    handleSubmit,
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
  } = formik;

  return {
    t,
    handleSubmit,
    errors,
    touched,
    values,
    getFieldProps,
    isLoading,
    mfaEmailSent,
    setFieldValue,
    handleMfaCodeValidation,
    handleResendMfaCode,
    countdown,
    isCounting,
    isCompleted,
  };
};

export default useChangePassword;
