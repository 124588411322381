import { useTranslation } from 'react-i18next';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { IOrderDto, OrderStatus } from '@waygee/shared-types';
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import { TableGrid } from '../../../../common/components/TableGrid';
import { TableGridProps } from '../../../../common/components/TableGrid/TableGrid';
import { Transferences } from '../../../Transferences';
import './../../Statement.language';
import useWithTransferences from '../../../Transferences/useWithTransferences';

export type PaymentHistoryProps = {
  tableGridProps: TableGridProps;
  isQuoting: boolean;
  setIsQuoting: (value: boolean) => void;
  selectedOrder?: IOrderDto;
  setSelectedOrder: (value?: IOrderDto) => void;
  loadTableData: () => void;
};
const PaymentHistory = ({
  tableGridProps,
  isQuoting,
  setIsQuoting,
  setSelectedOrder,
  selectedOrder,
  loadTableData,
}: PaymentHistoryProps) => {
  const { t } = useTranslation('statement');
  const toast = useToast();
  const handleOnClose = (success?: boolean) => {
    setIsQuoting(false);
    setSelectedOrder();
    if (success) {
      toast({
        title: t('Transference requested'),
        description: t(
          'Your order is being processed. You will receive the details in your email.'
        ),
        status: 'success',
        duration: 10000,
        isClosable: true,
      });
      loadTableData();
    }
  };
  const transferenceProps = useWithTransferences({
    order: selectedOrder,
    handleOnClose,
  });

  return (
    <>
      <TableGrid {...tableGridProps} />
      <Drawer
        isOpen={isQuoting}
        placement="right"
        onClose={handleOnClose}
        size="xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t('Quote')}</DrawerHeader>

          <DrawerBody overflowX="hidden">
            <Transferences {...transferenceProps} />
            <Text fontSize="small" margin="10px">
              {t('Exchange Disclaimer')}
            </Text>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={() => handleOnClose()}>
              {selectedOrder?.status === OrderStatus.AVAILABLE
                ? t('Cancel')
                : t('Close')}
            </Button>
            {selectedOrder?.status === OrderStatus.AVAILABLE && (
              <Button
                leftIcon={<FaMoneyBillTransfer />}
                colorScheme="green"
                isDisabled={transferenceProps.isSubmitting}
                onClick={transferenceProps.handleSubmitOrder}
              >
                {t('Submit Quote')}
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default PaymentHistory;
