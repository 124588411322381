var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div style=\"text-align: center\">\n  <div>\n    <div style=\"font-family: inherit; text-align: inherit\">\n      <span>Uma nova mensagem pelo site chegou</span>\n    </div>\n    <div></div>\n  </div>\n\n  <div>\n    <div style=\"font-family: inherit; text-align: center\">\n      <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"EMAIL") || (depth0 != null ? lookupProperty(depth0,"EMAIL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"EMAIL","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":21}}}) : helper)))
    + "</span>\n    </div>\n    <div style=\"font-family: inherit; text-align: center\">\n      <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":20}}}) : helper)))
    + "</span>\n    </div>\n    <div style=\"font-family: inherit; text-align: center\">\n      <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"PHONE") || (depth0 != null ? lookupProperty(depth0,"PHONE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PHONE","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":21}}}) : helper)))
    + "</span>\n    </div>\n    <div style=\"font-family: inherit; text-align: center\">\n      <span\n        style=\"\n          font-family: Colfax, Helvetica, Arial, sans-serif;\n          font-size: 14px;\n          font-style: normal;\n          font-variant-ligatures: normal;\n          font-variant-caps: normal;\n          font-weight: 400;\n          letter-spacing: normal;\n          orphans: 2;\n          text-align: center;\n          text-indent: 0px;\n          text-transform: none;\n          widows: 2;\n          word-spacing: 0px;\n          -webkit-text-stroke-width: 0px;\n          white-space-collapse: preserve;\n          text-wrap: wrap;\n          background-color: rgb(63, 66, 89);\n          text-decoration-thickness: initial;\n          text-decoration-style: initial;\n          text-decoration-color: initial;\n          float: none;\n          display: inline;\n        \"\n        >"
    + alias4(((helper = (helper = lookupProperty(helpers,"MSG_CONTENT") || (depth0 != null ? lookupProperty(depth0,"MSG_CONTENT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MSG_CONTENT","hash":{},"data":data,"loc":{"start":{"line":45,"column":9},"end":{"line":45,"column":24}}}) : helper)))
    + "</span\n      >&nbsp;\n    </div>\n    <div></div>\n  </div>\n</div>\n";
},"useData":true});