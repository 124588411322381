import { useTranslation } from 'react-i18next';
import {
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  FlexProps,
} from '@chakra-ui/react';
import {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { CPFInput } from '../../../common/components/CPFInput';
import { PhoneInput } from '../../../common/components/PhoneInput';
import { SignUpSchema } from '../types/signUpSchema';
import '../SignUp.language';

export type SignUpIndividualDataProps = {
  flexFieldProps: FlexProps;
  touched: FormikTouched<SignUpSchema>;
  errors: FormikErrors<SignUpSchema>;
  getFieldProps: (
    nameOrOptions:
      | string
      | FieldConfig<string | number | readonly string[] | undefined>
  ) => FieldInputProps<string | number | readonly string[] | undefined>;
};

const SignUpIndividualData = ({
  flexFieldProps,
  touched,
  errors,
  getFieldProps,
}: SignUpIndividualDataProps) => {
  const { t } = useTranslation('signup');
  return (
    <>
      <Heading size="md">{t('Individual data')}</Heading>
      <Flex {...flexFieldProps}>
        <Box w={{ base: 'unset', md: '450px' }}>
          <FormControl
            id="firstName"
            isRequired
            isInvalid={
              (touched.individualLegalName && !!errors.individualLegalName) ||
              false
            }
          >
            <FormLabel>{t('First Name')}</FormLabel>
            <Input type="text" {...getFieldProps('individualLegalName')} />
            <FormErrorMessage>{errors.individualLegalName}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl
            id="birthDate"
            isRequired
            isInvalid={
              (touched.individualDateOfBirth &&
                !!errors.individualDateOfBirth) ||
              false
            }
          >
            <FormLabel>{t('Birthdate')}</FormLabel>
            <Input type="date" {...getFieldProps('individualDateOfBirth')} />
            <FormErrorMessage>{errors.individualDateOfBirth}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
      <Flex {...flexFieldProps}>
        <Box>
          <FormControl
            id="phone"
            isRequired
            isInvalid={
              (touched.individualPhone && !!errors.individualPhone) || false
            }
          >
            <FormLabel>{t('Phone Number')}</FormLabel>
            <PhoneInput type="text" {...getFieldProps('individualPhone')} />
            <FormErrorMessage>{errors.individualPhone}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl
            id="documentNumber"
            isRequired
            isInvalid={
              (touched.individualCpf && !!errors.individualCpf) || false
            }
          >
            <FormLabel>{t('Document Number')}</FormLabel>
            <CPFInput type="text" {...getFieldProps('individualCpf')} />
            <FormErrorMessage>{errors.individualCpf}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
    </>
  );
};

export default SignUpIndividualData;
