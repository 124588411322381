import React from 'react';
import MaskedInput from 'react-text-mask';
import { Input, InputProps } from '@chakra-ui/react';
import { CNPJMask } from '../../utility/formatters';

const CNPJInput = (props: InputProps) => {
  return (
    <Input
      {...props}
      as={MaskedInput}
      mask={CNPJMask}
      placeholder="__.___.___/____-__"
    />
  );
};

export default CNPJInput;
