import i18n from '../../common/configuration/i18n';

i18n.addResourceBundle('en', 'crypto', {
  Crypto: 'Crypto',
  'Buy, sell and manage your crypto assets':
    'Buy, sell and manage your crypto assets',
});

i18n.addResourceBundle('ptbr', 'crypto', {
  Crypto: 'Criptomoedas',
  'Buy, sell and manage your crypto assets':
    'Compre, venda e gerencie seus ativos de criptomoeda',
});
