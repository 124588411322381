var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Olá "
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":24}}}) : helper)))
    + ",</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      ><br />\n      Sua transferência foi concluída e os valores foram enviados para sua conta\n      bancaria. Seguem os comprovantes da operação em anexo.</span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <br />\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Valor em moeda estrangeira: "
    + alias4(((helper = (helper = lookupProperty(helpers,"SOURCE_AMOUNT") || (depth0 != null ? lookupProperty(depth0,"SOURCE_AMOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SOURCE_AMOUNT","hash":{},"data":data,"loc":{"start":{"line":16,"column":38},"end":{"line":16,"column":57}}}) : helper)))
    + "</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Valor em moeda nacional: "
    + alias4(((helper = (helper = lookupProperty(helpers,"DESTINATION_AMOUNT") || (depth0 != null ? lookupProperty(depth0,"DESTINATION_AMOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DESTINATION_AMOUNT","hash":{},"data":data,"loc":{"start":{"line":19,"column":35},"end":{"line":19,"column":59}}}) : helper)))
    + "</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Dados bancários: "
    + alias4(((helper = (helper = lookupProperty(helpers,"DESTINATION_BANK_ACCOUNT") || (depth0 != null ? lookupProperty(depth0,"DESTINATION_BANK_ACCOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DESTINATION_BANK_ACCOUNT","hash":{},"data":data,"loc":{"start":{"line":22,"column":27},"end":{"line":22,"column":57}}}) : helper)))
    + "</span>\n  </div>\n</div>\n";
},"useData":true});