import { useTranslation } from 'react-i18next';
import {
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  FlexProps,
} from '@chakra-ui/react';
import {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { CepInput } from '../../../common/components/CepInput';
import { SignUpSchema } from '../types/signUpSchema';
import '../SignUp.language';

export type SignUpAddressDataProps = {
  flexFieldProps: FlexProps;
  touched: FormikTouched<SignUpSchema>;
  errors: FormikErrors<SignUpSchema>;
  getFieldProps: (
    nameOrOptions:
      | string
      | FieldConfig<string | number | readonly string[] | undefined>
  ) => FieldInputProps<string | number | readonly string[] | undefined>;
};

const SignUpAddressData = ({
  flexFieldProps,
  touched,
  errors,
  getFieldProps,
}: SignUpAddressDataProps) => {
  const { t } = useTranslation('signup');
  return (
    <>
      <Heading size="md">{t('Address data')}</Heading>
      <Flex {...flexFieldProps}>
        <Box>
          <FormControl
            id="zipCode"
            isRequired
            isInvalid={
              (touched.individualPostalCode && !!errors.individualPostalCode) ||
              false
            }
          >
            <FormLabel>{t('Zip Code')}</FormLabel>
            <CepInput type="text" {...getFieldProps('individualPostalCode')} />
            <FormErrorMessage>{errors.individualPostalCode}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
      <Flex {...flexFieldProps}>
        <Box minW={{ base: 'unset', md: '400px' }}>
          <FormControl
            id="address"
            isRequired
            isInvalid={
              (touched.individualStreet && !!errors.individualStreet) || false
            }
          >
            <FormLabel>{t('Street')}</FormLabel>
            <Input type="text" {...getFieldProps('individualStreet')} />
            <FormErrorMessage>{errors.individualStreet}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
      <Flex {...flexFieldProps}>
        <Box>
          <FormControl
            id="city"
            isRequired
            isInvalid={
              (touched.individualCity && !!errors.individualCity) || false
            }
          >
            <FormLabel>{t('City')}</FormLabel>
            <Input type="text" {...getFieldProps('individualCity')} />
            <FormErrorMessage>{errors.individualCity}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl
            id="state"
            isRequired
            isInvalid={
              (touched.individualSubdivision &&
                !!errors.individualSubdivision) ||
              false
            }
          >
            <FormLabel>{t('State')}</FormLabel>
            <Input type="text" {...getFieldProps('individualSubdivision')} />
            <FormErrorMessage>{errors.individualSubdivision}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
    </>
  );
};

export default SignUpAddressData;
