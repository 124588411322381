import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  Box,
  Flex,
  Heading,
  Stack,
  useColorModeValue,
  Text,
  Button,
} from '@chakra-ui/react';

interface CardProps {
  heading: string;
  description: string;
  icon: ReactElement;
  href: string;
}

const FeatureCard = ({ heading, description, icon, href }: CardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      maxW={{ base: 'full', md: '275px' }}
      w={'full'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      backgroundColor="white"
      p={5}
    >
      <Stack align={'start'} spacing={2} alignItems="center">
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          justifyContent={'center'}
          alignItems={'center'}
          color={'white'}
          rounded={'full'}
          bg={useColorModeValue('gray.100', 'gray.700')}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md">{heading}</Heading>
          <Text mt={1} fontSize={'sm'}>
            {description}
          </Text>
          <Button
            variant={'link'}
            colorScheme={'blue'}
            size={'sm'}
            onClick={() => navigate(href)}
          >
            {t('Learn more')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default FeatureCard;
