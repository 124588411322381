import React, { useContext } from 'react';
import { AuthContext } from '../../../contexts/AuthContext/AuthContext';

const useAccount = () => {
  const [backgroundImage] = React.useState<string>(
    'https://images.unsplash.com/photo-1612865547334-09cb8cb455da?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80'
  );
  const [userImage] = React.useState<string>('assets/images/user.png');

  const { user } = useContext(AuthContext);

  return { backgroundImage, userImage, user };
};

export default useAccount;
