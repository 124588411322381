import { ReactElement, ReactNode } from 'react';
import { DownloadIcon } from '@chakra-ui/icons';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  TableCellProps,
  TableColumnHeaderProps,
  TableRowProps,
  Skeleton,
} from '@chakra-ui/react';
import { ChakraColors } from '../../configuration/ChakraColors';

export type TableGridDataRowProps = {
  cells: TableGridDataItemProps[];
} & TableRowProps;

export type TableGridDataItemProps = {
  content: ReactElement | ReactNode | string;
} & TableCellProps;

export type TableGridHeaderProps = {
  content: ReactElement | ReactNode | string;
} & TableColumnHeaderProps;

export type TableGridProps = {
  headers?: TableGridHeaderProps[];
  rows?: TableGridDataRowProps[];
  isLoading: boolean;
};

const TableGrid = ({ headers, rows, isLoading }: TableGridProps) => {
  return (
    <TableContainer>
      <Table
        variant="striped"
        colorScheme="primary"
        minW={{ base: '400px', md: '720px' }}
      >
        <Thead>
          <tr>
            <Td colSpan={headers?.length ?? 2}>
              <Flex justifyContent="flex-end">
                <DownloadIcon
                  _hover={{ cursor: 'pointer', color: ChakraColors.hover }}
                />
              </Flex>
            </Td>
          </tr>
        </Thead>
        <Thead>
          <Tr>
            {headers?.map((item, index) => (
              <Th key={index} {...item}>
                {item.content}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isLoading &&
            Array.of(1, 2, 3).map((item, index) => (
              <Tr key={index}>
                <Td colSpan={headers?.length}>
                  <Skeleton height="20px" />
                </Td>
              </Tr>
            ))}
          {!isLoading &&
            rows?.map((item, index) => (
              <Tr key={index}>
                {item.cells.map((itemRow, indexRow) => (
                  <Td key={indexRow} {...itemRow}>
                    {itemRow.content}
                  </Td>
                ))}
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableGrid;
