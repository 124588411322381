import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@chakra-ui/react';
import './../../../../Invoice.language';
import { IInvoiceConfigDto } from '@waygee/shared-types';
import { InvoicePreview } from '../../../../../../common/components/InvoicePreview';

export type InvoiceConfigPreviewProps = {
  onClose: () => void;
  isOpen: boolean;
  invoiceData: Partial<IInvoiceConfigDto>;
};

const InvoiceConfigPreview = ({
  isOpen,
  onClose,
  invoiceData,
}: InvoiceConfigPreviewProps) => {
  const { t } = useTranslation('invoice');
  const invoicePreviewData = {
    invoiceTitle: invoiceData.title ?? 'My Company',
    invoiceSubTitle: invoiceData.subtitle ?? '',
    payerName: 'My Customer',
    invoiceNumber: '#21',
    invoiceDate: '05/01/2024',
    invoiceServiceDescription: 'My service',
    invoiceAmount: 'US5,000',
    payerAddress1: '7200 street',
    payerAddress2: 'Orlando, Florida',
    payerPhone: '+1 12345678',
    payerEmail: 'cliente@email.com',
    bankAccountDetailsLine1: 'Account number: 1222222',
    bankAccountDetailsLine2: 'Routing number: 1222222',
    bankAccountDetailsLine3: '',
    invoiceColor: invoiceData.color ?? '#9900EF',
  };
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Invoice Preview')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody width={{ base: 'unset', md: '720px' }}>
          <InvoicePreview invoiceData={invoicePreviewData} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InvoiceConfigPreview;
