import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

export type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (id?: string | number) => void;
  title: string;
  description: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  id?: string | number;
  confirmButtonColorSchema?: string;
  cancelButtonColorSchema?: string;
};

const ConfirmationModal = ({
  isOpen,
  onClose,
  cancelButtonLabel,
  confirmButtonLabel,
  description,
  onConfirm,
  title,
  id,
  confirmButtonColorSchema = 'red',
  cancelButtonColorSchema = 'gray',
}: ConfirmationModalProps) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{description}</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              colorScheme={cancelButtonColorSchema}
            >
              {cancelButtonLabel}
            </Button>
            <Button
              colorScheme={confirmButtonColorSchema}
              onClick={() => onConfirm(id)}
              ml={3}
            >
              {confirmButtonLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ConfirmationModal;
