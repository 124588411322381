export class RequestLock {
  static isLocked = false;
  static requestCount = 0;

  static async lock() {
    if (this.isLocked) return;
    this.isLocked = true;
    await this.waitForRequestsDone();
  }

  static unlock() {
    this.isLocked = false;
  }

  static newRequest() {
    this.requestCount++;
  }

  static requestDone() {
    this.requestCount--;
  }

  static async waitForRequestsDone() {
    await this.waitMs(100); // wait for eventual parallel requestCount changes
    if (this.requestCount === 0) return;
    await this.waitMs(100);
    await this.waitForRequestsDone();
  }

  static async waitForUnlock() {
    await this.waitMs(100); // wait for eventual parallel lock() calls
    if (!this.isLocked) return;
    await this.waitMs(100);
    await this.waitForUnlock();
  }

  private static waitMs(ms: number) {
    return new Promise<void>((resolve) => setTimeout(resolve, ms));
  }
}
