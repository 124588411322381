import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { Flex, useDisclosure, useToast } from '@chakra-ui/react';
import {
  ICryptoCurrencyDto,
  ICryptoOfferDto,
  ICryptoProviderDto,
} from '@waygee/shared-types';
import { debounce } from 'lodash';
import { getRequestConfig, ApiNames } from '../../../../../common/apis';
import { CryptoWalletFieldProps } from '../../../../../common/components/CryptoWalletField';
import { ExchangeCurrencyFieldProps } from '../../../../../common/components/ExchangeCurrencyField';
import { axiosCustomInstance } from '../../../../../common/network';
import { OptionItem } from '../../../../../common/types';
import { getPaymentMethodsList } from '../data';
import { CryptoPaymentMethod, CryptoTransactionDetails } from '../types';
import '../../../../../common/languages/BuyCrypto.language';

const DEFAULT_FIAT = 'BRL';
const DEFAULT_CRYPTO = 'BTC';
const DEFAULT_AMOUNT = '1000';

const useBuyCrypto = () => {
  const [fiatCurrencies, setFiatCurrencies] = useState<OptionItem[]>([]);
  const [cryptoCurrencies, setCryptoCurrencies] = useState<OptionItem[]>([]);
  const [offers, setOffers] = useState<ICryptoOfferDto[]>([]);
  const [selectedFiatCurrency, setSelectedFiatCurrency] =
    useState<string>(DEFAULT_FIAT);
  const [selectedCryptoCurrency, setSelectedCryptoCurrency] =
    useState<string>(DEFAULT_CRYPTO);
  const [isOfferLoading, setIsOfferLoading] = useState<boolean>(false);
  const [amountField, setAmountField] = useState<string>(DEFAULT_AMOUNT);
  const [amountFrom, setAmountFrom] = useState<string>(DEFAULT_AMOUNT);
  const [bestOffer, setBestOffer] = useState<ICryptoOfferDto | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<CryptoPaymentMethod[]>(
    []
  );
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const [providers, setProviders] = useState<ICryptoProviderDto[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | undefined
  >();
  const [selectedProvider, setSelectedProvider] = useState<
    string | undefined
  >();
  const [amountExpectedFrom, setAmountExpectedFrom] = useState<string>('0');
  const [cryptoWalletAddress, setCryptoWalletAddress] = useState<string>('');
  const [isValidatingCryptoWalletAddress, setIsValidatingCryptoWalletAddress] =
    useState<boolean>(false);
  const [isCryptoWalletAddressValid, setIsCryptoWalletAddressValid] =
    useState<boolean>(false);
  const [orderCreated, setOrderCreated] = useState<boolean>(false);
  const toast = useToast();
  const { t } = useTranslation('buy-crypto');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [providerUrl, setProviderUrl] = useState<string | undefined>();

  const getCurrencyLabel = (currency: ICryptoCurrencyDto) => {
    return (
      <Flex flexDir="row" gap="5px">
        <img src={currency.iconUrl} alt="currency" width="20" height="20" />
        {currency.ticker} - {currency.name}
      </Flex>
    );
  };

  const handleCleanWalletAddress = () => {
    setCryptoWalletAddress('');
    setIsCryptoWalletAddressValid(false);
  };

  const handleTermsChange = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  const handleCryptoWalletAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCryptoWalletAddress(e.target.value);
    validateCryptoWalletAddress(e.target.value);
  };

  const handleChangeSelectedPaymentMethod = (method: string) => {
    setSelectedPaymentMethod(method);
    const selectedProviderHasPaymentMethod = offers
      ?.find((offer) => offer.providerCode === selectedProvider)
      ?.paymentMethodOffer?.some((c) => c.method === method);

    if (!selectedProviderHasPaymentMethod) {
      const firstProviderWithPaymentMethod = offers?.find((offer) =>
        offer.paymentMethodOffer?.some((c) => c.method === method)
      );
      setSelectedProvider(firstProviderWithPaymentMethod?.providerCode);
    }
  };

  const handleSubmitOrder = () => {
    const promise = axiosCustomInstance
      .request(
        getRequestConfig({
          endpoint: ApiNames.CREATE_CRYPTO_ORDER,
          data: {
            providerCode: selectedProvider,
            currencyFrom: selectedFiatCurrency,
            currencyTo: selectedCryptoCurrency,
            amountFrom: amountField,
            walletAddress: cryptoWalletAddress,
          },
        })
      )
      .then((response) => {
        window.open(response.data.redirectUrl, '_blank');
        setProviderUrl(response.data.redirectUrl);
        setOrderCreated(true);
        onClose();
      })
      .catch((error) => {
        throw error;
      });

    toast.promise(promise, {
      success: {
        title: t('Order validated'),
        description: t('Redirecting you to the payment page...'),
        isClosable: true,
      },
      error: {
        title: t('Order not validated'),
        description: t('Please try again with a valid order'),
        isClosable: true,
      },
      loading: {
        title: t('Validating order'),
        description: t('Please wait...'),
        isClosable: true,
      },
    });
  };

  const validateCryptoWalletAddress = (address: string) => {
    setIsValidatingCryptoWalletAddress(true);
    const promise = axiosCustomInstance
      .request(
        getRequestConfig({
          endpoint: ApiNames.GET_CRYPTO_WALLET_ADDRESS_VALIDATION,
          params: selectedCryptoCurrency,
          query: { address: address },
        })
      )
      .then((response) => {
        setIsCryptoWalletAddressValid(true);
      })
      .catch((error) => {
        setIsCryptoWalletAddressValid(false);
        throw error;
      })
      .finally(() => setIsValidatingCryptoWalletAddress(false));

    toast.promise(promise, {
      success: {
        title: t('Address validated'),
        description: t('You can use this address'),
        isClosable: true,
      },
      error: {
        title: t('Address not validated'),
        description: t('Please try again with a valid address'),
        isClosable: true,
      },
      loading: {
        title: t('Validating address'),
        description: t('Please wait...'),
        isClosable: true,
      },
    });
  };

  const selectedPaymentMethodOffer = offers
    ?.find((offer) => offer.providerCode === selectedProvider)
    ?.paymentMethodOffer?.find((c) => c.method === selectedPaymentMethod);

  const invertedRateFormatted = selectedPaymentMethodOffer
    ? Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: selectedFiatCurrency,
      }).format(Number(selectedPaymentMethodOffer.invertedRate))
    : '0';

  const networkFeeFormatted = selectedPaymentMethodOffer
    ? Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: selectedFiatCurrency,
      }).format(Number(selectedPaymentMethodOffer.fee))
    : '0';

  const amountYouSendFormatted = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: selectedFiatCurrency,
  }).format(Number(amountField));

  const transactionDetails: CryptoTransactionDetails = {
    amountSend: `${amountYouSendFormatted} ${selectedFiatCurrency}`,
    amountGet: `${amountExpectedFrom} ${selectedCryptoCurrency}`,
    paymentMethod: selectedPaymentMethod
      ? getPaymentMethodsList?.find((c) => c.method === selectedPaymentMethod)
          ?.methodName
      : '',
    networkFee: `${networkFeeFormatted} ${selectedFiatCurrency}`,
    exchangeRate: selectedPaymentMethodOffer
      ? `1 ${selectedCryptoCurrency} = ${invertedRateFormatted} ${selectedFiatCurrency}`
      : '0',
    currencySend: selectedFiatCurrency,
    currencyGet: selectedCryptoCurrency,
  };

  const handleChangeSelectedProvider = useCallback(
    (provider: string) => {
      setSelectedProvider(provider);
      const providerAmountExpectedTo = offers
        ?.find((offer) => offer.providerCode === provider)
        ?.paymentMethodOffer?.find(
          (c) => c.method === selectedPaymentMethod
        )?.amountExpectedTo;
      if (providerAmountExpectedTo)
        setAmountExpectedFrom(providerAmountExpectedTo);
    },
    [offers, selectedPaymentMethod]
  );

  const handleChangeFiatCurrency = (currency: SingleValue<OptionItem>) => {
    if (currency) setSelectedFiatCurrency(currency?.value ?? DEFAULT_FIAT);
  };

  const handleChangeCryptoCurrency = (currency: SingleValue<OptionItem>) => {
    if (currency) setSelectedCryptoCurrency(currency?.value ?? DEFAULT_CRYPTO);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmountField(e.target.value);
  };

  const updateAmountFrom = useCallback(() => {
    setAmountFrom(amountField);
  }, [amountField]);

  const debouncedLoadOffer = useMemo(
    () => debounce(updateAmountFrom, 500),
    [updateAmountFrom]
  );

  const loadOffer = useCallback(() => {
    setIsOfferLoading(true);
    axiosCustomInstance
      .request<ICryptoOfferDto[]>(
        getRequestConfig({
          endpoint: ApiNames.GET_CRYPTO_OFFERS,
          data: {
            currencyFrom: selectedFiatCurrency,
            currencyTo: selectedCryptoCurrency,
            amountFrom: amountFrom,
          },
        })
      )
      .then(({ data }) => {
        setOffers(data);
      })
      .finally(() => setIsOfferLoading(false));
  }, [amountFrom, selectedCryptoCurrency, selectedFiatCurrency]);

  const loadProviders = useCallback(() => {
    axiosCustomInstance
      .request<ICryptoProviderDto[]>(
        getRequestConfig({
          endpoint: ApiNames.GET_CRYPTO_PROVIDERS,
        })
      )
      .then(({ data }) => {
        setProviders(data);
      });
  }, []);

  const loadCurrencies = useCallback(() => {
    axiosCustomInstance
      .request<ICryptoCurrencyDto[]>(
        getRequestConfig({
          endpoint: ApiNames.GET_ALL_CRYPTO_CURRENCIES,
        })
      )
      .then(({ data }) => {
        const fiatCurrencies: OptionItem[] = data
          .filter((c) => c.type === 'fiat')
          .map((currency) => ({
            value: currency.ticker,
            label: getCurrencyLabel(currency),
          }));
        setFiatCurrencies(fiatCurrencies);

        const cryptoCurrencies: OptionItem[] = data
          .filter((c) => c.type === 'crypto')
          .map((currency) => ({
            value: currency.ticker,
            label: getCurrencyLabel(currency),
          }));
        setCryptoCurrencies(cryptoCurrencies);
      });
  }, []);

  const loadBestOffer = useCallback(() => {
    if (offers.length > 0) {
      const bestOffer = offers
        .filter((c) => Number(c.amountExpectedTo) > 0)
        ?.reduce(
          (prev, current) =>
            parseFloat(prev.amountExpectedTo) >
            parseFloat(current.amountExpectedTo)
              ? prev
              : current,
          offers[0]
        );
      setBestOffer(bestOffer);
      setSelectedProvider(bestOffer.providerCode);
      setAmountExpectedFrom(bestOffer.amountExpectedTo);
    } else {
      setBestOffer(null);
    }
  }, [offers]);

  const loadPaymentMethods = useCallback(() => {
    const paymentMethods: CryptoPaymentMethod[] = getPaymentMethodsList.map(
      ({ method, image }) => {
        return {
          method,
          methodName: method,
          image,
        } as CryptoPaymentMethod;
      }
    );
    setPaymentMethods(paymentMethods);
    setSelectedPaymentMethod(paymentMethods[0].method);
  }, []);

  const exchangeCurrencyFieldProps: ExchangeCurrencyFieldProps = {
    fiatCurrencies,
    cryptoCurrencies,
    selectedCryptoCurrency,
    selectedFiatCurrency,
    handleChangeCryptoCurrency,
    handleChangeFiatCurrency,
    amountField,
    handleAmountChange,
    amountExpectedFrom: Number(amountExpectedFrom),
    isOfferLoading,
  };

  const cryptoWalletFieldProps: CryptoWalletFieldProps = {
    cryptoWalletAddress,
    isCryptoWalletAddressValid,
    isValidatingCryptoWalletAddress,
    handleCryptoWalletAddressChange,
    handleCleanWalletAddress,
  };

  useEffect(() => {
    loadPaymentMethods();
  }, [offers, loadPaymentMethods]);

  useEffect(() => {
    loadOffer();
  }, [loadOffer]);

  useEffect(() => {
    loadCurrencies();
  }, [loadCurrencies]);

  useEffect(() => {
    loadBestOffer();
  }, [loadBestOffer]);

  useEffect(() => {
    loadProviders();
  }, [loadProviders]);

  useEffect(() => {
    if (selectedProvider) handleChangeSelectedProvider(selectedProvider);
  }, [handleChangeSelectedProvider, selectedProvider]);

  useEffect(() => {
    if (amountField.length > 0) {
      debouncedLoadOffer();
    }
    return debouncedLoadOffer.cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountField]);

  return {
    fiatCurrencies,
    cryptoCurrencies,
    selectedCryptoCurrency,
    selectedFiatCurrency,
    handleChangeCryptoCurrency,
    handleChangeFiatCurrency,
    offers,
    isOfferLoading,
    amountField,
    handleAmountChange,
    bestOffer,
    paymentMethods,
    selectedPaymentMethod,
    handleChangeSelectedPaymentMethod,
    providers,
    selectedProvider,
    handleChangeSelectedProvider,
    amountExpectedFrom,
    transactionDetails,
    cryptoWalletAddress,
    handleCryptoWalletAddressChange,
    isCryptoWalletAddressValid,
    isOpen,
    onOpen,
    onClose,
    handleSubmitOrder,
    orderCreated,
    isValidatingCryptoWalletAddress,
    providerUrl,
    handleCleanWalletAddress,
    isTermsChecked,
    handleTermsChange,
    exchangeCurrencyFieldProps,
    cryptoWalletFieldProps,
  };
};

export default useBuyCrypto;
