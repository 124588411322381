export enum Currencies {
  USD = 'usd',
  EUR = 'eur',
  BRL = 'brl',
  MXN = 'mxn',
  ZAR = 'zar',
  CHF = 'chf',
  CAD = 'cad',
  SEK = 'sek',
  JPY = 'jpy',
  NZD = 'nzd',
  NOK = 'nok',
  AUD = 'aud',
  DKK = 'dkk',
  GBP = 'gbp',
}

export enum Countries {
  BRAZIL = 'br',
  GERMANY = 'de',
  ARGENTINA = 'ar',
  AUSTRALIA = 'au',
  BELGIUM = 'be',
  CANADA = 'ca',
  CHILE = 'cl',
  CHINA = 'cn',
  SINGAPORE = 'sg',
  COLOMBIA = 'co',
  SOUTH_KOREA = 'kr',
  DENMARK = 'dk',
  SPAIN = 'es',
  UNITED_STATES = 'us',
  TAIWAN = 'tw',
  FRANCE = 'fr',
  HONG_KONG = 'hk',
  INDIA = 'in',
  ISRAEL = 'il',
  ITALY = 'it',
  JAPAN = 'jp',
  MEXICO = 'mx',
  NETHERLANDS = 'nl',
  PARAGUAY = 'py',
  PERU = 'pe',
  PUERTO_RICO = 'pr',
  PORTUGAL = 'pt',
  UNITED_KINGDOM = 'gb',
  SWITZERLAND = 'ch',
  THAILAND = 'th',
  URUGUAY = 'uy',
}

export enum Finalities {
  COMMISSION_AND_SERVICE_REFUND = 'commission and service refund',
  LOAN = 'loan',
  FREIGHT = 'freight',
  INVESTMENT = 'investment',
  MERCHANDISE = 'merchandise',
  OTHER_NON_SERVICE_RELATED_PAYMENTS = 'other non-service related payments',
  CONSULTING_LEGAL_ACCOUNTING_AND_AUDIT_SERVICES = 'consulting legal accounting and audit services',
  MARKETING_ADVERTISING_AND_ENGINEERING_SERVICES = 'marketing advertising and engineering services',
  COMPUTER_IT_SERVICES = 'computer it services',
}

export enum UserLockContexts {
  MFA = 'mfa',
}

export enum AccountLockContexts {
  PAYMENTS = 'payments',
}
