import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Center, useDisclosure, useToast } from '@chakra-ui/react';

import { BRAZILIAN_BANKS } from '@waygee/common';
import { IBankAccountDto } from '@waygee/shared-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ApiNames, getRequestConfig } from '../../../common/apis/ApiRoutes';
import { AutoCompleteProps } from '../../../common/components/AutoComplete';
import {
  TableGridDataRowProps,
  TableGridProps,
} from '../../../common/components/TableGrid/TableGrid';
import { ChakraColors } from '../../../common/configuration/ChakraColors';
import { axiosCustomInstance } from '../../../common/network';
import { LocalAccountFormProps } from '../components/LocalAccount/components/LocalAccountForm';
import { LocalAccountProps } from '../components/LocalAccount/LocalAccount';

const useLocalAccount = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [idToDelete, setIdToDelete] = useState<string>('');

  const tableHeaders = useMemo(
    () => [
      {
        content: t('Bank Name'),
      },
      {
        content: t('Account Number'),
      },
      {
        content: t('Branch Code'),
      },
      {
        content: t('Account Type'),
      },
      {
        content: t('Actions'),
      },
    ],
    [t]
  );

  const validationSchema = Yup.object<Partial<IBankAccountDto>>({
    accountNumber: Yup.string().required(t('Account number is required')),
    branchCode: Yup.string().required(t('Branch code is required')),
    bankCode: Yup.string().required(t('Bank code is required')),
  });

  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      accountNumber: '',
      bankCode: '',
      branchCode: '',
      accountType: 'checking',
    },
    validationSchema,
    onSubmit: (values) => {
      const request = getRequestConfig({
        endpoint: ApiNames.CREATE_BANK_ACCOUNT,
        data: {
          ...values,
          accountType: 'checking',
        },
      });
      const promise = axiosCustomInstance.request(request).then((response) => {
        setIsLocalAccountFormOpen(false);
        formik.resetForm();
        loadUserBankAccounts();
      });
      toast.promise(promise, {
        success: {
          title: t('Success!'),
          description: t('Bank account saved successful'),
          isClosable: true,
        },
        error: {
          title: t('Bank account creation failed'),
          description: t('Something wrong'),
          isClosable: true,
        },
        loading: {
          title: t('Creating Bank account'),
          description: t('Please wait...'),
          isClosable: true,
        },
      });
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [tableGridProps, setTableGridProps] = useState<TableGridProps>({
    isLoading,
  });
  const [bankAccounts, setBankAccounts] = useState<
    IBankAccountDto | undefined
  >();
  const [isLocalAccountFormOpen, setIsLocalAccountFormOpen] =
    useState<boolean>(false);

  const loadUserBankAccounts = useCallback(() => {
    setIsLoading(true);
    axiosCustomInstance
      .request<IBankAccountDto>(
        getRequestConfig({
          endpoint: ApiNames.GET_ALL_BANK_ACCOUNTS,
        })
      )
      .then((res) => setBankAccounts(res.data))
      .catch(() => setBankAccounts(undefined))
      .finally(() => setIsLoading(false));
  }, []);

  const handleRemoveBankAccountConfirmation = useCallback(
    (id: string) => {
      onOpen();
      setIdToDelete(id);
    },
    [onOpen]
  );

  const handleRemoveBankAccount = useCallback(
    (id?: string | number) => {
      const promise = axiosCustomInstance
        .request(
          getRequestConfig({
            endpoint: ApiNames.DELETE_BANK_ACCOUNT,
            params: id,
          })
        )
        .then(() => {
          loadUserBankAccounts();
        })
        .finally(() => {
          setIdToDelete('');
          onClose();
        });
      toast.promise(promise, {
        success: {
          title: t('Success!'),
          description: t('Bank account deleted successful'),
          isClosable: true,
        },
        error: {
          title: t('Bank account deletion failed'),
          description: t('Something wrong'),
          isClosable: true,
        },
        loading: {
          title: t('Deleting Bank account'),
          description: t('Please wait...'),
          isClosable: true,
        },
      });
    },
    [loadUserBankAccounts, onClose, t, toast]
  );

  const {
    getFieldProps,
    touched,
    errors,
    handleSubmit,
    values,

    setTouched,
    setValues,
  } = formik;

  const brazilianBankOptions = useMemo(
    () =>
      BRAZILIAN_BANKS.map((bank) => ({
        id: bank.code ?? '',
        name: `${bank.code} - ${bank.name}`,
      })),
    []
  );

  const autoCompleteProps: AutoCompleteProps = {
    placeholder: t('Search for a bank'),
    items: brazilianBankOptions,
    width: '380px',
    onSelectItem: (item) => {
      setTouched({ bankCode: true });

      setValues({
        ...values,
        bankCode: item.id.toString(),
      });
    },
    onReset: () => {
      setTouched({ bankCode: true });
      setValues({
        ...values,
        bankCode: '',
      });
    },
  };

  const localAccountFormProps: LocalAccountFormProps = {
    handleSubmit,
    isOpen: isLocalAccountFormOpen,
    isSubmitDisabled: false,
    onClose: () => setIsLocalAccountFormOpen(false),
    touched,
    errors,
    getFieldProps,
    autoCompleteProps,
  };

  const addRow = useCallback(() => {
    return {
      cells: [
        {
          content: (
            <Center>
              <Button
                variant={'outline'}
                colorScheme="primary"
                onClick={() => setIsLocalAccountFormOpen(true)}
              >
                {t('Add new Bank Account')}
              </Button>
            </Center>
          ),
          colSpan: 6,
        },
      ],
    } as TableGridDataRowProps;
  }, [t]);

  useEffect(() => {
    const rowsWithData = [
      {
        cells: [
          {
            content: bankAccounts?.bankName,
          },
          {
            content: bankAccounts?.accountNumber,
          },
          {
            content: bankAccounts?.branchCode,
          },
          {
            content: t(bankAccounts?.accountType ?? ''),
          },
          {
            content: (
              <DeleteIcon
                onClick={() =>
                  handleRemoveBankAccountConfirmation(bankAccounts?.id ?? '')
                }
                color={ChakraColors.info}
                _hover={{
                  cursor: 'pointer',
                  color: ChakraColors.hover,
                }}
              />
            ),
          },
        ],
      },
    ] as TableGridDataRowProps[];
    const props = {
      isLoading,
      headers: tableHeaders,
      rows: [addRow(), ...rowsWithData],
    };
    setTableGridProps(props);
  }, [
    addRow,
    bankAccounts,
    handleRemoveBankAccountConfirmation,
    isLoading,
    t,
    tableHeaders,
  ]);

  const localAccountProps: LocalAccountProps = {
    tableGridProps,
    localAccountFormProps,
    isOpen,
    onClose,
    onDeleteConfirm: handleRemoveBankAccount,
    id: idToDelete,
  };

  useEffect(() => {
    loadUserBankAccounts();
  }, [loadUserBankAccounts]);

  return {
    localAccountProps,
    localAccountFormProps,
    onClose,
    onOpen,
    isOpen,
  };
};
export default useLocalAccount;
