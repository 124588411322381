export enum EmailTemplates {
  // auth
  EMAIL_SIGNUP = 'SIGNUP',
  EMAIL_MFA = 'MFA',
  EMAIL_RESET_PASSWORD = 'RESET_PASSWORD',
  // contact
  EMAIL_CONTACT_US = 'CONTACT_US',
  // invoices
  EMAIL_INVOICE_TO_PAYER = 'INVOICE_TO_PAYER',
  // onboarding
  EMAIL_ONBOARDING_WELCOME = 'ONBOARDING_WELCOME',
  EMAIL_ONBOARDING_SENT_TO_REVIEW = 'ONBOARDING_SENT_TO_REVIEW',
  EMAIL_ONBOARDING_REVIEW_PENDING_INFO = 'ONBOARDING_REVIEW_PENDING_INFO',
  EMAIL_ONBOARDING_REVIEW_APPROVED = 'ONBOARDING_REVIEW_APPROVED',
  // orders
  EMAIL_ORDER_AVAILABLE = 'ORDER_AVAILABLE',
  EMAIL_ORDER_SENT = 'ORDER_SENT',
  EMAIL_ORDER_PAID = 'ORDER_PAID',
}

export interface IEmailTemplateMfaTemplateData {
  SIGN_IN_CODE: string;
}

export interface IEmailTemplateInvoiceToPayerTemplateData {
  PAYER_NAME: string;
  CUSTOMER_NAME: string;
  CUSTOMER_EMAIL: string;
  INVOICE_DATE: string;
  INVOICE_NUMBER: string;
  INVOICE_AMOUNT: string;
}

export interface IEmailTemplateContactUsTemplateData {
  NAME: string;
  PHONE: string;
  EMAIL: string;
  MSG_CONTENT: string;
}

export interface IEmailTemplateSignupTemplateData {
  SIGNUP_REDIRECT_URL: string;
}

export interface IEmailTemplateResetPasswordTemplateData {
  RESET_PASSWORD_REDIRECT_URL: string;
}

export interface IEmailTemplateOnboardingWelcomeTemplateData {
  NAME: string;
  URL_LOGIN: string;
}

export interface IEmailTemplateOnboardingSentToReviewTemplateData {
  NAME: string;
}

export interface IEmailTemplateOnboardingReviewPendingInfoTemplateData {
  NAME: string;
  PENDING_LIST: string; // html
}

export interface IEmailTemplateOnboardingReviewApprovedTemplateData {
  NAME: string;
}

export interface IEmailTemplateOrderAvailableTemplateData {
  NAME: string;
  AMOUNT: string;
}

export interface IEmailTemplateOrderSentTemplateData {
  NAME: string;
  SOURCE_AMOUNT: string;
  DESTINATION_AMOUNT: string;
  DESTINATION_BANK_ACCOUNT: string;
  RATE: string;
  IOF: string;
  IR: string;
  FEE: string;
  SPREAD: string;
  VET: string;
}

export interface IEmailTemplateOrderPaidTemplateData {
  NAME: string;
  SOURCE_AMOUNT: string;
  DESTINATION_AMOUNT: string;
  DESTINATION_BANK_ACCOUNT: string;
}

export interface IEmailTemplatesDataTypes {
  [EmailTemplates.EMAIL_MFA]: IEmailTemplateMfaTemplateData;
  [EmailTemplates.EMAIL_INVOICE_TO_PAYER]: IEmailTemplateInvoiceToPayerTemplateData;
  [EmailTemplates.EMAIL_CONTACT_US]: IEmailTemplateContactUsTemplateData;
  [EmailTemplates.EMAIL_SIGNUP]: IEmailTemplateSignupTemplateData;
  [EmailTemplates.EMAIL_RESET_PASSWORD]: IEmailTemplateResetPasswordTemplateData;
  [EmailTemplates.EMAIL_ONBOARDING_WELCOME]: IEmailTemplateOnboardingWelcomeTemplateData;
  [EmailTemplates.EMAIL_ONBOARDING_SENT_TO_REVIEW]: IEmailTemplateOnboardingSentToReviewTemplateData;
  [EmailTemplates.EMAIL_ONBOARDING_REVIEW_PENDING_INFO]: IEmailTemplateOnboardingReviewPendingInfoTemplateData;
  [EmailTemplates.EMAIL_ONBOARDING_REVIEW_APPROVED]: IEmailTemplateOnboardingReviewApprovedTemplateData;
  [EmailTemplates.EMAIL_ORDER_AVAILABLE]: IEmailTemplateOrderAvailableTemplateData;
  [EmailTemplates.EMAIL_ORDER_SENT]: IEmailTemplateOrderSentTemplateData;
  [EmailTemplates.EMAIL_ORDER_PAID]: IEmailTemplateOrderPaidTemplateData;
}
