import React from 'react';
import MaskedInput from 'react-text-mask';
import { Input, InputProps } from '@chakra-ui/react';
import { PhoneMask } from '../../utility/formatters';

const PhoneInput = (props: InputProps) => {
  return (
    <Input
      {...props}
      as={MaskedInput}
      mask={PhoneMask}
      guide={false}
      placeholder="(xx) xxxxx-xxxx"
    />
  );
};

export default PhoneInput;
