var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Olá "
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":24}}}) : helper)))
    + ",</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      ><br />\n      Um pagamento no valor de "
    + alias4(((helper = (helper = lookupProperty(helpers,"AMOUNT") || (depth0 != null ? lookupProperty(depth0,"AMOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AMOUNT","hash":{},"data":data,"loc":{"start":{"line":8,"column":31},"end":{"line":8,"column":41}}}) : helper)))
    + " foi realizado em sua conta\n      Waygee.</span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <br />\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      >Acesse a plataforma agora mesmo para conferir o valor e cotar a\n      transferência para sua conta no Brasil.<br />\n      <br />\n    </span>\n  </div>\n\n  <div></div>\n</div>\n";
},"useData":true});