var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Olá "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":24}}}) : helper)))
    + ",</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      ><br />\n      Seja muito bem-vindo à Waygee! Estamos felizes em tê-lo(a) conosco.<br />\n      <br />\n      Agora que você criou sua conta, pedimos que complete os detalhes do seu\n      cadastro para aproveitar ao máximo nossa experiência. Isso nos ajudará a\n      personalizar nossos serviços de acordo com o seu perfil e com as normais\n      regulatórias.<br />\n      <br />\n      Para concluir seu cadastro, basta clicar no link abaixo e acessar sua\n      conta. Se surgir alguma dúvida ou se precisar de assistência, não hesite\n      em entrar em contato conosco através deste e-mail.<br />\n      <br />\n      Agradecemos por escolher nossa plataforma e estamos ansiosos para\n      proporcionar a você uma experiência incrível!<br />\n      <br />\n    </span>\n  </div>\n\n  <div></div>\n</div>\n";
},"useData":true});