import { Partners } from '../partners';

export enum ContactTypes {
  INDIVIDUAL = 'individual',
  BUSINESS = 'business',
}

export interface IContactPartnerData<TPartner extends Partners> {
  partner: TPartner;
}

export interface IOzCambioContactPartnerData
  extends IContactPartnerData<Partners.OZ_CAMBIO> {
  companyResponsibleId?: number;
}

interface IContactPartnerDataTypes {
  [Partners.OZ_CAMBIO]: IOzCambioContactPartnerData;
  [Partners.RENDIMENTO]: never;
}

export type ContactPartnerDataMap = {
  [key in Partners]?: IContactPartnerDataTypes[Partners];
};
