import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';
import {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { ChakraColors } from '../../../common/configuration/ChakraColors';
import { SignUpSchema } from '../types/signUpSchema';
import '../SignUp.language';

export type LeadDataProps = {
  boxPropsLead: BoxProps;
  handleLeadSubmit: () => void;
  isSubmitDisabled: boolean;
  isLoading: boolean;
  touched: FormikTouched<SignUpSchema>;
  errors: FormikErrors<SignUpSchema>;
  isLeadGenerated: boolean;
  getFieldProps: (
    nameOrOptions:
      | string
      | FieldConfig<string | number | readonly string[] | undefined>
  ) => FieldInputProps<string | number | readonly string[] | undefined>;
  disableSignup: boolean;
};

const LeadData = ({
  boxPropsLead,
  errors,
  getFieldProps,
  touched,
  handleLeadSubmit,
  isLoading,
  isSubmitDisabled,
  isLeadGenerated,
  disableSignup,
}: LeadDataProps) => {
  const { t } = useTranslation('signup');
  return (
    <>
      <Box {...boxPropsLead}>
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          wrap="wrap"
          gap="20px"
        >
          {isLeadGenerated && (
            <Flex flexDirection="column" gap="20px">
              <Heading size="md" color={ChakraColors.success}>
                {t('Check your e-mail')}!
              </Heading>
              <Text>{t('An email was sent with the confirmation link')}</Text>
              <Text>{t('Please check your inbox to continue')}</Text>
            </Flex>
          )}
          {!isLeadGenerated && (
            <>
              <Center>
                <Text>{t('Please, enter your e-mail')}</Text>
              </Center>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleLeadSubmit();
                }}
              >
                <Flex
                  flexDir="column"
                  gap="20px"
                  w={{ base: '280px', md: '350px' }}
                >
                  <FormControl
                    id="leadEmail"
                    isRequired
                    isInvalid={
                      (touched.leadEmail && !!errors.leadEmail) || false
                    }
                  >
                    <FormLabel>{t('Email address')}</FormLabel>
                    <Input type="leadEmail" {...getFieldProps('leadEmail')} />
                    <FormErrorMessage>{errors.leadEmail}</FormErrorMessage>
                  </FormControl>
                  <Button
                    rightIcon={<CheckIcon />}
                    type="submit"
                    loadingText="Submitting"
                    colorScheme="green"
                    _hover={{
                      bg: 'blue.500',
                    }}
                    isDisabled={isSubmitDisabled || isLoading || disableSignup}
                  >
                    {t('Sign Up')}
                  </Button>
                  {disableSignup && (
                    <Text color="red.500" fontSize="sm">
                      {t(
                        'Sign up is disabled. Please contact support for more information.'
                      )}
                    </Text>
                  )}
                </Flex>
              </form>
            </>
          )}
        </Flex>
      </Box>

      <Box {...boxPropsLead}>
        <Text fontSize="small">
          {t('Already a user?')}&nbsp;
          <Link href="/secure/login">{t('Sign in')}</Link>
        </Text>
      </Box>
    </>
  );
};

export default LeadData;
