import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Container, Flex, Icon } from '@chakra-ui/react';
import { FcDepartment } from 'react-icons/fc';
import { FeatureCard } from '../../common/components/FeatureCard';
import { PageContainer } from '../../common/components/PageContainer';
import { PaymentHistory } from './Components/PaymentHistory';
import usePaymentHistory from './hooks/usePaymentHistory';

const Statement = ({ children }: { children: ReactNode }) => ({
  children,
});

const Main = () => {
  const { t } = useTranslation();
  return (
    <PageContainer title={t('Statement')} subTitle={t('Your payouts overview')}>
      <Container maxW={'5xl'} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <FeatureCard
            heading={t('Banking Statements')}
            icon={<Icon as={FcDepartment} w={10} h={10} />}
            description={t('See your payout history and statuses here.')}
            href={'/secure/statement/transferences'}
          />
        </Flex>
      </Container>
    </PageContainer>
  );
};

const Transferences = () => {
  const { paymentHistoryProps } = usePaymentHistory();
  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('Banking Statements')}
      subTitle={t('Your payouts overview')}
    >
      <Container maxW={'4xl'} mt={12}>
        <Card padding="20px">
          <PaymentHistory {...paymentHistoryProps} />
        </Card>
      </Container>
    </PageContainer>
  );
};

const StatementPages = { Statement, Main, Transferences };
export default StatementPages;
