import i18n from '../../configuration/i18n';

i18n.addResourceBundle('en', 'layout-wrapper', {
  'Your session is about to expire': 'Your session is about to expire',
  'Are you still there?': 'Are you still there?',
  Logout: 'Logout',
  'Yes, keep me logged in': 'Yes, keep me logged in',
  'Your session will expire in {{countDownInSeconds}}':
    'Your session will expire in {{countDownInSeconds}}',
  'New login': 'New login',
  'Your session has expired': 'Your session has expired',
  'Finish your registration to start using Waygee. In order to execute exchange operations, we need a few more data from you.':
    'Finish your registration to start using Waygee. In order to execute exchange operations, we need a few more data from you.',
  'Registration pending to be completed':
    'Registration pending to be completed',
  'Complete registration': 'Complete registration',
  'Your registration is being reviewed. We will notify you when it is ready.':
    'Your registration is being reviewed. We will notify you when it is ready.',
  'View the progress': 'View the progress',
  'We need a few more data from you to finish your registration. Please, click on the button to complete your registration.':
    'We need a few more data from you to finish your registration. Please, click on the button to complete your registration.',
});

i18n.addResourceBundle('ptbr', 'layout-wrapper', {
  'Your session is about to expire': 'Sua sessão está prestes a expirar',
  'Are you still there?': 'Você ainda está aí?',
  Logout: 'Sair',
  'Yes, keep me logged in': 'Sim, mantenha-me conectado',
  'Your session will expire in {{countDownInSeconds}}':
    'Sua sessão expirará em {{countDownInSeconds}}',
  'New login': 'Entrar novamente',
  'Your session has expired': 'Sua sessão expirou',
  'Finish your registration to start using Waygee. In order to execute exchange operations, we need a few more data from you.':
    'Finalize seu registro para começar a usar o Waygee. Para executar operações de câmbio, precisamos confirmar mais algumas informações',
  'Registration pending to be completed': 'Cadastro pendente',
  'Complete registration': 'Finalizar cadastro',
  'Your registration is being reviewed. We will notify you when it is ready.':
    'Seu cadastro está sendo revisado. Notificaremos você quando estiver pronto.',
  'View the progress': 'Ver o progresso',
  'We need a few more data from you to finish your registration. Please, click on the button to complete your registration.':
    'Precisamos de mais algumas informações para finalizar seu cadastro. Clique no botão completa-las.',
});
