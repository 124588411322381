import i18n from '../../configuration/i18n';

i18n.addResourceBundle('en', 'copy-to-clipboard', {
  'Success!': 'Success!',
  'Copied to clipboard.': 'Copied to clipboard.',
});

i18n.addResourceBundle('ptbr', 'copy-to-clipboard', {
  'Success!': 'Sucesso!',
  'Copied to clipboard.': 'Copiado para a área de transferência.',
});
