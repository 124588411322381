import MaskedInput from 'react-text-mask';
import { Input, InputProps } from '@chakra-ui/react';
import { CepMask } from '../../utility/formatters';

const CepInput = (props: InputProps) => {
  return (
    <Input
      {...props}
      as={MaskedInput}
      mask={CepMask}
      guide={false}
      placeholder="xxxxx-xxx"
    />
  );
};

export default CepInput;
