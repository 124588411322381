export const LanguagePTBR = {
  translation: {
    'Sign in to your account': 'Faça login na sua conta',
    'to enjoy all of our cool': 'para aproveitar todos os nossos recursos',
    'to enjoy all of our cool features':
      'para aproveitar todos os nossos recursos',
    features: 'recursos',
    'Email address': 'Endereço de e-mail',
    Password: 'Senha',
    'Remember me': 'Lembrar-me',
    'Forgot password': 'Esqueceu a senha',
    'Sign in': 'Entrar',
    'Payment History': 'Histórico de Recebimentos',
    'See your payment history and statuses here.':
      'Veja seu histórico de Recebimentos e status aqui.',
    Configure: 'Configurar',
    'Configure your virtual bank account to receive payments.':
      'Seus canais bancários para receber moeda estrangeira',
    Payouts: 'Transferências',
    'Your payouts overview': 'Visão geral das suas movimentações financeiras',
    'Payout History': 'Histórico de transferências',
    'See your payout history and statuses here.':
      'Veja seu histórico de trasferências e status aqui.',
    'Configure your phisical bank account to transfer money.':
      'Configure sua conta bancária brasileira para transferir dinheiro.',
    Payments: 'Recebimentos',
    'Sign up': 'Cadastrar-se',
    'We are not ready!': 'Não estamos prontos!',
    'This is a preview version, we are still working on it for public access':
      'Esta é uma versão de pré-visualização, ainda estamos trabalhando nela para acesso público',
    Company: 'Empresa',
    'About us': 'Sobre nós',
    Blog: 'Blog',
    'Contact us': 'Contate-nos',
    Pricing: 'Tarifas',
    Testimonials: 'Depoimentos',
    Support: 'Suporte',
    'Help Center': 'Central de Ajuda',
    'Terms of Service': 'Termos de Serviço',
    Legal: 'Legal',
    'Privacy Policy': 'Política de Privacidade',
    'Stay up to date': 'Mantenha-se atualizado',
    'Your email address': 'Seu endereço de e-mail',
    History: 'Histórico',
    'See your recents payments': 'Veja seus Recebimentos recentes',
    'Configure your virtual bank account':
      'Sua conta bancária global para receber em dolar',
    'See your recents payouts': 'Veja suas transferências recentes',
    'Configure your local bank account':
      'Sua conta bancária local para enviar em reais',
    'See your recents invoices': 'Veja suas faturas recentes',
    'Configure your invoice': 'Configure sua Invoice',
    Create: 'Criar',
    'Account Settings': 'Configurações da conta',
    Logout: 'Sair',
    Account: 'Conta',
    'Your account overview': 'Visão geral da sua conta',
    Balance: 'Saldo',
    'Edit personal details': 'Editar detalhes pessoais',
    'Edit password details': 'Editar detalhes da senha',
    'Configure Two factor authentication':
      'Configurar autenticação de dois fatores',
    'Request limit change': 'Solicitar alteração de limite',
    'Contact the support': 'Contatar o suporte',
    'Virtual Account': 'Conta Virtual',
    'Your bank account is configurated! You can share with your clients safely.':
      'Sua conta bancária está configurada! Você pode compartilhar com seus pagadores com segurança.',
    Currency: 'Moeda',
    'You need to configure your bank account to receive payments.':
      'Você precisa configurar sua conta bancária para receber Recebimentos.',
    'Configure now!': 'Configure agora!',
    Nick: 'Apelido',
    'Bank Name': 'Nome do Banco',
    'Account Number': 'Número da Conta',
    'Branch Code': 'Código da Agência',
    'Account Type': 'Tipo da Conta',
    Actions: 'Ações',
    'Add new Bank Account': 'Adicionar nova Conta Bancária',
    'New Bank Account': 'Nova Conta Bancária',
    'Currently we are just supporting checking accounts':
      'Atualmente, estamos apenas suportando contas correntes',
    Checking: 'Corrente',
    checking: 'corrente',
    'Must be 15 characters or less': 'Deve ter 15 caracteres ou menos',
    'Must be 3 characters or more': 'Deve ter 3 caracteres ou mais',
    'Label is required': 'O apelido é obrigatório',
    'Bank Name is required': 'O nome do banco é obrigatório',
    'Account number is required': 'O número da conta é obrigatório',
    'Branch code is required': 'O código da agência é obrigatório',
    'Bank code is required': 'O código do banco é obrigatório',
    Close: 'Fechar',
    'First Name': 'Nome',
    'Last Name': 'Sobrenome',
    'Phone Number': 'Telefone',
    'Document Number': 'CPF',
    'Already a user?': 'Já é um usuário?',
    About: 'Sobre',
    'Know better what Waygee does': 'Saiba melhor o que a Waygee faz',
    'Bank Accounts': 'Contas Bancarias',
    'Bank statement': 'Extrato Bancário',
    'Global Account': 'Conta Global',
    'Brazilian Account': 'Conta Brasileira',
    'Your bank accounts': 'Suas contas bancárias',
    Statement: 'Transferências',
    'Banking Statements': 'Transferências',
    'Your banking statements': 'Suas transferências, envios e recebimentos',
    'Learn more': 'Veja mais',
    'Are you sure?': 'Você tem certeza?',
    'Are you sure you want to delete this account?':
      'Voce realmente deseja deletar essa conta bancaria?',
    'E-mail registered': 'E-mail registrado',
    'We will keep you updated': 'Vamos te manter atualizado',
    Failed: 'Falhou',
    'Registering email': 'Registrando e-mail',
    'Please wait...': 'Por favor, aguarde...',
    'Search for a bank': 'Pesquise por um banco',
    'No items found': 'Nenhum item encontrado',
    'Quick Actions': 'Atalhos',
    'My bank accounts': 'Meus canais bancários',
    'My transference': 'Minhas transferências',
    'My invoices': 'Minhas invoices',
    'We are currently not supporting this currency. Please select another currency.':
      'Atualmente, não estamos suportando essa moeda. Por favor, selecione outra moeda.',
    'Depending on the system you are filling, this might be separated in two different fields: One for IBAN/Account Number and other for Additional data':
      'Dependendo do sistema que você está preenchendo, isso pode estar separado em dois campos diferentes: um para IBAN/Número da Conta e outro para Dados Adicionais',
  },
};
