import { DateTimeHelper } from '@waygee/common';
import appConfig from '../configuration/AppConfig';
import { getAuthTokenCookie } from '../utility/cookies/authTokenStorage';

let intervalId: number | null = null;

export const isIdleTimeExpired = () => {
  const lastRequest = sessionStorage.getItem(appConfig.lasRequestDateSessionId);
  if (lastRequest) {
    const diffInMinutes =
      DateTimeHelper.getDifferenceInMinutes(lastRequest) * -1;
    return diffInMinutes >= appConfig.idleTimeToExpireSessionInMinutes;
  }
  return false;
};

export const addIdleTimeChecker = (
  counterSessionId: string,
  callback: () => void
) => {
  if (intervalId) {
    window.clearInterval(intervalId);
  }

  const checkCookieChanges = () => {
    if (!sessionStorage.getItem(counterSessionId)) {
      const token = getAuthTokenCookie();
      const lastRequest = sessionStorage.getItem(
        appConfig.lasRequestDateSessionId
      );
      if (lastRequest && token) {
        const diffInMinutes =
          DateTimeHelper.getDifferenceInMinutes(lastRequest) * -1;
        if (diffInMinutes >= appConfig.idleToleranceInMinutes) {
          callback();
        }
      }
    }
  };

  intervalId = window.setInterval(() => {
    checkCookieChanges();
  }, appConfig.authCookieIntervalCheckInMilliseconds);
};
