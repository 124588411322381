import { Card, Flex, Progress, HStack, Divider } from '@chakra-ui/react';
import { InvoiceCreateConfigAndPayerSelect } from './components/InvoiceCreateConfigAndPayerSelect';
import { InvoiceCreateNewConfig } from './components/InvoiceCreateNewConfig';
import { InvoiceCreateNewPayer } from './components/InvoiceCreateNewPayer';
import InvoiceCreatePreview from './components/InvoiceCreatePreview/InvoiceCreatePreview';
import { InvoiceCreateServicesData } from './components/InvoiceCreateServicesData';
import useInvoiceCreate from './hooks/useInvoiceCreate';

const InvoiceCreate = () => {
  const {
    invoiceConfigOptions,
    isLoading,
    invoicePayerOptions,
    invoice,
    invoiceConfig,
    invoicePayer,
    handleSubmit,
    invoicePreviewStackProps,
    bottomObserver,
    footerObserver,
    isOptToNewConfiguration,
    isOptToNewPayer,
    invoiceCreateNewConfigProps,
    invoiceCreateNewPayerProps,
    invoiceCreateConfigAndPayerSelectProps,
    invoiceCreateServicesDataProps,
  } = useInvoiceCreate();
  return (
    <Card
      w={{ base: '400px', md: '770px', lg: '1100px', xl: '1100px' }}
      padding="20px"
    >
      {isLoading && (
        <Progress size="xs" isIndeterminate colorScheme="primary" />
      )}
      <form onSubmit={handleSubmit}>
        <Flex flexDir="column" gap="20px">
          {(invoiceConfigOptions.length === 0 || isOptToNewConfiguration) && (
            <InvoiceCreateNewConfig {...invoiceCreateNewConfigProps} />
          )}
          {(invoicePayerOptions.length === 0 || isOptToNewPayer) && (
            <InvoiceCreateNewPayer {...invoiceCreateNewPayerProps} />
          )}
          {(invoiceConfigOptions?.length > 0 ||
            invoicePayerOptions?.length > 0) && (
            <InvoiceCreateConfigAndPayerSelect
              {...invoiceCreateConfigAndPayerSelectProps}
            />
          )}
          <InvoiceCreateServicesData {...invoiceCreateServicesDataProps} />
          <Divider />
          <span ref={bottomObserver} />
          {invoiceConfig && invoicePayer && (
            <HStack {...invoicePreviewStackProps}>
              <InvoiceCreatePreview
                invoice={invoice}
                invoiceConfig={invoiceConfig}
                invoicePayer={invoicePayer}
              />
            </HStack>
          )}
        </Flex>
      </form>
      <span ref={footerObserver} />
    </Card>
  );
};

export default InvoiceCreate;
