import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  Flex,
  Input,
  InputGroup,
  Text,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
} from '@chakra-ui/react';
import useAutoComplete from './hooks/useAutoComplete';

export type AutoCompleteItem = {
  id: string | number;
  name: string;
};

export type AutoCompleteProps = {
  placeholder?: string;
  items: AutoCompleteItem[];
  width?: string;
  onSelectItem?: (item: AutoCompleteItem) => void;
  onReset?: () => void;
  initialValue?: string;
};

const AutoComplete = ({
  placeholder,
  items,
  width,
  onSelectItem,
  onReset,
  initialValue,
}: AutoCompleteProps) => {
  const {
    isInputFocused,
    setInputFocused,
    handleKeyDown,
    handleSearch,
    filteredData,
    handleSelectItem,
    selectedItem,
    handleReset,
    containerRef,
    listRef,
    t,
  } = useAutoComplete({ items, onSelectItem, onReset, initialValue });
  return (
    <div ref={containerRef}>
      <Flex position="relative">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          {selectedItem && (
            <InputRightElement>
              <Button onClick={handleReset} size="sm">
                <CloseIcon
                  color="gray.300"
                  onClick={handleReset}
                  cursor="pointer"
                />
              </Button>
            </InputRightElement>
          )}

          {selectedItem && (
            <Input
              type="tel"
              placeholder={placeholder ?? 'Search'}
              onFocus={() => setInputFocused(true)}
              onKeyUp={handleSearch}
              value={selectedItem}
              isReadOnly
            />
          )}
          {!selectedItem && (
            <Input
              type="tel"
              placeholder={placeholder ?? 'Search'}
              onFocus={() => setInputFocused(true)}
              onKeyUp={handleSearch}
            />
          )}
        </InputGroup>
        {isInputFocused && (
          <Card
            position="absolute"
            zIndex="1000"
            marginTop="50px"
            w={width ?? '450px'}
            maxH="180px"
            overflowY="scroll"
            sx={{
              '::-webkit-scrollbar': {
                width: '0px',
              },
            }}
          >
            <Box padding="10px">
              <List spacing={3} ref={listRef}>
                {filteredData.length &&
                  filteredData.map((item, index) => (
                    <ListItem
                      key={`${item.id}-${item.name}`}
                      tabIndex={index}
                      onKeyDown={handleKeyDown}
                      onClick={() => handleSelectItem(item)}
                      data-value={item.id}
                      data-name={item.name}
                      sx={{
                        cursor: 'pointer',
                        ':hover': {
                          backgroundColor: 'primary.100',
                        },
                        ':focus': {
                          backgroundColor: 'primary.100',
                          outline: 'none',
                        },
                      }}
                    >
                      {item.name}
                    </ListItem>
                  ))}
                {!filteredData.length && <Text>{t('No items found')}</Text>}
              </List>
            </Box>
          </Card>
        )}
      </Flex>
    </div>
  );
};

export default AutoComplete;
