export const LanguageEN = {
  translation: {
    'Sign in to your account': 'Sign in to your account',
    'to enjoy all of our cool': 'to enjoy all of our cool',
    'to enjoy all of our cool features': 'to enjoy all of our cool features',
    features: 'features',
    'Email address': 'Email address',
    Password: 'Password',
    'Remember me': 'Remember me',
    'Forgot password': 'Forgot password',
    'Sign in': 'Sign in',
    'Payment History': 'Payment History',
    'See your payment history and statuses here.':
      'See your payment history and statuses here.',
    Configure: 'Configure',
    'Configure your virtual bank account to receive payments.':
      'Configure your virtual bank account to receive payments.',
    Payouts: 'Payouts',
    'Your payouts overview': 'Your payouts overview',
    'Payout History': 'Payout History',
    'See your payout history and statuses here.':
      'See your payout history and statuses here.',
    'Configure your phisical bank account to transfer money.':
      'Configure your phisical bank account to transfer money.',
    Payments: 'Payments',
    'Sign up': 'Sign up',
    'This is a preview version, we are still working on it for public access':
      'This is a preview version, we are still working on it for public access',
    Company: 'Company',
    'About us': 'About us',
    Blog: 'Blog',
    'Contact us': 'Contact us',
    Pricing: 'Pricing',
    Testimonials: 'Testimonials',
    Support: 'Support',
    'Help Center': 'Help Center',
    'Terms of Service': 'Terms of Service',
    Legal: 'Legal',
    'Privacy Policy': 'Privacy Policy',
    'Stay up to date': 'Stay up to date',
    'Your email address': 'Your email address',
    History: 'History',
    'See your recents payments': 'See your recents payments',
    'Configure your virtual bank account':
      'Configure your virtual bank account',
    'See your recents payouts': 'See your recents payouts',
    'Configure your local bank account': 'Configure your local bank account',
    'See your recents invoices': 'See your recents invoices',
    'Configure your invoice': 'Configure your invoice',
    Create: 'Create',
    'Create new invoice': 'Create new invoice',
    'Account Settings': 'Account Settings',
    Logout: 'Logout',
    Account: 'Account',
    'Your account overview': 'Your account overview',
    Balance: 'Balance',
    'Edit personal details': 'Edit personal details',
    'Edit password details': 'Edit password details',
    'Configure Two factor authentication':
      'Configure Two factor authentication',
    'Request limit change': 'Request limit change',
    'Contact the support': 'Contact the support team',
    'Virtual Account': 'Virtual Account',
    'Your bank account is configurated! You can share with your clients safely.':
      'Your bank account is configurated! You can share with your clients safely.',
    Currency: 'Currency',
    'You need to configure your bank account to receive payments.':
      'You need to configure your bank account to receive payments.',
    'Configure now!': 'Configure now!',
    Nick: 'Nick',
    'Bank Name': 'Bank Name',
    'Account Number': 'Account Number',
    'Branch Code': 'Branch Code',
    'Account Type': 'Account Type',
    Actions: 'Actions',
    'Add new Bank Account': 'Add new Bank Account',
    'New Bank Account': 'New Bank Account',
    'Currently we are just supporting checking accounts':
      'Currently we are just supporting checking accounts',
    Checking: 'Checking',
    checking: 'checking',
    'Must be 15 characters or less': 'Must be 15 characters or less',
    'Must be 3 characters or more': 'Must be 3 characters or more',
    'Label is required': 'Label is required',
    'Bank Name is required': 'Bank Name is required',
    'Account number is required': 'Account number is required',
    'Branch code is required': 'Branch code is required',
    'Bank code is required': 'Bank code is required',
    Close: 'Close',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    'Phone Number': 'Phone Number',
    'Document Number': 'Document Number',
    'Already a user?': 'Already a user?',
    About: 'About',
    'Know better what Waygee does': 'Know better what Waygee does',
    'Bank Accounts': 'Bank Accounts',
    'Bank statement': 'Bank statement',
    'Global Account': 'Global Account',
    'Brazilian Account': 'Brazilian Account',
    'Your bank accounts': 'Your bank accounts overview',
    Statement: 'Statement',
    'Banking Statements': 'Banking Statements',
    'Your banking statements': 'Your banking statements',
    'Learn more': 'Learn more',
    'Are you sure?': 'Are you sure?',
    'Are you sure you want to delete this account?':
      'Are you sure you want to delete this account?',
    'E-mail registered': 'E-mail registered',
    'We will keep you updated': 'We will keep you updated',
    Failed: 'Failed',
    'Registering email': 'Registering email',
    'Please wait...': 'Please wait...',
    'Search for a bank': 'Search for a bank',
    'No items found': 'No items found',
    'Quick Actions': 'Quick Actions',
    'My bank accounts': 'My bank accounts',
    'My transference': 'My transference',
    'My invoices': 'My invoices',
    'We are currently not supporting this currency. Please select another currency.':
      'We are currently not supporting this currency. Please select another currency.',
  },
};
