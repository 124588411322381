import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { LanguageEN } from '../languages/en';
import { LanguagePTBR } from '../languages/pt-br';

const resources = {
  en: LanguageEN,
  ptbr: LanguagePTBR,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ptbr',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
