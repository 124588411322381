import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { IInvoiceConfigDto, IPayerDto } from '@waygee/shared-types';
import {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { FaEdit } from 'react-icons/fa';
import { VerticalLabelDivider } from '../../../../../../common/components/VerticalLabelDivider';
import { InvoiceCreateSchema } from '../../types/invoiceCreateSchema';
import './../../../../Invoice.language';

export type InvoiceCreateConfigAndPayerSelectProps = {
  setIsOptToNewConfiguration: (value: boolean) => void;
  setIsOptToNewPayer: (value: boolean) => void;
  invoiceConfigOptions: IInvoiceConfigDto[];
  invoicePayerOptions: IPayerDto[];
  touched: FormikTouched<InvoiceCreateSchema>;
  values: InvoiceCreateSchema;
  errors: FormikErrors<InvoiceCreateSchema>;
  getFieldProps: (
    nameOrOptions: string | FieldConfig
  ) => FieldInputProps<string | number>;
  handleOnInvoiceConfigSelectChange: (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  handleOnInvoicePayerSelectChange: (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  isLoading: boolean;
  isOptToNewConfiguration: boolean;
  isOptToNewPayer: boolean;
};

const InvoiceCreateConfigAndPayerSelect = ({
  setIsOptToNewConfiguration,
  setIsOptToNewPayer,
  invoiceConfigOptions,
  invoicePayerOptions,
  touched,
  values,
  errors,
  getFieldProps,
  handleOnInvoiceConfigSelectChange,
  handleOnInvoicePayerSelectChange,
  isLoading,
  isOptToNewConfiguration,
  isOptToNewPayer,
}: InvoiceCreateConfigAndPayerSelectProps) => {
  const { t } = useTranslation('invoice');
  return (
    <>
      <Flex flexDir="row" gap="20px" wrap={{ base: 'wrap', md: 'unset' }}>
        {invoiceConfigOptions?.length > 0 && !isOptToNewConfiguration && (
          <FormControl
            colorScheme="primary"
            isRequired
            isInvalid={
              (touched.invoiceConfigId && !!errors.invoiceConfigId) || false
            }
          >
            <Flex flexDir="row" gap="5px">
              <FormLabel>{t('Configuration')}</FormLabel>
              <VerticalLabelDivider marginTop="3px" marginRight="5px" />
              <Button
                leftIcon={<FaEdit />}
                colorScheme="primary"
                variant="solid"
                size="xs"
                onClick={() => setIsOptToNewConfiguration(true)}
              >
                {t('New')}
              </Button>
            </Flex>
            <Select
              isDisabled={isLoading}
              {...getFieldProps('invoiceConfigId')}
              onChange={handleOnInvoiceConfigSelectChange}
            >
              {invoiceConfigOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.title}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.invoiceConfigId}</FormErrorMessage>
          </FormControl>
        )}
        {invoicePayerOptions?.length > 0 && !isOptToNewPayer && (
          <FormControl
            colorScheme="primary"
            isRequired
            isInvalid={(touched.payerId && !!errors.payerId) || false}
          >
            <Flex flexDir="row" gap="10px">
              <FormLabel>{t('payer')}</FormLabel>
              <VerticalLabelDivider marginTop="3px" marginRight="5px" />
              <Button
                leftIcon={<FaEdit />}
                colorScheme="primary"
                variant="solid"
                size="xs"
                onClick={() => setIsOptToNewPayer(true)}
              >
                {t('New')}
              </Button>
            </Flex>
            <Select
              isDisabled={isLoading}
              onChange={handleOnInvoicePayerSelectChange}
            >
              {invoicePayerOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name} - {option.email}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.payerId}</FormErrorMessage>
          </FormControl>
        )}
      </Flex>
      <Divider />
    </>
  );
};

export default InvoiceCreateConfigAndPayerSelect;
