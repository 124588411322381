import i18n from '../../common/configuration/i18n';

i18n.addResourceBundle('en', 'contact', {
  'Invalid email address': 'Invalid email address',
  'Must be 100 characters or less': 'Must be 100 characters or less',
  'Email is required': 'Email is required',
  'Name is required': 'Name is required',
  'Message is required': 'Message is required',
  'Message received': 'Message received',
  'We will contact you soon': 'We will contact you soon',
  'Message failed': 'Message failed',
  'Something wrong': 'Something wrong',
  'Sending message': 'Sending message',
  'Please wait...': 'Please wait...',
});

i18n.addResourceBundle('ptbr', 'contact', {
  'Invalid email address': 'Endereço de e-mail inválido',
  'Must be 100 characters or less': 'Deve ter 100 caracteres ou menos',
  'Email is required': 'O email é obrigatório',
  'Name is required': 'O nome é obrigatório',
  'Message is required': 'A mensagem é obrigatória',
  'Message received': 'Mensagem recebida',
  'We will contact you soon': 'Entraremos em contato em breve',
  'Message failed': 'Falha na mensagem',
  'Something wrong': 'Algo deu errado',
  'Sending message': 'Enviando mensagem',
  'Please wait...': 'Por favor, aguarde...',
});
