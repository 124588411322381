import { Partners } from '../partners';

export interface IOrderPartnerData<TPartner extends Partners> {
  partner: TPartner;
  orderId: number;
  status: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOzCambioOrderPartnerData
  extends IOrderPartnerData<Partners.OZ_CAMBIO> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRendimentoOrderPartnerData
  extends IOrderPartnerData<Partners.RENDIMENTO> {}

export interface IOrderPartnerDataTypes {
  [Partners.OZ_CAMBIO]: IOzCambioOrderPartnerData;
  [Partners.RENDIMENTO]: IRendimentoOrderPartnerData;
}

export type OrderPartnerDataMap = {
  [key in Partners]?: IOrderPartnerDataTypes[Partners];
};

export interface IOrderExecutionData {
  orderId: string;
  ozToken: string;
  currencyExchangeValue: number;
}

export enum OrderStatus {
  AVAILABLE = 'available',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

export interface IOrderQuotation {
  currencyExchangeValue: number;
  active: boolean;
  amountME: number;
  amountMN: number;
  vet: number;
  rate: number;
  dateQuotation: string;
  dueDate: string;
  iofInboud: number;
  iofOutbound: number;
  irInbound: number;
  irOutbound: number;
  spread: number;
  tariff: number;
  ptax: number;
  commercialInbound: number;
  commercialOutbound: number;
}
