import i18n from '../../common/configuration/i18n';

i18n.addResourceBundle('en', 'signup', {
  'Sign up': 'Sign up',
  'to enjoy all of our cool features': 'to enjoy all of our cool features',
  'For who are you opening this account?':
    'For who are you opening this account?',
  'For me as individual': 'For me as individual',
  'Individual data': 'Individual data',
  Birthdate: 'Birthdate',
  Next: 'Next',
  'Business data': 'Business data',
  'Address street is required': 'Address street is required',
  'Must be 100 characters or less': 'Must be 100 characters or less',
  'Address number is required': 'Address number is required',
  'Must be 1 characters or more': 'Must be 1 characters or more',
  'Must be 10 characters or less': 'Must be 10 characters or less',
  'Must be 8 characters or more': 'Must be 8 characters or more',
  'Must be 8 characters or less': 'Must be 8 characters or less',
  'Address neighborhood is required': 'Address neighborhood is required',
  'Address city is required': 'Address city is required',
  'Address state is required': 'Address state is required',
  'Must be 2 characters or more': 'Must be 2 characters or more',
  'Must be 2 characters or less': 'Must be 2 characters or less',
  'Address zip code is required': 'Address zip code is required',
  'CNPJ is required': 'CNPJ is required',
  'CNPJ invalido': 'CNPJ invalido',
  'Business name is required': 'Business name is required',
  'Must at last 5 characters': 'Must at last 5 characters',
  'Email is required': 'Email is required',
  'Invalid email address': 'Invalid email address',
  'Password is required': 'Password is required',
  'Must be 16 characters or less': 'Must be 16 characters or less',
  'First name is required': 'First name is required',
  'Last name is required': 'Last name is required',
  'Phone number is required': 'Phone number is required',
  'Invalid phone number': 'Invalid phone number',
  'Document number is required': 'Document number is required',
  'CPF invalido': 'CPF invalido',
  'Must be 10 characters or more': 'Must be 10 characters or more',
  'Invalid date': 'Invalid date',
  'Birth date is required': 'Birth date is required',
  'Invalid date format': 'Invalid date format',
  Back: 'Back',
  'Already a user?': 'Already a user?',
  'Sign in': 'Sign in',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  'Phone Number': 'Phone Number',
  'Document Number': 'Document Number',
  'Business name': 'Business name',
  Street: 'Street',
  Number: 'Number',
  Complement: 'Complement',
  Neighborhood: 'Neighborhood',
  City: 'City',
  State: 'State',
  'Zip Code': 'Zip Code',
  'User data': 'User data',
  Password: 'Password',
  'Email address': 'Email address',
  'Address data': 'Address data',
  Gender: 'Gender',
  Occupation: 'Occupation',
  'Gender is required': 'Gender is required',
  'Occupation is required': 'Occupation is required',
  male: 'Male',
  female: 'Female',
  student: 'Student',
  'Select option': 'Select option',
  other: 'Other',
  'Please, enter your e-mail': 'Please, enter your e-mail',
  'An email was sent with the confirmation link':
    'An email was sent with the confirmation link',
  'Please check your inbox to continue': 'Please check your inbox to continue',
  'Creating account': 'Creating account',
  'Account creation failed': 'Account creation failed',
  'Something wrong': 'Something wrong',
  'Please wait...': 'Please wait...',
  'Check your e-mail': 'Check your e-mail',
  'E-mail registered': 'E-mail registered',
  'Account created successful': 'Account created successfully',
  'Please log-in to continue': 'Please log-in to continue',
  'Have you already an account?': 'Have you already an account?',
  'Click here to login!': 'Click here to login!',
  'By registering you accept our': 'By registering you accept our',
  'Terms & Conditions': 'Terms & Conditions',
  and: 'and',
  'Privacy Policy': 'Privacy Policy',
  'At least 8 characters, 1 uppercase and 1 lowercase.':
    'At least 8 characters, 1 uppercase and 1 lowercase.',
  'Review your data': 'Review your data',
  'up to 1 business days': 'up to 1 business days',
  'Create global account': 'Create global account',
  'Ready to operate': 'Ready to operate',
  'Share your data with your payers': 'Share your data with your payers',
  'Account created': 'Account created',
  'You are now able to login using your e-mail and password':
    'You are now able to login using your e-mail and password',
  'Click here to go to login page': 'Click here to go to login page',
  'Look what come next': 'Look what come next',
  Start: 'Start',
  'E-mail confirmation': 'E-mail confirmation',
  Identify: 'Identify',
  'Additional Data': 'Additional Data',
  'Address and others': 'Address and others',
  'Access data': 'Access data',
  'Login and password': 'Login and password',
  End: 'End',
  'Personal Data': 'Personal Data',
  'Address and other': 'Address and other',
  'Coming soon': 'Coming soon',
  'Currently we are only accepting business accounts. If you have a business, please select the business option.':
    'Currently we are only accepting business accounts. If you have a business, please select the business option.',
  'Access your account to finish sending your data':
    'Access your account to finish sending your data',
  'Finish your profile': 'Finish your profile',
  'Sign up is disabled. Please contact support for more information.':
    'Sign up is disabled. Please contact support for more information.',
  'Access your account to finish your profile':
    'Access your account to finish your profile',
});

i18n.addResourceBundle('ptbr', 'signup', {
  'Sign Up': 'Cadastre-se',
  'to enjoy all of our cool features':
    'para aproveitar todos os nossos recursos',
  'For who are you opening this account?':
    'Para quem você está abrindo esta conta?',
  'For me as individual': 'Para mim como Pessoa Fisica',
  'For me as business': 'Para mim como Pessoa Jurídica',
  'Individual data': 'Dados do titular',
  Birthdate: 'Data de Nascimento',
  Next: 'Próximo',
  'Business data': 'Dados da empresa',
  'Address street is required': 'Endereço é obrigatório',
  'Must be 100 characters or less': 'Deve ter 100 caracteres ou menos',
  'Address number is required': 'Número é obrigatório',
  'Must be 1 characters or more': 'Deve ter 1 caracteres ou mais',
  'Must be 10 characters or less': 'Deve ter 10 caracteres ou menos',
  'Must be 8 characters or more': 'Deve ter 8 caracteres ou mais',
  'Must be 8 characters or less': 'Deve ter 8 caracteres ou menos',
  'Address neighborhood is required': 'Bairro é obrigatório',
  'Address city is required': 'Cidade é obrigatório',
  'Address state is required': 'Estado é obrigatório',
  'Must be 2 characters or more': 'Deve ter 2 caracteres ou mais',
  'Must be 2 characters or less': 'Deve ter 2 caracteres ou menos',
  'Address zip code is required': 'CEP é obrigatório',
  'CNPJ is required': 'CNPJ é obrigatório',
  'CNPJ invalido': 'CNPJ inválido',
  'Business name is required': 'Nome da empresa é obrigatório',
  'Must at last 5 characters': 'Deve ter pelo menos 5 caracteres',
  'Email is required': 'E-mail é obrigatório',
  'Invalid email address': 'E-mail inválido',
  'Password is required': 'Senha é obrigatório',
  'Must be 16 characters or less': 'Deve ter 16 caracteres ou menos',
  'First name is required': 'Nome é obrigatório',
  'Last name is required': 'Sobrenome é obrigatório',
  'Phone number is required': 'Telefone é obrigatório',
  'Invalid phone number': 'Telefone inválido',
  'Document number is required': 'CPF é obrigatório',
  'CPF invalido': 'CPF inválido',
  'Must be 10 characters or more': 'Deve ter 10 caracteres ou mais',
  'Invalid date': 'Data inválida',
  'Birth date is required': 'Data de nascimento é obrigatório',
  'Invalid date format': 'Formato de data inválido',
  Back: 'Voltar',
  'Already a user?': 'Já é um usuário?',
  'Sign in': 'Clique aqui para Entrar em sua conta!',
  'First Name': 'Nome',
  'Last Name': 'Sobrenome',
  'Phone Number': 'Telefone',
  'Document Number': 'CPF',
  'Business name': 'Nome da empresa',
  Street: 'Rua',
  Number: 'Número',
  Complement: 'Complemento',
  Neighborhood: 'Bairro',
  City: 'Cidade',
  State: 'Estado',
  'Zip Code': 'CEP',
  'User data': 'Dados de acesso',
  Password: 'Senha',
  'Email address': 'E-mail',
  'Address data': 'Endereço',
  Gender: 'Genero',
  Occupation: 'Ocupação',
  'Gender is required': 'Genero é obrigatório',
  'Occupation is required': 'Ocupação é obrigatório',
  male: 'Masculino',
  female: 'Feminino',
  student: 'Estudante',
  'Select option': 'Selecione uma opção',
  other: 'Outro',
  'Please, enter your e-mail': 'Por favor, digite seu e-mail',
  'An email was sent with the confirmation link':
    'Um e-mail foi enviado com o link de confirmação',
  'Please check your inbox to continue':
    'Por favor, verifique sua caixa de entrada para continuar',
  'Creating account': 'Criando conta',
  'Account creation failed': 'Falha ao criar conta',
  'Something wrong': 'Algo deu errado',
  'Please wait...': 'Por favor, aguarde...',
  'Check your e-mail': 'Verifique seu e-mail',
  'E-mail registered': 'E-mail registrado',
  'Account created successful': 'Conta criada com sucesso',
  'Please log-in to continue': 'Por favor, faça login para continuar',
  'Have you already an account?': 'Já tem uma conta?',
  'Click here to login!': 'Clique aqui para fazer login!',
  'By registering you accept our': 'Ao se registrar, você aceita nossos',
  'Terms & Conditions': 'Termos & Condições',
  and: 'e',
  'Privacy Policy': 'Política de Privacidade',
  'At least 8 characters, 1 uppercase and 1 lowercase.':
    'Pelo menos 8 caracteres, 1 maiúscula e 1 minúscula.',
  'Review your data': 'Revisaremos seus dados cadastrais',
  'up to 1 business days': 'em até 1 dia útil',
  'Create global account': 'Criaremos sua conta global',
  'Ready to operate': 'Você estará pronto para operar',
  'Share your data with your payers':
    'Compartilhe seus dados com seus pagadores',
  'Account created': 'Conta criada',
  'You are now able to login using your e-mail and password':
    'Você já pode fazer login usando seu e-mail e senha',
  'Click here to go to login page':
    'Clique aqui para ir para a página de login',
  'Look what come next': 'Veja o que vem a seguir',
  Start: 'Pré-registro',
  'E-mail confirmation': 'Confirmação de e-mail',
  Identify: 'Identificação',
  'Additional Data': 'Dados adicionais',
  'Address and others': 'Endereço e outros',
  'Access data': 'Dados de acesso',
  'Login and password': 'Login e senha',
  End: 'Fim',
  'Personal Data': 'Dados Pessoais',
  'Address and other': 'Endereço e outros',
  'Coming soon': 'Em breve',
  'Currently we are only accepting business accounts. If you have a business, please select the business option.':
    'Atualmente, estamos aceitando apenas contas empresariais. Se você tem uma empresa, selecione a opção de empresa.',
  'Access your account to finish sending your data':
    'Acesse sua conta para terminar de enviar seus dados',
  'Finish your profile': 'Envie seus documentos',
  'Sign up is disabled. Please contact support for more information.':
    'O cadastro está desativado. Estamos trabalhando para lançar a Waygee em breve.',
  'Access your account to finish your profile':
    'Acesse sua conta para terminar seu perfil',
});

export default i18n;
