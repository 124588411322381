import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './NavBar.language';
import { AuthContext } from '../../../contexts/AuthContext/AuthContext';

export interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  soon?: boolean;
  count?: number;
}

const useNavBarItems = () => {
  const { t } = useTranslation('navbar');
  const { orderAvailableCount } = useContext(AuthContext);
  const navBarItems: Array<NavItem> = [
    {
      label: t('Home'),
      href: '/secure/dashboard',
    },
    {
      label: t('Bank Accounts'),
      href: '/secure/bank-accounts',

      children: [
        {
          label: t('Global Account'),
          subLabel: t('Configure your virtual bank account'),
          href: '/secure/bank-accounts/global',
        },
        {
          label: t('Brazilian Account'),
          subLabel: t('Configure your local bank account'),
          href: '/secure/bank-accounts/local',
        },
      ],
    },
    {
      label: t('Bank statement'),
      href: '/secure/statement/transferences',
      count: orderAvailableCount,
      children: [
        {
          label: t('History'),
          subLabel: t('Your banking statements'),
          href: '/secure/statement/transferences',
        },
      ],
    },
    {
      label: t('Invoices'),
      href: '/secure/invoice',
      children: [
        {
          label: t('History'),
          subLabel: t('See your recents invoices'),
          href: '/secure/invoice/history',
        },
        {
          label: t('Configure'),
          subLabel: t('Configure your invoice'),
          href: '/secure/invoice/config',
        },
        {
          label: t('Payers'),
          subLabel: t('Configure your invoice payers'),
          href: '/secure/invoice/payers',
        },
        {
          label: t('Create'),
          subLabel: t('Create new invoice'),
          href: '/secure/invoice/create',
        },
      ],
    },
    // {
    //   label: t('Crypto'),
    //   href: '/secure/crypto',
    // },
  ];

  return { navBarItems };
};

export default useNavBarItems;
