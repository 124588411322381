import { useState, useEffect } from 'react';
import { DateTimeHelper } from '@waygee/common';
import axios from 'axios';

const dateFormat = 'dd/LL/yyyy';

type DataType = { name: string; pv: number }[];

const useDashboardExchangeRate = () => {
  const [data, setData] = useState<DataType>([]);
  const [minDomain, setMinDomain] = useState<number>(4.7);
  const [maxDomain, setMaxDomain] = useState<number>(5.1);
  const [currentPV, setCurrentPV] = useState(data[data.length - 1]?.pv || 0);
  useEffect(() => {
    const fetchData = async () => {
      const fetchDailyData = () =>
        axios.get('https://economia.awesomeapi.com.br/json/daily/usd/30');
      const fetchLastData = () =>
        axios.get('https://economia.awesomeapi.com.br/json/last/usd');

      const [dailyResponse, lastResponse] = await Promise.all([
        fetchDailyData(),
        fetchLastData(),
      ]);

      const dailyData = dailyResponse.data.map(
        (item: { timestamp: number; bid: string }) => ({
          name: DateTimeHelper.format(
            DateTimeHelper.fromUnixTimestampWithoutSeconds(
              Number(item.timestamp)
            ),
            dateFormat
          ),
          pv: parseFloat(item.bid),
        })
      );

      const lastData = {
        name: DateTimeHelper.format(
          DateTimeHelper.fromUnixTimestampWithoutSeconds(
            Number(lastResponse.data.USDBRL.timestamp)
          ),
          dateFormat
        ),
        pv: parseFloat(lastResponse.data.USDBRL.bid),
      };

      const resultData = [...dailyData, lastData];

      const getUnixTimestamp = (date: string) =>
        DateTimeHelper.toUnixTimestamp(DateTimeHelper.parse(date, dateFormat));

      resultData.sort(
        (a, b) => getUnixTimestamp(a.name) - getUnixTimestamp(b.name)
      );

      const uniqueData = resultData.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => t.name === item.name && t.pv === item.pv)
      );

      const pvValues = uniqueData.map((item) => item.pv);
      const minValue = Math.min(...pvValues) * 1.1;
      const maxValue = Math.max(...pvValues) * 0.2;
      const lastValue = uniqueData[uniqueData.length - 1].pv;

      setCurrentPV(lastValue);

      setMinDomain(minValue);
      setMaxDomain(maxValue);

      setData(uniqueData);
    };

    fetchData();
  }, []);

  return { data, minDomain, maxDomain, currentPV, setCurrentPV };
};

export default useDashboardExchangeRate;
