import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Link } from '@chakra-ui/react';

const LanguageSelector = () => {
  const [language, setLanguage] = React.useState('en'); // ["en", "pt"
  const { i18n } = useTranslation();

  const handleOnChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    setLanguage(i18n.language);
  }, [language, i18n]);

  return (
    <Flex direction="row" gap="10px">
      <Link onClick={() => handleOnChangeLanguage('en')}>
        <img
          src="assets/images/usa.svg"
          alt="United States of America"
          width="35px"
        />
      </Link>
      <Link onClick={() => handleOnChangeLanguage('ptbr')}>
        <img src="assets/images/br.svg" alt="Brazil" width="35px" />
      </Link>
    </Flex>
  );
};

export default LanguageSelector;
