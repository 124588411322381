import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DateTimeHelper } from '@waygee/common';
import { AuthContext } from '../../../contexts/AuthContext/AuthContext';
import { ApiNames, getRequestConfig } from '../../apis';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import appConfig from '../../configuration/AppConfig';
import { axiosCustomInstance } from '../../network';
import {
  addIdleTimeChecker,
  isIdleTimeExpired,
} from '../../network/idleTimeCheck';
import {
  getAuthTokenCookie,
  removeAuthToken,
} from '../../utility/cookies/authTokenStorage';
import './LayoutWrapper.language';

const IS_COUNTING_SESSION_ID = 'isCounting';

export const finishCounters = () => {
  sessionStorage.removeItem(IS_COUNTING_SESSION_ID);
};

const LayoutWrapperLogic = () => {
  const didMountRef = useRef(false);
  const { validateUserAccess } = useContext(AuthContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_countDownInSeconds, setCountDownInSeconds] = useState<number>(0);
  const [isCloseToIdleLimit, setIsCloseToIdleLimit] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>('');
  const [alertDescription, setAlertDescription] = useState<string>('');
  const [cancelButtonLabel, setCancelButtonLabel] = useState<string>('');
  const [confirmButtonLabel, setConfirmButtonLabel] = useState<string>('');
  const { t } = useTranslation('layout-wrapper');
  const token = getAuthTokenCookie();
  const navigate = useNavigate();
  const { refreshAuthToken } = useContext(AuthContext);

  const handleIdleTimeCallback = () => {
    if (!sessionStorage.getItem(IS_COUNTING_SESSION_ID)) {
      setIsCloseToIdleLimit(true);
      setAlertTitle(t('Are you still there?'));
      setAlertDescription(`${t('Your session will expire in')}`);
      setCancelButtonLabel(t('Logout'));
      setConfirmButtonLabel(t('Yes, keep me logged in'));
      startIdleCountDown();
      sessionStorage.setItem(IS_COUNTING_SESSION_ID, 'true');
    }
  };

  const handleLogout = (closeModal: boolean) => {
    finishCounters();
    removeAuthToken();
    setIsCloseToIdleLimit(false);
    setCountDownInSeconds(0);
    didMountRef.current = false;
    sessionStorage.removeItem(appConfig.lasRequestDateSessionId);
    if (closeModal) {
      setIsCloseToIdleLimit(false);
    } else {
      setConfirmButtonLabel(t('New login'));
    }
  };

  const onClose = () => {
    handleLogout(true);
  };
  const handleRevalidate = async () => {
    finishCounters();
    setIsCloseToIdleLimit(false);
    if (token) {
      await refreshAuthToken(token);
      axiosCustomInstance.request(
        getRequestConfig({
          endpoint: ApiNames.ME,
          token,
        })
      );
    } else {
      removeAuthToken();
      navigate('/secure/login');
    }
  };

  const startIdleCountDown = () => {
    const diffUntilIdleLimit =
      appConfig.idleTimeToExpireSessionInMinutes -
      appConfig.idleToleranceInMinutes;
    setCountDownInSeconds(diffUntilIdleLimit * 60);
    const interval = setInterval(() => {
      const cookieStillPresent = getAuthTokenCookie();
      setCountDownInSeconds((prevCountDownInSeconds) => {
        const isExpired = isIdleTimeExpired();
        if (prevCountDownInSeconds <= 0 || !cookieStillPresent) {
          clearInterval(interval);
          handleLogout(false);
        }
        setAlertDescription(() => {
          if (prevCountDownInSeconds <= 0 || !cookieStillPresent || isExpired) {
            return t('Your session has expired');
          }
          return t('Your session will expire in {{countDownInSeconds}}', {
            countDownInSeconds: DateTimeHelper.formatSecondsToMinutesRemaining(
              prevCountDownInSeconds
            ),
          });
        });
        return isExpired ? 0 : prevCountDownInSeconds - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (!didMountRef.current) {
      validateUserAccess();
      sessionStorage.removeItem(IS_COUNTING_SESSION_ID);
      addIdleTimeChecker(IS_COUNTING_SESSION_ID, handleIdleTimeCallback);
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isCloseToIdleLimit ? (
    <ConfirmationModal
      title={alertTitle}
      description={alertDescription}
      cancelButtonLabel={cancelButtonLabel}
      confirmButtonLabel={confirmButtonLabel}
      isOpen={isCloseToIdleLimit}
      onClose={onClose}
      onConfirm={handleRevalidate}
    />
  ) : null;
};

export default LayoutWrapperLogic;
