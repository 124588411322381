import { ContactTypes } from '../contact';
import { IDocumentDto } from './dtos';

export enum DocumentTypes {
  TIN = 'tin', // Tax Identification Number, e.g. CPF, CNPJ, SSN, etc.
}

export enum DocumentStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface IDocumentConfig {
  type: DocumentTypes;
  name: string;
  description: string;
  isMultiple: boolean;
  isUnique: boolean;
  accountTypes: ContactTypes[];
  hasFile: boolean;
  isFileRequired: boolean;
  // TODO: add dto property to validate document value
}

export enum BrazilianTinTypes {
  CPF = 'cpf',
  CNPJ = 'cnpj',
}

export interface IDocumentMetadataTypes {
  [DocumentTypes.TIN]: void;
}

export type DocumentsByTypeMap = Record<
  DocumentTypes,
  IDocumentDto<DocumentTypes>[]
>;
