import { Partners } from '../partners';

export enum AccountTypes {
  INDIVIDUAL = 'individual',
  BUSINESS = 'business',
}

export enum AccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
}

export interface IAccountPartnerData<TPartner extends Partners> {
  partner: TPartner;
  partnerAccountId: string | number;
}

export interface IOzCambioAccountPartnerData
  extends IAccountPartnerData<Partners.OZ_CAMBIO> {
  proposalSubmittedAt?: Date;
  proposalStatus?: string;
  proposalStatusUpdatedAt?: Date;
  proposalStatusReason?: string;
  proposalApprovedAt?: Date;
}

interface IAccountPartnerDataTypes {
  [Partners.OZ_CAMBIO]: IOzCambioAccountPartnerData;
  [Partners.RENDIMENTO]: never;
}

export type AccountPartnerDataMap = {
  [key in Partners]?: IAccountPartnerDataTypes[Partners];
};
