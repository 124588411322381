import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { Flex } from '@chakra-ui/react';
import { Currencies, IGlobalAccountDto } from '@waygee/shared-types';
import { ApiNames, getRequestConfig } from '../../../common/apis';
import { axiosCustomInstance } from '../../../common/network';
import { OptionItem } from '../../../common/types';
import { AuthContext } from '../../../contexts/AuthContext/AuthContext';
import { GlobalAccountProps } from '../components/GlobalAccount/GlobalAccount';

const useGlobalAccount = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<OptionItem[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<OptionItem | null>(
    null
  );
  const [account, setAccount] = useState<IGlobalAccountDto | undefined>();
  const { authToken } = useContext(AuthContext);

  const handleChangeCurrency = (option: SingleValue<OptionItem>) => {
    setSelectedCurrency(option);
  };

  const loadGlobalAccount = useCallback(() => {
    setIsLoading(true);
    const selectedCurrencyKey = selectedCurrency?.value;
    if (selectedCurrencyKey) {
      axiosCustomInstance
        .request<IGlobalAccountDto>(
          getRequestConfig({
            endpoint: ApiNames.GET_GLOBAL_ACCOUNT,
            token: authToken,
            params: selectedCurrencyKey,
          })
        )
        .then(({ data }) => {
          if (data?.beneficiaryBank) {
            setAccount(data);
          } else {
            setAccount(undefined);
          }
        })
        .catch(() => setAccount(undefined))
        .finally(() => setIsLoading(false));
    }
  }, [authToken, selectedCurrency?.value]);

  const loadCurrencies = useCallback(() => {
    const currencies: OptionItem[] = Object.keys(Currencies)
      .filter((c) => c !== Currencies.BRL.toUpperCase())
      .map((key) => ({
        value: Currencies[key as keyof typeof Currencies],
        label: (
          <Flex flexDir="row" gap="5px">
            <img
              src={`/assets/images/${
                Currencies[key as keyof typeof Currencies]
              }.svg`}
              alt="currency"
              width="20"
              height="20"
            />
            {t(Currencies[key as keyof typeof Currencies])}
          </Flex>
        ),
      }));
    setCurrencies(currencies);
    setSelectedCurrency(
      currencies.find((c) => c.value === Currencies.USD) || null
    );
  }, [t]);

  const globalAccountProps: GlobalAccountProps = {
    isLoading,
    selectedCurrency,
    currencies,
    handleChangeCurrency,
    account,
  };

  useEffect(() => {
    loadGlobalAccount();
  }, [loadGlobalAccount]);

  useEffect(() => {
    loadCurrencies();
  }, [loadCurrencies]);

  return { globalAccountProps };
};

export default useGlobalAccount;
