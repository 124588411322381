var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div style=\"text-align: center\">\n  <div>\n    <div style=\"font-family: inherit; text-align: inherit\">\n      <span\n        >Uma solicitação de recuperação de senha foi realizada. Clique no link\n        abaixo para prosseguir em nosso ambiente seguro.</span\n      >\n    </div>\n    <div></div>\n  </div>\n\n  <a\n    href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"RESET_PASSWORD_REDIRECT_URL") || (depth0 != null ? lookupProperty(depth0,"RESET_PASSWORD_REDIRECT_URL") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"RESET_PASSWORD_REDIRECT_URL","hash":{},"data":data,"loc":{"start":{"line":13,"column":10},"end":{"line":13,"column":41}}}) : helper)))
    + "\"\n    style=\"\n      background-color: #eeeef7;\n      border: 1px solid #b0b0d9;\n      border-color: #b0b0d9;\n      border-radius: 40px;\n      border-width: 1px;\n      color: #3f4259;\n      display: inline-block;\n      font-size: 15px;\n      font-weight: normal;\n      letter-spacing: 0px;\n      line-height: 25px;\n      padding: 12px 18px 10px 18px;\n      text-align: center;\n      text-decoration: none;\n      border-style: solid;\n      font-family: inherit;\n      width: 168px;\n    \"\n    target=\"_blank\"\n    >Recuperar Senha</a\n  >\n\n  <div>\n    <div style=\"font-family: inherit; text-align: center\">\n      <span\n        >Caso não tenha sido você, entre em contato com nosso time de\n        atendimento em help@waygee.io</span\n      >\n    </div>\n    <div></div>\n  </div>\n</div>\n";
},"useData":true});