import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Center,
  Flex,
  Heading,
  Stack,
  useColorModeValue,
  Text,
  Image,
  Link,
  Divider,
} from '@chakra-ui/react';
import { FormatHelper } from '@waygee/common';
import { Currencies } from '@waygee/shared-types';
import { PageContainer } from '../../common/components/PageContainer';
import useAccount from './hooks/useAccount';

const Account = () => {
  const { backgroundImage, userImage, user } = useAccount();
  const { t } = useTranslation();
  return (
    <PageContainer title={t('Account')} subTitle={t('Your account overview')}>
      <Box
        maxW={{ base: 'full', md: '620px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}
      >
        <Flex flexWrap={'wrap'} justifyContent={'center'} gap="30px">
          <Center py={6}>
            <Box
              maxW={'270px'}
              w={'full'}
              bg={useColorModeValue('white', 'gray.800')}
              boxShadow={'2xl'}
              rounded={'md'}
              overflow={'hidden'}
            >
              <Image
                h={'120px'}
                w={'full'}
                src={backgroundImage}
                objectFit="cover"
                alt="#"
              />
              <Flex justify={'center'} mt={-12}>
                <Avatar
                  size={'xl'}
                  src={userImage}
                  css={{
                    border: '2px solid white',
                  }}
                />
              </Flex>

              <Box p={6}>
                <Stack spacing={0} align={'center'} mb={5}>
                  <Heading
                    fontSize={'2xl'}
                    fontWeight={500}
                    fontFamily={'body'}
                  >
                    {user?.ownerIndividualContact?.legalName}
                  </Heading>
                </Stack>

                <Stack direction={'row'} justify={'center'} spacing={6}>
                  {false && (
                    <Stack spacing={0} align={'center'}>
                      <Text fontWeight={600}>
                        {FormatHelper.formatCurrency(0, Currencies.USD)}
                        {/* TODO: get the balance object */}
                      </Text>
                      <Text fontSize={'sm'} color={'gray.500'}>
                        {t('Balance')}
                      </Text>
                    </Stack>
                  )}
                </Stack>
              </Box>
            </Box>
          </Center>
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent={'center'}
            gap="10px"
          >
            <Link>{t('Edit personal details')}</Link>
            <Link href="secure/account/change-password">
              {t('Edit password details')}
            </Link>
            <Link>{t('Configure Two factor authentication')}</Link>
            <Divider></Divider>
            <Link>{t('Request limit change')}</Link>
            <Link>{t('Contact the support')}</Link>
          </Flex>
        </Flex>
      </Box>
    </PageContainer>
  );
};

export default Account;
