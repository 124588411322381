import i18n from '../../common/configuration/i18n';

i18n.addResourceBundle('en', 'onboarding', {
  Onboarding: 'Onboarding',
  'Fill in your personal information to start using Waygee':
    'Fill in your personal information to start using Waygee',
  'Personal data': 'Personal data',
  'Individual data': 'Individual data',
  'Banking data': 'Banking data',
  'Banking details': 'Banking details',
  Documents: 'Documents',
  Complete: 'Complete',
  'Upload physical docs': 'Upload physical docs',
  'Submit for review': 'Submit for review',
  'Is Political Exposed Person': 'Is Political Exposed Person',
  'Is US Person': 'Is US Person',

  'Born American': 'Born American',
  'Naturalized American': 'Naturalized American',
  'Partner In American Company': 'Partner In American Company',
  'Has green card': 'Has green card',
  'Has tax residence in EUA': 'Has tax residence in EUA',
  'Tax Identification Number': 'Tax Identification Number',
  'Documents Upload': 'Documents Upload',
  'Individual document': 'Individual document',
  'Please, you need to submit your individual document, such like your ID card, passport or driver license':
    'Please, you need to submit your individual document, such like your ID card, passport or driver license',
  'Company document': 'Company document',
  'Please, you need to submit your company document, such like your company registration certificate, articles of association or memorandum of association':
    'Please, you need to submit your company document, such like your company registration certificate, articles of association or memorandum of association',
  'Select File': 'Select File',
  'Upload File': 'Upload File',
  'File selected': 'File selected',
  'No file selected': 'No file selected',
  Next: 'Next',
  Back: 'Back',
  'Submit to review': 'Submit to review',
  'Please, make sure that you have filled the correct data. Your registration might be reproved if there are any mismatching':
    'Please, make sure that you have filled the correct data. Your registration might be reproved if there are any mismatching',
  'If you are sure that you have filled the correct data, please click on the button below to submit your registration':
    'If you are sure that you have filled the correct data, please click on the button below to submit your registration',
  'Born as American': 'Born as American',
  'Tax Identification number required for US tax residents':
    'Tax Identification number required for US tax residents',
  'Bank Name is required': 'Bank Name is required',
  'Branch code is required': 'Branch code is required',
  'Account number is required': 'Account number is required',
  'Bank code is required': 'Bank code is required',
  Name: 'Name',
  'Error saving individuals data': 'Error saving individuals data',
  'Please, try again later or ask for help':
    'Please, try again later or ask for help',
  'Error saving banking account data': 'Error saving banking account data',
  'Please, upload all the required documents':
    'Please, upload all the required documents',
  'All documents are required': 'All documents are required',
  'You have already uploaded a file previously':
    'You have already uploaded a file previously',
  'We are reviewing your registration': 'We are reviewing your registration',
  'This process may take a few days. We will notify you as soon as your registration is approved. If you need support, please reach out to us.':
    'This process may take a few days. We will notify you as soon as your registration is approved. If you need support, please reach out to us.',
  'Our team have found some pending with your data, please check and fix that':
    'Our team have found some pending with your data, please check and fix that',
});

i18n.addResourceBundle('ptbr', 'onboarding', {
  Onboarding: 'Dados cadastrais',
  'Fill in your personal information to start using Waygee':
    'Confirme suas informações pessoais para começar a usar o Waygee',
  'Personal data': 'Dados pessoais',
  'Individual data': 'Dados dos sócios',
  'Banking data': 'Dados bancários',
  'Banking details': 'Detalhes bancários',
  Documents: 'Documentos',
  Complete: 'Finalizar',
  'Upload physical docs': 'Envie seus documentos',
  'Submit for review': 'Enviar para análise',
  'Is Political Exposed Person': 'É Pessoa Politicamente Exposta',
  'Is US Person': 'É Pessoa Americana',
  'Born American': 'Nascido Americano',
  'Naturalized American': 'Naturalizado Americano',
  'Partner In American Company': 'Sócio de Empresa Americana',
  'Has green card': 'Tem Green Card',
  'Has tax residence in EUA': 'Tem Residência Fiscal nos EUA',
  'Tax Identification Number': 'Número de Identificação Fiscal (S.S.N)',
  'Documents Upload': 'Envio de documentos',
  'Individual document': 'Documento do sócio',
  'Please, you need to submit your individual document, such like your ID card, passport or driver license':
    'Por favor, envie seu documento individual de cada sócio, como seu RG, passaporte ou carteira de motorista',
  'Company document': 'Documento da empresa',
  'Please, you need to submit your company document, such like your company registration certificate, articles of association or memorandum of association':
    'Por favor, envie seu documento da empresa, como seu contrato social, estatuto ou ata de constituição',
  'Select File': 'Selecionar arquivo',
  'Upload File': 'Enviar arquivo',
  'File selected': 'Arquivo selecionado',
  'No file selected': 'Nenhum novo arquivo selecionado',
  Next: 'Próximo',
  Back: 'Voltar',
  'Submit to review': 'Enviar para análise',
  'Please, make sure that you have filled the correct data. Your registration might be reproved if there are any mismatching':
    'Você esta a um passo de finalizar seu cadastro. Caso precise alterar alguma informação, por favor clique em voltar e faça as alterações necessárias',
  'If you are sure that you have filled the correct data, please click on the button below to submit your registration':
    'Caso tudo esteja correto, por favor clique no botão abaixo para enviar seu cadastro para analise',
  'Born as American': 'Nascido Americano',
  'Tax Identification number required for US tax residents':
    'Número de Identificação Fiscal é obrigatório para residentes fiscais nos EUA',
  'Bank Name is required': 'Nome do banco é obrigatório',
  'Branch code is required': 'Código da agência é obrigatório',
  'Account number is required': 'Número da conta é obrigatório',
  'Bank code is required': 'Banco é obrigatório',
  Name: 'Nome',
  'Error saving individuals data': 'Erro ao salvar dados dos sócios',
  'Please, try again later or ask for help':
    'Por favor, tente novamente mais tarde ou entre em contato com o suporte',
  'Error saving banking account data': 'Erro ao salvar dados bancários',
  'Please, upload all the required documents':
    'Por favor, envie todos os documentos necessários',
  'All documents are required': 'Todos os documentos são obrigatórios',
  'You have already uploaded a file previously':
    'Você já enviou um arquivo anteriormente. Caso queira você pode enviar novos.',
  'We are reviewing your registration': 'Estamos analisando seu cadastro',
  'This process may take a few days. We will notify you as soon as your registration is approved. If you need support, please reach out to us.':
    'Esse processo pode levar alguns dias. Entraremos em contato assim que seu cadastro for aprovado. Se precisar de suporte, entre em contato conosco.',
  'Our team have found some pending with your data, please check and fix that':
    'Nossa equipe encontrou pendências com seus dados, por favor verifique e as corrija',
});
