import { Stack, Flex, Image } from '@chakra-ui/react';
import { PageContainer } from '../../common/components/PageContainer';
import { DashboardExchangeRate } from './components/DashboardExchangeRate';
import { DashboardQuickActions } from './components/DashboardQuickActions';
import { DashboardWelcome } from './components/DashboardWelcome';
import useDashboard from './hooks/useDashboard';
import './Dashboard.language';

const Dashboard = () => {
  const { t } = useDashboard();
  return (
    <PageContainer title={t('Dashboard')} subTitle={t('Your account overview')}>
      <Stack>
        <Flex wrap="wrap" gap="15px">
          <Flex flexDir="column" gap="30px">
            {/* <DashboardBalance
              onChangeBalanceVisibility={setShowBalance}
              balanceVisibility={showBalance}
            /> */}
            <DashboardQuickActions />
            <Image
              src="/assets/images/ban-home-2.png"
              height="250px"
              display={{ base: 'none', md: 'none', lg: 'block' }}
            />
          </Flex>
          <Flex flexDir="column" wrap="wrap" gap="15px">
            <DashboardWelcome />
            <DashboardExchangeRate />
          </Flex>
        </Flex>
      </Stack>
    </PageContainer>
  );
};

export default Dashboard;
