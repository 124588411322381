import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColorResult } from 'react-color';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  Flex,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { IInvoiceConfigDto } from '@waygee/shared-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import '../../Invoice.language';
import { ApiNames, getRequestConfig } from '../../../../common/apis';
import {
  TableGridDataRowProps,
  TableGridProps,
} from '../../../../common/components/TableGrid/TableGrid';
import {
  ChakraColors,
  DefaultInvoicePalletColor,
} from '../../../../common/configuration/ChakraColors';
import { axiosCustomInstance } from '../../../../common/network';

const INVOICE_CONFIG_NEW_IDENTIFIER = 'new';

const useInvoiceConfig = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [invoiceToDelete, setInvoiceToDelete] = useState<string>('');
  const [invoiceConfigs, setInvoiceConfigs] = useState<IInvoiceConfigDto[]>([]);
  const [tableGridProps, setTableGridProps] = useState<TableGridProps>({
    isLoading,
  });
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
  const [color, setColor] = useState<string>('#9900EF');
  const { t } = useTranslation('invoice');
  const toast = useToast();
  const colors = DefaultInvoicePalletColor;

  const tableHeaders = useMemo(
    () => [
      {
        content: t('Title'),
      },
      {
        content: t('Currency'),
      },

      {
        content: t('Actions'),
      },
    ],
    [t]
  );

  const validationSchema = Yup.object({
    title: Yup.string().required(t('Title is required')),
    subtitle: Yup.string(),
    address1: Yup.string(),
    address2: Yup.string(),
    phone: Yup.string(),
    email: Yup.string().nullable().email(t('Invalid email address')),
    currency: Yup.string().required(t('Currency is required')),
    sendMail: Yup.boolean(),
    id: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      subtitle: '',
      address1: '',
      address2: '',
      phone: '',
      email: null,
      currency: '',
      sendMail: false,
      id: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      const { id, ...data } = values;
      const promise = axiosCustomInstance
        .request<IInvoiceConfigDto>(
          getRequestConfig({
            endpoint:
              values.id && values.id !== INVOICE_CONFIG_NEW_IDENTIFIER
                ? ApiNames.UPDATE_INVOICE_CONFIG
                : ApiNames.CREATE_INVOICE_CONFIG,
            data: { ...data, color },
            params: values.id,
          })
        )
        .then(() => {
          setFieldValue('id', null);
          loadInvoiceConfigs();
        })
        .finally(() => setIsLoading(false));

      toast.promise(promise, {
        success: {
          title: t('Success!'),
          description: t('Invoice configuration saved'),
          isClosable: true,
        },
        error: {
          title: t('Failed'),
          description: t('Something wrong'),
          isClosable: true,
        },
        loading: {
          title: t('Saving invoice configuration'),
          description: t('Please wait...'),
          isClosable: true,
        },
      });
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const handleDelete = useCallback(
    (id: string) => {
      setInvoiceToDelete(id);
      onOpen();
    },
    [onOpen]
  );

  const handleEdit = useCallback(
    (id: string) => {
      const invoiceConfig = invoiceConfigs.find((config) => config.id === id);
      if (invoiceConfig) {
        setFieldValue('title', invoiceConfig.title);
        setFieldValue('subtitle', invoiceConfig.subtitle);
        setFieldValue('address1', invoiceConfig.address1);
        setFieldValue('address2', invoiceConfig.address2);
        setFieldValue('phone', invoiceConfig.phone);
        setFieldValue('email', invoiceConfig.email);
        setFieldValue('currency', invoiceConfig.currency);
        setFieldValue('sendMail', invoiceConfig.sendMail);
        setFieldValue('id', invoiceConfig.id);
        setColor(invoiceConfig.color);
      }
    },
    [invoiceConfigs, setFieldValue]
  );

  const addRow = useCallback(() => {
    return {
      cells: [
        {
          content: (
            <Center>
              <Button
                variant={'outline'}
                colorScheme="primary"
                onClick={() =>
                  setFieldValue('id', INVOICE_CONFIG_NEW_IDENTIFIER)
                }
              >
                {t('Add new Configuration')}
              </Button>
            </Center>
          ),
          colSpan: 3,
        },
      ],
    } as TableGridDataRowProps;
  }, [setFieldValue, t]);

  const loadInvoiceConfigs = useCallback(() => {
    setIsLoading(true);

    axiosCustomInstance
      .request<IInvoiceConfigDto[]>(
        getRequestConfig({
          endpoint: ApiNames.GET_INVOICE_CONFIG,
        })
      )
      .then((response) => {
        setInvoiceConfigs(response.data);
      })
      .catch(() => {
        setInvoiceConfigs([]);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleDeleteConfirmation = useCallback(() => {
    const promise = axiosCustomInstance
      .request(
        getRequestConfig({
          endpoint: ApiNames.DELETE_INVOICE_CONFIG,
          params: invoiceToDelete,
        })
      )
      .then(() => setInvoiceToDelete(''))
      .finally(() => {
        loadInvoiceConfigs();
        onClose();
      });

    toast.promise(promise, {
      success: {
        title: t('Success!'),
        description: t('Invoice configuration deleted'),
        isClosable: true,
      },
      error: {
        title: t('Failed'),
        description: t('Something wrong'),
        isClosable: true,
      },
      loading: {
        title: t('Deleting invoice configuration'),
        description: t('Please wait...'),
        isClosable: true,
      },
    });
  }, [invoiceToDelete, loadInvoiceConfigs, onClose, t, toast]);

  const handlePreviewClose = () => setIsPreviewVisible(false);
  const handlePreviewOpen = () => setIsPreviewVisible(true);

  const handleCollorSelected = (colorResult: ColorResult) =>
    setColor(colorResult.hex);

  const invoiceData: Partial<IInvoiceConfigDto> = {
    title: values.title,
    subtitle: values.subtitle,
    color: color,
  };

  useEffect(() => {
    const rowsWithData = invoiceConfigs?.map((invoiceConfig) => ({
      cells: [
        {
          content: invoiceConfig.title,
        },
        {
          content: invoiceConfig.currency,
        },
        {
          content: (
            <Flex gap="10px">
              <DeleteIcon
                onClick={() => handleDelete(invoiceConfig.id)}
                color={ChakraColors.info}
                _hover={{
                  cursor: 'pointer',
                  color: ChakraColors.hover,
                }}
              />{' '}
              <EditIcon
                onClick={() => handleEdit(invoiceConfig.id)}
                color={ChakraColors.info}
                _hover={{
                  cursor: 'pointer',
                  color: ChakraColors.hover,
                }}
              />
            </Flex>
          ),
        },
      ],
    })) as TableGridDataRowProps[];
    const props = {
      isLoading,
      headers: tableHeaders,
      rows: [addRow(), ...rowsWithData],
    };
    setTableGridProps(props);
  }, [
    addRow,
    handleDelete,
    handleEdit,
    invoiceConfigs,
    isLoading,
    t,
    tableHeaders,
  ]);

  useEffect(() => {
    loadInvoiceConfigs();
  }, [loadInvoiceConfigs]);

  return {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    tableGridProps,
    setFieldValue,
    isOpen,
    onOpen,
    onClose,
    handleDeleteConfirmation,
    color,
    handleCollorSelected,
    colors,
    handlePreviewClose,
    isPreviewVisible,
    handlePreviewOpen,
    invoiceData,
  };
};

export default useInvoiceConfig;
