import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';
import { IContactDto } from '@waygee/shared-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './Contact.language';
import { ApiNames, getRequestConfig } from '../../common/apis';
import { axiosCustomInstance } from '../../common/network';

const useContact = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation('contact');
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('Invalid email address'))
      .trim()
      .max(100, t('Must be 100 characters or less'))
      .required(t('Email is required')),
    name: Yup.string().required(t('Name is required')),
    message: Yup.string().required(t('Message is required')),
  });

  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      message: '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (!isLoading) {
        setIsLoading(true);
        const promise = axiosCustomInstance
          .request<IContactDto>(
            getRequestConfig({
              endpoint: ApiNames.CONTACT,
              data: {
                email: values.email,
                name: values.name,
                message: values.message,
              },
            })
          )
          .finally(() => setIsLoading(false));

        toast.promise(promise, {
          success: {
            title: t('Message received'),
            description: t('We will contact you soon'),
            isClosable: true,
          },
          error: {
            title: t('Message failed'),
            description: t('Something wrong'),
            isClosable: true,
          },
          loading: {
            title: t('Sending message'),
            description: t('Please wait...'),
            isClosable: true,
          },
        });
      }
    },
  });

  const { errors, setFieldValue, getFieldProps, touched, handleSubmit } =
    formik;

  return {
    isLoading,
    errors,
    getFieldProps,
    touched,
    handleSubmit,
    setFieldValue,
  };
};

export default useContact;
