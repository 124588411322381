import { cpf, cnpj } from 'cpf-cnpj-validator';

export function isValidCPF(value: string): boolean {
  if (typeof value !== 'string') return false;

  // only cpf with exactly 11 numbers or with mask (999.999.999-99) are valid
  if (!/^\d{3}\.\d{3}\.\d{3}-\d{2}$|^\d{11}$/.test(value)) {
    return false;
  }

  return cpf.isValid(value, true);
}

export function isValidCNPJ(value: string): boolean {
  if (typeof value !== 'string') return false;

  // only cnpj with exactly 14 numbers or with mask (99.999.999/9999-99) are valid
  if (!/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$|^\d{14}$/.test(value)) {
    return false;
  }

  return cnpj.isValid(value, false);
}
