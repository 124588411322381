import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Divider,
  Link,
  Progress,
} from '@chakra-ui/react';
import { MfaForm } from '../../common/components/MfaForm';
import { ChakraColors } from '../../common/configuration/ChakraColors';
import useLogin from './hooks/useLogin';

const Login = () => {
  const {
    getFieldProps,
    touched,
    handleSubmit,
    errors,
    t,
    isLoading,
    mfaEmailSent,
    values,
    handleChange,
    mfaFormProps,
    showBgImage,
  } = useLogin();

  return (
    <div>
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent="space-around"
        bgColor={ChakraColors.waygee_dark}
        height={{ base: '100%', md: '1000px' }}
        style={{
          backgroundImage: showBgImage
            ? 'url(/assets/images/login-bg.png)'
            : 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url(/assets/images/login-bg.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'initial',
        }}
      >
        <Flex></Flex>
        <Flex
          minH={{ base: 'unset', md: '80vh' }}
          align={'center'}
          justify={'center'}
        >
          <Stack
            spacing={3}
            mx={'auto'}
            maxW={'lg'}
            py={12}
            px={6}
            minH={'800px'}
          >
            <Flex
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              gap="20px"
            >
              <img src="assets/images/logo.png" width="350px" alt="Waygee" />
              <Divider />

              <Stack align={'center'}>
                <Heading fontSize={'4xl'} color="primary.100">
                  {t('Sign in to your account')}
                </Heading>
                <Text fontSize={'lg'} color={'gray.100'}>
                  {t('to enjoy all of our cool')}{' '}
                  <Text color={'blue.100'}>{t('features')}.</Text> ✌️
                </Text>
              </Stack>
            </Flex>
            {isLoading && (
              <Progress size="xs" isIndeterminate colorScheme="primary" />
            )}
            <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}
            >
              {mfaEmailSent && <MfaForm {...mfaFormProps} />}
              {!mfaEmailSent && (
                <Stack spacing={4}>
                  <form onSubmit={handleSubmit}>
                    <FormControl
                      id="email"
                      isInvalid={(touched.email && !!errors.email) || false}
                    >
                      <FormLabel>{t('Email address')}</FormLabel>
                      <Input type="email" {...getFieldProps('email')} />
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="password"
                      isInvalid={
                        (touched.password && !!errors.password) || false
                      }
                    >
                      <FormLabel>{t('Password')}</FormLabel>
                      <Input type="password" {...getFieldProps('password')} />
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                    <Stack spacing={10}>
                      <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        align={'start'}
                        justify={'space-between'}
                      >
                        <Checkbox
                          isChecked={values.rememberMe}
                          onChange={handleChange}
                          name="rememberMe"
                          colorScheme="primary"
                        >
                          {t('Remember me')}
                        </Checkbox>
                        <Link href="secure/password-reset" color={'blue.400'}>
                          {t('Forgot password')}?
                        </Link>
                      </Stack>
                      <Button
                        colorScheme="primary"
                        type="submit"
                        isDisabled={isLoading}
                      >
                        {t('Sign in')}
                      </Button>
                    </Stack>
                  </form>
                </Stack>
              )}
            </Box>
            <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}
            >
              <Text fontSize="small">
                {t('Do not have an account yet?')}&nbsp;
                <Link href="/secure/signup">{t('Click here to get one!')}</Link>
              </Text>
            </Box>
          </Stack>
        </Flex>
      </Flex>
    </div>
  );
};

export default Login;
