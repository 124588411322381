import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, DownloadIcon } from '@chakra-ui/icons';
import { Flex, useDisclosure, useToast } from '@chakra-ui/react';
import { DateTimeHelper, FormatHelper } from '@waygee/common';
import { IInvoiceDto } from '@waygee/shared-types';
import '../../../Invoice.language';

import { ApiNames, getRequestConfig } from '../../../../../common/apis';
import {
  TableGridDataRowProps,
  TableGridProps,
} from '../../../../../common/components/TableGrid/TableGrid';
import { ChakraColors } from '../../../../../common/configuration/ChakraColors';
import { axiosCustomInstance } from '../../../../../common/network';
import { handleDownloadResponse } from '../../../../../common/network/utils/downloadResponse';

const dateFormat = 'dd/LL/yyyy';

const useInvoiceHistory = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [idToDelete, setIdToDelete] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<IInvoiceDto[]>([]);
  const [tableGridProps, setTableGridProps] = useState<TableGridProps>({
    isLoading,
  });
  const { t } = useTranslation('invoice');
  const toast = useToast();

  const tableHeaders = useMemo(
    () => [
      {
        content: t('Number'),
      },
      {
        content: t('Date'),
      },
      {
        content: t('Due Date'),
      },
      {
        content: t('Payer Name'),
      },
      {
        content: t('Amount'),
      },
      {
        content: t('Actions'),
      },
    ],
    [t]
  );

  const handleDownload = useCallback(
    (id: string) => {
      const thisInvoice = invoice.find((i) => i.id === id);
      const promise = axiosCustomInstance
        .request(
          getRequestConfig({
            endpoint: ApiNames.DOWNLOAD_INVOICE,
            params: id,
            headers: {
              Accept: 'application/pdf',
            },
            responseType: 'arraybuffer',
          })
        )
        .then((response) => {
          handleDownloadResponse(
            response,
            `INVOICE_${thisInvoice?.invoiceNumber}_${thisInvoice?.configData.title}.pdf`
          );
        })
        .finally(() => setIsLoading(false));

      toast.promise(promise, {
        success: {
          title: t('Invoice downloaded'),
          description: t('Invoice downloaded successfully'),
          isClosable: true,
        },
        error: {
          title: t('Failed'),
          description: t('Something wrong'),
          isClosable: true,
        },
        loading: {
          title: t('Downloading invoice'),
          description: t('Please wait...'),
          isClosable: true,
        },
      });
    },
    [invoice, t, toast]
  );

  const handleRemoveInvoice = useCallback(
    (id: string) => {
      onOpen();
      setIdToDelete(id);
    },
    [onOpen]
  );

  const loadInvoiceHistory = useCallback(() => {
    setIsLoading(true);
    axiosCustomInstance
      .request<IInvoiceDto[]>(
        getRequestConfig({
          endpoint: ApiNames.GET_INVOICES,
        })
      )
      .then((response) => {
        setInvoice(response.data);
      })
      .catch(() => {
        setInvoice([]);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleRemoveBankAccount = useCallback(
    (id?: string | number) => {
      const promise = axiosCustomInstance
        .request(
          getRequestConfig({
            endpoint: ApiNames.DELETE_INVOICE,
            params: id,
          })
        )
        .then(() => {
          loadInvoiceHistory();
        })
        .finally(() => {
          setIdToDelete('');
          onClose();
        });
      toast.promise(promise, {
        success: {
          title: t('Success!'),
          description: t('Invoice deleted successful'),
          isClosable: true,
        },
        error: {
          title: t('Invoice deletion failed'),
          description: t('Something wrong'),
          isClosable: true,
        },
        loading: {
          title: t('Deleting Invoice'),
          description: t('Please wait...'),
          isClosable: true,
        },
      });
    },
    [loadInvoiceHistory, onClose, t, toast]
  );

  useEffect(() => {
    const rowsWithData = invoice?.map((invoice) => ({
      cells: [
        {
          content: invoice.invoiceNumber,
        },
        {
          content: DateTimeHelper.formatUtc(invoice.date, dateFormat),
        },
        {
          content: invoice.dueDate
            ? DateTimeHelper.formatUtc(invoice.dueDate, dateFormat)
            : '',
        },
        {
          content: invoice.payerData.name,
        },
        {
          content: FormatHelper.formatCurrency(
            invoice.amount,
            invoice.currency
          ),
        },
        {
          content: (
            <Flex gap="10px">
              <DownloadIcon
                onClick={() => handleDownload(invoice.id)}
                color={ChakraColors.info}
                _hover={{
                  cursor: 'pointer',
                  color: ChakraColors.hover,
                }}
              />
              <DeleteIcon
                onClick={() => handleRemoveInvoice(invoice.id)}
                color={ChakraColors.info}
                _hover={{
                  cursor: 'pointer',
                  color: ChakraColors.hover,
                }}
              />
            </Flex>
          ),
        },
      ],
    })) as TableGridDataRowProps[];
    const props = {
      isLoading,
      headers: tableHeaders,
      rows: [...rowsWithData],
    };
    setTableGridProps(props);
  }, [
    handleDownload,
    handleRemoveInvoice,
    invoice,
    isLoading,
    t,
    tableHeaders,
  ]);

  useEffect(() => {
    loadInvoiceHistory();
  }, [loadInvoiceHistory]);

  return {
    tableGridProps,
    onClose,
    isOpen,
    id: idToDelete,
    t,
    onDeleteConfirm: handleRemoveBankAccount,
  };
};

export default useInvoiceHistory;
