export interface IOnboardingStepConfig {
  step: OnboardingSteps;
  title: string;
}

export enum OnboardingSteps {
  ACCOUNT_DATA = 'accountData',
  BANKING_DATA = 'bankingData',
  DOCUMENTS = 'documents',
  REVIEW = 'review',
}

export enum OnboardingStepStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
}
