import { useTranslation } from 'react-i18next';
import {
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  FlexProps,
} from '@chakra-ui/react';
import {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { CNPJInput } from '../../../common/components/CNPJInput';
import { SignUpSchema } from '../types/signUpSchema';
import '../SignUp.language';

export type SignUpBusinessDataProps = {
  flexFieldProps: FlexProps;
  touched: FormikTouched<SignUpSchema>;
  errors: FormikErrors<SignUpSchema>;
  getFieldProps: (
    nameOrOptions:
      | string
      | FieldConfig<string | number | readonly string[] | undefined>
  ) => FieldInputProps<string | number | readonly string[] | undefined>;
};

const SignUpBusinessData = ({
  flexFieldProps,
  touched,
  errors,
  getFieldProps,
}: SignUpBusinessDataProps) => {
  const { t } = useTranslation('signup');
  return (
    <>
      <Heading size="md">{t('Business data')}</Heading>
      <Flex {...flexFieldProps}>
        <Box>
          <FormControl
            id="businessDocumentId"
            isRequired
            isInvalid={(touched.businessCnpj && !!errors.businessCnpj) || false}
          >
            <FormLabel>{t('CNPJ')}</FormLabel>
            <CNPJInput type="text" {...getFieldProps('businessCnpj')} />
            <FormErrorMessage>{errors.businessCnpj}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box minW="100%">
          <FormControl
            id="businessName"
            isRequired
            isInvalid={
              (touched.businessLegalName && !!errors.businessLegalName) || false
            }
          >
            <FormLabel>{t('Business name')}</FormLabel>
            <Input type="text" {...getFieldProps('businessLegalName')} />
            <FormErrorMessage>{errors.businessLegalName}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
    </>
  );
};

export default SignUpBusinessData;
