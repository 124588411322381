import i18n from '../../../../common/configuration/i18n';

i18n.addResourceBundle('en', 'change-password', {
  'Change Password': 'Change Password',
  'Update your secure authentication password':
    'Update your secure authentication password',
  'Current Password': 'Current Password',
  'New Password': 'New Password',
  'Confirm New Password': 'Confirm New Password',
  'Current Password is required': 'Current Password is required',
  'New Password is required': 'New Password is required',
  'Confirm New Password is required': 'Confirm New Password is required',
  'Passwords must match': 'Passwords must match',
  'Password changed': 'Password changed',
  'Your password has been changed': 'Your password has been changed',
  'Password change failed': 'Password change failed',
  'Something wrong': 'Something wrong',
  'Changing password': 'Changing password',
  'Please wait': 'Please wait',
  'Please verify your e-mail with the 6 digits code complete your password change':
    'Please verify your e-mail with the 6 digits code complete your password change',
  'Email sent': 'Email sent',
  'Check your inbox to get the confirmation code':
    'Check your inbox to get the confirmation code',
  'Your password has been successfully changed':
    'Your password has been successfully changed',
  'Success!': 'Success!',
  Wait: 'Wait',
  'seconds to resend the code': 'seconds to resend the code',
  'Resend e-mail': 'Resend e-mail',
});

i18n.addResourceBundle('ptbr', 'change-password', {
  'Change Password': 'Alterar Senha',
  'Update your secure authentication password':
    'Atualize sua senha de autenticação segura',
  'Current Password': 'Senha Atual',
  'New Password': 'Nova Senha',
  'Confirm New Password': 'Confirmar Novar Senha',
  'Current Password is required': 'Senha Atual é obrigatória',
  'New Password is required': 'Nova Senha é obrigatória',
  'Confirm New Password is required': 'Confirmar Nova Senha é obrigatória',
  'Passwords must match': 'As senhas devem ser iguais',
  'Password changed': 'Senha alterada',
  'Your password has been changed': 'Sua senha foi alterada',
  'Password change failed': 'Falha ao alterar senha',
  'Something wrong': 'Algo deu errado',
  'Changing password': 'Alterando senha',
  'Please wait': 'Por favor aguarde',
  'Please verify your e-mail with the 6 digits code complete your password change':
    'Por favor verifique seu e-mail com o código de 6 dígitos para completar a alteração de senha',
  'Email sent': 'E-mail enviado',
  'Check your inbox to get the confirmation code':
    'Verifique sua caixa de entrada para obter o código de confirmação',
  'Your password has been successfully changed':
    'Sua senha foi alterada com sucesso',
  'Success!': 'Finalizado',
  Wait: 'Aguarde',
  'seconds to resend the code': 'segundos para reenviar o código',
  'Resend e-mail': 'Reenviar e-mail',
});
