var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Hello "
    + alias4(((helper = (helper = lookupProperty(helpers,"PAYER_NAME") || (depth0 != null ? lookupProperty(depth0,"PAYER_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PAYER_NAME","hash":{},"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":30}}}) : helper)))
    + ",</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <br />\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"CUSTOMER_NAME") || (depth0 != null ? lookupProperty(depth0,"CUSTOMER_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CUSTOMER_NAME","hash":{},"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":27}}}) : helper)))
    + " generated an invoice for you.</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <br />\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Date: "
    + alias4(((helper = (helper = lookupProperty(helpers,"INVOICE_DATE") || (depth0 != null ? lookupProperty(depth0,"INVOICE_DATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"INVOICE_DATE","hash":{},"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":32}}}) : helper)))
    + "</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Number: "
    + alias4(((helper = (helper = lookupProperty(helpers,"INVOICE_NUMBER") || (depth0 != null ? lookupProperty(depth0,"INVOICE_NUMBER") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"INVOICE_NUMBER","hash":{},"data":data,"loc":{"start":{"line":18,"column":18},"end":{"line":18,"column":36}}}) : helper)))
    + "</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Amount: "
    + alias4(((helper = (helper = lookupProperty(helpers,"INVOICE_AMOUNT") || (depth0 != null ? lookupProperty(depth0,"INVOICE_AMOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"INVOICE_AMOUNT","hash":{},"data":data,"loc":{"start":{"line":21,"column":18},"end":{"line":21,"column":36}}}) : helper)))
    + "</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <br />\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Please, reach out "
    + alias4(((helper = (helper = lookupProperty(helpers,"CUSTOMER_NAME") || (depth0 != null ? lookupProperty(depth0,"CUSTOMER_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CUSTOMER_NAME","hash":{},"data":data,"loc":{"start":{"line":27,"column":28},"end":{"line":27,"column":45}}}) : helper)))
    + " at "
    + alias4(((helper = (helper = lookupProperty(helpers,"CUSTOMER_EMAIL") || (depth0 != null ? lookupProperty(depth0,"CUSTOMER_EMAIL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CUSTOMER_EMAIL","hash":{},"data":data,"loc":{"start":{"line":27,"column":49},"end":{"line":27,"column":67}}}) : helper)))
    + " if you have\n      question about it.</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <br />\n  </div>\n  <div></div>\n</div>";
},"useData":true});