import {
  TemplateTypes,
  TemplatesService,
  IInvoiceTemplateDefaultTemplateData,
} from '@waygee/templates';

export type InvoicePreviewProps = {
  invoiceData: IInvoiceTemplateDefaultTemplateData;
};

const InvoicePreview = ({ invoiceData }: InvoicePreviewProps) => {
  const html = TemplatesService.renderTemplate(
    TemplateTypes.INVOICE_DEFAULT,
    invoiceData
  );
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      style={{ width: '100%', maxHeight: '625px' }}
    />
  );
};

export default InvoicePreview;
