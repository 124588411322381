import { Flex } from '@chakra-ui/react';
import { BuyCrypto } from '../../Crypto/components/BuyCrypto';

const BuyCryptoDemo = () => {
  return (
    <Flex flexDir="column" align="center">
      <BuyCrypto />
    </Flex>
  );
};

export default BuyCryptoDemo;
