import { TemplateTypes, TemplateViewTypesMap } from './templates.definitions';
import { TEMPLATE_COMPILER_BY_TYPE } from './templates.constants';
import { CompiledTemplateFn } from '@waygee/shared-types';
import handlebars from 'handlebars';
import sha256 from 'crypto-js/sha256';

export class TemplatesService {
  private static compiledTemplates = new Map<string, CompiledTemplateFn>();

  static renderTemplate<TType extends TemplateTypes>(
    templateType: TType,
    params: TemplateViewTypesMap[TType]
  ) {
    const compiledTemplate = TEMPLATE_COMPILER_BY_TYPE[templateType];
    if (!compiledTemplate) {
      throw new Error(`Template compiler for type ${templateType} not found`);
    }
    return compiledTemplate(params);
  }

  static renderCustomTemplate<TParams = unknown>(
    template: string,
    params: TParams
  ) {
    const compiledTemplate = TemplatesService.compileTemplate(template);
    return compiledTemplate(params);
  }

  private static compileTemplate(template: string) {
    const templateKey = sha256(template).toString();
    let compiledTemplate = TemplatesService.compiledTemplates.get(templateKey);
    if (!compiledTemplate) {
      compiledTemplate = handlebars.compile(template);
      TemplatesService.compiledTemplates.set(templateKey, compiledTemplate);
    }
    return compiledTemplate;
  }
}
