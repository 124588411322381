import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Switch,
  Text,
} from '@chakra-ui/react';
import '././../../../../Invoice.language';
import { IPayerDto } from '@waygee/shared-types';
import {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { VerticalLabelDivider } from '../../../../../../common/components/VerticalLabelDivider';
import { InvoiceCreateSchema } from '../../types/invoiceCreateSchema';

export type InvoiceCreateNewPayerProps = {
  setIsOptToNewPayer: (value: boolean) => void;
  invoicePayerOptions: IPayerDto[];
  touched: FormikTouched<InvoiceCreateSchema>;
  errors: FormikErrors<InvoiceCreateSchema>;
  getFieldProps: (
    nameOrOptions: string | FieldConfig
  ) => FieldInputProps<string | number>;
  isLoggedIn: boolean;
  values: InvoiceCreateSchema;
};

const InvoiceCreateNewPayer = ({
  errors,
  getFieldProps,
  invoicePayerOptions,
  setIsOptToNewPayer,
  touched,
  isLoggedIn,
  values,
}: InvoiceCreateNewPayerProps) => {
  const { t } = useTranslation('invoice');
  return (
    <>
      <Flex flexDir="row" gap="10px" align="center">
        <Heading size="sm">{t('Your Payer Data')}</Heading>
        <VerticalLabelDivider />
        {invoicePayerOptions.length > 0 && (
          <Button
            leftIcon={<FaArrowAltCircleLeft />}
            colorScheme="primary"
            variant="solid"
            size="xs"
            onClick={() => setIsOptToNewPayer(false)}
          >
            {t('Select existing')}
          </Button>
        )}
        {isLoggedIn && (
          <>
            <Switch
              size="sm"
              isChecked={values.savePayer}
              {...getFieldProps('savePayer')}
              colorScheme="primary"
            />
            <Text fontSize="smaller">{t('Save for use again later')}</Text>
          </>
        )}
      </Flex>
      <Flex flexDir="row" gap="20px" wrap={{ base: 'wrap', md: 'unset' }}>
        <Box w={{ base: '100%', md: '200px' }}>
          <FormControl
            id="payerName"
            isRequired
            isInvalid={(touched.payerName && !!errors.payerName) || false}
          >
            <FormLabel>{t('Payer Name')}</FormLabel>
            <Input type="text" {...getFieldProps('payerName')} />
            <FormErrorMessage>{errors.payerName}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box w="200px">
          <FormControl
            id="payerPhone"
            isInvalid={(touched.payerPhone && !!errors.payerPhone) || false}
          >
            <FormLabel>{t('Phone')}</FormLabel>
            <Input type="text" {...getFieldProps('payerPhone')} />
            <FormErrorMessage>{errors.payerPhone}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box w="350px">
          <FormControl
            id="payerEmail"
            isInvalid={(touched.payerEmail && !!errors.payerEmail) || false}
          >
            <FormLabel>{t('E-mail')}</FormLabel>
            <Input type="text" {...getFieldProps('payerEmail')} />
            <FormErrorMessage>{errors.payerEmail}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
      <Flex flexDir="row" gap="20px" wrap={{ base: 'wrap', md: 'unset' }}>
        <Box w="100%">
          <FormControl
            id="payerAddress1"
            isInvalid={
              (touched.payerAddress1 && !!errors.payerAddress1) || false
            }
          >
            <FormLabel>{t('Address')}</FormLabel>
            <Input type="text" {...getFieldProps('payerAddress1')} />
            <FormErrorMessage>{errors.payerAddress1}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box w="100%">
          <FormControl
            id="payerAddress2"
            isInvalid={
              (touched.payerAddress2 && !!errors.payerAddress2) || false
            }
          >
            <FormLabel>{t('Address 2')}</FormLabel>
            <Input type="text" {...getFieldProps('payerAddress2')} />
            <FormErrorMessage>{errors.payerAddress2}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>

      <Divider />
    </>
  );
};

export default InvoiceCreateNewPayer;
