import {
  Button,
  Card,
  Center,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Link,
  PinInput,
  Text,
  PinInputField,
  Progress,
  Heading,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import { PageContainer } from '../../../../common/components/PageContainer';
import { ChakraColors } from '../../../../common/configuration/ChakraColors';
import useChangePassword from './hooks/useChangePassword';

const ChangePassword = () => {
  const {
    t,
    errors,
    handleSubmit,
    touched,
    getFieldProps,
    isLoading,
    mfaEmailSent,
    setFieldValue,
    handleMfaCodeValidation,
    countdown,
    handleResendMfaCode,
    isCounting,
    isCompleted,
  } = useChangePassword();
  return (
    <PageContainer
      title={t('Change Password')}
      subTitle={t('Update your secure authentication password')}
    >
      <Card padding="20px" minW="500px">
        <form onSubmit={handleSubmit}>
          <Collapse in={!isCompleted} animateOpacity>
            <Stack spacing={4}>
              {isLoading && (
                <Progress size="xs" isIndeterminate colorScheme="primary" />
              )}
              <FormControl
                isRequired
                isInvalid={
                  (touched.oldPassword && !!errors.oldPassword) || false
                }
              >
                <FormLabel>{t('Current Password')}</FormLabel>
                <Input
                  type="password"
                  {...getFieldProps('oldPassword')}
                  isDisabled={mfaEmailSent}
                />
                <FormErrorMessage>
                  {touched.oldPassword && errors.oldPassword}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={
                  (touched.newPassword && !!errors.newPassword) || false
                }
              >
                <FormLabel>{t('New Password')}</FormLabel>
                <Input type="password" {...getFieldProps('newPassword')} />
                <FormErrorMessage>
                  {touched.newPassword && errors.newPassword}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={
                  (touched.confirmNewPassword && !!errors.confirmNewPassword) ||
                  false
                }
              >
                <FormLabel>{t('Confirm New Password')}</FormLabel>
                <Input
                  type="password"
                  {...getFieldProps('confirmNewPassword')}
                />
                <FormErrorMessage>
                  {touched.confirmNewPassword && errors.confirmNewPassword}
                </FormErrorMessage>
              </FormControl>
              <StackDivider />
              <Collapse in={mfaEmailSent} animateOpacity>
                <Stack spacing={4}>
                  <Flex
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                    gap="20px"
                  >
                    <Text>
                      {t(
                        'Please verify your e-mail with the 6 digits code complete your password change'
                      )}
                    </Text>
                    <FormControl
                      id="mfaCode"
                      isInvalid={(touched.mfaCode && !!errors.mfaCode) || false}
                    >
                      <Flex
                        flexDir="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <HStack>
                          <PinInput
                            onChange={(mfa) => setFieldValue('mfaCode', mfa)}
                          >
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                          </PinInput>
                        </HStack>
                      </Flex>
                      <Center>
                        <FormErrorMessage>
                          {touched.mfaCode && errors.mfaCode}
                        </FormErrorMessage>
                      </Center>
                    </FormControl>
                    <Button
                      colorScheme="green"
                      onClick={handleMfaCodeValidation}
                      isDisabled={isLoading}
                    >
                      {t('Validate')}
                    </Button>
                    <Link
                      fontSize="small"
                      onClick={isCounting ? undefined : handleResendMfaCode}
                      cursor={isCounting ? 'not-allowed' : 'pointer'}
                    >
                      {isCounting
                        ? `${t('Wait')} ${countdown} ${t(
                            'seconds to resend the code'
                          )}`
                        : t('Resend e-mail')}
                    </Link>
                  </Flex>
                </Stack>
              </Collapse>
              <Button colorScheme="blue" type="submit">
                {t('Change Password')}
              </Button>
            </Stack>
          </Collapse>
          <Collapse in={isCompleted} animateOpacity>
            <Stack spacing={4}>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                minW={{ base: '100%', md: '720px' }}
                minH="200px"
              >
                <Heading size="md" color={ChakraColors.success}>
                  {t('Success!')}!
                </Heading>
                <Text>{t('Your password has been successfully changed')}</Text>
              </Flex>
            </Stack>
          </Collapse>
        </form>
      </Card>
    </PageContainer>
  );
};

export default ChangePassword;
