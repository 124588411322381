import {
  EmailTemplates,
  IEmailTemplatesDataTypes,
} from './templates/emails/emails.templates.definitions';
import {
  IInvoiceTemplatesDataTypes,
  InvoiceTemplates,
} from './templates/invoices/invoices.templates.definitions';

export const TemplateTypes = {
  ...EmailTemplates,
  ...InvoiceTemplates,
} as const;

export type TemplateTypes = (typeof TemplateTypes)[keyof typeof TemplateTypes];

export type TemplateViewTypesMap = IEmailTemplatesDataTypes &
  IInvoiceTemplatesDataTypes;
