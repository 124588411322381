import i18n from '../../common/configuration/i18n';

i18n.addResourceBundle('en', 'dashboard', {
  Dashboard: 'Dashboard',
  'Your account overview': 'Your account overview',
  Balance: 'Balance',
  'Global Account': 'Global Account',
  'Exchange rate': 'Exchange rate',
  'A month ago': 'A month ago',
  Hello: 'Hello',
  'Everything is calm here, disclose your details to your payers to receive payments.':
    'Everything is calm here, disclose your details to your payers to receive payments.',
  'You have': 'You have',
  'available to be transferred to your local account. Click here and check it out.':
    'available to be transferred to your local account',
});

i18n.addResourceBundle('ptbr', 'dashboard', {
  Dashboard: 'Dashboard',
  'Your account overview': 'Visão geral da sua conta',
  Balance: 'Saldo',
  'Global Account': 'Conta Global',
  'Exchange rate': 'Taxa de câmbio',
  'A month ago': 'Um mês atrás',
  Hello: 'Olá',
  'Everything is calm here, disclose your details to your payers to receive payments.':
    'Tudo calmo por aqui, divulgue seus dados para seus pagadores para receber um pagamento.',
  'You have': 'Você tem',
  'available to be transferred to your local account. Click here and check it out.':
    'ordem(s) disponível(s) para ser transferida(s) para sua conta local. Clique aqui e confira.',
});
