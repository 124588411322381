import axios from 'axios';
import { ApiNames, getRequestConfig } from '../apis';
import { AuthToken } from '../types/auth';
import { getAuthTokenCookie } from '../utility/cookies/authTokenStorage';

export const fetchRefreshToken = async () => {
  const authToken = getAuthTokenCookie();
  return axios
    .request<AuthToken>(
      getRequestConfig({
        endpoint: ApiNames.REFRESH_TOKEN,
        token: {
          accessToken: authToken?.refreshToken,
        },
      })
    )
    .then((response) => response.data);
};
