import { useCallback, useContext, useEffect, useState } from 'react';
import './Transference.language';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { Flex, useDisclosure, useToast } from '@chakra-ui/react';
import { FormatHelper } from '@waygee/common';
import {
  Finalities,
  IBankAccountDto,
  Currencies,
  Countries,
  IOrderDto,
  IOperationTaxDto,
  OrderStatus,
  IAccessTokenDto,
} from '@waygee/shared-types';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { ApiNames, getRequestConfig } from '../../common/apis';
import useWithMfaForm, {
  RequestToastOptions,
} from '../../common/components/MfaForm/hooks/useWithMfaForm';
import { MfaModalFormProps } from '../../common/components/MfaModalForm';
import appConfig from '../../common/configuration/AppConfig';
import { axiosCustomInstance } from '../../common/network';
import { OptionItem } from '../../common/types';
import { ExchangeTaxResult } from '../../common/types/exchange';
import { AuthContext } from '../../contexts/AuthContext/AuthContext';

export type UseWithTransferencesProps = {
  order?: IOrderDto;
  handleOnClose: (success?: boolean) => void;
};

const DECIMAL_CASES = 4;

const useWithTransferences = ({
  order,
  handleOnClose,
}: UseWithTransferencesProps) => {
  const [updateCountDown, setUpdateCountDown] = useState<number>(100);
  const { t } = useTranslation('transferences');
  const [isTaxLoading, setIsTaxLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditingAccount, setIsEditingAccount] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const [finalities, setFinalities] = useState<OptionItem[]>([]);
  const [currencies, setCurrencies] = useState<OptionItem[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<OptionItem | null>(
    null
  );
  const [amountToSend, setAmountToSend] = useState<string>('1000');
  const [exchangeTaxResult, setExchangeTaxResult] = useState<
    ExchangeTaxResult | undefined
  >();
  const [selectedFinality, setSelectedFinality] = useState<OptionItem | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [bankAccounts, setBankAccounts] = useState<IBankAccountDto[]>([]);
  const [taxToken, setTaxToken] = useState<string | undefined>();
  const [selectedBankAccount, setSelectedBankAccount] =
    useState<IBankAccountDto | null>(null);
  const [isReadyToTransfer, setIsReadyToTransfer] = useState<boolean>(true);
  const isReadOnly = order?.status !== OrderStatus.AVAILABLE;
  const { disableOrderMfa } = appConfig;

  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const onMfaModalClose = useCallback(() => {
    onClose();
    setIsSubmitting(false);
  }, [onClose]);

  const handleChangeSelectedAccount = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const account = bankAccounts.find(
      (account) => account.id === event.target.value
    );
    if (account) setSelectedBankAccount(account);
    setIsEditingAccount(false);
  };

  const handleChangeCurrency = (option: SingleValue<OptionItem>) => {
    setSelectedCurrency(option);
    setAmountToSend('');
  };

  const handleGoToBankAccounts = () => {
    navigate('/secure/bank-accounts/local');
  };

  const handleChangeFinality = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const finality = finalities.find(
      (finality) => finality.value === event.target.value
    );
    if (finality) setSelectedFinality(finality);
  };

  const handleOnInputCurrencyChangeValue = (value: string) =>
    setAmountToSend(value);

  const getTaxByCurrencyAndFinality = useCallback(async () => {
    if (
      !selectedCurrency ||
      !selectedFinality ||
      !order ||
      order.status !== OrderStatus.AVAILABLE
    )
      return;
    setIsTaxLoading(true);
    const { data: taxResponse } =
      await axiosCustomInstance.request<IOperationTaxDto>(
        getRequestConfig({
          endpoint: ApiNames.OZ_CALCULATE_TAX_BY_ORDER,
          data: {
            finality: selectedFinality?.value,
            country: Countries.UNITED_STATES,
          },
          params: order?.id,
        })
      );
    setTaxToken(taxResponse.taxToken);
    setExchangeTaxResult({
      amountToReceive: FormatHelper.formatCurrency(
        taxResponse?.total ?? 0,
        Currencies.BRL
      ),
      iof: `${taxResponse.iofPercentage * 100}%`,
      ir: `${taxResponse.irPercentage * 100}%`,
      spread: `${taxResponse.spreadPercentage * 100}%`,
      vet: FormatHelper.formatCurrency(
        taxResponse.vet,
        Currencies.BRL,
        DECIMAL_CASES
      ),
      fee:
        taxResponse.fee > 0
          ? FormatHelper.formatCurrency(taxResponse.fee, Currencies.BRL)
          : undefined,
      currencyExchangeValue: FormatHelper.formatCurrency(
        taxResponse.currencyExchangeValue,
        Currencies.BRL,
        DECIMAL_CASES
      ),
    });
    setIsTaxLoading(false);
  }, [order, selectedCurrency, selectedFinality]);

  const loadCurrencies = useCallback(() => {
    const currencies: OptionItem[] = Object.keys(Currencies)
      .filter((c) => c !== Currencies.BRL.toUpperCase())
      .map((key) => ({
        value: Currencies[key as keyof typeof Currencies],
        label: (
          <Flex flexDir="row" gap="5px">
            <img
              src={`/assets/images/${
                Currencies[key as keyof typeof Currencies]
              }.svg`}
              alt="currency"
              width="20"
              height="20"
            />
            {t(Currencies[key as keyof typeof Currencies])}
          </Flex>
        ),
      }));
    setCurrencies(currencies);
  }, [t]);

  const loadFinalities = useCallback(() => {
    const finalities: OptionItem[] = Object.keys(Finalities).map((key) => ({
      value: Finalities[key as keyof typeof Finalities],
      label: t(Finalities[key as keyof typeof Finalities]),
    }));
    setFinalities(finalities);
  }, [t]);

  const loadDestinationAccount = useCallback(() => {
    axiosCustomInstance
      .request<IBankAccountDto>(
        getRequestConfig({
          endpoint: ApiNames.GET_ALL_BANK_ACCOUNTS,
        })
      )
      .then((res) => {
        setBankAccounts([res.data]);
        if (res.data) setSelectedBankAccount(res.data);
      })
      .catch(() => setBankAccounts([]));
  }, []);

  const getSourceCurrencyImg = () => {
    if (selectedCurrency) {
      return `/assets/images/${selectedCurrency.value.toLowerCase()}.svg`;
    }
    return '';
  };

  const firstRequest = {
    endpoint: ApiNames.SUBMIT_ORDER,
  };

  const finalRequest = {
    endpoint: ApiNames.SUBMIT_ORDER,
  };

  const firstCallback = () => null;

  const finalCallback = (response: AxiosResponse<IAccessTokenDto>) => {
    handleOnClose(true);
    setIsSubmitting(false);
  };

  const finalCallbackError = (error: AxiosError<{ message: string }>) => {
    setIsSubmitting(false);
    onMfaModalClose();
    toast({
      title: t('Submition failed'),
      description: error?.response?.data?.message ?? t('Something wrong'),
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const finalRequestToastOptions: RequestToastOptions = {
    success: {
      title: t('Order submited'),
      description: t('You will be notified when the order is paid via email'),
    },
    loading: {
      title: t('Submiting order'),
      description: t('Please wait...'),
    },
    error: {
      title: t('Submition failed'),
      description: t('Something wrong'),
    },
  };

  const firstRequestToastOptions: RequestToastOptions = {
    success: {
      title: t('E-mail sent with code'),
      description: t('Verify your inbox'),
    },
  };

  const { mfaFormProps, handleMfaSubmit } = useWithMfaForm({
    firstCallback,
    firstRequest,
    finalRequest,
    finalCallbackError,
    firstRequestToastOptions,
    finalCallback: finalCallback,
    finalRequestToastOptions,
    parentFormSubmit: () => {
      setIsSubmitting(true);
    },
    parentFormIsValid: true,
    disclaimer: t(
      'Please verify your e-mail with the 6 digits code to validade this operation'
    ),
    initialMfaRequestToken: taxToken,
  });

  const mfaModalFormProps: MfaModalFormProps = {
    mfaFormProps,
    isOpen,
    onClose: onMfaModalClose,
  };

  const handleSubmitOrderWithoutMfa = () => {
    setIsSubmitting(true);
    axios
      .request(
        getRequestConfig({
          ...finalRequest,
          token: {
            accessToken: taxToken,
          },
        })
      )
      .then(finalCallback)
      .catch(finalCallbackError)
      .finally(() => setIsSubmitting(false));
  };

  const handleSubmitOrder = () => {
    if (!disableOrderMfa) {
      onOpen();
      handleMfaSubmit();
    } else {
      handleSubmitOrderWithoutMfa();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      loadDestinationAccount(),
      loadFinalities(),
      loadCurrencies(),
    ]).then(() => {
      setIsLoading(false);
    });
  }, [loadCurrencies, loadDestinationAccount, loadFinalities]);

  useEffect(() => {
    if (isReadOnly) return;
    const interval = setInterval(() => {
      setUpdateCountDown((prevCountDown) => {
        if (prevCountDown > 0) {
          return prevCountDown - 1;
        } else {
          onMfaModalClose();
          setIsSubmitting(false);
          getTaxByCurrencyAndFinality();
          return 100;
        }
      });
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, [getTaxByCurrencyAndFinality, isReadOnly, onMfaModalClose]);

  useEffect(() => {
    setIsReadyToTransfer(bankAccounts.length > 0);
  }, [bankAccounts]);

  useEffect(() => {
    if (order?.status !== OrderStatus.AVAILABLE && order?.quotation) {
      setExchangeTaxResult({
        amountToReceive: FormatHelper.formatCurrency(
          order?.quotation?.amountMN * 100,
          Currencies.BRL
        ),
        currencyExchangeValue: FormatHelper.formatCurrency(
          order?.quotation?.currencyExchangeValue,
          Currencies.BRL,
          DECIMAL_CASES
        ),
        iof: `${order?.quotation?.iofInboud * 100}%`,
        ir: `${order?.quotation?.irInbound * 100}%`,
        fee: FormatHelper.formatCurrency(
          order?.quotation?.tariff * 100,
          Currencies.BRL
        ),
        vet: FormatHelper.formatCurrency(
          order?.quotation?.vet * 100,
          Currencies.BRL,
          DECIMAL_CASES
        ),
        spread: `${order?.quotation?.spread * 100}%`,
      });
    }
  }, [order]);

  useEffect(() => {
    const finality = finalities.find(
      (f) => f.value === Finalities.COMPUTER_IT_SERVICES
    );
    if (finality) setSelectedFinality(finality);
  }, [finalities]);

  useEffect(() => {
    if (order?.currency) {
      setSelectedCurrency({ value: order?.currency, label: t(Currencies.USD) });
      setAmountToSend(
        FormatHelper.formatCurrency(order?.amount ?? 0, order?.currency)
      );
    }
  }, [currencies, order?.amount, order?.currency, t]);

  useEffect(() => {
    getTaxByCurrencyAndFinality();
  }, [getTaxByCurrencyAndFinality]);

  return {
    user,
    isLoading,
    t,
    selectedBankAccount,
    bankAccounts,
    isEditingAccount,
    setIsEditingAccount,
    handleChangeSelectedAccount,
    isReadyToTransfer,
    finalities,
    currencies,
    handleChangeCurrency,
    selectedCurrency,
    getSourceCurrencyImg,
    handleOnInputCurrencyChangeValue,
    amountToSend,
    handleChangeFinality,
    exchangeTaxResult,
    selectedFinality,
    isTaxLoading,
    updateCountDown,
    isReadOnly,
    handleSubmitOrder,
    isSubmitting,
    order,
    handleGoToBankAccounts,
    mfaModalFormProps,
  };
};

export default useWithTransferences;
