var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div style=\"text-align: center\">\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Segue abaixo seu código de confirmação para prosseguir:</span>\n  </div>\n  <div></div>\n</div>\n\n<div>\n  <div style=\"font-family: inherit; text-align: center\">\n    <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"SIGN_IN_CODE") || (depth0 != null ? lookupProperty(depth0,"SIGN_IN_CODE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SIGN_IN_CODE","hash":{},"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":10,"column":26}}}) : helper)))
    + "</span>\n  </div>\n  <div></div>\n</div>\n";
},"useData":true});