import i18n from '../../configuration/i18n';

i18n.addResourceBundle('en', 'navbar', {
  Transferences: 'Transferences',
  'Bank Accounts': 'Bank Accounts',
  'Global Account': 'Global Account',
  'Configure your virtual bank account': 'Configure your virtual bank account',
  'Brazilian Account': 'Brazilian Account',
  'Configure your local bank account': 'Configure your local bank account',
  'Bank statement': 'Bank statement',
  History: 'History',
  'Your banking statements': 'Your banking statements',
  Invoices: 'Invoices',
  Configure: 'Configure',
  Create: 'Create',
  'See your recents invoices': 'See your recents invoices',
  'Configure your invoice': 'Configure your invoice',
  'Create new invoice': 'Create new invoice',
  Payers: 'Payers',
  'Configure your invoice payers': 'Configure your invoice payers',
  Home: 'Home',
  Crypto: 'Crypto',
});

i18n.addResourceBundle('ptbr', 'navbar', {
  Transferences: 'Transferências',
  'Bank Accounts': 'Contas Bancárias',
  'Global Account': 'Conta Global',
  'Configure your virtual bank account':
    'Seus canais bancários para receber moeda estrangeira',
  'Brazilian Account': 'Conta Brasileira',
  'Configure your local bank account':
    'Sua sua conta bancária para receber moeda local',
  'Bank statement': 'Transferências',
  History: 'Histórico',
  'Your banking statements': 'Suas transferências aparecerão aqui',
  Invoices: 'Invoices',
  Configure: 'Configurar',
  Create: 'Criar',
  'See your recents invoices': 'Veja suas faturas recentes',
  'Configure your invoice': 'Configure sua fatura',
  'Create new invoice': 'Criar nova fatura',
  Payers: 'Pagadores',
  'Configure your invoice payers': 'Configure seus pagadores',
  Home: 'Inicio',
  Crypto: 'Criptomoedas',
});
