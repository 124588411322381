import { Flex, Button, Text } from '@chakra-ui/react';
import { ICryptoOfferDto, ICryptoProviderDto } from '@waygee/shared-types';

export type BuyCryptoOffersProps = {
  offers: ICryptoOfferDto[];
  selectedPaymentMethod?: string;
  selectedProvider?: string;
  handleChangeSelectedProvider: (providerCode: string) => void;
  providers: ICryptoProviderDto[];
  selectedCryptoCurrency: string;
};

const BuyCryptoOffers = ({
  offers,
  selectedPaymentMethod,
  selectedProvider,
  handleChangeSelectedProvider,
  providers,
  selectedCryptoCurrency,
}: BuyCryptoOffersProps) => {
  return (
    <Flex flexDir="row" gap="5px" justifyContent="space-around">
      {offers?.map((offer) => {
        const amountExpectedTo = offer?.paymentMethodOffer?.find(
          (c) => c.method === selectedPaymentMethod
        )?.amountExpectedTo;
        return amountExpectedTo ? (
          <Button
            variant={
              selectedProvider === offer.providerCode ? undefined : 'outline'
            }
            onClick={() => handleChangeSelectedProvider(offer.providerCode)}
            padding="30px 10px"
            colorScheme="primary"
          >
            <Flex
              flexDir="column"
              gap="5px"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={
                  providers?.find((c) => c.code === offer.providerCode)?.iconUrl
                }
                alt={offer.providerCode}
                width="75"
                height="31"
              />
              <Text>
                {amountExpectedTo} {selectedCryptoCurrency}
              </Text>
            </Flex>
          </Button>
        ) : null;
      })}
    </Flex>
  );
};

export default BuyCryptoOffers;
