import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { MfaForm, MfaFormProps } from '../MfaForm';
import './../MfaForm/Mfa.language';

export type MfaModalFormProps = {
  mfaFormProps: MfaFormProps;
  isOpen: boolean;
  onClose: () => void;
};

const MfaModalForm = ({ mfaFormProps, isOpen, onClose }: MfaModalFormProps) => {
  const { t } = useTranslation('mfa');
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent
        position={{ base: 'unset', md: 'fixed' }}
        right={{ base: 'unset', md: '10%' }}
      >
        <ModalHeader>{t('Secure verification')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <MfaForm {...mfaFormProps} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MfaModalForm;
