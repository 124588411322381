import React, { createContext, useMemo } from 'react';
import {
  IAuthenticatedUserDataDto,
  OnboardingSteps,
  OnboardingStepStatus,
} from '@waygee/shared-types';
import { SignUpStepResult } from '../../common/types';
import { AuthToken } from '../../common/types/auth';
import useAuthContext from './useAuthContext';

export type AuthContextType = {
  isLoggedIn: boolean;
  user?: IAuthenticatedUserDataDto;
  authToken?: AuthToken;
  validateUserAccess: () => Promise<void>;
  refreshAuthToken: (newAuthToken: AuthToken) => Promise<boolean | undefined>;
  onboardingStep: OnboardingSteps;
  onboardingStatus: OnboardingStepStatus;
  onboardingStepResults: SignUpStepResult[];
  orderAvailableCount: number;
};

const initialState: AuthContextType = {
  isLoggedIn: false,
  user: undefined,
  authToken: undefined,
  validateUserAccess: () => Promise.resolve(),
  refreshAuthToken: (newAuthToken: AuthToken) => Promise.resolve(false),
  onboardingStep: OnboardingSteps.ACCOUNT_DATA,
  onboardingStatus: OnboardingStepStatus.PENDING,
  onboardingStepResults: [],
  orderAvailableCount: 0,
};

const AuthContext = createContext(initialState);

type AuthContextProviderProps = {
  children: React.ReactNode;
};

const AuthContextProvider = ({
  children,
}: AuthContextProviderProps): JSX.Element => {
  const {
    isLoggedIn,
    user,
    authToken,
    validateUserAccess,
    refreshAuthToken,
    onboardingStatus,
    onboardingStep,
    onboardingStepResults,
    orderAvailableCount,
  } = useAuthContext();

  const context = useMemo(
    () => ({
      isLoggedIn,
      user,
      authToken,
      validateUserAccess,
      refreshAuthToken,
      onboardingStatus,
      onboardingStep,
      onboardingStepResults,
      orderAvailableCount,
    }),
    //eslint-disable-next-line
    [user, authToken, isLoggedIn]
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
