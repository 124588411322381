import { ChatIcon, EmailIcon } from '@chakra-ui/icons';
import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  FormErrorMessage,
} from '@chakra-ui/react';
import { IoIosPerson } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import { openChat } from './../../common/utility/chat/chat.js';
import useContact from './useContact';

const Contact = () => {
  const { errors, getFieldProps, touched, handleSubmit, isLoading } =
    useContact();

  const handleChatClick = () => openChat();

  return (
    <Container maxW="full" mt={0} centerContent overflow="hidden">
      <Flex>
        <Box
          bg="white"
          color="gray.700"
          borderRadius="lg"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 5, lg: 16 }}
        >
          <Box p={4}>
            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
              <WrapItem>
                <Box>
                  <Heading>Contato</Heading>
                  <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.700">
                    Preencha o formulário e entre em contato conosco.
                  </Text>
                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                    <VStack pl={0} spacing={3} alignItems="flex-start">
                      <Button
                        size="md"
                        height="48px"
                        width="200px"
                        variant="ghost"
                        color="gray.700"
                        onClick={() =>
                          (window.location.href = 'mailto:help@waygee.io')
                        }
                        _hover={{ border: '2px solid #1C6FEB' }}
                        leftIcon={<MdEmail color="#1970F1" size="20px" />}
                      >
                        help@waygee.io
                      </Button>

                      <Button
                        size="md"
                        height="48px"
                        width="200px"
                        colorScheme="green"
                        leftIcon={<ChatIcon />}
                        onClick={handleChatClick}
                      >
                        Chat
                      </Button>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
              <WrapItem>
                <Box bg="white" borderRadius="lg">
                  <Box m={8} color="#0B0E3F">
                    <VStack spacing={5}>
                      <form onSubmit={handleSubmit}>
                        <FormControl
                          id="name"
                          isInvalid={(touched.name && !!errors.name) || false}
                        >
                          <FormLabel>Seu nome</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement pointerEvents="none">
                              <IoIosPerson color="gray.800" />
                            </InputLeftElement>
                            <Input
                              type="text"
                              size="md"
                              {...getFieldProps('name')}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {touched.name && errors.name}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          id="email"
                          isInvalid={(touched.email && !!errors.email) || false}
                        >
                          <FormLabel>Seu email</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement pointerEvents="none">
                              <EmailIcon color="gray.800" />
                            </InputLeftElement>
                            <Input
                              type="text"
                              size="md"
                              {...getFieldProps('email')}
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {touched.email && errors.email}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          id="message"
                          isInvalid={
                            (touched.message && !!errors.message) || false
                          }
                        >
                          <FormLabel>Mensagem</FormLabel>
                          <Textarea
                            borderColor="gray.300"
                            _hover={{
                              borderRadius: 'gray.300',
                            }}
                            placeholder="message"
                            {...getFieldProps('message')}
                          />
                          <FormErrorMessage>
                            {touched.message && errors.message}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl id="button" float="right">
                          <Flex
                            margin="10px"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Button
                              variant="solid"
                              colorScheme="blue"
                              type="submit"
                              isDisabled={isLoading}
                            >
                              Enviar
                            </Button>
                          </Flex>
                        </FormControl>
                      </form>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default Contact;
