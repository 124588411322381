import React from 'react';
import { Flex } from '@chakra-ui/react';
import InvoiceCreate from '../../Invoice/components/InvoiceCreate/InvoiceCreate';

const InvoiceDemo = () => {
  return (
    <Flex flexDir="column" align="center">
      <InvoiceCreate />
    </Flex>
  );
};

export default InvoiceDemo;
