import i18n from '../configuration/i18n';

i18n.addResourceBundle('en', 'buy-crypto', {
  Transferences: 'Transferences',
  'You will be directed to': 'You will be directed to',
  'to buy coins': 'to buy coins',
  'is a fiat currency and cryptocurrency trading platform independently operated by a third party, and all related services are provided by':
    'is a fiat currency and cryptocurrency trading platform independently operated by a third party, and all related services are provided by',
  'and not by Waygee': 'and not by Waygee',
  'Go to Payment at': 'Go to Payment at',
  Cancel: 'Cancel',
  Buy: 'Buy',
  Currency: 'Currency',
  'You send': 'You send',
  'You get': 'You get',
  'Payment methods': 'Payment methods',
  'Payment offers': 'Payment offers',
  'We have no offer for this value and payment method yet. Try a different value or payment method.':
    'We have no offer for this value and payment method yet. Try a different value or payment method.',
  'Wallet address': 'Wallet address',
  'Enter your wallet address': 'Enter your wallet address',
  'Invalid wallet address': 'Invalid wallet address',
  'I agree with Terms of Use, Privacy Policy and AML/KYC':
    'I agree with Terms of Use, Privacy Policy and AML/KYC',
  'Transaction Details': 'Transaction Details',
  'Payment method': 'Payment method',
  'Network fee': 'Network fee',
  'Exchange rate': 'Exchange rate',
  'Crypto purchase steps': 'Crypto purchase steps',
  'Select the crypto you would like to buy and the fiat currency you want to purchase crypto with.':
    'Select the crypto you would like to buy and the fiat currency you want to purchase crypto with.',
  'Enter the address of the crypto wallet to which your new cryptocurrency will be sent.':
    'Enter the address of the crypto wallet to which your new cryptocurrency will be sent',
  'Confirm the payment and receive your newly purchased cryptocurrency in a few minutes.':
    'Confirm the payment and receive your newly purchased cryptocurrency in a few minutes.',
  'The transaction is processed on the side of the provider':
    'The transaction is processed on the side of the provider',
  'Currently, the transaction is processed on the side of the provider. You can always create a new transaction if it is needed.':
    'Currently, the transaction is processed on the side of the provider. You can always create a new transaction if it is needed.',
  'Go to the provider': 'Go to the provider',
  'New transaction': 'New transaction',
  'Order validated': 'Order validated',
  'Redirecting you to the payment page...':
    'Redirecting you to the payment page...',
  'Order not validated': 'Order not validated',
  'Please try again with a valid order': 'Please try again with a valid order',
  'Validating order': 'Validating order',
  'Please wait...': 'Please wait...',
  'Address validated': 'Address validated',
  'You can use this address': 'You can use this address',
  'Address not validated': 'Address not validated',
  'Please try again with a valid address':
    'Please try again with a valid address',
  'Validating address': 'Validating address',
  '*Disclaimer':
    '*The estimated network fee is an approximation and the actual network fee applied on a withdrawal may differ. The actual network fee can vary due to blockchain conditions like network congestion and demand at the time of the transaction. Orders may not be canceled or reversed once submitted by you. By submitting a withdrawal request, you are requesting an on-chain transaction that is not reversible or recallable. You are responsible for reviewing the recipient address and ensuring it is the correct address for the selected asset for withdrawal.',
  'I agree with': 'I agree with',
  'Terms of Use, Privacy Policy and AML/KYC':
    'Terms of Use, Privacy Policy and AML/KYC',
});

i18n.addResourceBundle('ptbr', 'buy-crypto', {
  Transferences: 'Transferências',
  'You will be directed to': 'Você será direcionado para',
  'to buy coins': 'para finalizara a compra',
  'is a fiat currency and cryptocurrency trading platform independently operated by a third party, and all related services are provided by':
    'é uma plataforma de negociação de moeda fiduciária e criptomoedas operada independentemente por terceiros, e todos os serviços relacionados são fornecidos por',
  'and not by Waygee': 'e não pela Waygee',
  'Go to Payment at': 'Ir para Pagamento em',
  Cancel: 'Cancelar',
  Buy: 'Comprar',
  Currency: 'Moeda',
  'You send': 'Você envia',
  'You get': 'Você recebe',
  'Payment methods': 'Métodos de pagamento',
  'Payment offers': 'Ofertas de pagamento',
  'We have no offer for this value and payment method yet. Try a different value or payment method.':
    'Ainda não temos oferta para este valor e método de pagamento. Tente um valor ou método de pagamento diferente.',
  'Wallet address': 'Endereço da carteira',
  'Enter your wallet address': 'Insira o endereço da sua carteira',
  'Invalid wallet address': 'Endereço de carteira inválido',
  'I agree with Terms of Use, Privacy Policy and AML/KYC':
    'Concordo com os Termos de Uso, Política de Privacidade e AML/KYC',
  'Transaction Details': 'Detalhes da transação',
  'Payment method': 'Método de pagamento',
  'Network fee': 'Taxa de rede',
  'Exchange rate': 'Taxa de câmbio',
  'Crypto purchase steps': 'Passos para compra de criptomoedas',
  'Select the crypto you would like to buy and the fiat currency you want to purchase crypto with.':
    'Selecione a criptomoeda que deseja comprar e a moeda fiduciária com a qual deseja comprar criptomoedas.',
  'Enter the address of the crypto wallet to which your new cryptocurrency will be sent.':
    'Insira o endereço da carteira de criptomoedas para a qual sua nova criptomoeda será enviada',
  'Confirm the payment and receive your newly purchased cryptocurrency in a few minutes.':
    'Confirme o pagamento e receba sua nova criptomoeda recém-comprada em poucos minutos.',
  'The transaction is processed on the side of the provider':
    'A transação é processada no lado do provedor',
  'Currently, the transaction is processed on the side of the provider. You can always create a new transaction if it is needed.':
    'Atualmente, a transação é processada no lado do provedor. Você sempre pode criar uma nova transação se necessário.',
  'Go to the provider': 'Ir para o provedor',
  'New transaction': 'Nova transação',
  'Order validated': 'Ordem validada',
  'Redirecting you to the payment page...':
    'Redirecionando você para a página de pagamento...',
  'Order not validated': 'Ordem não validada',
  'Please try again with a valid order':
    'Por favor, tente novamente com um pedido válido',
  'Validating order': 'Validando pedido',
  'Please wait...': 'Por favor, aguarde...',
  'Address validated': 'Endereço validado',
  'You can use this address': 'Você pode usar este endereço',
  'Address not validated': 'Endereço não validado',
  'Please try again with a valid address':
    'Por favor, tente novamente com um endereço válido',
  'Validating address': 'Validando endereço',
  '*Disclaimer':
    '*A taxa de rede estimada é uma aproximação e a taxa de rede real aplicada. A taxa de rede real pode variar devido a condições de blockchain, como congestionamento e demanda na hora da transação. Os pedidos não podem ser cancelados ou revertidos uma vez enviados para você.',
  'I agree with': 'Eu concordo com os',
  'Terms of Use, Privacy Policy and AML/KYC':
    'Termos de Uso, Política de Privacidade e AML/KYC',
});
