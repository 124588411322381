import { useTranslation } from 'react-i18next';
import { ViewIcon, ViewOffIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  FormErrorMessage,
  FlexProps,
  InputGroup,
  InputRightElement,
  Button,
  Heading,
  Divider,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { SignUpSchema } from '../types/signUpSchema';
import '../SignUp.language';

export type SignUpUserDataProps = {
  flexFieldProps: FlexProps;
  touched: FormikTouched<SignUpSchema>;
  errors: FormikErrors<SignUpSchema>;
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
  getFieldProps: (
    nameOrOptions:
      | string
      | FieldConfig<string | number | readonly string[] | undefined>
  ) => FieldInputProps<string | number | readonly string[] | undefined>;
};

const SignUpUserData = ({
  errors,
  flexFieldProps,
  getFieldProps,
  touched,
  showPassword,
  setShowPassword,
}: SignUpUserDataProps) => {
  const { t } = useTranslation('signup');
  return (
    <>
      <Heading size="md">{t('User data')}</Heading>
      <Flex {...flexFieldProps}>
        <FormControl
          id="email"
          isRequired
          isInvalid={(touched.email && !!errors.email) || false}
        >
          <FormLabel>{t('Email address')}</FormLabel>
          <Input type="email" {...getFieldProps('email')} />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex {...flexFieldProps}>
        <FormControl
          id="password"
          isRequired
          isInvalid={(touched.password && !!errors.password) || false}
        >
          <FormLabel>
            {t('Password')}
            <Tooltip
              label={t('At least 8 characters, 1 uppercase and 1 lowercase.')}
              fontSize="md"
            >
              <WarningIcon marginLeft="5px" />
            </Tooltip>
          </FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              {...getFieldProps('password')}
            />
            <InputRightElement h={'full'}>
              <Button
                variant={'ghost'}
                onClick={() => setShowPassword((showPassword) => !showPassword)}
              >
                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors.password}</FormErrorMessage>
        </FormControl>
        <Divider />
        <Flex>
          <Text>
            {t('By registering you accept our')}{' '}
            <Link href="https://waygee.io/termos-de-servico" target="_blank">
              {t('Terms & Conditions')}
            </Link>{' '}
            {t('and')}{' '}
            <Link href="https://waygee.io/privacidade" target="_blank">
              {t('Privacy Policy')}
            </Link>
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default SignUpUserData;
