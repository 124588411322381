import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './../Dashboard.language';

const useDashboard = () => {
  const [showBalance, setShowBalance] = useState<boolean>(false);
  const { t } = useTranslation('dashboard');
  return {
    t,
    showBalance,
    setShowBalance,
  };
};

export default useDashboard;
