var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Olá "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":24}}}) : helper)))
    + ",</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      ><br />\n      É com grande satisfação que informamos que seus dados foram revisados e\n      sua conta foi aprovada com sucesso! Você agora tem acesso total aos\n      serviços oferecidos em nossa plataforma.<br />\n      <br />\n      Estamos entusiasmados em tê-lo(a) como parte de nossa comunidade. Sinta-se\n      à vontade para explorar todos os recursos e benefícios disponíveis. Se\n      tiver alguma dúvida ou precisar de assistência, nossa equipe de suporte\n      estará pronta para ajudar.<br />\n      <br />\n      Agradecemos por escolher a Waygee. Estamos comprometidos em proporcionar\n      uma experiência excepcional a todos os nossos usuários.<br />\n      <br />\n      Seja bem-vindo(a)!<br />\n      <br />\n    </span>\n  </div>\n</div>\n";
},"useData":true});