import i18n from '../../common/configuration/i18n';

i18n.addResourceBundle('en', 'login', {
  'Invalid email address': 'Invalid email address',
  'Must be 100 characters or less': 'Must be 100 characters or less',
  'Email is required': 'Email is required',
  'Password is required': 'Password is required',
  'Login successful': 'Login successful',
  'Welcome back!': 'Welcome back!',
  'Login failed': 'Login failed',
  'Something wrong': 'Something wrong',
  'Authenticating user': 'Authenticating user',
  'Please wait...': 'Please wait...',
  'Sign in': 'Sign in',
  'Forgot password': 'Forgot password?',
  'Remember me': 'Remember me',
  'Email address': 'Email address',
  Password: 'Password',
  'Sign in to your account': 'Sign in to your account',
  'to enjoy all of our cool': 'to enjoy all of our cool',
  features: 'features',
  'E-mail sent with code': 'E-mail sent with code',
  'Verify your inbox': 'Verify your inbox',
  'Must be 6 digits': 'Must be 6 digits',
  '6 digit code is required': '6 digit code is required',
  'Please verify your e-mail with the 6 digits code to validade your access':
    'Please verify your e-mail with the 6 digits code to validade your access',
  'Resend e-mail': 'Resend e-mail',
  Validate: 'Validate',
  'Do not have an account yet?': 'Do not have an account yet?',
  'Click here to get one!': 'Click here to get one!',
  Wait: 'Wait',
  'seconds to resend the code': 'seconds to resend the code',
});

i18n.addResourceBundle('ptbr', 'login', {
  'Invalid email address': 'Endereço de e-mail inválido',
  'Must be 100 characters or less': 'Deve ter 100 caracteres ou menos',
  'Email is required': 'O email é obrigatório',
  'Password is required': 'A senha é obrigatória',
  'Login successful': 'Login realizado com sucesso',
  'Welcome back!': 'Bem vindo de volta!',
  'Login failed': 'Falha no login',
  'Something wrong': 'Algo deu errado',
  'Authenticating user': 'Autenticando usuário',
  'Please wait ...': 'Por favor, aguarde ...',
  'Sign in': 'Entrar',
  'Forgot password': 'Esqueceu a senha?',
  'Remember me': 'Lembre de mim',
  'Email address': 'Endereço de e-mail',
  Password: 'Senha',
  'Sign in to your account': 'Faça login na sua conta',
  'to enjoy all of our cool': 'para aproveitar todos os nossos',
  features: 'recursos',
  'Please wait...': 'Por favor, aguarde...',
  'E-mail sent with code': 'E-mail enviado com código',
  'Verify your inbox': 'Verifique sua caixa de entrada',
  'Must be 6 digits': 'Deve ter 6 dígitos',
  '6 digit code is required': 'O código de 6 dígitos é obrigatório',
  'Please verify your e-mail with the 6 digits code to validade your access':
    'Por favor, verifique seu e-mail com o código de 6 dígitos para validar seu acesso',
  'Resend e-mail': 'Reenviar e-mail',
  Validate: 'Validar',
  'Do not have an account yet?': 'Ainda não tem uma conta?',
  'Click here to get one!': 'Clique aqui para obter uma!',
  Wait: 'Aguarde',
  'seconds to resend the code': 'segundos para reenviar o código',
});
