import { extendTheme } from '@chakra-ui/react';
//#131856
export const colors = {
  black: '#0c1015',
  gray: {
    '50': '#f9fafa',
    '100': '#f1f1f2',
    '200': '#e6e7e9',
    '300': '#d2d4d7',
    '400': '#a9adb2',
    '500': '#797f88',
    '600': '#4d5560',
    '700': '#2e3744',
    '800': '#19202b',
    '900': '#141a23',
  },
  purple: {
    '50': '#f8f6fb',
    '100': '#e2dcee',
    '200': '#cdc2e2',
    '300': '#af9dd0',
    '400': '#9a84c4',
    '500': '#7f63b4',
    '600': '#6d4caa',
    '700': '#5b37a0',
    '800': '#4c2496',
    '900': '#381a71',
  },
  pink: {
    '50': '#fbf6f8',
    '100': '#eedce5',
    '200': '#e1c0d0',
    '300': '#cf9bb5',
    '400': '#c381a1',
    '500': '#b35f88',
    '600': '#a74776',
    '700': '#98285f',
    '800': '#791c4a',
    '900': '#5a1536',
  },
  red: {
    '50': '#fbf6f6',
    '100': '#eedcdc',
    '200': '#e0bebd',
    '300': '#ce9998',
    '400': '#c48382',
    '500': '#b66765',
    '600': '#a94d4b',
    '700': '#9a2d2b',
    '800': '#87211f',
    '900': '#621817',
  },
  orange: {
    '50': '#fcfaf8',
    '100': '#f3ece5',
    '200': '#e5d6c8',
    '300': '#d1b89e',
    '400': '#be9c78',
    '500': '#ae8356',
    '600': '#9e6b34',
    '700': '#84521f',
    '800': '#684118',
    '900': '#553514',
  },
  yellow: {
    '50': '#fefefd',
    '100': '#faf9f4',
    '200': '#f0eddf',
    '300': '#e4e0c6',
    '400': '#d4cda4',
    '500': '#b5a963',
    '600': '#978625',
    '700': '#76691c',
    '800': '#584e15',
    '900': '#494111',
  },
  green: {
    '50': '#f9fcfa',
    '100': '#ddefe6',
    '200': '#b9decc',
    '300': '#91cbaf',
    '400': '#66b68f',
    '500': '#39a16e',
    '600': '#1f8654',
    '700': '#186942',
    '800': '#145636',
    '900': '#10462c',
  },
  teal: {
    '50': '#f6fbfb',
    '100': '#d8ecec',
    '200': '#b7dcdc',
    '300': '#8ec8c9',
    '400': '#59aeb0',
    '500': '#299799',
    '600': '#1d7b7d',
    '700': '#176061',
    '800': '#135051',
    '900': '#0f4242',
  },
  cyan: {
    '50': '#f7fafb',
    '100': '#ddecef',
    '200': '#cee3e8',
    '300': '#bdd9e0',
    '400': '#8bbdc8',
    '500': '#73afbc',
    '600': '#58a0af',
    '700': '#2c869a',
    '800': '#1e6e80',
    '900': '#175563',
  },
  blue: {
    '50': '#f3f6f9',
    '100': '#d4dfea',
    '200': '#b5c8dc',
    '300': '#93afcb',
    '400': '#7397bc',
    '500': '#5782af',
    '600': '#3b6da1',
    '700': '#205288',
    '800': '#1a436f',
    '900': '#15375b',
  },
  primary: {
    '50': '#f8f7fb',
    '100': '#e1deef',
    '200': '#c8c2e2',
    '300': '#a99fd1',
    '400': '#988cc8',
    '500': '#8173bc',
    '600': '#6d5db1',
    '700': '#5744a6',
    '800': '#48349e',
    '900': '#321f85',
  },
};

const defaultProps = {
  focusBorderColor: 'primary.600',
};

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: '"Roboto", sans-serif',
        backgroundColor: 'primary.50',
      },
      '*::-webkit-scrollbar-track': {
        background: 'primary.900',
      },
      '*::-webkit-scrollbar': {
        width: '10px',
      },
      '*::-webkit-scrollbar-button, *::-webkit-scrollbar-thumb': {
        backgroundColor: 'primary.800',
      },
    },
  },
  colors,
  config: { initialColorMode: 'light', useSystemColorMode: false },
  components: {
    PinInput: {
      defaultProps,
    },
    Textarea: {
      defaultProps,
    },
    Input: {
      defaultProps,
    },
    Select: {
      defaultProps,
    },
  },
});

export const DefaultInvoicePalletColor = [
  '#9900EF',
  '#FF0080',
  '#FF0000',
  '#FF6600',
  '#FFD100',
  '#8FC31F',
  '#00B050',
  '#00B0F0',
  '#0070C0',
  '#002060',
  '#7030A0',
  '#000000',
];

export enum ChakraColors {
  success = 'green.500',
  error = 'red.500',
  warning = 'yellow.500',
  info = 'blue.400',
  hover = 'blue.500',
  primary = 'gray.500',
  secondary = 'gray.400',
  disabled = 'gray.100',
  text = 'gray.500',
  waygee = 'primary.500',
  waygee_light = 'primary.50',
  waygee_dark = 'primary.900',
}
