import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useToast } from '@chakra-ui/react';
import { IAccessTokenDto } from '@waygee/shared-types';

import axios, { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import * as Yup from 'yup';
import { ApiNames, getRequestConfig } from '../../../common/apis/ApiRoutes';
import useWithMfaForm, {
  RequestToastOptions,
} from '../../../common/components/MfaForm/hooks/useWithMfaForm';
import appConfig from '../../../common/configuration/AppConfig';
import { colors } from '../../../common/configuration/ChakraColors';
import { AuthToken } from '../../../common/types/auth';
import { setAuthToken } from '../../../common/utility/cookies/authTokenStorage';
import '../Login.language';

const useLogin = () => {
  const { t } = useTranslation('login');
  const [showBgImage] = useMediaQuery('(min-width: 1000px)');
  const [isLoadingWithoutMfa, setIsLoadingWithoutMfa] = useState(false);
  const toast = useToast();

  const finalRequestToastOptions: RequestToastOptions = {
    success: {
      title: t('Login successful'),
      description: t('Welcome back!'),
    },
  };

  const firstRequestToastOptions: RequestToastOptions = {
    error: {
      title: t('Login failed'),
      description: t('Something wrong'),
    },
    loading: {
      title: t('Authenticating user'),
      description: t('Please wait...'),
    },
    success: {
      title: t('E-mail sent with code'),
      description: t('Verify your inbox'),
    },
  };

  const firstCallback = () => {
    if (values.rememberMe)
      Cookies.set('email', values.email, { expires: 365 * 100 });
    else Cookies.remove('email');
  };

  const finalCallback = (response: AxiosResponse<IAccessTokenDto>) => {
    setAuthToken(response.data as AuthToken);
  };

  const validationSchema = Yup.object({
    rememberMe: Yup.boolean(),
    email: Yup.string()
      .email(t('Invalid email address'))
      .trim()
      .max(100, t('Must be 100 characters or less'))
      .required(t('Email is required')),
    password: Yup.string()
      .trim()
      .max(16, t('Must be 16 characters or less'))
      .required(t('Password is required')),
  });

  const formik = useFormik({
    initialValues: {
      rememberMe: Cookies.get('email') ? true : false,
      email: Cookies.get('email') ?? '',
      password: '',
    },
    validationSchema,
    onSubmit: () => {
      if (appConfig.disableLoginMfa) {
        setIsLoadingWithoutMfa(true);
        const promise = axios
          .request(
            getRequestConfig({
              endpoint: ApiNames.LOGIN,
              data: {
                email: values.email,
                password: values.password,
              },
            })
          )
          .then((response) => {
            finalCallback(response);
          })
          .finally(() => setIsLoadingWithoutMfa(false));
        const { error, success } = finalRequestToastOptions;
        const { loading } = firstRequestToastOptions;

        toast.promise(promise, {
          success: {
            ...success,
            isClosable: true,
          },
          error: {
            ...error,
            isClosable: true,
          },
          loading: {
            ...loading,
            isClosable: true,
          },
        });
      }
      return;
    },
  });

  const {
    values,
    getFieldProps,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    handleChange,
  } = formik;

  const mfaFirstRequest = {
    endpoint: ApiNames.LOGIN,
    data: {
      email: values.email,
      password: values.password,
    },
  };

  const mfaFinalRequest = {
    endpoint: ApiNames.LOGIN_WITH_MFA,
    data: {
      email: values.email,
    },
  };

  const { mfaFormProps, mfaEmailSent, isLoading, handleMfaSubmit } =
    useWithMfaForm({
      firstRequest: mfaFirstRequest,
      firstRequestToastOptions,
      finalRequestToastOptions,
      finalRequest: mfaFinalRequest,
      firstCallback: firstCallback,
      finalCallback: finalCallback,
      parentFormSubmit: handleSubmit,
      parentFormIsValid: Object.keys(errors).length === 0,
    });

  useEffect(() => {
    // Set the background color when the component mounts
    const originalBgColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = colors.primary['900'];
    // Reset the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = originalBgColor;
    };
  }, []);

  return {
    values,
    getFieldProps,
    touched,
    errors,
    handleSubmit: appConfig.disableLoginMfa ? handleSubmit : handleMfaSubmit,
    t,
    isLoading: appConfig.disableLoginMfa ? isLoadingWithoutMfa : isLoading,
    mfaEmailSent,
    setFieldValue,
    handleChange,
    showBgImage,
    mfaFormProps,
  };
};

export default useLogin;
