import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ColorResult } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StackProps, useToast } from '@chakra-ui/react';
import {
  Currencies,
  IInvoiceConfigDto,
  INextInvoiceNumberDto,
  IPayerDto,
} from '@waygee/shared-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ApiNames, getRequestConfig } from '../../../../../common/apis';
import appConfig from '../../../../../common/configuration/AppConfig';
import { DefaultInvoicePalletColor } from '../../../../../common/configuration/ChakraColors';
import { axiosCustomInstance } from '../../../../../common/network';
import { handleDownloadResponse } from '../../../../../common/network/utils/downloadResponse';
import { AuthContext } from '../../../../../contexts/AuthContext/AuthContext';
import { InvoiceCreateConfigAndPayerSelectProps } from '../components/InvoiceCreateConfigAndPayerSelect';
import { InvoiceCreateNewConfigProps } from '../components/InvoiceCreateNewConfig';
import { InvoiceCreateNewPayerProps } from '../components/InvoiceCreateNewPayer';
import { InvoiceCreateServicesDataProps } from '../components/InvoiceCreateServicesData';
import { InvoiceCreateSchema } from '../types/invoiceCreateSchema';
import useInvoiceScreenObserver from './useInvoiceScreenObserver';

const useInvoiceCreate = () => {
  const [invoiceConfigOptions, setInvoiceConfigOptions] = useState<
    IInvoiceConfigDto[]
  >([]);
  const [invoicePayerOptions, setInvoicePayerOptions] = useState<IPayerDto[]>(
    []
  );
  const [invoiceCurrency, setInvoiceCurrency] = useState<Currencies>(
    Currencies.USD
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBottomObserverVisible, setIsBottomObserverVisible] =
    useState<boolean>(false);
  const [isFooterObserverVisible, setIsFooterObserverVisible] =
    useState<boolean>(false);
  const [showPreviewFixed, setShowPreviewFixed] = useState<boolean>(false);
  const [color, setColor] = useState<string>('#9900EF');
  const [isOptToNewConfiguration, setIsOptToNewConfiguration] =
    useState<boolean>(false);
  const [isOptToNewPayer, setIsOptToNewPayer] = useState<boolean>(false);
  const colors = DefaultInvoicePalletColor;
  const { isLoggedIn } = useContext(AuthContext);
  const { t } = useTranslation('invoice');
  const toast = useToast({
    position: isLoggedIn ? undefined : 'top-right',
    duration: 6000,
    isClosable: true,
  });
  const navigate = useNavigate();
  const bottomObserver = useRef(null);
  const footerObserver = useRef(null);
  useInvoiceScreenObserver({
    bottomObserver,
    footerObserver,
    setIsBottomObserverVisible,
    setIsFooterObserverVisible,
  });

  const validationSchema = Yup.object<InvoiceCreateSchema>({
    payerId: Yup.string(),
    invoiceConfigId: Yup.string(),
    invoiceNumber: Yup.number().required(t('Invoice number is required')),
    amount: Yup.string().required(t('Amount is required')),
    serviceDescription: Yup.string().required(
      t('Service description is required')
    ),
    date: Yup.date().required(t('Date is required')),
    dueDate: Yup.date(),
    title: Yup.string().when('invoiceConfigId', {
      is: undefined,
      then: (schema) => schema.required(t('Title is required')),
      otherwise: (schema) => schema.notRequired(),
    }),
    subtitle: Yup.string(),
    currency: Yup.string().when('invoiceConfigId', {
      is: undefined,
      then: (schema) => schema.required(t('Currency is required')),
      otherwise: (schema) => schema.notRequired(),
    }),
    email: Yup.string().nullable().email(t('Invalid email address')),
    address1: Yup.string(),
    address2: Yup.string(),
    payerName: Yup.string().when('payerId', {
      is: undefined,
      then: (schema) => schema.required(t('Name is required')),
      otherwise: (schema) => schema.notRequired(),
    }),
    payerAddress1: Yup.string(),
    payerAddress2: Yup.string(),
    payerPhone: Yup.string(),
    payerEmail: Yup.string().nullable().email(t('Invalid email address')),
    sendMail: Yup.boolean(),
    saveConfig: Yup.boolean(),
    savePayer: Yup.boolean(),
  });
  const formik = useFormik<InvoiceCreateSchema>({
    initialValues: {
      payerId: '',
      invoiceConfigId: '',
      invoiceNumber: '',
      amount: '',
      serviceDescription: '',
      date: new Date(),
      dueDate: undefined,
      title: '',
      subtitle: '',
      currency: '',
      address1: undefined,
      address2: undefined,
      phone: undefined,
      email: null,
      payerName: '',
      payerPhone: '',
      payerEmail: null,
      payerAddress1: undefined,
      payerAddress2: undefined,
      color: DefaultInvoicePalletColor[0],
      sendMail: false,
      saveConfig: false,
      savePayer: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const { invoiceConfigId, payerId, ...rest } = values;
        let configData = undefined;
        let payerData = undefined;

        if (isOptToNewConfiguration) {
          const {
            invoiceNumber,
            amount,
            serviceDescription,
            date,
            dueDate,
            payerName,
            payerPhone,
            payerEmail,
            payerAddress1,
            payerAddress2,
            saveConfig,
            savePayer,
            ...restConfigData
          } = rest;
          configData = restConfigData;
        }

        if (isOptToNewPayer) {
          payerData = {
            name: rest.payerName,
            address1: rest.payerAddress1,
            address2: rest.payerAddress2,
            phone: rest.payerPhone,
            email:
              rest?.payerEmail && rest.payerEmail.length > 0
                ? rest.payerEmail
                : undefined,
          };
        }
        const {
          title,
          subtitle,
          address1,
          address2,
          phone,
          email,
          payerName,
          payerAddress1,
          payerAddress2,
          payerEmail,
          payerPhone,
          color,
          sendMail,
          saveConfig,
          savePayer,
          ...invoiceData
        } = rest;

        const data = {
          ...invoiceData,
          invoiceConfigId: isOptToNewConfiguration
            ? undefined
            : invoiceConfigId,
          payerId: isOptToNewPayer ? undefined : payerId,
          amount: Number(values.amount.replace(/[^0-9]/g, '')),
          currency: invoiceCurrency,
          date: new Date(values.date).toISOString(),
          dueDate: values?.dueDate
            ? new Date(values?.dueDate).toISOString()
            : undefined,
          savePayer: isOptToNewPayer ? values.savePayer : undefined,
          saveConfig: isOptToNewConfiguration ? values.saveConfig : undefined,
          configData: isOptToNewConfiguration ? configData : undefined,
          payerData: isOptToNewPayer ? payerData : undefined,
        };

        const promise = axiosCustomInstance
          .request(
            getRequestConfig({
              endpoint: isLoggedIn
                ? ApiNames.CREATE_INVOICE
                : ApiNames.CREATE_AND_DOWNLOAD_INVOICE_PUBLIC,
              data,
              headers: isLoggedIn
                ? undefined
                : {
                    Accept: 'application/pdf',
                  },
              responseType: isLoggedIn ? undefined : 'arraybuffer',
            })
          )
          .then((response) => {
            if (isLoggedIn) {
              navigate('/secure/invoice/history', { replace: true });
            } else {
              handleDownloadResponse(
                response,
                `INVOICE_${values?.invoiceNumber}.pdf`
              );
            }
          })
          .finally(() => setIsLoading(false));
        toast.promise(promise, {
          success: {
            title: t('Success!'),
            description: t('Invoice created'),
            isClosable: true,
          },
          error: {
            title: t('Failed'),
            description: t('Something wrong'),
            isClosable: true,
          },
          loading: {
            title: t('Creating invoice...'),
            isClosable: true,
          },
        });
      } catch (error) {
        console.error(error);
        toast({
          title: t('Failed'),
          description: t('Something wrong'),
          status: 'error',
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const {
    getFieldProps,
    errors,
    touched,
    setFieldValue,
    values,
    handleSubmit,
  } = formik;

  const loadInvoicePayers = useCallback(() => {
    setIsLoading(true);
    axiosCustomInstance
      .request<IPayerDto[]>(
        getRequestConfig({
          endpoint: ApiNames.GET_PAYERS,
        })
      )
      .then((response) => {
        setIsOptToNewPayer(response.data.length === 0);
        setInvoicePayerOptions(response.data);
        setFieldValue('payerId', response.data[0].id);
      })
      .catch(() => {
        setIsOptToNewPayer(true);
        setInvoicePayerOptions([]);
      })
      .finally(() => setIsLoading(false));
  }, [setFieldValue]);

  const loadInvoiceConfigs = useCallback(() => {
    setIsLoading(true);
    axiosCustomInstance
      .request<IInvoiceConfigDto[]>(
        getRequestConfig({
          endpoint: ApiNames.GET_INVOICE_CONFIG,
        })
      )
      .then((response) => {
        setIsOptToNewConfiguration(response.data.length === 0);
        setInvoiceConfigOptions(response.data);
        setFieldValue('invoiceConfigId', response.data[0].id);
      })
      .catch(() => {
        setIsOptToNewConfiguration(true);
        setInvoiceConfigOptions([]);
      })
      .finally(() => setIsLoading(false));
  }, [setFieldValue]);

  const loadInvoiceNextNumber = useCallback(() => {
    setIsLoading(true);
    axiosCustomInstance
      .request<INextInvoiceNumberDto>(
        getRequestConfig({
          endpoint: ApiNames.GET_INVOICE_NEXT_NUMBER,
        })
      )
      .then((response) => {
        setFieldValue('invoiceNumber', response.data.nextInvoiceNumber);
      })
      .catch(() => {
        setFieldValue('invoiceNumber', '1');
      })
      .finally(() => setIsLoading(false));
  }, [setFieldValue]);

  const handleInvoiceCurrencyChange = (event: {
    target: { value: string };
  }) => {
    if (event.target.value !== '') {
      setInvoiceCurrency(event.target.value as Currencies);
    }
    setFieldValue('currency', event.target.value);
  };

  const handleOnInvoicePayerSelectChange = (event: {
    target: { value: string };
  }) => {
    const invoicePayer = invoicePayerOptions.find(
      (payer) => payer.id === event.target.value
    );
    if (invoicePayer) {
      setFieldValue('payerId', event.target.value);
    }
  };

  const handleOnInvoiceConfigSelectChange = (event: {
    target: { value: string };
  }) => {
    const invoiceConfig = invoiceConfigOptions.find(
      (config) => config.id === event.target.value
    );
    if (invoiceConfig) {
      if (invoiceConfig?.currency) {
        setInvoiceCurrency(invoiceConfig?.currency);
      }
      setFieldValue('invoiceConfigId', event.target.value);
      setFieldValue('amount', '');
    }
  };

  const invoicePreviewStackProps: StackProps | undefined = showPreviewFixed
    ? {
        padding: '20px',
        position: 'fixed',
        bottom: '0',
        right: '25px',
        transform: 'scale(0.5)',
        transformOrigin: 'bottom right',
      }
    : undefined;

  const invoiceConfig =
    invoiceConfigOptions.find(
      (config) =>
        config.id === values.invoiceConfigId && !isOptToNewConfiguration
    ) ??
    ({
      title: values.title,
      subtitle: values.subtitle,
      currency: values.currency,
      address1: values.address1,
      address2: values.address2,
      phone: values.phone,
      email: values.email ?? undefined,
      color: values.color,
    } as IInvoiceConfigDto);
  const invoicePayer =
    invoicePayerOptions.find(
      (payer) => payer.id === values.payerId && !isOptToNewPayer
    ) ??
    ({
      name: values.payerName,
      address1: values.payerAddress1,
      address2: values.payerAddress2,
      phone: values.payerPhone,
      email: values.payerEmail ?? undefined,
    } as IPayerDto);

  const handleColorSelected = (colorResult: ColorResult) => {
    setColor(colorResult.hex);
    setFieldValue('color', colorResult.hex);
  };

  const handleOnInputCurrencyChangeValue = (value: string) =>
    setFieldValue('amount', value);

  const invoice = {
    ...values,
    amount: Number(values.amount.replace(/[^0-9]/g, '')),
    currency: invoiceCurrency,
  };

  const handleSignInClick = () => {
    window.open(appConfig.signInUrl, '_blank');
  };

  const invoiceCreateServicesDataProps: InvoiceCreateServicesDataProps = {
    errors,
    getFieldProps,
    touched,
    invoiceCurrency,
    onCurrencyChange: handleOnInputCurrencyChangeValue,
    isLoggedIn,
    handleSignInClick,
    isLoading,
  };

  const invoiceCreateConfigAndPayerSelectProps: InvoiceCreateConfigAndPayerSelectProps =
    {
      setIsOptToNewConfiguration,
      setIsOptToNewPayer,
      invoiceConfigOptions,
      invoicePayerOptions,
      touched,
      values,
      errors,
      getFieldProps,
      handleOnInvoiceConfigSelectChange,
      handleOnInvoicePayerSelectChange,
      isLoading,
      isOptToNewConfiguration,
      isOptToNewPayer,
    };

  const invoiceCreateNewPayerProps: InvoiceCreateNewPayerProps = {
    errors,
    getFieldProps,
    invoicePayerOptions,
    setIsOptToNewPayer,
    touched,
    isLoggedIn,
    values,
  };

  const invoiceCreateNewConfigProps: InvoiceCreateNewConfigProps = {
    errors,
    getFieldProps,
    touched,
    setIsOptToNewConfiguration,
    invoiceConfigOptions,
    values,
    handleInvoiceCurrencyChange,
    color,
    colors,
    handleColorSelected,
    isLoggedIn,
  };

  useEffect(() => {
    loadInvoiceConfigs();
    loadInvoicePayers();
    loadInvoiceNextNumber();
  }, [loadInvoiceConfigs, loadInvoiceNextNumber, loadInvoicePayers]);

  useEffect(() => {
    setShowPreviewFixed(!isBottomObserverVisible && !isFooterObserverVisible);
  }, [isBottomObserverVisible, isFooterObserverVisible]);

  useEffect(() => {
    if (isOptToNewPayer) {
      setFieldValue('payerId', '');
    } else {
      setFieldValue('payerId', invoicePayerOptions[0]?.id);
    }
  }, [invoicePayerOptions, isOptToNewPayer, setFieldValue]);

  useEffect(() => {
    if (isOptToNewConfiguration) {
      setFieldValue('invoiceConfigId', '');
    } else {
      setFieldValue('invoiceConfigId', invoiceConfigOptions[0]?.id);
    }
  }, [invoiceConfigOptions, isOptToNewConfiguration, setFieldValue]);

  return {
    invoiceConfigOptions,
    isLoading,
    invoicePayerOptions,
    invoice,
    invoiceConfig,
    invoicePayer,
    handleSubmit,
    invoicePreviewStackProps,
    bottomObserver,
    footerObserver,
    isOptToNewConfiguration,
    isOptToNewPayer,
    invoiceCreateNewConfigProps,
    invoiceCreateNewPayerProps,
    invoiceCreateConfigAndPayerSelectProps,
    invoiceCreateServicesDataProps,
  };
};

export default useInvoiceCreate;
