import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

type VerticalLabelDividerProps = {
  height?: string;
} & BoxProps;

const VerticalLabelDivider = ({
  height,
  ...props
}: VerticalLabelDividerProps) => {
  return (
    <Box
      display="flex"
      borderLeft="1px solid"
      borderColor="gray.200"
      height={height ?? '15px'}
      {...props}
    />
  );
};

export default VerticalLabelDivider;
