import { useTranslation } from 'react-i18next';
import {
  Card,
  Heading,
  Flex,
  Skeleton,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { CryptoTransactionDetails } from '../../../pages/Crypto/components/BuyCrypto/types';
import './../../languages/BuyCrypto.language';

export type ExchangeCurrencyDetailCardProps = {
  isOfferLoading: boolean;
  transactionDetails: CryptoTransactionDetails;
};

const ExchangeCurrencyDetailCard = ({
  isOfferLoading,
  transactionDetails,
}: ExchangeCurrencyDetailCardProps) => {
  const { t } = useTranslation('buy-crypto');
  return (
    <Card padding="20px" gap="20px">
      <Heading as="h2" size="md">
        {t('Transaction Details')}
      </Heading>
      {isOfferLoading && (
        <Flex flexDir="column" gap="5px">
          <Skeleton colorScheme="primary" height="40px" />
          <Skeleton colorScheme="primary" height="40px" />
          <Skeleton colorScheme="primary" height="40px" />
          <Skeleton colorScheme="primary" height="40px" />
          <Skeleton colorScheme="primary" height="40px" />
        </Flex>
      )}
      {!isOfferLoading && (
        <Flex flexDir="column" gap="5px">
          <FormControl>
            <FormLabel>{t('You send')}</FormLabel>
            <Text>{transactionDetails.amountSend}</Text>
          </FormControl>
          <FormControl>
            <FormLabel>{t('You get')}</FormLabel>
            <Text>{transactionDetails.amountGet}</Text>
          </FormControl>
          <FormControl>
            <FormLabel>{t('Payment method')}</FormLabel>
            <Text>{transactionDetails.paymentMethod}</Text>
          </FormControl>
          <FormControl>
            <FormLabel>{t('Network fee')}</FormLabel>
            <Text>{transactionDetails.networkFee}</Text>
          </FormControl>
          <FormControl>
            <FormLabel>{t('Exchange rate')}</FormLabel>
            <Text>{transactionDetails.exchangeRate}</Text>
          </FormControl>
        </Flex>
      )}
    </Card>
  );
};

export default ExchangeCurrencyDetailCard;
