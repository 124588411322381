import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Flex, Img, Text } from '@chakra-ui/react';

const OnboardingStatusData = () => {
  const { t } = useTranslation('onboarding');
  return (
    <>
      <Heading size="md">{t('We are reviewing your registration')}</Heading>
      <Flex flexDir="row" gap="20px" padding="20px" maxW="720px">
        <Flex>
          <Text>
            {t(
              'This process may take a few days. We will notify you as soon as your registration is approved. If you need support, please reach out to us.'
            )}
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="center">
          <Img
            src="assets/images/onboarding.png"
            alt="registration review"
            w="420px"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default OnboardingStatusData;
