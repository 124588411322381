export const getPaymentMethodsList = [
  {
    method: 'card',
    image: '/assets/images/crypto/visa-mastercard.png',
    methodName: 'Visa / Mastercard',
  },
  {
    method: 'apple_pay',
    image: '/assets/images/crypto/apay-gpay.png',
    methodName: 'Apple Pay / Google Pay',
  },
  {
    method: 'pix',
    image: '/assets/images/crypto/pix.png',
    methodName: 'Pix',
  },
];
