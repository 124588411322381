import i18n from '../../common/configuration/i18n';

i18n.addResourceBundle('en', 'transferences', {
  Transferences: 'Transferences',
  'Manage your transferences': 'Manage your transferences',
  'Source account': 'Source account',
  'Destination account': 'Destination account',
  Balance: 'Balance',
  'Account Owner': 'Account Owner',
  'Account Number': 'Account Number',
  'Routing Number': 'Routing Number',
  'Bank Name': 'Bank Name',
  'Branch Number': 'Branch Number',
  'Edit Destination': 'Edit Destination',
  'Amount you send': 'Amount you send',
  'Amount you receive': 'Amount you receive',
  'This include all cost (VET)': 'This include all cost (VET)',
  'This transfer will get': 'This transfer will get',
  'as fees': 'as fees',
  'Click here to get more details about ours fees':
    'Click here to get more details about ours fees',
  'Transfer money': 'Transfer money',
  Currency: 'Currency',
  'This transfer will arrive in': 'This transfer will arrive in',
  'business days': 'business days',
  Cancel: 'Cancel',
  'You are not ready to transfer money': 'You are not ready to transfer money',
  'Please, wait until we verify your account':
    'Please, verify if your accounts are set up, both local and global.',
  'If you need help, reach out our team at':
    'If you need help, reach out our team at',
  'Verifying your accounts...': 'Verifying your accounts...',
  Finality: 'Finality',
  'Select option': 'Select option',
  'commission and service': 'commission and service',
  loan: 'loan',
  freight: 'freight',
  investment: 'investment',
  merchandise: 'merchandise',
  'other non-service related payments': 'other non-service related payments',
  'consulting legal accounting and audit services':
    'consulting legal accounting and audit services',
  'marketing advertising and engineering services':
    'marketing advertising and engineering services',
  'computer it services': 'computer it services',
  'Exchange Rate': 'Exchange Rate',
  VET: 'VET',
  'We could not calculate the exchange tax. Please try again later.':
    'We could not calculate the exchange tax. Please try again later.',
  Source: 'Source',
  Fee: 'Fee',
  available: 'available',
  processing: 'processing',
  completed: 'completed',
  canceled: 'canceled',
  'Order submited': 'Order submited',
  'You will be notified when the order is paid via email':
    'You will be notified when the order is paid via email',
  'Submition failed': 'Submition failed',
  'Something wrong': 'Something wrong',
  'Submiting order': 'Submiting order',
  'Please wait...': 'Please wait...',
  'Go to Local Bank Accounts': 'Go to Local Bank Accounts',
  'Please verify your e-mail with the 6 digits code to validade this operation':
    'Please verify your e-mail with the 6 digits code to validade this operation',
  'E-mail sent with code': 'E-mail sent with code',
  'Verify your inbox': 'Verify your inbox',
});

i18n.addResourceBundle('ptbr', 'transferences', {
  Transferences: 'Transferências',
  'Manage your transferences': 'Gerencie suas transferências',
  'Source account': 'Conta de origem',
  'Destination account': 'Conta de destino',
  Balance: 'Saldo',
  'Account Owner': 'Titular da Conta',
  'Account Number': 'Numero da Conta',
  'Routing Number': 'Routing Number',
  'Bank Name': 'Nome do banco',
  'Branch Number': 'Numero da agencia',
  'Edit Destination': 'Editar destino',
  'Amount you send': 'Valor a enviar',
  'Amount you receive': 'Valor a receber',
  'This include all cost (VET)': 'Isso inclui todos os custos (VET)',
  'This transfer will get': 'Esta transferência terá',
  'as fees': 'em taxas',
  'Click here to get more details about ours fees':
    'Clique aqui para obter mais detalhes sobre nossas taxas',
  'Transfer money': 'Transferir dinheiro',
  Currency: 'Moeda',
  'This transfer will arrive in': 'Esta transferência chegará em até',
  'business days': 'dias úteis',
  Cancel: 'Cancelar',
  'You are not ready to transfer money':
    'Você não está pronto para cotar orderns',
  'You need to verify your accounts to continue':
    'Por favor, cadastre uma conta bancaria de destino para continuar',
  'If you need help, reach out our team at':
    'Se você precisar de ajuda, entre em contato com nossa equipe em',
  'Verifying your accounts...': 'Verificando suas contas...',
  Finality: 'Finalidade da operação',
  'Select option': 'Selecione uma opção',
  'commission and service refund': 'Comissão e serviço',
  loan: 'Empréstimo',
  freight: 'Frete',
  investment: 'Investimento',
  merchandise: 'Mercadoria',
  'other non-service related payments':
    'Outros pagamentos não relacionados a serviços',
  'consulting legal accounting and audit services':
    'Consultoria jurídica, contábil e de auditoria',
  'marketing advertising and engineering services':
    'Marketing, publicidade e serviços de engenharia',
  'computer it services': 'Serviços de computador e TI',
  'Exchange Rate': 'Câmbio',
  VET: 'Valor efetivo total',
  'We could not calculate the exchange tax. Please try again later.':
    'Não foi possível calcular a taxa de câmbio. Por favor, tente novamente mais tarde.',
  Source: 'Conta de Origem',
  Fee: 'Taxa fixa',
  available: 'Disponível para cotação',
  processing: 'Transferência em processamento',
  completed: 'Transferido',
  canceled: 'Cancelado',
  'Order submited': 'Ordem submetida',
  'You will be notified when the order is paid via email':
    'Você será notificado por e-mail quando a ordem for paga',
  'Submition failed': 'Falha na formalização da ordem',
  'Something wrong': 'Algo deu errado',
  'Submiting order': 'Enviando ordem',
  'Please wait...': 'Por favor, aguarde...',
  'Go to Local Bank Accounts': 'Ir para contas bancárias',
  'Please verify your e-mail with the 6 digits code to validade this operation':
    'Por favor, verifique seu e-mail com o código de 6 dígitos para validar esta operação',
  'E-mail sent with code': 'E-mail enviado com código',
  'Verify your inbox': 'Verifique sua caixa de entrada',
});
