import React from 'react';
import {
  Divider,
  Flex,
  Box,
  Button,
  Collapse,
  CircularProgress,
} from '@chakra-ui/react';
import { OnboardingStepStatus, OnboardingSteps } from '@waygee/shared-types';
import { PageContainer } from '../../common/components/PageContainer';
import { OnboardingBankingData } from './components/OnboardingBankingData';
import { OnboardingCompleteData } from './components/OnboardingCompleteData';
import { OnboardingDocumentsData } from './components/OnboardingDocumentsData';
import { OnboardingIndividualData } from './components/OnboardingIndividualData';
import { OnboardingStatusData } from './components/OnboardingStatusData';
import { OnboardingStepper } from './components/OnboardingStepper';
import useOnboarding from './hooks/useOnboarding';

const Onboarding = () => {
  const {
    subTitle,
    title,
    onboardingStepperProps,
    step,
    onboardingIndividualDataProps,
    handleSubmit,
    flexProps,
    boxProps,
    submitButtonLabel,
    onboardingBankingDataProps,
    t,
    handleBackStepClick,
    handleNextStepClick,
    isLoading,
    onboardingDocumentsDataProps,
    onboardingStatus,
  } = useOnboarding();
  return (
    <PageContainer title={title} subTitle={subTitle}>
      <OnboardingStepper {...onboardingStepperProps} />
      <Flex {...flexProps}>
        <Box {...boxProps}>
          <form onSubmit={handleSubmit}>
            <Flex flexDir="column" gap="15px">
              <Collapse
                in={step === OnboardingSteps.ACCOUNT_DATA}
                animateOpacity
                delay={2}
              >
                <OnboardingIndividualData {...onboardingIndividualDataProps} />
              </Collapse>
              <Collapse
                in={step === OnboardingSteps.BANKING_DATA}
                animateOpacity
                delay={2}
              >
                <OnboardingBankingData {...onboardingBankingDataProps} />
              </Collapse>
              <Collapse
                in={step === OnboardingSteps.DOCUMENTS}
                animateOpacity
                delay={2}
              >
                <OnboardingDocumentsData {...onboardingDocumentsDataProps} />
              </Collapse>
              <Collapse
                in={
                  step === OnboardingSteps.REVIEW &&
                  onboardingStatus === OnboardingStepStatus.PENDING
                }
                animateOpacity
                delay={2}
              >
                <OnboardingCompleteData />
              </Collapse>
              <Collapse
                in={
                  step === OnboardingSteps.REVIEW &&
                  onboardingStatus !== OnboardingStepStatus.PENDING
                }
                animateOpacity
                delay={2}
              >
                <OnboardingStatusData />
              </Collapse>
              <Divider />
              {step === OnboardingSteps.REVIEW &&
              onboardingStatus === OnboardingStepStatus.IN_PROGRESS ? null : (
                <Flex justifyContent="flex-end" gap="15px">
                  {isLoading && (
                    <CircularProgress isIndeterminate color="primary.500" />
                  )}
                  {step !== OnboardingSteps.ACCOUNT_DATA && (
                    <Button
                      onClick={handleBackStepClick}
                      isDisabled={isLoading}
                    >
                      {t('Back')}
                    </Button>
                  )}
                  {step !== OnboardingSteps.REVIEW && (
                    <Button
                      onClick={handleNextStepClick}
                      colorScheme="green"
                      isDisabled={isLoading}
                    >
                      {submitButtonLabel}
                    </Button>
                  )}
                  {step === OnboardingSteps.REVIEW &&
                    onboardingStatus === OnboardingStepStatus.PENDING && (
                      <Button
                        type="submit"
                        colorScheme="green"
                        onClick={handleNextStepClick}
                        isDisabled={isLoading}
                      >
                        {submitButtonLabel}
                      </Button>
                    )}
                </Flex>
              )}
            </Flex>
          </form>
        </Box>
      </Flex>
    </PageContainer>
  );
};

export default Onboarding;
