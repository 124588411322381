import { useTranslation } from 'react-i18next';
import {
  FormControl,
  Flex,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
  FormHelperText,
} from '@chakra-ui/react';
import { IBankAccountDto } from '@waygee/shared-types';
import {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import {
  AutoComplete,
  AutoCompleteProps,
} from '../../../../../../../../common/components/AutoComplete';

export type LocalAccountFormFieldsProps = {
  touched: FormikTouched<IBankAccountDto>;
  errors: FormikErrors<IBankAccountDto>;
  getFieldProps: (
    nameOrOptions: string | FieldConfig
  ) => FieldInputProps<string | number>;
  autoCompleteProps: AutoCompleteProps;
  fieldStartName?: string;
};

const LocalAccountFormFields = ({
  autoCompleteProps,
  errors,
  getFieldProps,
  touched,
  fieldStartName = '',
}: LocalAccountFormFieldsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex flexDirection="row" gap="10px">
        <FormControl
          mr="5%"
          isInvalid={(touched.bankCode && !!errors.bankCode) || false}
          isRequired
        >
          <FormLabel
            htmlFor={`${fieldStartName}bankCode`}
            fontWeight={'normal'}
          >
            {t('Bank Name')}
          </FormLabel>
          <AutoComplete {...autoCompleteProps} />
          <FormErrorMessage>{errors.bankCode}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex flexDirection="row" gap="10px">
        <FormControl
          mr="5%"
          isInvalid={(touched.branchCode && !!errors.branchCode) || false}
          isRequired
        >
          <FormLabel
            htmlFor={`${fieldStartName}branchCode`}
            fontWeight={'normal'}
          >
            {t('Branch Code')}
          </FormLabel>
          <Input
            type="text"
            {...getFieldProps(`${fieldStartName}branchCode`)}
          />
          <FormErrorMessage>{errors.branchCode}</FormErrorMessage>
        </FormControl>
        <FormControl
          mr="5%"
          isInvalid={(touched.accountNumber && !!errors.accountNumber) || false}
          isRequired
        >
          <FormLabel
            htmlFor={`${fieldStartName}accountNumber`}
            fontWeight={'normal'}
          >
            {t('Account Number')}
          </FormLabel>
          <Input
            type="text"
            {...getFieldProps(`${fieldStartName}accountNumber`)}
          />
          <FormErrorMessage>{errors.accountNumber}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex flexDirection="row" gap="10px">
        <FormControl>
          <FormLabel htmlFor={`${fieldStartName}amount`} fontWeight={'normal'}>
            {t('Account Type')}
          </FormLabel>

          <Select placeholder={t('Checking')} disabled></Select>
          <FormHelperText>
            {t('Currently we are just supporting checking accounts')}
          </FormHelperText>
        </FormControl>
      </Flex>
    </>
  );
};

export default LocalAccountFormFields;
