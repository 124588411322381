var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Olá "
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":24}}}) : helper)))
    + ",</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      ><br />\n      Sua ordem de transferência está em andamento.</span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <br />\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Valor em moeda extrangeira: "
    + alias4(((helper = (helper = lookupProperty(helpers,"SOURCE_AMOUNT") || (depth0 != null ? lookupProperty(depth0,"SOURCE_AMOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SOURCE_AMOUNT","hash":{},"data":data,"loc":{"start":{"line":15,"column":38},"end":{"line":15,"column":57}}}) : helper)))
    + "</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Valor em moeda nacional: "
    + alias4(((helper = (helper = lookupProperty(helpers,"DESTINATION_AMOUNT") || (depth0 != null ? lookupProperty(depth0,"DESTINATION_AMOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DESTINATION_AMOUNT","hash":{},"data":data,"loc":{"start":{"line":18,"column":35},"end":{"line":18,"column":59}}}) : helper)))
    + "</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      style=\"\n        font-family: Colfax, Helvetica, Arial, sans-serif;\n        font-size: 14px;\n        font-style: normal;\n        font-variant-ligatures: normal;\n        font-variant-caps: normal;\n        font-weight: 400;\n        letter-spacing: normal;\n        orphans: 2;\n        text-align: start;\n        text-indent: 0px;\n        text-transform: none;\n        widows: 2;\n        word-spacing: 0px;\n        -webkit-text-stroke-width: 0px;\n        white-space-collapse: preserve;\n        text-wrap: wrap;\n        text-decoration-thickness: initial;\n        text-decoration-style: initial;\n        text-decoration-color: initial;\n        float: none;\n        display: inline;\n      \"\n      >Dados bancários: "
    + alias4(((helper = (helper = lookupProperty(helpers,"DESTINATION_BANK_ACCOUNT") || (depth0 != null ? lookupProperty(depth0,"DESTINATION_BANK_ACCOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DESTINATION_BANK_ACCOUNT","hash":{},"data":data,"loc":{"start":{"line":45,"column":24},"end":{"line":45,"column":54}}}) : helper)))
    + "</span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <br />\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      style=\"\n        font-family: Colfax, Helvetica, Arial, sans-serif;\n        font-size: 14px;\n        font-style: normal;\n        font-variant-ligatures: normal;\n        font-variant-caps: normal;\n        font-weight: 400;\n        letter-spacing: normal;\n        orphans: 2;\n        text-align: start;\n        text-indent: 0px;\n        text-transform: none;\n        widows: 2;\n        word-spacing: 0px;\n        -webkit-text-stroke-width: 0px;\n        white-space-collapse: preserve;\n        text-wrap: wrap;\n        text-decoration-thickness: initial;\n        text-decoration-style: initial;\n        text-decoration-color: initial;\n        float: none;\n        display: inline;\n      \"\n      ><u><strong>Dados da Cotação</strong></u></span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      style=\"\n        font-family: Colfax, Helvetica, Arial, sans-serif;\n        font-size: 14px;\n        font-style: normal;\n        font-variant-ligatures: normal;\n        font-variant-caps: normal;\n        font-weight: 400;\n        letter-spacing: normal;\n        orphans: 2;\n        text-align: start;\n        text-indent: 0px;\n        text-transform: none;\n        widows: 2;\n        word-spacing: 0px;\n        -webkit-text-stroke-width: 0px;\n        white-space-collapse: preserve;\n        text-wrap: wrap;\n        text-decoration-thickness: initial;\n        text-decoration-style: initial;\n        text-decoration-color: initial;\n        float: none;\n        display: inline;\n      \"\n      ><strong>Dolar comercial: "
    + alias4(((helper = (helper = lookupProperty(helpers,"RATE") || (depth0 != null ? lookupProperty(depth0,"RATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RATE","hash":{},"data":data,"loc":{"start":{"line":104,"column":32},"end":{"line":104,"column":42}}}) : helper)))
    + "</strong></span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      style=\"\n        font-family: Colfax, Helvetica, Arial, sans-serif;\n        font-size: 14px;\n        font-style: normal;\n        font-variant-ligatures: normal;\n        font-variant-caps: normal;\n        font-weight: 400;\n        letter-spacing: normal;\n        orphans: 2;\n        text-align: start;\n        text-indent: 0px;\n        text-transform: none;\n        widows: 2;\n        word-spacing: 0px;\n        -webkit-text-stroke-width: 0px;\n        white-space-collapse: preserve;\n        text-wrap: wrap;\n        text-decoration-thickness: initial;\n        text-decoration-style: initial;\n        text-decoration-color: initial;\n        float: none;\n        display: inline;\n      \"\n      ><strong>IOF: "
    + alias4(((helper = (helper = lookupProperty(helpers,"IOF") || (depth0 != null ? lookupProperty(depth0,"IOF") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IOF","hash":{},"data":data,"loc":{"start":{"line":132,"column":20},"end":{"line":132,"column":29}}}) : helper)))
    + "</strong></span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      style=\"\n        font-family: Colfax, Helvetica, Arial, sans-serif;\n        font-size: 14px;\n        font-style: normal;\n        font-variant-ligatures: normal;\n        font-variant-caps: normal;\n        font-weight: 400;\n        letter-spacing: normal;\n        orphans: 2;\n        text-align: start;\n        text-indent: 0px;\n        text-transform: none;\n        widows: 2;\n        word-spacing: 0px;\n        -webkit-text-stroke-width: 0px;\n        white-space-collapse: preserve;\n        text-wrap: wrap;\n        text-decoration-thickness: initial;\n        text-decoration-style: initial;\n        text-decoration-color: initial;\n        float: none;\n        display: inline;\n      \"\n      ><strong>IR: "
    + alias4(((helper = (helper = lookupProperty(helpers,"IR") || (depth0 != null ? lookupProperty(depth0,"IR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IR","hash":{},"data":data,"loc":{"start":{"line":160,"column":19},"end":{"line":160,"column":27}}}) : helper)))
    + "</strong></span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      style=\"\n        font-family: Colfax, Helvetica, Arial, sans-serif;\n        font-size: 14px;\n        font-style: normal;\n        font-variant-ligatures: normal;\n        font-variant-caps: normal;\n        font-weight: 400;\n        letter-spacing: normal;\n        orphans: 2;\n        text-align: start;\n        text-indent: 0px;\n        text-transform: none;\n        widows: 2;\n        word-spacing: 0px;\n        -webkit-text-stroke-width: 0px;\n        white-space-collapse: preserve;\n        text-wrap: wrap;\n        text-decoration-thickness: initial;\n        text-decoration-style: initial;\n        text-decoration-color: initial;\n        float: none;\n        display: inline;\n      \"\n      ><strong>Taxa: "
    + alias4(((helper = (helper = lookupProperty(helpers,"FEE") || (depth0 != null ? lookupProperty(depth0,"FEE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"FEE","hash":{},"data":data,"loc":{"start":{"line":188,"column":21},"end":{"line":188,"column":30}}}) : helper)))
    + "</strong></span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      style=\"\n        font-family: Colfax, Helvetica, Arial, sans-serif;\n        font-size: 14px;\n        font-style: normal;\n        font-variant-ligatures: normal;\n        font-variant-caps: normal;\n        font-weight: 400;\n        letter-spacing: normal;\n        orphans: 2;\n        text-align: start;\n        text-indent: 0px;\n        text-transform: none;\n        widows: 2;\n        word-spacing: 0px;\n        -webkit-text-stroke-width: 0px;\n        white-space-collapse: preserve;\n        text-wrap: wrap;\n        text-decoration-thickness: initial;\n        text-decoration-style: initial;\n        text-decoration-color: initial;\n        float: none;\n        display: inline;\n      \"\n      ><strong>Spread: "
    + alias4(((helper = (helper = lookupProperty(helpers,"SPREAD") || (depth0 != null ? lookupProperty(depth0,"SPREAD") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SPREAD","hash":{},"data":data,"loc":{"start":{"line":216,"column":23},"end":{"line":216,"column":35}}}) : helper)))
    + "</strong></span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      style=\"\n        font-family: Colfax, Helvetica, Arial, sans-serif;\n        font-size: 14px;\n        font-style: normal;\n        font-variant-ligatures: normal;\n        font-variant-caps: normal;\n        font-weight: 400;\n        letter-spacing: normal;\n        orphans: 2;\n        text-align: start;\n        text-indent: 0px;\n        text-transform: none;\n        widows: 2;\n        word-spacing: 0px;\n        -webkit-text-stroke-width: 0px;\n        white-space-collapse: preserve;\n        text-wrap: wrap;\n        text-decoration-thickness: initial;\n        text-decoration-style: initial;\n        text-decoration-color: initial;\n        float: none;\n        display: inline;\n      \"\n      ><strong>Valor Efetivo Total: "
    + alias4(((helper = (helper = lookupProperty(helpers,"VET") || (depth0 != null ? lookupProperty(depth0,"VET") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"VET","hash":{},"data":data,"loc":{"start":{"line":244,"column":36},"end":{"line":244,"column":45}}}) : helper)))
    + "</strong></span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <br />\n  </div>\n\n  <div></div>\n</div>\n";
},"useData":true});