import { ColorResult, TwitterPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Switch,
  Text,
} from '@chakra-ui/react';
import { IInvoiceConfigDto } from '@waygee/shared-types';
import {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import './../../../../Invoice.language';
import { PhoneInput } from '../../../../../../common/components/PhoneInput';
import { VerticalLabelDivider } from '../../../../../../common/components/VerticalLabelDivider';
import { InvoiceCreateSchema } from '../../types/invoiceCreateSchema';

export type InvoiceCreateNewConfigProps = {
  setIsOptToNewConfiguration: (value: boolean) => void;
  invoiceConfigOptions: IInvoiceConfigDto[];
  touched: FormikTouched<InvoiceCreateSchema>;
  values: InvoiceCreateSchema;
  errors: FormikErrors<InvoiceCreateSchema>;
  getFieldProps: (
    nameOrOptions: string | FieldConfig
  ) => FieldInputProps<string | number>;
  handleInvoiceCurrencyChange: (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  color: string;
  colors: string[];
  handleColorSelected: (color: ColorResult) => void;
  isLoggedIn: boolean;
};

const InvoiceCreateNewConfig = ({
  errors,
  getFieldProps,
  touched,
  setIsOptToNewConfiguration,
  invoiceConfigOptions,
  values,
  handleInvoiceCurrencyChange,
  color,
  colors,
  handleColorSelected,
  isLoggedIn,
}: InvoiceCreateNewConfigProps) => {
  const { t } = useTranslation('invoice');
  return (
    <>
      <Flex flexDir="row" gap="10px" align="center">
        <Heading size="sm">{t('Your data')}</Heading>
        <VerticalLabelDivider />
        {invoiceConfigOptions.length > 0 && (
          <Button
            leftIcon={<FaArrowAltCircleLeft />}
            colorScheme="primary"
            variant="solid"
            size="xs"
            onClick={() => setIsOptToNewConfiguration(false)}
          >
            {t('Select existing')}
          </Button>
        )}
        {isLoggedIn && (
          <>
            <Switch
              size="sm"
              isChecked={values.saveConfig}
              {...getFieldProps('saveConfig')}
              colorScheme="primary"
            />
            <Text fontSize="smaller">{t('Save for use again later')}</Text>{' '}
          </>
        )}
      </Flex>
      <Flex flexDir="row" gap="20px" wrap={{ base: 'wrap', md: 'unset' }}>
        <Box w={{ base: '100%', md: '200px' }}>
          <FormControl
            id="title"
            isRequired
            isInvalid={(touched.title && !!errors.title) || false}
          >
            <FormLabel>{t('Title')}</FormLabel>
            <Input type="text" {...getFieldProps('title')} />
            <FormErrorMessage>{errors.title}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box w={{ base: '100%', md: '200px' }}>
          <FormControl
            id="subtitle"
            isInvalid={(touched.subtitle && !!errors.subtitle) || false}
          >
            <FormLabel>{t('Sub Title')}</FormLabel>
            <Input type="text" {...getFieldProps('subtitle')} />
            <FormErrorMessage>{errors.subtitle}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box w={{ base: '100%', md: '200px' }}>
          <FormControl
            id="currency"
            isRequired
            isInvalid={(touched.currency && !!errors.currency) || false}
          >
            <FormLabel>{t('Currency')}</FormLabel>
            <Select
              {...getFieldProps('currency')}
              onChange={handleInvoiceCurrencyChange}
            >
              <option value="">{t('Select a currency')}</option>
              <option value="usd">USD</option>
              <option value="eur">EUR</option>
              <option value="brl">BRL</option>
              <option value="aud">AUD</option>
              <option value="gbp">GBP</option>
              <option value="cad">CAD</option>
              <option value="chf">CHF</option>
              <option value="sek">SEK</option>
              <option value="jpy">JPY</option>
              <option value="nzd">NZD</option>
              <option value="nok">NOK</option>
              <option value="dkk">DKK</option>
              <option value="mxn">MXN</option>
            </Select>
            <FormErrorMessage>{errors.currency}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
      <Flex flexDir="row" gap="20px" wrap={{ base: 'wrap', md: 'unset' }}>
        <Box w="100%">
          <FormControl
            id="address1"
            isInvalid={(touched.address1 && !!errors.address1) || false}
          >
            <FormLabel>{t('Address')}</FormLabel>
            <Input type="text" {...getFieldProps('address1')} />
            <FormErrorMessage>{errors.address1}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box w="100%">
          <FormControl
            id="address2"
            isInvalid={(touched.address2 && !!errors.address2) || false}
          >
            <FormLabel>{t('Address 2')}</FormLabel>
            <Input type="text" {...getFieldProps('address2')} />
            <FormErrorMessage>{errors.address2}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
      <Flex flexDir="row" gap="20px" wrap={{ base: 'wrap', md: 'unset' }}>
        <Box w="250px">
          <FormControl
            id="phone"
            isInvalid={(touched.phone && !!errors.phone) || false}
          >
            <FormLabel>{t('Phone')}</FormLabel>
            <PhoneInput type="text" {...getFieldProps('phone')} />
            <FormErrorMessage>{errors.phone}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box w="350px">
          <FormControl
            id="email"
            isInvalid={(touched.email && !!errors.email) || false}
          >
            <FormLabel>{t('E-mail')}</FormLabel>
            <Input type="text" {...getFieldProps('email')} />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box w="100%">
          <FormControl
            id="sendMail"
            isInvalid={(touched.sendMail && !!errors.sendMail) || false}
          >
            <FormLabel>{t('Send e-mail to payer')}</FormLabel>
            <Checkbox
              isChecked={values.sendMail}
              {...getFieldProps('sendMail')}
            />
            <FormErrorMessage>{errors.sendMail}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
      <Flex flexDir="row" gap="20px" wrap={{ base: 'wrap', md: 'unset' }}>
        <Box w="100%">
          <FormControl>
            <FormLabel>{t('Invoice Paragraph Colors')}</FormLabel>
            <TwitterPicker
              onChangeComplete={handleColorSelected}
              color={color}
              width="100%"
              colors={colors}
            />
          </FormControl>
        </Box>
      </Flex>
      <Divider />
    </>
  );
};

export default InvoiceCreateNewConfig;
