import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { SingleValue } from 'react-select';
import {
  Flex,
  FormControl,
  FormLabel,
  Skeleton,
  Input,
} from '@chakra-ui/react';
import { ChakraColors } from '../../configuration/ChakraColors';
import { OptionItem } from '../../types';

export type ExchangeCurrencyFieldProps = {
  fiatCurrencies: OptionItem[];
  selectedFiatCurrency: string;
  handleChangeFiatCurrency: (selectedOption: SingleValue<OptionItem>) => void;
  amountField: string;
  handleAmountChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  cryptoCurrencies: OptionItem[];
  selectedCryptoCurrency: string;
  handleChangeCryptoCurrency: (selectedOption: SingleValue<OptionItem>) => void;
  amountExpectedFrom: number;
  isOfferLoading: boolean;
};

const ExchangeCurrencyField = ({
  fiatCurrencies,
  selectedFiatCurrency,
  handleChangeFiatCurrency,
  amountField,
  handleAmountChange,
  cryptoCurrencies,
  selectedCryptoCurrency,
  handleChangeCryptoCurrency,
  amountExpectedFrom,
  isOfferLoading,
}: ExchangeCurrencyFieldProps) => {
  const { t } = useTranslation('buy-crypto');
  return (
    <Flex flexDir="column" gap="5px">
      <Flex
        flexDir="row"
        gap="20px"
        backgroundColor="primary.50"
        _hover={{ backgroundColor: 'primary.100' }}
        borderRadius="10"
        padding="10px"
      >
        <FormControl>
          <FormLabel>{t('Currency')}</FormLabel>
          {fiatCurrencies.length > 0 ? (
            <ReactSelect
              placeholder={t('Currency')}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderColor: state.isFocused
                    ? ChakraColors.waygee
                    : ChakraColors.waygee_light,
                  boxShadow: ChakraColors.waygee,
                }),
              }}
              defaultValue={fiatCurrencies?.find((c: OptionItem): boolean =>
                c.value.includes(selectedFiatCurrency)
              )}
              onChange={handleChangeFiatCurrency}
              options={fiatCurrencies}
            />
          ) : (
            <Skeleton colorScheme="primary" height="40px" />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>{t('You send')}</FormLabel>
          <Input
            fontWeight={600}
            type="number"
            value={amountField}
            onChange={handleAmountChange}
          />
        </FormControl>
      </Flex>
      <Flex
        flexDir="row"
        gap="20px"
        borderRadius="10"
        backgroundColor="green.50"
        _hover={{ backgroundColor: 'green.100' }}
        padding="10px"
      >
        <FormControl>
          <FormLabel>{t('Currency')}</FormLabel>
          {cryptoCurrencies.length > 0 ? (
            <ReactSelect
              placeholder={t('Currency')}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderColor: state.isFocused
                    ? ChakraColors.waygee
                    : ChakraColors.waygee_light,
                  boxShadow: ChakraColors.waygee,
                }),
              }}
              defaultValue={cryptoCurrencies?.find((c: OptionItem): boolean =>
                c.value.includes(selectedCryptoCurrency)
              )}
              onChange={handleChangeCryptoCurrency}
              options={cryptoCurrencies}
            />
          ) : (
            <Skeleton colorScheme="primary" height="40px" />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>{t('You get')}</FormLabel>
          {!isOfferLoading ? (
            <Input
              fontWeight={600}
              isReadOnly
              value={`~ ${amountExpectedFrom ?? 0}`}
            />
          ) : (
            <Skeleton colorScheme="primary" height="40px" />
          )}
        </FormControl>
      </Flex>
    </Flex>
  );
};

export default ExchangeCurrencyField;
