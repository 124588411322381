import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { DateTimeHelper, FormatHelper } from '@waygee/common';
import { IOrderDto, OrderStatus } from '@waygee/shared-types';
import { FaCalculator, FaReceipt } from 'react-icons/fa';
import { ApiNames, getRequestConfig } from '../../../common/apis';
import {
  TableGridDataItemProps,
  TableGridDataRowProps,
  TableGridProps,
} from '../../../common/components/TableGrid/TableGrid';
import { ChakraColors } from '../../../common/configuration/ChakraColors';
import '../Statement.language';
import { axiosCustomInstance } from '../../../common/network';
import { PaymentHistoryProps } from '../Components/PaymentHistory/PaymentHistory';

const usePaymentHistory = () => {
  const { t } = useTranslation('statement');
  const [orders, setOrders] = useState<IOrderDto[]>();
  const [selectedOrder, setSelectedOrder] = useState<IOrderDto | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [tableGridProps, setTableGridProps] = useState<TableGridProps>({
    headers: undefined,
    rows: undefined,
    isLoading: true,
  });
  const [isQuoting, setIsQuoting] = useState(false);

  const loadTableData = useCallback(() => {
    setIsLoading(true);
    axiosCustomInstance
      .request<IOrderDto[]>(
        getRequestConfig({
          endpoint: ApiNames.GET_ALL_ORDERS,
        })
      )
      .then((res) => setOrders(res.data))
      .catch(() => setOrders(undefined))
      .finally(() => setIsLoading(false));
  }, []);

  const handleOrderClick = (order: IOrderDto) => {
    setIsQuoting(true);
    setSelectedOrder(order);
  };

  const paymentHistoryProps: PaymentHistoryProps = {
    tableGridProps,
    isQuoting,
    setIsQuoting,
    selectedOrder,
    setSelectedOrder,
    loadTableData,
  };

  useEffect(() => {
    const rows = orders?.map((order) => {
      return {
        cells: [
          {
            content: DateTimeHelper.formatUtc(order.createdAt, 'dd/LL/yyyy'),
          },
          {
            content: t(order.status),
            color:
              order.status === OrderStatus.AVAILABLE ||
              order.status === OrderStatus.COMPLETED
                ? ChakraColors.success
                : ChakraColors.warning,
          },
          {
            content: order?.currency?.toUpperCase(),
          },
          {
            content: FormatHelper.formatCurrency(order.amount, order.currency),
          },
          {
            content:
              order.status === OrderStatus.AVAILABLE ? (
                <Button
                  colorScheme="green"
                  size="sm"
                  minW="90px"
                  onClick={() => handleOrderClick(order)}
                  leftIcon={<FaCalculator />}
                >
                  {t('Quote')}
                </Button>
              ) : (
                order.status !== OrderStatus.CANCELED && (
                  <Button
                    colorScheme="primary"
                    size="sm"
                    minW="90px"
                    onClick={() => handleOrderClick(order)}
                    leftIcon={<FaReceipt />}
                  >
                    {t('Receipt')}
                  </Button>
                )
              ),
          } as TableGridDataItemProps,
        ],
      } as TableGridDataRowProps;
    });

    setTableGridProps({
      headers: [
        {
          content: t('Date'),
        },
        {
          content: t('Status'),
        },
        {
          content: t('Currency'),
        },
        {
          content: t('Amount'),
        },
        {
          content: t('Action'),
        },
      ],
      rows,
      isLoading,
    });
  }, [isLoading, orders, t]);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return { paymentHistoryProps };
};

export default usePaymentHistory;
