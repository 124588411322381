import { useTranslation } from 'react-i18next';
import {
  Box,
  BoxProps,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
} from '@chakra-ui/react';
import { ChakraColors } from '../../../common/configuration/ChakraColors';
import '../SignUp.language';

export type SignUpCompleteProps = {
  boxProps: BoxProps;
};

const SignUpComplete = ({ boxProps }: SignUpCompleteProps) => {
  const { t } = useTranslation('signup');
  const nextSteps = [
    {
      title: t('Finish your profile'),
      description: t('Access your account to finish your profile'),
    },
    {
      title: t('Review your data'),
      description: t('up to 1 business days'),
    },
    {
      title: t('Ready to operate'),
      description: t('Share your data with your payers'),
    },
  ];
  return (
    <Box {...boxProps}>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        wrap="wrap"
        gap="20px"
      >
        <Flex flexDirection="column" gap="20px">
          <Heading as="h4" color={ChakraColors.success}>
            {t('Account created')}!
          </Heading>
          <Text>
            {t('You are now able to login using your e-mail and password')}
            {'.'}
            &nbsp;
            <Link href="secure/login" textDecor="underline">
              {t('Click here to go to login page')}
            </Link>
          </Text>
          <Divider />
          <Heading size="md">{t('Look what come next')}</Heading>
          <Flex flexDir="row">
            <Flex w="100%" alignItems="center">
              <Stepper orientation="vertical" height="250px" gap="0" index={0}>
                {nextSteps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>

                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
            </Flex>
            <Flex
              alignItems="flex-end"
              justifyContent="flex-end"
              flexDir="row"
              w="100%"
            >
              <Image src="assets/images/signup-bg.png" height="350px" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default SignUpComplete;
