import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './common/configuration/ChakraColors';
import LayoutWrapper from './common/templates/LayoutWrapper';
import { Account } from './pages/Account';
import { ChangePassword } from './pages/Account/components/ChangePassword';
import { BankAccounts } from './pages/BankAccounts';
import { Contact } from './pages/Contact';
import { Crypto } from './pages/Crypto';
import { Dashboard } from './pages/Dashboard';
import { Home } from './pages/Home';
import { Invoice } from './pages/Invoice';
import { Login } from './pages/Login';
import { Onboarding } from './pages/Onboarding';
import { PasswordReset } from './pages/PasswordReset';
import { BuyCryptoDemo } from './pages/Public/BuyCryptoDemo';
import { InvoiceDemo } from './pages/Public/InvoiceDemo';
import { SignUp } from './pages/SignUp';
import { Statement } from './pages/Statement';

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<LayoutWrapper />}>
            <Route path="secure" element={<Navigate to="login" replace />} />
            <Route path="secure/login" element={<Login />} />
            <Route path="secure/password-reset" element={<PasswordReset />} />
            <Route path="secure/signup" element={<SignUp />} />
            <Route path="secure/dashboard" element={<Dashboard />} />
            <Route
              path="secure/bank-accounts"
              element={<BankAccounts.Main />}
            />
            <Route path="secure/statement" element={<Statement.Main />} />
            <Route
              path="secure/bank-accounts/local"
              element={<BankAccounts.Local />}
            />
            <Route
              path="secure/statement/transferences"
              element={<Statement.Transferences />}
            />
            <Route
              path="secure/bank-accounts/global"
              element={<BankAccounts.Global />}
            />
            <Route path="secure/crypto" element={<Crypto.Main />} />
            <Route path="secure/invoice" element={<Invoice.Main />} />
            <Route path="secure/invoice/config" element={<Invoice.Config />} />
            <Route path="secure/invoice/payers" element={<Invoice.Payers />} />
            <Route path="secure/invoice/create" element={<Invoice.Create />} />
            <Route
              path="secure/invoice/history"
              element={<Invoice.History />}
            />
            <Route path="secure/account" element={<Account />} />
            <Route
              path="secure/account/change-password"
              element={<ChangePassword />}
            />
            <Route path="secure/onboarding" element={<Onboarding />} />
            <Route path="secure/contact" element={<Contact />} />
          </Route>
          <Route path="public/invoice-demo" element={<InvoiceDemo />} />
          <Route path="public/buy-crypto" element={<BuyCryptoDemo />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
};
