import { useTranslation } from 'react-i18next';
import { Heading, Flex, Text } from '@chakra-ui/react';
import '../../Onboarding.language';

const OnboardingCompleteData = () => {
  const { t } = useTranslation('onboarding');
  return (
    <>
      <Heading size="md">{t('Submit to review')}</Heading>
      <Flex flexDir="column" gap="20px" padding="20px">
        <Text>
          {t(
            'Please, make sure that you have filled the correct data. Your registration might be reproved if there are any mismatching'
          )}
        </Text>
        <Text>
          {t(
            'If you are sure that you have filled the correct data, please click on the button below to submit your registration'
          )}
        </Text>
      </Flex>
    </>
  );
};

export default OnboardingCompleteData;
