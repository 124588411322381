import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';
import { AuthContextProvider } from '../../contexts/AuthContext/AuthContext';
import Footer from '../components/Footer/Footer';
import NavBar from '../components/NavBar/NavBar';
import { LayoutWrapperLogic } from './components';
import { SignUpCheck } from './components/SignUpCheck';
import styles from './LayoutWrapper.module.scss';

const LayoutWrapper = () => {
  const location = useLocation();
  const hideNavBar = [
    '/secure/login',
    '/secure/signup',
    '/secure/password-reset',
  ].includes(location.pathname);
  const hideFooterBar = hideNavBar;

  useEffect(() => {
    sessionStorage.setItem('chakra-ui-color-mode', 'light');
  }, []);

  return (
    <AuthContextProvider>
      <LayoutWrapperLogic />
      {!hideNavBar && (
        <div className={styles.wrapper}>
          <NavBar />
        </div>
      )}
      <SignUpCheck />
      <Stack className={styles.content} bgColor="primary.50" margin="0">
        <Outlet />
      </Stack>
      {!hideFooterBar && <Footer />}
    </AuthContextProvider>
  );
};

export default LayoutWrapper;
