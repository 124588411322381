import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@chakra-ui/react';
import { FcMoneyTransfer } from 'react-icons/fc';
import { FeatureCard } from '../../common/components/FeatureCard';
import { PageContainer } from '../../common/components/PageContainer';
import { GlobalAccount } from './components/GlobalAccount';
import LocalAccount from './components/LocalAccount/LocalAccount';
import useGlobalAccount from './hooks/useGlobalAccount';
import useLocalAccount from './hooks/useLocalAccount';

const BankAccounts = ({ children }: { children: ReactNode }) => ({
  children,
});

const Main = () => {
  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('Bank Accounts')}
      subTitle={t('Your bank accounts')}
    >
      <FeatureCard
        heading={t('Global Account')}
        icon={<Icon as={FcMoneyTransfer} w={10} h={10} />}
        description={t(
          'Configure your virtual bank account to receive payments.'
        )}
        href={'/secure/bank-accounts/global'}
      />
      <FeatureCard
        heading={t('Brazilian Account')}
        icon={<Icon as={FcMoneyTransfer} w={10} h={10} />}
        description={t(
          'Configure your phisical bank account to transfer money.'
        )}
        href={'/secure/bank-accounts/local'}
      />
    </PageContainer>
  );
};

const Global = () => {
  const { globalAccountProps } = useGlobalAccount();
  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('Global Account')}
      subTitle={t('Configure your virtual bank account to receive payments.')}
    >
      <GlobalAccount {...globalAccountProps} />
    </PageContainer>
  );
};

const Local = () => {
  const { localAccountProps } = useLocalAccount();
  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('Brazilian Account')}
      subTitle={t('Configure your phisical bank account to transfer money.')}
    >
      <LocalAccount {...localAccountProps} />
    </PageContainer>
  );
};

const BankAccountsPages = { BankAccounts, Main, Global, Local };
export default BankAccountsPages;
