import {
  signUpEmail,
  mfaEmail,
  resetPasswordEmail,
  contactUsEmail,
  invoiceToPayerEmail,
  onboardingWelcomeEmail,
  onboardingSentToReviewEmail,
  onboardingReviewPendingInfoEmail,
  onboardingReviewApprovedEmail,
  orderAvailableEmail,
  orderSentEmail,
  orderPaidEmail,
} from './templates/emails';
// invoice templates
import { defaultInvoice } from './templates/invoices';
import { TemplateTypes } from './templates.definitions';
import { CompiledTemplateFn } from '@waygee/shared-types';

export const TEMPLATE_COMPILER_BY_TYPE: Record<
  TemplateTypes,
  CompiledTemplateFn
> = {
  [TemplateTypes.EMAIL_SIGNUP]: signUpEmail,
  [TemplateTypes.EMAIL_MFA]: mfaEmail,
  [TemplateTypes.EMAIL_RESET_PASSWORD]: resetPasswordEmail,
  [TemplateTypes.EMAIL_CONTACT_US]: contactUsEmail,
  [TemplateTypes.EMAIL_INVOICE_TO_PAYER]: invoiceToPayerEmail,
  [TemplateTypes.EMAIL_ONBOARDING_WELCOME]: onboardingWelcomeEmail,
  [TemplateTypes.EMAIL_ONBOARDING_SENT_TO_REVIEW]: onboardingSentToReviewEmail,
  [TemplateTypes.EMAIL_ONBOARDING_REVIEW_PENDING_INFO]:
    onboardingReviewPendingInfoEmail,
  [TemplateTypes.EMAIL_ONBOARDING_REVIEW_APPROVED]:
    onboardingReviewApprovedEmail,
  [TemplateTypes.EMAIL_ORDER_AVAILABLE]: orderAvailableEmail,
  [TemplateTypes.EMAIL_ORDER_SENT]: orderSentEmail,
  [TemplateTypes.EMAIL_ORDER_PAID]: orderPaidEmail,
  [TemplateTypes.INVOICE_DEFAULT]: defaultInvoice,
};
