import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../common/components/PageContainer';
import { BuyCrypto } from './components/BuyCrypto';
import './Crypto.language';

const Main = () => {
  const { t } = useTranslation('crypto');

  return (
    <PageContainer
      title={t('Crypto')}
      subTitle={t('Buy, sell and manage your crypto assets')}
    >
      <BuyCrypto />
    </PageContainer>
  );
};

const InvoicePages = { Crypto, Main };
export default InvoicePages;
