var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span>Olá "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":24}}}) : helper)))
    + ",</span>\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span\n      ><br />\n      Agradecemos por fornecer seus dados. Eles foram recebidos com sucesso!<br />\n      <br />\n      Nossa equipe está revisando as informações e você pode esperar uma\n      resposta dentro de até 1 dia útil. Estamos comprometidos em garantir que\n      cada detalhe seja analisado com cuidado.<br />\n      <br />\n      Caso surjam dúvidas adicionais durante a revisão, entraremos em contato\n      para esclarecimentos. Caso contrário, fique tranquilo(a) que assim que\n      concluirmos a análise, enviaremos uma resposta detalhada.<br />\n      <br />\n      Agradecemos pela sua paciência e pela confiança em nossa plataforma.<br />\n      <br />\n    </span>\n  </div>\n</div>\n";
},"useData":true});