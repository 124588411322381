import i18n from './../../common/configuration/i18n';

i18n.addResourceBundle('en', 'statement', {
  Transferences: 'Transferences',
  Date: 'Date',
  Payer: 'Payer',
  Status: 'Status',
  Currency: 'Currency',
  Amount: 'Amount',
  Action: 'Action',
  Quote: 'Quote',
  Receipt: 'Receipt',
  Cancel: 'Cancel',
  'Submit Quote': 'Submit Quote',
  available: 'Available to quote',
  processing: 'Processing transference',
  completed: 'Transference completed',
  canceled: 'Transference canceled',
  Close: 'Close',
  'Transference requested': 'Transference requested',
  'Your order is being processed. You will receive the details in your email.':
    'Your order is being processed. You will receive the details in your email.',
  'Exchange Disclaimer':
    'If you obtain a foreign exchange quote outside business hours, your transfer will be processed at the updated exchange rate on the next business day of the foreign exchange market. Rates may vary significantly outside business hours due to market fluctuations. By proceeding with the quote, you agree to this condition.',
});

i18n.addResourceBundle('ptbr', 'statement', {
  Transferences: 'Transferências',
  Date: 'Data',
  Payer: 'Pagador',
  Status: 'Status',
  Currency: 'Moeda',
  Amount: 'Quantia',
  Action: 'Ação',
  Quote: 'Cotar',
  Receipt: 'Recibo',
  Cancel: 'Cancelar',
  'Submit Quote': 'Realizar transferência',
  available: 'Disponível para cotação',
  processing: 'Transferência em processamento',
  completed: 'Transferido',
  canceled: 'Cancelado',
  Close: 'Fechar',
  'Transference requested': 'Transferência solicitada',
  'Your order is being processed. You will receive the details in your email.':
    'Seu pedido está sendo processado. Você receberá os detalhes em seu e-mail.',
  'Exchange Disclaimer':
    'Caso você realize uma cotação de câmbio fora do horário comercial de negociação de câmbio, sua transferência estará sujeita a ser processada com a taxa de câmbio atualizada no próximo dia útil de funcionamento do mercado de câmbio. As taxas podem variar significativamente fora do horário comercial devido a flutuações do mercado. Ao prosseguir com a cotação, você concorda com essa condição.',
});
