import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Button, Text } from '@chakra-ui/react';
import { DocumentTypes, IDocumentDto } from '@waygee/shared-types';
import { LuFileSearch2 } from 'react-icons/lu';
import { MdUploadFile } from 'react-icons/md';
import './../../../../Onboarding.language';
import { ContactFiles } from '../../../../';
import styles from './OnboardingDocumentsDataFileUpload.module.scss';

export type OnboardingDocumentsDataFileUploadProps = {
  contactId: string;
  name: string;
  multiple?: boolean;
  onChange: (contactFile: ContactFiles) => void;
  documents: IDocumentDto<DocumentTypes.TIN>[];
};

const OnboardingDocumentsDataFileUpload = ({
  name,
  multiple,
  onChange,
  contactId,
  documents,
}: OnboardingDocumentsDataFileUploadProps) => {
  const [files, setFiles] = useState<File[] | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('onboarding');

  const onSelectFileClick = () => {
    fileRef.current?.click();
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.files);
    if (event.target.files) {
      setFiles(Array.from(event.target.files));
      onChange({
        contactId: contactId,
        files: Array.from(event.target.files),
      });
    }
  };

  const getFileNames = () => {
    if (!files) {
      return '';
    }

    return files.map((file) => file.name).join(', ');
  };

  return (
    <Flex flexDir="row" gap="20px">
      <input
        type="file"
        id={name}
        ref={fileRef}
        onChange={onFileChange}
        style={{ display: 'none' }}
        multiple={multiple}
      />
      <Button
        leftIcon={<LuFileSearch2 />}
        colorScheme="primary"
        variant="outline"
        onClick={onSelectFileClick}
      >
        {t('Select File')}
      </Button>
      <Button
        leftIcon={<MdUploadFile />}
        colorScheme="primary"
        variant="outline"
      >
        {t('Upload File')}
      </Button>
      <div className={styles.fileMsg}>
        <Text fontSize="small" color="gray.200">
          {documents.filter((c) => !!c.filePath)?.length > 0 &&
            t('You have already uploaded a file previously')}
        </Text>
        <Text>
          {files
            ? `${t('File selected')}: ${getFileNames()}`
            : t('No file selected')}
        </Text>
      </div>
    </Flex>
  );
};

export default OnboardingDocumentsDataFileUpload;
