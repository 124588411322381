import { Currencies } from '@waygee/shared-types';
import Decimal from 'decimal.js';

export class FormatHelper {
  static formatCurrency(value: number, currency: Currencies, decimals = 2) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: decimals,
    });
    return formatter.format(new Decimal(value).div(100).toNumber());
  }

  static formatPercentage(value: number) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  }
}
