var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div style=\"text-align: center\">\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span style=\"font-size: 15px\"\n      >Estamos muito felizes com seu interesse em criar uma conta\n      conosco.&nbsp;</span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span style=\"font-size: 15px\"\n      >Mas antes, precisamos confirmar seu e-mail.&nbsp;</span\n    >\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <br />\n  </div>\n  <div style=\"font-family: inherit; text-align: inherit\">\n    <span style=\"font-size: 15px\"\n      >Por favor, clique no botão abaixo para continuar o seu cadastro.</span\n    >\n  </div>\n  <a\n    href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"SIGNUP_REDIRECT_URL") || (depth0 != null ? lookupProperty(depth0,"SIGNUP_REDIRECT_URL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SIGNUP_REDIRECT_URL","hash":{},"data":data,"loc":{"start":{"line":22,"column":10},"end":{"line":22,"column":33}}}) : helper)))
    + "\"\n    style=\"\n      background-color: #eeeef7;\n      border: 1px solid #b0b0d9;\n      border-color: #b0b0d9;\n      border-radius: 40px;\n      border-width: 1px;\n      color: #3f4259;\n      display: inline-block;\n      font-size: 15px;\n      font-weight: normal;\n      letter-spacing: 0px;\n      line-height: 25px;\n      padding: 12px 18px 10px 18px;\n      margin: 10px;\n      text-align: center;\n      text-decoration: none;\n      border-style: solid;\n      font-family: inherit;\n      width: 168px;\n    \"\n    target=\"_blank\"\n    >Continuar Cadastro</a\n  >\n  <div>\n    <div style=\"font-family: inherit\">\n      <span\n        >Caso o link acima não esteja funcionando, acesse através dessa\n        url:&nbsp;</span\n      >\n    </div>\n    <div style=\"font-family: inherit\">\n      <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"SIGNUP_REDIRECT_URL") || (depth0 != null ? lookupProperty(depth0,"SIGNUP_REDIRECT_URL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SIGNUP_REDIRECT_URL","hash":{},"data":data,"loc":{"start":{"line":54,"column":12},"end":{"line":54,"column":35}}}) : helper)))
    + "</span>\n    </div>\n    <div></div>\n  </div>\n</div>\n";
},"useData":true});