import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EditIcon, RepeatClockIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  Card,
  Flex,
  Icon,
  CardHeader,
  Heading,
  CardBody,
  ListItem,
  List,
  ListIcon,
  Text,
} from '@chakra-ui/react';
import { MdOutlinePayments } from 'react-icons/md';
import {
  TbRosetteNumber1,
  TbRosetteNumber2,
  TbRosetteNumber3,
  TbRosetteNumber4,
} from 'react-icons/tb';
import { FeatureCard } from '../../common/components/FeatureCard';
import { PageContainer } from '../../common/components/PageContainer';
import { InvoiceConfig } from './components/InvoiceConfig';
import './Invoice.language';
import InvoiceCreate from './components/InvoiceCreate/InvoiceCreate';
import { InvoiceHistory } from './components/InvoiceHistory';
import { InvoicePayers } from './components/InvoicePayers';

const Invoice = ({ children }: { children: ReactNode }) => ({
  children,
});

const Main = () => {
  const { t } = useTranslation('invoice');
  const navigate = useNavigate();

  return (
    <PageContainer title={t('Invoice')} subTitle={t('Your invoices')}>
      <Flex flexDir="row" gap="20px" flexWrap={{ base: 'wrap', md: 'unset' }}>
        <Card>
          {' '}
          <CardHeader>
            <Heading size="md">{t('Look how it is easy to start!')}</Heading>
          </CardHeader>
          <CardBody maxW="400px">
            <List spacing={3}>
              <ListItem
                onClick={() => navigate('/secure/invoice/config')}
                cursor="pointer"
              >
                <Flex flexDir="row" gap="10px" alignItems="center">
                  <ListIcon
                    as={TbRosetteNumber1}
                    color="green.500"
                    fontSize="xx-large"
                  />
                  <Text fontSize="small">
                    {t(
                      'Start by configuring your invoice, here you will define the invoice structure to be used as template'
                    )}
                  </Text>
                </Flex>
              </ListItem>
              <ListItem
                onClick={() => navigate('/secure/invoice/payers')}
                cursor="pointer"
              >
                <Flex flexDir="row" gap="10px" alignItems="center">
                  <ListIcon
                    as={TbRosetteNumber2}
                    color="green.500"
                    fontSize="xx-large"
                  />
                  <Text fontSize="small">
                    {t(
                      'Then you need to configure your payers, here you will define the payers that will receive the invoice'
                    )}
                  </Text>
                </Flex>
              </ListItem>
              <ListItem
                onClick={() => navigate('/secure/invoice/create')}
                cursor="pointer"
              >
                <Flex flexDir="row" gap="10px" alignItems="center">
                  <ListIcon
                    as={TbRosetteNumber3}
                    color="green.500"
                    fontSize="xx-large"
                  />
                  <Text fontSize="small">
                    {t(
                      'Finally you can create your invoice, here you will select the configuration and payer and set the invoice data'
                    )}
                  </Text>
                </Flex>
              </ListItem>
              <ListItem
                onClick={() => navigate('/secure/invoice/history')}
                cursor="pointer"
              >
                <Flex flexDir="row" gap="10px" alignItems="center">
                  <ListIcon
                    as={TbRosetteNumber4}
                    color="green.500"
                    fontSize="xx-large"
                  />
                  <Text fontSize="small">
                    {t(
                      'You can access your invoice history to see your recent invoices and download them'
                    )}
                  </Text>
                </Flex>
              </ListItem>
            </List>
          </CardBody>
        </Card>
        <Flex flexDir="row" flexWrap="wrap" gridGap={6} justify="center">
          <FeatureCard
            heading={t('Configure')}
            icon={<Icon as={SettingsIcon} w={10} h={10} />}
            description={t('Configure your invoice')}
            href={'/secure/invoice/config'}
          />
          <FeatureCard
            heading={t('Payers')}
            icon={<Icon as={MdOutlinePayments} w={10} h={10} />}
            description={t('Configure your invoice payers')}
            href={'/secure/invoice/payers'}
          />
          <FeatureCard
            heading={t('Create')}
            icon={<Icon as={EditIcon} w={10} h={10} />}
            description={t('Create new invoice')}
            href={'/secure/invoice/create'}
          />
          <FeatureCard
            heading={t('History')}
            icon={<Icon as={RepeatClockIcon} w={10} h={10} />}
            description={t('See your recent invoices')}
            href={'/secure/invoice/history'}
          />
        </Flex>
      </Flex>
    </PageContainer>
  );
};

const Config = () => {
  const { t } = useTranslation('invoice');
  return (
    <PageContainer title={t('Invoice')} subTitle={t('Configure invoice')}>
      <InvoiceConfig />
    </PageContainer>
  );
};

const Payers = () => {
  const { t } = useTranslation('invoice');
  return (
    <PageContainer
      title={t('Payers')}
      subTitle={t('Configure your invoice payers')}
    >
      <InvoicePayers />
    </PageContainer>
  );
};

const Create = () => {
  const { t } = useTranslation('invoice');
  return (
    <PageContainer
      title={t('Create Invoice')}
      subTitle={t('Create and send invoice to your payers')}
    >
      <InvoiceCreate />
    </PageContainer>
  );
};

const History = () => {
  const { t } = useTranslation('invoice');
  return (
    <PageContainer
      title={t('Invoice History')}
      subTitle={t('See your recent invoices')}
    >
      <InvoiceHistory />
    </PageContainer>
  );
};

const InvoicePages = { Invoice, Main, Config, Payers, Create, History };
export default InvoicePages;
