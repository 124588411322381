import { useTranslation } from 'react-i18next';
import {
  Card,
  Flex,
  FormControl,
  Heading,
  Divider,
  Skeleton,
  Button,
  Text,
  Checkbox,
  Spacer,
  Link,
} from '@chakra-ui/react';
import {
  TbRosetteNumber2,
  TbRosetteNumber1,
  TbRosetteNumber3,
} from 'react-icons/tb';
import { ConfirmationModal } from '../../../../common/components/ConfirmationModal';
import { CryptoWalletField } from '../../../../common/components/CryptoWalletField';
import { ExchangeCurrencyDetailCard } from '../../../../common/components/ExchangeCurrencyDetailCard';
import { ExchangeCurrencyField } from '../../../../common/components/ExchangeCurrencyField';
import { BuyCryptoCompletePage } from './components/BuyCryptoCompletePage';
import { BuyCryptoOffers } from './components/BuyCryptoOffers';
import { BuyCryptoPaymentMethods } from './components/BuyCryptoPaymentMethods';
import useBuyCrypto from './hooks/useBuyCrypto';
import '../../../../common/languages/BuyCrypto.language';

const BuyCrypto = () => {
  const {
    selectedCryptoCurrency,
    isOfferLoading,
    offers,
    paymentMethods,
    selectedPaymentMethod,
    handleChangeSelectedPaymentMethod,
    providers,
    selectedProvider,
    handleChangeSelectedProvider,
    amountExpectedFrom,
    transactionDetails,
    isCryptoWalletAddressValid,
    isOpen,
    onClose,
    onOpen,
    handleSubmitOrder,
    orderCreated,
    providerUrl,
    isTermsChecked,
    handleTermsChange,
    exchangeCurrencyFieldProps,
    cryptoWalletFieldProps,
  } = useBuyCrypto();
  const { t } = useTranslation('buy-crypto');
  const confirmationTitle = `${t(
    'You will be directed to'
  )} ${selectedProvider} ${t('to buy coins')}`;
  const description = `${selectedProvider} ${t(
    'is a fiat currency and cryptocurrency trading platform independently operated by a third party, and all related services are provided by'
  )}
  ${selectedProvider} ${t('and not by Waygee')}`;
  const confirmationButton = `${t('Go to Payment at')} ${selectedProvider}`;

  return (
    <Flex margin="20px 0">
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        title={confirmationTitle}
        description={description}
        cancelButtonLabel={t('Cancel')}
        confirmButtonLabel={confirmationButton}
        confirmButtonColorSchema="green"
        onConfirm={handleSubmitOrder}
      />
      <Flex flexDir="row" gap="20px" flexWrap="wrap">
        <Card w={{ base: '100%', md: '650px' }} padding="20px" gap="20px">
          {orderCreated && <BuyCryptoCompletePage url={providerUrl} />}
          {!orderCreated && (
            <>
              <Heading as="h2" size="md">
                {t('Buy')}
              </Heading>
              <ExchangeCurrencyField {...exchangeCurrencyFieldProps} />

              <Divider />
              <Heading as="h2" size="md">
                {t('Payment methods')}
              </Heading>
              {isOfferLoading && (
                <Flex flexDir="row" gap="5px" justifyContent="space-around">
                  <>
                    <Skeleton
                      colorScheme="primary"
                      height="40px"
                      width="110px"
                    />
                    <Skeleton
                      colorScheme="primary"
                      height="40px"
                      width="110px"
                    />
                    <Skeleton
                      colorScheme="primary"
                      height="40px"
                      width="110px"
                    />
                  </>
                </Flex>
              )}
              {!isOfferLoading && (
                <BuyCryptoPaymentMethods
                  paymentMethods={paymentMethods}
                  selectedPaymentMethod={selectedPaymentMethod}
                  handleChangeSelectedPaymentMethod={
                    handleChangeSelectedPaymentMethod
                  }
                />
              )}

              <Divider />
              <Heading as="h2" size="md">
                {t('Payment offers')}
              </Heading>

              {!isOfferLoading && !selectedProvider && (
                <Flex flexDir="row" gap="5px" justifyContent="space-around">
                  <Text color="red.500">
                    {t(
                      'We have no offer for this value and payment method yet. Try a different value or payment method.'
                    )}
                  </Text>
                </Flex>
              )}
              {isOfferLoading && (
                <Flex flexDir="row" gap="5px" justifyContent="space-around">
                  <Skeleton colorScheme="primary" height="40px" width="110px" />
                  <Skeleton colorScheme="primary" height="40px" width="110px" />
                  <Skeleton colorScheme="primary" height="40px" width="110px" />
                </Flex>
              )}
              {!isOfferLoading && (
                <BuyCryptoOffers
                  offers={offers}
                  selectedPaymentMethod={selectedPaymentMethod}
                  selectedProvider={selectedProvider}
                  handleChangeSelectedProvider={handleChangeSelectedProvider}
                  providers={providers}
                  selectedCryptoCurrency={selectedCryptoCurrency}
                />
              )}

              <Divider />
              {selectedProvider && (
                <>
                  <Heading as="h2" size="md">
                    {t('Wallet address')}
                  </Heading>
                  <Flex flexDir="column">
                    {!isOfferLoading && (
                      <CryptoWalletField {...cryptoWalletFieldProps} />
                    )}
                    {isOfferLoading && (
                      <Skeleton colorScheme="primary" height="40px" />
                    )}
                    <Flex flexDir="column">
                      <Spacer margin="15px" />
                      <FormControl>
                        <Checkbox
                          isChecked={isTermsChecked}
                          onChange={handleTermsChange}
                          colorScheme="primary"
                          name="termsCheckbox"
                        >
                          {t('I agree with')}
                        </Checkbox>
                        <Link
                          marginLeft="5px"
                          target="_blank"
                          href="https://waygee.io/termos-de-servico"
                        >
                          {t('Terms of Use, Privacy Policy and AML/KYC')}
                        </Link>
                      </FormControl>
                    </Flex>
                  </Flex>
                </>
              )}
            </>
          )}
          <Text fontSize="xs">{t('*Disclaimer')}</Text>
        </Card>
        <Flex flexDir="column" gap="20px" w={{ base: '100%', md: '300px' }}>
          <ExchangeCurrencyDetailCard
            isOfferLoading={isOfferLoading}
            transactionDetails={transactionDetails}
          />
          {!orderCreated && (
            <Card padding="20px" gap="20px">
              <Button
                colorScheme="green"
                isDisabled={
                  !isCryptoWalletAddressValid ||
                  !selectedProvider ||
                  !isTermsChecked
                }
                onClick={() => onOpen()}
              >
                {t('Buy')}
                {` ${amountExpectedFrom} ${selectedCryptoCurrency} `}
              </Button>
            </Card>
          )}
          <Card padding="20px" gap="20px">
            <Heading as="h2" size="xsm">
              {t('Crypto purchase steps')}
            </Heading>
            <Flex flexDir="column" gap="10px">
              <Flex
                flexDir="row"
                gap="10px"
                bgColor="gray.50"
                padding="5px"
                borderRadius="10px"
              >
                <Flex justifyContent="center" alignItems="center">
                  <TbRosetteNumber1 />
                </Flex>
                <Text fontSize="xs">
                  {t(
                    'Select the crypto you would like to buy and the fiat currency you want to purchase crypto with.'
                  )}
                </Text>
              </Flex>

              <Flex
                flexDir="row"
                gap="10px"
                bgColor="gray.50"
                padding="5px"
                borderRadius="10px"
              >
                <Flex justifyContent="center" alignItems="center">
                  <TbRosetteNumber2 />
                </Flex>
                <Text fontSize="xs">
                  {t(
                    'Enter the address of the crypto wallet to which your new cryptocurrency will be sent.'
                  )}
                </Text>
              </Flex>

              <Flex
                flexDir="row"
                gap="10px"
                bgColor="gray.50"
                padding="5px"
                borderRadius="10px"
              >
                <Flex justifyContent="center" alignItems="center">
                  <TbRosetteNumber3 />
                </Flex>
                <Text fontSize="xs">
                  {t(
                    'Confirm the payment and receive your newly purchased cryptocurrency in a few minutes.'
                  )}
                </Text>
              </Flex>
            </Flex>
          </Card>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BuyCrypto;
