import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  Text,
} from '@chakra-ui/react';
import { Currencies } from '@waygee/shared-types';
import {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { CurrencyInputField } from '../../../../../../common/components/CurrencyInputField';
import { InvoiceCreateSchema } from '../../types/invoiceCreateSchema';
import './../../../../Invoice.language';

export type InvoiceCreateServicesDataProps = {
  touched: FormikTouched<InvoiceCreateSchema>;
  errors: FormikErrors<InvoiceCreateSchema>;
  getFieldProps: (
    nameOrOptions: string | FieldConfig
  ) => FieldInputProps<string | number>;
  invoiceCurrency: Currencies;
  onCurrencyChange: (value: string) => void;
  isLoggedIn: boolean;
  handleSignInClick: () => void;
  isLoading: boolean;
};

const InvoiceCreateServicesData = ({
  touched,
  errors,
  getFieldProps,
  invoiceCurrency,
  onCurrencyChange,
  isLoggedIn,
  handleSignInClick,
  isLoading,
}: InvoiceCreateServicesDataProps) => {
  const { t } = useTranslation('invoice');
  const { onChange, ...amountFieldProps } = getFieldProps('amount');

  return (
    <>
      <Flex flexDir="row" gap="20px" wrap={{ base: 'wrap', md: 'unset' }}>
        <FormControl
          id="currency"
          isRequired
          width={{ base: 'unset', md: '100px' }}
        >
          <FormLabel>{t('Currency')}</FormLabel>
          <Text verticalAlign="center">{invoiceCurrency.toUpperCase()}</Text>
        </FormControl>
        <FormControl
          id="amount"
          isRequired
          isInvalid={(touched.amount && !!errors.amount) || false}
          width={{ base: 'unset', md: '165px' }}
        >
          <FormLabel>{t('Amount')}</FormLabel>
          <CurrencyInputField
            currency={invoiceCurrency}
            props={{
              type: 'text',
              id: 'amount',
              w: '165px',
              placeholder: '',
              ...amountFieldProps,
            }}
            onValueChange={onCurrencyChange}
          />
          <FormErrorMessage>{errors.amount}</FormErrorMessage>
        </FormControl>
        <FormControl
          id="number"
          isRequired
          width={{ base: 'unset', md: '165px' }}
          isInvalid={(touched.invoiceNumber && !!errors.invoiceNumber) || false}
        >
          <FormLabel>{t('Number')}</FormLabel>
          <Input width="165px" {...getFieldProps('invoiceNumber')} />
          <FormErrorMessage>{errors.invoiceNumber}</FormErrorMessage>
        </FormControl>
        <FormControl
          id="serviceDescription"
          isRequired
          isInvalid={
            (touched.serviceDescription && !!errors.serviceDescription) || false
          }
        >
          <FormLabel>{t('Service Description')}</FormLabel>
          <Input {...getFieldProps('serviceDescription')} />
          <FormErrorMessage>{errors.serviceDescription}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex flexDir="row" gap="20px">
        <FormControl
          id="date"
          isRequired
          isInvalid={(touched.date && !!errors.date) || false}
        >
          <FormLabel>{t('Date')}</FormLabel>
          <Input type="date" {...getFieldProps('date')} />
          <FormErrorMessage>
            {errors?.date ? String(errors.date) : ''}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id="dueDate"
          isInvalid={(touched.dueDate && !!errors.dueDate) || false}
        >
          <FormLabel>{t('Due Date')}</FormLabel>
          <Input type="date" {...getFieldProps('dueDate')} />
          <FormErrorMessage>
            {errors?.dueDate ? String(errors.dueDate) : ''}
          </FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex justifyContent="flex-end">
        <Button type="submit" colorScheme="primary" isDisabled={isLoading}>
          {t(isLoggedIn ? 'Create' : 'Download')}
        </Button>
      </Flex>
    </>
  );
};

export default InvoiceCreateServicesData;
