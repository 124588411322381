'use client';

import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { IContactDto } from '@waygee/shared-types';
import { useFormik } from 'formik';
import { BiMailSend } from 'react-icons/bi';
import { FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa';
import * as Yup from 'yup';
import { ApiNames, getRequestConfig } from '../../apis';
import { ChakraColors } from '../../configuration/ChakraColors';
import { axiosCustomInstance } from '../../network';
import { LanguageSelector } from '../LanguageSelector';
import { Logo } from '../Logo';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('Invalid email address'))
      .trim()
      .max(100, t('Must be 100 characters or less'))
      .required(t('Email is required')),
  });

  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (!isLoading) {
        setIsLoading(true);
        const promise = axiosCustomInstance
          .request<IContactDto>(
            getRequestConfig({
              endpoint: ApiNames.CONTACT,
              data: {
                email: values.email,
                name: 'LEAD',
                message: 'LEAD REGISTERED FROM THE WEBSITE FOOTER',
              },
            })
          )
          .finally(() => setIsLoading(false));

        toast.promise(promise, {
          success: {
            title: t('E-mail registered'),
            description: t('We will keep you updated'),
            isClosable: true,
          },
          error: {
            title: t('Failed'),
            description: t('Something wrong'),
            isClosable: true,
          },
          loading: {
            title: t('Registering email'),
            description: t('Please wait...'),
            isClosable: true,
          },
        });
      }
    },
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <Box
      bg={useColorModeValue('primary.100', 'primary.900')}
      color={useColorModeValue('primary.700', 'primary.200')}
    >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Logo />
            </Box>
            <Stack direction={'column'} spacing={2}>
              <Text fontSize={'sm'}>
                © 2023 Waygee LTDA. All rights reserved
              </Text>

              <Text fontSize={'sm'} color={ChakraColors.secondary}>
                help@waygee.io
              </Text>
            </Stack>
            <Stack direction={'row'} spacing={6}>
              <SocialButton
                label={'Twitter'}
                href={'https://twitter.com/WaygeeBrasil'}
              >
                <FaTwitter />
              </SocialButton>
              <SocialButton
                label={'YouTube'}
                href={'https://www.youtube.com/@waygee.brasil'}
              >
                <FaYoutube />
              </SocialButton>
              <SocialButton
                label={'Instagram'}
                href={'https://www.instagram.com/waygee.io'}
              >
                <FaInstagram />
              </SocialButton>
              <SocialButton
                label={'LinkedIn'}
                href={'https://www.linkedin.com/company/101345650/'}
              >
                <FaLinkedin />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>{t('Company')}</ListHeader>
            <Box as="a" href={'https://waygee.io/sobre'}>
              {t('About us')}
            </Box>
            <Box as="a" href={'https://pages.waygee.io/'}>
              {t('Blog')}
            </Box>
            <Box as="a" href={'/secure/contact'}>
              {t('Contact us')}
            </Box>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>{t('Support')}</ListHeader>
            <Box as="a" target="_blank" href="https://pages.waygee.io/ajuda">
              {t('Help Center')}
            </Box>
            <Box as="a" href="https://waygee.io/termos-de-servico">
              {t('Terms of Service')}
            </Box>
            <Box as="a" href="https://waygee.io/privacidade">
              {t('Privacy Policy')}
            </Box>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>{t('Stay up to date')}</ListHeader>
            <form onSubmit={handleSubmit}>
              <Stack direction={'row'}>
                <Input
                  placeholder={t('Your email address')}
                  bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                  border={0}
                  _focus={{
                    bg: 'whiteAlpha.300',
                  }}
                  {...getFieldProps('email')}
                />
                <IconButton
                  bg={useColorModeValue('green.400', 'green.800')}
                  color={useColorModeValue('white', 'primary.800')}
                  _hover={{
                    bg: 'green.600',
                  }}
                  aria-label="Subscribe"
                  icon={<BiMailSend />}
                  type="submit"
                />
              </Stack>
            </form>
            <ListHeader>{t('Languages')}</ListHeader>
            <LanguageSelector />
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
