import React from 'react';
import MaskedInput from 'react-text-mask';
import { Input, InputProps } from '@chakra-ui/react';
import { CPFMask } from '../../utility/formatters';

const CPFInput = (props: InputProps) => {
  return (
    <Input
      {...props}
      as={MaskedInput}
      mask={CPFMask}
      guide={false}
      placeholder="___.___.___-__"
    />
  );
};

export default CPFInput;
