/* eslint-disable @typescript-eslint/no-explicit-any */

let lastGuest = '';
export function setChatData() {
  if (window.RocketChat)
    window.RocketChat(function () {
      this.setGuestEmail('sample@rocket.chat');
    });
}

export function openChat() {
  if (window.RocketChat)
    window.RocketChat(function () {
      this.maximizeWidget();
    });
}

export function closeChat() {
  if (window.RocketChat)
    window.RocketChat(function () {
      this.minimizeWidget();
    });
}

export function hideChat() {
  if (window.RocketChat)
    window.RocketChat(function () {
      this.hideWidget();
    });
}

export function showChat() {
  if (window.RocketChat)
    window.RocketChat(function () {
      this.showWidget();
    });
}

export function getLastGuest() {
  return lastGuest;
}

export function registerAndIdentifyGuest(id, name, email) {
  if (email === lastGuest) {
    return;
  }
  lastGuest = email;
  if (window.RocketChat)
    window.RocketChat(function () {
      this.registerGuest({
        token: id,
        name: name,
        email: email,
      });
    });
}
