import { useTranslation } from 'react-i18next';
import { Card } from '@chakra-ui/react';
import { ConfirmationModal } from '../../../../common/components/ConfirmationModal';
import { TableGrid } from '../../../../common/components/TableGrid';
import { TableGridProps } from '../../../../common/components/TableGrid/TableGrid';
import {
  LocalAccountForm,
  LocalAccountFormProps,
} from './components/LocalAccountForm';

export type LocalAccountProps = {
  tableGridProps: TableGridProps;
  localAccountFormProps: LocalAccountFormProps;
  onDeleteConfirm: (id?: string | number) => void;
  onClose: () => void;
  isOpen: boolean;
  id: string;
};

const LocalAccount = ({
  tableGridProps,
  localAccountFormProps,
  isOpen,
  onClose,
  onDeleteConfirm,
  id,
}: LocalAccountProps) => {
  const { t } = useTranslation();
  return (
    <>
      <ConfirmationModal
        title={t('Are you sure?')}
        description={t('Are you sure you want to delete this account?')}
        cancelButtonLabel={t('Cancel')}
        confirmButtonLabel={t('Delete')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onDeleteConfirm}
        id={id}
      />
      <LocalAccountForm {...localAccountFormProps} />
      <Card padding="20px">
        <TableGrid {...tableGridProps} />
      </Card>
    </>
  );
};
export default LocalAccount;
