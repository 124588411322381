import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import '../../Invoice.language';
import { ConfirmationModal } from '../../../../common/components/ConfirmationModal';
import { TableGrid } from '../../../../common/components/TableGrid';
import useInvoicePayer from './useInvoicePayer';

const InvoicePayers = () => {
  const { t } = useTranslation('invoice');
  const {
    errors,
    handleSubmit,
    touched,
    getFieldProps,
    tableGridProps,
    values,
    setFieldValue,
    isOpen,
    onClose,
    handleDeleteConfirmation,
  } = useInvoicePayer();
  const flexProps: FlexProps = {
    gap: '20px',
    wrap: { base: 'wrap', md: 'unset' },
    margin: '10px',
  };
  return (
    <>
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        title={t('Are you sure?')}
        description={t('Delete this invoice configuration?')}
        cancelButtonLabel={t('Cancel')}
        confirmButtonLabel={t('Delete')}
        onConfirm={handleDeleteConfirmation}
      />
      <Collapse in={!!values.id} animateOpacity delay={2}>
        <Card w={{ base: '100%', md: '750px' }} padding="20px">
          <form onSubmit={handleSubmit}>
            <Flex {...flexProps}>
              <Box w={{ base: '100%', md: '200px' }}>
                <FormControl
                  id="name"
                  isRequired
                  isInvalid={(touched.name && !!errors.name) || false}
                >
                  <FormLabel>{t('Payer Name')}</FormLabel>
                  <Input type="text" {...getFieldProps('name')} />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>
            <Flex {...flexProps}>
              <Box w="100%">
                <FormControl
                  id="address1"
                  isInvalid={(touched.address1 && !!errors.address1) || false}
                >
                  <FormLabel>{t('Address')}</FormLabel>
                  <Input type="text" {...getFieldProps('address1')} />
                  <FormErrorMessage>{errors.address1}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            <Flex {...flexProps}>
              <Box w="100%">
                <FormControl
                  id="address2"
                  isInvalid={(touched.address2 && !!errors.address2) || false}
                >
                  <FormLabel>{t('Address 2')}</FormLabel>
                  <Input type="text" {...getFieldProps('address2')} />
                  <FormErrorMessage>{errors.address2}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            <Flex {...flexProps}>
              <Box w="150px">
                <FormControl
                  id="phone"
                  isInvalid={(touched.phone && !!errors.phone) || false}
                >
                  <FormLabel>{t('Phone')}</FormLabel>
                  <Input type="text" {...getFieldProps('phone')} />
                  <FormErrorMessage>{errors.phone}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            <Flex {...flexProps}>
              <Box w="350px">
                <FormControl
                  id="email"
                  isInvalid={(touched.email && !!errors.email) || false}
                >
                  <FormLabel>{t('E-mail')}</FormLabel>
                  <Input type="text" {...getFieldProps('email')} />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            <Divider margin="20px 0 20px 0" />

            <Flex justifyContent="flex-end" gap="20px">
              <Button onClick={() => setFieldValue('id', null)}>Cancel</Button>
              <Button type="submit" colorScheme="green">
                {t('Save')}
              </Button>
            </Flex>
          </form>
        </Card>
      </Collapse>
      <Collapse in={!values.id} animateOpacity delay={2}>
        <Card padding="20px">
          {' '}
          <TableGrid {...tableGridProps} />
        </Card>
      </Collapse>
    </>
  );
};

export default InvoicePayers;
