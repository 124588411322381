import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  Flex,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { IPayerDto } from '@waygee/shared-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import '../../Invoice.language';
import { ApiNames, getRequestConfig } from '../../../../common/apis';
import {
  TableGridDataRowProps,
  TableGridProps,
} from '../../../../common/components/TableGrid/TableGrid';
import { ChakraColors } from '../../../../common/configuration/ChakraColors';
import { axiosCustomInstance } from '../../../../common/network';

const PAYER_NEW_IDENTIFIER = 'new';

const useInvoicePayer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [invoicePayerToDelete, setInvoicePayerToDelete] = useState<string>('');
  const [invoicePayers, setInvoicePayers] = useState<IPayerDto[]>([]);
  const [tableGridProps, setTableGridProps] = useState<TableGridProps>({
    isLoading,
  });
  const { t } = useTranslation('invoice');

  const toast = useToast();

  const tableHeaders = useMemo(
    () => [
      {
        content: t('Name'),
      },
      {
        content: t('Phone'),
      },
      {
        content: t('Email'),
      },
      {
        content: t('Actions'),
      },
    ],
    [t]
  );

  const validationSchema = Yup.object({
    name: Yup.string().required(t('Name is required')),
    address1: Yup.string(),
    address2: Yup.string(),
    phone: Yup.string(),
    email: Yup.string().nullable().email(t('Invalid email address')),
    id: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      address1: '',
      address2: '',
      phone: '',
      email: null,
      id: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      const { id, ...data } = values;
      const promise = axiosCustomInstance
        .request<IPayerDto>(
          getRequestConfig({
            endpoint:
              values.id && values.id !== PAYER_NEW_IDENTIFIER
                ? ApiNames.UPDATE_PAYER
                : ApiNames.CREATE_PAYER,
            data,
            params: values.id,
          })
        )
        .then(() => {
          setFieldValue('id', null);
          loadInvoicePayers();
        })
        .finally(() => setIsLoading(false));

      toast.promise(promise, {
        success: {
          title: t('Success!'),
          description: t('Invoice payer saved'),
          isClosable: true,
        },
        error: {
          title: t('Failed'),
          description: t('Something wrong'),
          isClosable: true,
        },
        loading: {
          title: t('Saving invoice payer'),
          description: t('Please wait...'),
          isClosable: true,
        },
      });
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const handleDelete = useCallback(
    (id: string) => {
      setInvoicePayerToDelete(id);
      onOpen();
    },
    [onOpen]
  );

  const handleEdit = useCallback(
    (id: string) => {
      const invoicePayer = invoicePayers.find((config) => config.id === id);
      if (invoicePayer) {
        setFieldValue('phone', invoicePayer.phone);
        setFieldValue('email', invoicePayer.email);
        setFieldValue('id', invoicePayer.id);
        setFieldValue('name', invoicePayer.name);
        setFieldValue('address1', invoicePayer.address1);
        setFieldValue('address2', invoicePayer.address2);
      }
    },
    [invoicePayers, setFieldValue]
  );

  const addRow = useCallback(() => {
    return {
      cells: [
        {
          content: (
            <Center>
              <Button
                variant={'outline'}
                colorScheme="primary"
                onClick={() => setFieldValue('id', PAYER_NEW_IDENTIFIER)}
              >
                {t('Add new payer')}
              </Button>
            </Center>
          ),
          colSpan: 4,
        },
      ],
    } as TableGridDataRowProps;
  }, [setFieldValue, t]);

  const loadInvoicePayers = useCallback(() => {
    setIsLoading(true);
    axiosCustomInstance
      .request<IPayerDto[]>(
        getRequestConfig({
          endpoint: ApiNames.GET_PAYERS,
        })
      )
      .then((response) => {
        setInvoicePayers(response.data);
      })
      .catch(() => {
        setInvoicePayers([]);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleDeleteConfirmation = useCallback(() => {
    const promise = axiosCustomInstance
      .request(
        getRequestConfig({
          endpoint: ApiNames.DELETE_PAYER,
          params: invoicePayerToDelete,
        })
      )
      .then(() => setInvoicePayerToDelete(''))
      .finally(() => {
        loadInvoicePayers();
        onClose();
      });

    toast.promise(promise, {
      success: {
        title: t('Success!'),
        description: t('Invoice payer deleted'),
        isClosable: true,
      },
      error: {
        title: t('Failed'),
        description: t('Something wrong'),
        isClosable: true,
      },
      loading: {
        title: t('Deleting invoice configuration'),
        description: t('Please wait...'),
        isClosable: true,
      },
    });
  }, [invoicePayerToDelete, loadInvoicePayers, onClose, t, toast]);

  useEffect(() => {
    const rowsWithData = invoicePayers?.map((invoicePayer) => ({
      cells: [
        {
          content: invoicePayer.name,
        },
        {
          content: invoicePayer.phone,
        },
        {
          content: invoicePayer.email,
        },
        {
          content: (
            <Flex gap="10px">
              <DeleteIcon
                onClick={() => handleDelete(invoicePayer.id)}
                color={ChakraColors.info}
                _hover={{
                  cursor: 'pointer',
                  color: ChakraColors.hover,
                }}
              />{' '}
              <EditIcon
                onClick={() => handleEdit(invoicePayer.id)}
                color={ChakraColors.info}
                _hover={{
                  cursor: 'pointer',
                  color: ChakraColors.hover,
                }}
              />
            </Flex>
          ),
        },
      ],
    })) as TableGridDataRowProps[];
    const props = {
      isLoading,
      headers: tableHeaders,
      rows: [addRow(), ...rowsWithData],
    };
    setTableGridProps(props);
  }, [
    addRow,
    handleDelete,
    handleEdit,
    invoicePayers,
    isLoading,
    t,
    tableHeaders,
  ]);

  useEffect(() => {
    loadInvoicePayers();
  }, [loadInvoicePayers]);

  return {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    tableGridProps,
    setFieldValue,
    isOpen,
    onOpen,
    onClose,
    handleDeleteConfirmation,
  };
};

export default useInvoicePayer;
