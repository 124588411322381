import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/button';
import { Text, Flex, Heading, Divider } from '@chakra-ui/layout';
import '../../../../../../common/languages/BuyCrypto.language';
export type BuyCryptoCompletePageProps = {
  url?: string;
};

const BuyCryptoCompletePage = ({ url }: BuyCryptoCompletePageProps) => {
  const { t } = useTranslation('buy-crypto');

  const handleNewTransactionClick = () => {
    window.location.reload();
  };

  const handleOpenProviderClick = () => {
    window.open(url, '_blank');
  };

  return (
    <Flex flexDir="column" gap="20px">
      <Heading size="md">
        {t('The transaction is processed on the side of the provider')}
      </Heading>
      <Text>
        {t(
          'Currently, the transaction is processed on the side of the provider. You can always create a new transaction if it is needed.'
        )}
      </Text>
      <Divider />
      <Flex flexDir="row" gap="20px" w="100%" justifyContent="center">
        <Button colorScheme="primary" onClick={handleOpenProviderClick}>
          {t('Go to the provider')}
        </Button>
        <Button colorScheme="green" onClick={handleNewTransactionClick}>
          {t('New transaction')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default BuyCryptoCompletePage;
