import { ReactNode } from 'react';
import {
  Heading,
  VStack,
  Stack,
  Text,
  Divider,
  Container,
  Flex,
} from '@chakra-ui/react';

export type PageContainerProps = {
  children: ReactNode;
  title: string;
  subTitle?: string;
};

const PageContainer = ({ children, title, subTitle }: PageContainerProps) => {
  return (
    <>
      <VStack spacing={2} align="center" margin="20px">
        <Heading as="h2">{title}</Heading>
        <Text fontSize="md">{subTitle}</Text>
      </VStack>
      <Divider borderBottomColor="primary.100" />
      <Stack spacing={4} direction="column" align="center" marginBottom="20px">
        <Container maxW={'5xl'} mt={12}>
          <Flex flexWrap="wrap" gridGap={6} justify="center">
            {children}
          </Flex>
        </Container>
      </Stack>
    </>
  );
};

export default PageContainer;
