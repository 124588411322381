import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { IResetPasswordCompleteDto } from '@waygee/shared-types';

import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import * as Yup from 'yup';
import { ApiNames, getRequestConfig } from '../../../common/apis/ApiRoutes';
import { axiosCustomInstance } from '../../../common/network';
import '../PasswordReset.language';
import { getQueryParam } from '../../../common/utility/navigation';

type PasswordResetSchema = {
  email: string;
  password: string;
  hasToken: boolean;
  dateOfBirth: string;
};

const usePasswordReset = () => {
  const { t } = useTranslation('password-reset');
  const [mfaEmailSent, setMfaEmailSent] = useState<boolean>(false);
  const navigate = useNavigate();

  const resetPasswordToken = getQueryParam('token');

  const validationSchema = Yup.object<PasswordResetSchema>({
    email: Yup.string()
      .email(t('Invalid email address'))
      .trim()
      .max(100, t('Must be 100 characters or less'))
      .required(t('Email is required')),
    password: Yup.string().when('hasToken', {
      is: true,
      then: (schema) =>
        schema
          .required(t('Password is required'))
          .min(8, t('Must be 8 characters or more'))
          .max(16, t('Must be 16 characters or less')),
      otherwise: (schema) => schema.notRequired(),
    }),
    dateOfBirth: Yup.date().when('hasToken', {
      is: true,
      then: (schema) => schema.required(t('Date of birth is required')),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      email: Cookies.get('email') ?? '',
      password: '',
      hasToken: !!resetPasswordToken,
      dateOfBirth: '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (!isLoading) {
        setIsLoading(true);
        const promise = axiosCustomInstance
          .request(
            getRequestConfig({
              endpoint: ApiNames.RESET_PASSWORD,
              data: {
                email: values.email,
              },
            })
          )

          .then(() => setMfaEmailSent(true))
          .finally(() => setIsLoading(false));

        toast.promise(promise, {
          success: {
            title: t('E-mail sent with code'),
            description: t('Verify your inbox'),
            isClosable: true,
          },
          error: {
            title: t('Login failed'),
            description: t('Something wrong'),
            isClosable: true,
          },
          loading: {
            title: t('Authenticating user'),
            description: t('Please wait...'),
            isClosable: true,
          },
        });
      }
    },
  });

  const handlePasswordResetSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);
    const promise = axiosCustomInstance
      .request<IResetPasswordCompleteDto>(
        getRequestConfig({
          endpoint: ApiNames.RESET_PASSWORD_WITH_MFA,
          token: {
            accessToken: resetPasswordToken ?? '',
            refreshToken: '',
          },
          data: {
            password: values.password,
            dateOfBirth: values.dateOfBirth,
          },
        })
      )
      .then(() => navigate('/secure/login'))
      .finally(() => setIsLoading(false));

    toast.promise(promise, {
      success: {
        title: t('New password created successful'),
        description: t('Please login again'),
        isClosable: true,
      },
      error: {
        title: t('Password reset failed'),
        description: t('Something wrong'),
        isClosable: true,
      },
      loading: {
        title: t('Resetting password user'),
        description: t('Please wait...'),
        isClosable: true,
      },
    });
  };

  const {
    values,
    getFieldProps,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    handleChange,
  } = formik;

  useEffect(() => {
    setFieldValue('hasToken', !!resetPasswordToken);
  }, [resetPasswordToken, setFieldValue]);

  return {
    values,
    getFieldProps,
    touched,
    errors,
    handleSubmit,
    t,
    isLoading,
    mfaEmailSent,
    handlePasswordResetSubmit,
    setFieldValue,
    handleChange,
    resetPasswordToken,
  };
};

export default usePasswordReset;
