'use client';

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Avatar,
  Center,
  Badge,
  Tooltip,
  Tag,
} from '@chakra-ui/react';
import { AuthContext } from '../../../contexts/AuthContext/AuthContext';
import { getRequestConfig } from '../../apis';
import { ApiNames } from '../../apis/ApiRoutes';
import { axiosCustomInstance } from '../../network';
import { removeAuthToken } from '../../utility/cookies/authTokenStorage';
import { Logo } from '../Logo';
import useNavBarItems, { NavItem } from './NavBarItems';

export default function NavBar() {
  const { isOpen, onToggle } = useDisclosure();
  const { isLoggedIn, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    axiosCustomInstance
      .request(
        getRequestConfig({
          endpoint: ApiNames.LOGOUT,
        })
      )
      .catch((err) => console.error(err));
    removeAuthToken();
  };
  const handleAccountSettings = () => navigate('/secure/account');
  const { t } = useTranslation();

  return (
    <Box>
      <Flex
        bg={useColorModeValue('primary.700', 'primary.900')}
        color={useColorModeValue('primary.600', 'primary.700')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('primary.200', 'primary.900')}
        align={'center'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'heading'}
            color={useColorModeValue('primary.800', 'primary.700')}
          >
            <Logo />
          </Text>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav isLoggedIn={isLoggedIn} />
          </Flex>
        </Flex>

        {!isLoggedIn && (
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={6}
          >
            <Button
              as={'a'}
              color="primary.50"
              fontSize={'sm'}
              fontWeight={400}
              variant={'link'}
              href={'/secure/login'}
            >
              {t('Sign in')}
            </Button>
            <Button
              as={'a'}
              display={{ base: 'none', md: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              color={'primary.700'}
              bg={'green.400'}
              href={'/secure/signup'}
              _hover={{
                bg: 'green.200',
              }}
            >
              {t('Sign up')}
            </Button>
          </Stack>
        )}
        {/* {isLoggedIn && <NavBarBalance user={user} />} */}
        {isLoggedIn && (
          <Menu>
            <MenuButton
              as={Button}
              rounded={'full'}
              variant={'link'}
              cursor={'pointer'}
              minW={0}
            >
              <Avatar size={'sm'} src="assets/images/user.png" />
            </MenuButton>
            <MenuList alignItems={'center'}>
              <br />
              <Center>
                <Avatar
                  size={'2xl'}
                  src={user?.user.imageUrl ?? 'assets/images/user.png'}
                />
              </Center>
              <br />
              <Center>{user?.ownerIndividualContact?.legalName}</Center>
              <br />
              <MenuDivider />
              <MenuItem onClick={handleAccountSettings}>
                {t('Account Settings')}
              </MenuItem>
              <MenuItem onClick={handleLogout}>{'Logout'}</MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav isLoggedIn={isLoggedIn} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const linkColor = useColorModeValue('primary.100', 'primary.200');
  const linkHoverColor = useColorModeValue('primary.50', 'primary.700');
  const popoverContentBgColor = useColorModeValue('primary.100', 'primary.800');
  const navigate = useNavigate();

  const { navBarItems } = useNavBarItems();

  return (
    <Stack direction={'row'} spacing={4}>
      {isLoggedIn &&
        navBarItems.map((navItem: NavItem) => (
          <Box key={navItem.label}>
            <Popover
              trigger={navItem.soon ? undefined : 'hover'}
              placement={'bottom-start'}
            >
              <PopoverTrigger>
                <Box
                  as="a"
                  p={2}
                  onClick={() =>
                    navItem.soon ? null : navigate(navItem.href ?? '')
                  }
                  cursor={navItem.soon ? 'not-allowed' : 'pointer'}
                  fontSize={'sm'}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}
                >
                  <Tooltip
                    label={navItem.soon ? 'Em breve' : undefined}
                    hasArrow
                    bg="primary.300"
                    color="black"
                  >
                    <span>
                      {navItem.label}
                      {!!navItem.count && (
                        <Tag
                          size="sm"
                          key="sm"
                          borderRadius="full"
                          variant="solid"
                          colorScheme="red"
                        >
                          {navItem?.count}
                        </Tag>
                      )}
                    </span>
                  </Tooltip>
                </Box>
              </PopoverTrigger>

              {!navItem.soon && navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}
                >
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  const navigate = useNavigate();
  return (
    <Box
      as="a"
      onClick={() => navigate(href ?? '')}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{
        bg: useColorModeValue('pink.50', 'primary.900'),
        cursor: 'pointer',
      }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'primary.400' }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'primary.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { navBarItems } = useNavBarItems();
  return (
    <Stack
      bg={useColorModeValue('primary.700', 'primary.800')}
      p={4}
      display={{ md: 'none' }}
    >
      {isLoggedIn &&
        navBarItems.map((navItem: NavItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href, soon }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        py={2}
        as="a"
        href={soon ? undefined : href}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue('primary.100', 'primary.200')}
        >
          {label}
          {soon && (
            <Badge ml="1" fontSize="0.8em" colorScheme="green">
              Em breve
            </Badge>
          )}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Box>

      {!soon && (
        <Collapse
          in={isOpen}
          animateOpacity
          style={{ marginTop: '0!important' }}
        >
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={'primary.200'}
            align={'start'}
          >
            {children &&
              children.map((child) => (
                <Box
                  as="a"
                  key={child.label}
                  py={2}
                  href={child.soon ? undefined : child.href}
                >
                  {child.label}
                </Box>
              ))}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
};
