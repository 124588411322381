import { useTranslation } from 'react-i18next';
import ReactSelect, { SingleValue } from 'react-select';
import { InfoIcon, WarningIcon } from '@chakra-ui/icons';
import {
  CardHeader,
  Heading,
  CardBody,
  Stack,
  Text,
  Box,
  Card,
  StackDivider,
  Spacer,
  Grid,
  GridItem,
  Center,
  Skeleton,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import { IGlobalAccountDto } from '@waygee/shared-types';
import { CopyToClipboard } from '../../../../common/components/CopyToClipboard';
import { ChakraColors } from '../../../../common/configuration/ChakraColors';
import { OptionItem } from '../../../../common/types';

export type GlobalAccountProps = {
  isLoading: boolean;
  account?: IGlobalAccountDto;
  selectedCurrency: OptionItem | null;
  currencies: OptionItem[];
  handleChangeCurrency: (option: SingleValue<OptionItem>) => void;
};

const GlobalAccount = ({
  account,
  isLoading,
  currencies,
  selectedCurrency,
  handleChangeCurrency,
}: GlobalAccountProps) => {
  const { t } = useTranslation();
  const defaultOption = currencies.find(
    (c) => c.value === selectedCurrency?.value
  );
  return currencies?.length > 0 ? (
    <Card minW={{ base: '100%', md: '690px' }}>
      <>
        <CardHeader>
          <Heading size="md">{t('Global Account')}</Heading>
          {!isLoading && !account && (
            <Center marginTop="20px">
              <Text fontSize="sm" color={ChakraColors.warning}>
                <WarningIcon marginRight="10px" />
                {t(
                  'We are currently not supporting this currency. Please select another currency.'
                )}
              </Text>
            </Center>
          )}
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Grid
                templateColumns={{ base: undefined, md: 'repeat(2, 1fr)' }}
                gap={6}
              >
                <GridItem w="100%" h="10">
                  <Heading size="xs" textTransform="uppercase">
                    {t('Currency')}
                  </Heading>
                  <Flex>
                    <ReactSelect
                      placeholder={t('Currency')}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: state.isFocused
                            ? ChakraColors.waygee
                            : ChakraColors.waygee_light,
                          boxShadow: ChakraColors.waygee,
                        }),
                      }}
                      defaultValue={defaultOption}
                      onChange={handleChangeCurrency}
                      options={currencies}
                    />
                  </Flex>
                </GridItem>
              </Grid>
              <Spacer margin="10px" />
            </Box>
            <Box>
              <Grid
                templateColumns={{ base: undefined, md: 'repeat(2, 1fr)' }}
                gap={6}
              >
                <GridItem w="100%" h="10">
                  <Heading size="xs" textTransform="uppercase">
                    {t('Intermediary Bank')}
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {isLoading && <Skeleton height="20px" />}
                    {!isLoading && account?.intermediaryBank?.bank}
                    {account?.intermediaryBank?.bank && (
                      <CopyToClipboard text={account?.intermediaryBank?.bank} />
                    )}
                  </Text>
                </GridItem>
                <GridItem w="100%" h="10">
                  <Heading size="xs" textTransform="uppercase">
                    {t('Swift Code')}
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {isLoading && <Skeleton height="20px" />}
                    {!isLoading && account?.intermediaryBank?.swift}
                    {account?.intermediaryBank?.swift && (
                      <CopyToClipboard
                        text={account?.intermediaryBank?.swift}
                      />
                    )}
                  </Text>
                </GridItem>
              </Grid>

              <Spacer margin="20px" />
              <Grid
                templateColumns={{ base: undefined, md: 'repeat(2, 1fr)' }}
                gap={6}
              >
                <GridItem w="100%" h="10">
                  <Heading size="xs" textTransform="uppercase">
                    {'Account'}
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {!isLoading && isLoading && <Skeleton height="20px" />}
                    {account?.intermediaryBank.account}
                    {account?.intermediaryBank.account && (
                      <CopyToClipboard
                        text={account?.intermediaryBank.account}
                      />
                    )}
                  </Text>
                </GridItem>
                <GridItem w="100%" h="10">
                  <Heading size="xs" textTransform="uppercase">
                    {t('Address')}
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {isLoading && <Skeleton height="20px" />}
                    {!isLoading && account?.intermediaryBank.address}
                    {account?.intermediaryBank.address && (
                      <CopyToClipboard
                        text={account?.intermediaryBank.address}
                      />
                    )}
                  </Text>
                </GridItem>
              </Grid>
            </Box>

            <Box>
              <Grid
                templateColumns={{ base: undefined, md: 'repeat(2, 1fr)' }}
                gap={6}
              >
                <GridItem w="100%" h="10">
                  <Heading size="xs" textTransform="uppercase">
                    {t('Beneficiary Bank')}
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {isLoading && <Skeleton height="20px" />}
                    {!isLoading && account?.beneficiaryBank.bank}
                    {account?.beneficiaryBank.bank && (
                      <CopyToClipboard text={account?.beneficiaryBank.bank} />
                    )}
                  </Text>
                </GridItem>
                <GridItem w="100%" h="10">
                  <Heading size="xs" textTransform="uppercase">
                    {t('Swift Code')}
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {isLoading && <Skeleton height="20px" />}
                    {!isLoading && account?.beneficiaryBank?.swift}
                    {account?.beneficiaryBank?.swift && (
                      <CopyToClipboard text={account?.beneficiaryBank?.swift} />
                    )}
                  </Text>
                </GridItem>
              </Grid>

              <Spacer margin="20px" />
              <Grid
                templateColumns={{ base: undefined, md: 'repeat(2, 1fr)' }}
                gap={6}
              >
                <GridItem w="100%" h="10">
                  <Heading size="xs" textTransform="uppercase">
                    {t('Address')}
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {isLoading && <Skeleton height="20px" />}
                    {!isLoading && account?.beneficiaryBank.address}
                    {account?.beneficiaryBank.address && (
                      <CopyToClipboard
                        text={account?.beneficiaryBank.address}
                      />
                    )}
                  </Text>
                </GridItem>
              </Grid>
            </Box>

            <Box>
              <Grid templateColumns="repeat(1, 1fr)" gap={6}>
                <GridItem w="100%" h="10">
                  <Heading size="xs" textTransform="uppercase">
                    {t('Beneficiary Account')}
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {isLoading && <Skeleton height="20px" />}
                    {!isLoading && account?.beneficiary.account}
                    {account?.beneficiary.account && (
                      <CopyToClipboard text={account?.beneficiary.account} />
                    )}
                    <Tooltip
                      label={t(
                        'Depending on the system you are filling, this might be separated in two different fields: One for IBAN/Account Number and other for Additional data'
                      )}
                      fontSize="md"
                    >
                      <InfoIcon marginLeft="5px" />
                    </Tooltip>
                  </Text>
                </GridItem>
                <GridItem w="100%" h="10">
                  <Heading size="xs" textTransform="uppercase">
                    {t('Reference')}
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {isLoading && <Skeleton height="20px" />}
                    {!isLoading && account?.beneficiary.reference}
                    {account?.beneficiary.reference && (
                      <CopyToClipboard text={account?.beneficiary.reference} />
                    )}
                  </Text>
                </GridItem>
              </Grid>
            </Box>
          </Stack>
        </CardBody>
      </>
    </Card>
  ) : null;
};

export default GlobalAccount;
