import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useMediaQuery,
  useSteps,
} from '@chakra-ui/react';

export type SignUpStepperSteps = {
  title: string;
  description: string;
};

export type SignUpStepperProps = {
  steps: SignUpStepperSteps[];
  activeStepIndex: number;
};

const SignUpStepper = ({ activeStepIndex, steps }: SignUpStepperProps) => {
  useSteps({
    index: activeStepIndex,
    count: steps.length,
  });
  const [isLargerThan1300] = useMediaQuery('(min-width: 1300px)');

  const boxProps = {
    rounded: 'lg',
    bg: 'white',
    boxShadow: 'lg',
    p: 8,
  };

  return isLargerThan1300 ? (
    <Box {...boxProps}>
      <Stepper index={activeStepIndex} colorScheme="primary">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    </Box>
  ) : null;
};

export default SignUpStepper;
