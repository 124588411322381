import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoCompleteItem } from '../AutoComplete';

type UseAutoCompleteProps = {
  items: AutoCompleteItem[];
  onSelectItem?: (item: AutoCompleteItem) => void;
  onReset?: () => void;
  initialValue?: string;
};

const useAutoComplete = ({
  items,
  onSelectItem,
  onReset,
  initialValue,
}: UseAutoCompleteProps) => {
  const containerRef = useRef(null);
  const listRef = useRef<HTMLUListElement>(null);
  const { t } = useTranslation();

  const [isSearching, setIsSearching] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);
  const [filteredData, setFilteredData] = useState(items);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setInputFocused(true);
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      const firstListItem = listRef.current?.firstElementChild as HTMLElement;
      firstListItem && firstListItem.focus();
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      const lastListItem = listRef.current?.lastElementChild as HTMLElement;
      lastListItem && lastListItem.focus();
    }
    if (event.key === 'Tab') {
      event.preventDefault();
      setInputFocused(false);
    }

    const query = event.currentTarget.value
      .normalize('NFD') // decompose accented characters
      .replace(/[\u0300-\u036f]/g, '') // remove diacritics
      .toLowerCase()
      .replace(/[^a-z0-9]/gi, ''); // remove special characters
    const filteredItems = items.filter((item) =>
      item.name
        .normalize('NFD') // decompose accented characters
        .replace(/[\u0300-\u036f]/g, '') // remove diacritics
        .toLowerCase()
        .replace(/[^a-z0-9]/gi, '') // remove special characters
        .includes(query)
    );
    setFilteredData(filteredItems);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      // Move focus to the next item
      const nextElement = event.currentTarget.nextElementSibling as HTMLElement;
      nextElement?.focus();
    } else if (event.key === 'ArrowUp') {
      // Move focus to the previous item
      const previousElement = event.currentTarget
        .previousElementSibling as HTMLElement;
      previousElement?.focus();
    } else if (event.key === 'Enter') {
      const item = event.currentTarget as HTMLLIElement;
      handleSelectItem({
        name: item.innerText,
        id: item.getAttribute('data-value') || '',
      });
    }
  };

  const handleReset = () => {
    setFilteredData(items);
    setSelectedItem(null);
    setInputFocused(false);
    if (onReset) onReset();
  };

  const handleSelectItem = (item: AutoCompleteItem) => {
    setSelectedItem(item.name);
    setIsSearching(false);
    setInputFocused(false);
    if (onSelectItem) onSelectItem(item);
  };

  useEffect(() => {
    if (initialValue) setSelectedItem(initialValue);
  }, [initialValue]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !(containerRef.current as HTMLElement).contains(event.target as Node)
      ) {
        (containerRef.current as HTMLElement).blur();
        setInputFocused(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  return {
    isSearching,
    setIsSearching,
    isInputFocused,
    setInputFocused,
    handleKeyDown,
    handleSearch,
    filteredData,
    handleSelectItem,
    selectedItem,
    handleReset,
    containerRef,
    listRef,
    t,
  };
};

export default useAutoComplete;
