import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  InputGroup,
  Text,
  VStack,
  Select,
  Image,
  CircularProgress,
  FormControl,
  FormLabel,
  Input,
  Skeleton,
  Progress,
} from '@chakra-ui/react';
import {
  IAuthenticatedUserDataDto,
  IBankAccountDto,
  IOrderDto,
} from '@waygee/shared-types';
import { BiTransfer } from 'react-icons/bi';
import { FaEdit } from 'react-icons/fa';
import {
  MfaModalForm,
  MfaModalFormProps,
} from '../../common/components/MfaModalForm';
import { ChakraColors } from '../../common/configuration/ChakraColors';
import './Transference.language';
import { OptionItem } from '../../common/types';
import { ExchangeTaxResult } from '../../common/types/exchange';
import './Transference.language';

export type TransferencesProps = {
  order?: IOrderDto;
  user: IAuthenticatedUserDataDto | undefined;
  selectedBankAccount: IBankAccountDto | null;
  bankAccounts: IBankAccountDto[];
  getSourceCurrencyImg: () => string;
  isEditingAccount: boolean;
  setIsEditingAccount: (value: boolean) => void;
  handleChangeSelectedAccount: (event: ChangeEvent<HTMLSelectElement>) => void;
  isReadyToTransfer: boolean;
  isLoading: boolean;
  finalities: OptionItem[];
  selectedCurrency: OptionItem | null;
  amountToSend: string;
  handleChangeFinality: (event: ChangeEvent<HTMLSelectElement>) => void;
  exchangeTaxResult: ExchangeTaxResult | undefined;
  selectedFinality: OptionItem | null;
  isTaxLoading: boolean;
  updateCountDown: number;
  isReadOnly: boolean;
  isSubmitting: boolean;
  handleGoToBankAccounts: () => void;
  mfaModalFormProps: MfaModalFormProps;
};

const Transferences = ({
  order,
  user,
  selectedBankAccount,
  bankAccounts,
  getSourceCurrencyImg,
  isEditingAccount,
  setIsEditingAccount,
  handleChangeSelectedAccount,
  isReadyToTransfer,
  isLoading,
  finalities,
  selectedCurrency,
  amountToSend,
  handleChangeFinality,
  exchangeTaxResult,
  selectedFinality,
  isTaxLoading,
  updateCountDown,
  isReadOnly,
  isSubmitting,
  handleGoToBankAccounts,
  mfaModalFormProps,
}: TransferencesProps) => {
  const { t } = useTranslation('transferences');

  return (
    <>
      <MfaModalForm {...mfaModalFormProps} />
      {isLoading && (
        <Card w={{ base: '400px', md: '850px' }} padding="20px">
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap="20px"
          >
            <Heading as="h2" size="md" mb="10px" color={ChakraColors.warning}>
              {t('Verifying your accounts...')}
            </Heading>
            <CircularProgress isIndeterminate />
          </Flex>
        </Card>
      )}
      {!isReadyToTransfer && !isLoading && (
        <Card w={{ base: '400px', md: '850px' }} padding="20px">
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap="20px"
          >
            <Heading as="h2" size="md" mb="10px" color={ChakraColors.warning}>
              {t('You are not ready to transfer money')}
            </Heading>
            <Text>{t('You need to verify your accounts to continue')}</Text>
            <Button
              size="sm"
              onClick={handleGoToBankAccounts}
              variant="outline"
              colorScheme="primary"
            >
              {t('Go to Local Bank Accounts')}
            </Button>
          </Flex>
        </Card>
      )}
      {isReadyToTransfer && !isLoading && (
        <Card w={{ base: '400px', md: '850px' }} padding="10px 20px">
          <Flex flexDir="column" gap="20px">
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              padding="20px"
              gap="20px"
            >
              <VStack>
                <Heading as="h2" size="md" mb="10px">
                  {t('Source account')}
                </Heading>
                <Grid
                  templateColumns="repeat(2, 1fr)"
                  gap="10px"
                  color={ChakraColors.text}
                >
                  <GridItem>
                    <Text as="b">{t('Currency')}</Text>
                  </GridItem>
                  <GridItem>
                    <Flex flexDir="row" gap="5px">
                      <Image src={getSourceCurrencyImg()} h="20px" />{' '}
                      {selectedCurrency?.value?.toUpperCase()}
                    </Flex>
                  </GridItem>
                </Grid>

                <Grid
                  templateColumns="repeat(2, 1fr)"
                  gap="10px"
                  color={ChakraColors.text}
                ></Grid>

                <Grid
                  templateColumns="repeat(2, 1fr)"
                  gap="10px"
                  color={ChakraColors.text}
                >
                  <GridItem>
                    <Text as="b">{t('Account Owner')}</Text>
                  </GridItem>
                  <GridItem>{user?.ownerIndividualContact?.legalName}</GridItem>
                </Grid>

                <Grid
                  templateColumns="repeat(2, 1fr)"
                  gap="10px"
                  color={ChakraColors.text}
                >
                  <GridItem>
                    <Text as="b">{t('Source')}</Text>
                  </GridItem>
                  <GridItem>Waygee</GridItem>
                </Grid>
              </VStack>
              <VStack paddingTop="20px" paddingBottom="20px">
                <Text>
                  <BiTransfer size="30" />
                </Text>
              </VStack>
              <VStack>
                <Heading as="h2" size="md" mb="10px">
                  {t('Destination account')}
                </Heading>
                <Grid
                  templateColumns="repeat(2, 1fr)"
                  gap="10px"
                  color={ChakraColors.text}
                >
                  <GridItem>
                    <Text as="b">{t('Currency')}</Text>
                  </GridItem>
                  <GridItem>
                    <Flex flexDir="row" gap="5px">
                      <Image src="assets/images/br.svg" h="20px" /> BRL
                    </Flex>
                  </GridItem>
                </Grid>

                <Grid
                  templateColumns="repeat(2, 1fr)"
                  gap="10px"
                  color={ChakraColors.text}
                >
                  <GridItem>
                    <Text as="b">{t('Account Owner')}</Text>
                  </GridItem>
                  <GridItem>{user?.ownerIndividualContact?.legalName}</GridItem>
                </Grid>

                <Grid
                  templateColumns="repeat(2, 1fr)"
                  gap="10px"
                  color={ChakraColors.text}
                >
                  <GridItem>
                    <Text as="b">{t('Bank Name')}</Text>
                  </GridItem>
                  <GridItem>{selectedBankAccount?.bankName}</GridItem>
                </Grid>

                <Grid
                  templateColumns="repeat(2, 1fr)"
                  gap="10px"
                  color={ChakraColors.text}
                >
                  <GridItem>
                    <Text as="b">{t('Branch Number')}</Text>
                  </GridItem>
                  <GridItem>{selectedBankAccount?.branchCode}</GridItem>
                </Grid>

                <Grid
                  templateColumns="repeat(2, 1fr)"
                  gap="10px"
                  color={ChakraColors.text}
                >
                  <GridItem>
                    <Text as="b">{t('Account Number')}</Text>
                  </GridItem>
                  <GridItem>{selectedBankAccount?.accountNumber}</GridItem>
                </Grid>
                {/* Temporarily disabled edit destination account */}
                {!isEditingAccount && false && (
                  <Button
                    size="sm"
                    onClick={() => setIsEditingAccount(true)}
                    variant="outline"
                    leftIcon={<FaEdit />}
                  >
                    {t('Edit Destination')}
                  </Button>
                )}
                {isEditingAccount && false && (
                  <Flex flexDir="row" gap="20px">
                    <Select
                      placeholder="Select option"
                      onChange={handleChangeSelectedAccount}
                    >
                      {bankAccounts?.map((bankAccount, index) => (
                        <option value={bankAccount.id} key={index}>
                          {bankAccount.label} - {bankAccount.bankName}{' '}
                          {bankAccount.accountNumber} / {bankAccount.branchCode}
                        </option>
                      ))}
                    </Select>
                    <Button onClick={() => setIsEditingAccount(false)}>
                      {t('Cancel')}
                    </Button>
                  </Flex>
                )}
              </VStack>
            </Flex>
            <Divider />
            <Flex
              justifyContent="space-between"
              padding="0 20px"
              gap="20px"
              wrap="wrap"
            >
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                minW={{ base: 'unset', md: '320px' }}
                w={{ base: '100%', md: 'unset' }}
              >
                <Heading as="h2" size="md" mb="10px">
                  {t('Amount you send')}
                </Heading>
                <Center>
                  <InputGroup>
                    <Input
                      isReadOnly={true}
                      cursor="auto"
                      type="text"
                      id="amount"
                      name="amount"
                      w="200px"
                      value={amountToSend}
                      backgroundColor="primary.50"
                      fontWeight="bold"
                    />
                  </InputGroup>
                </Center>
              </Flex>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                minW={{ base: 'unset', md: '320px' }}
                w={{ base: '100%', md: 'unset' }}
              >
                <Heading as="h2" size="md" mb="10px">
                  {t('Amount you receive')}
                </Heading>
                <Center>
                  {!exchangeTaxResult || isTaxLoading ? (
                    <Skeleton height="20px" width="150px" />
                  ) : (
                    <InputGroup>
                      <Input
                        isReadOnly={true}
                        cursor="auto"
                        type="text"
                        id="amount"
                        name="amount"
                        w="200px"
                        value={exchangeTaxResult?.amountToReceive}
                        backgroundColor="green.50"
                        fontWeight="bold"
                      />
                    </InputGroup>
                  )}
                </Center>
              </Flex>
            </Flex>
            <Divider />
            <VStack>
              <Flex maxW="320px">
                <FormControl id="finality">
                  <FormLabel>{t('Finality')}</FormLabel>
                  <Select
                    placeholder={t('Select option')}
                    onChange={handleChangeFinality}
                    value={selectedFinality?.value}
                    isDisabled={isReadOnly}
                  >
                    {finalities?.map((finality, index) => (
                      <option value={finality.value} key={index}>
                        {finality.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
              <Grid templateColumns="repeat(2, 1fr)" gap="10px" minW="320px">
                <GridItem>
                  <Text as="b" fontSize="small">
                    {t('Exchange Rate')}
                  </Text>
                </GridItem>
                <GridItem textAlign="end">
                  {!exchangeTaxResult || isTaxLoading ? (
                    <Skeleton height="20px" />
                  ) : (
                    exchangeTaxResult?.currencyExchangeValue
                  )}
                </GridItem>
                <GridItem>
                  <Text as="b" fontSize="small">
                    {t('IOF')}
                  </Text>
                </GridItem>
                <GridItem textAlign="end">
                  {!exchangeTaxResult || isTaxLoading ? (
                    <Skeleton height="20px" />
                  ) : (
                    exchangeTaxResult?.iof
                  )}
                </GridItem>
                <GridItem>
                  <Text as="b" fontSize="small">
                    {t('IR')}
                  </Text>
                </GridItem>
                <GridItem textAlign="end">
                  {!exchangeTaxResult || isTaxLoading ? (
                    <Skeleton height="20px" />
                  ) : (
                    exchangeTaxResult?.ir
                  )}
                </GridItem>
                {exchangeTaxResult && (
                  <>
                    <GridItem>
                      <Text as="b" fontSize="small">
                        {t('Fee')}
                      </Text>
                    </GridItem>
                    <GridItem textAlign="end">
                      {exchangeTaxResult?.fee}
                    </GridItem>
                  </>
                )}
                <GridItem>
                  <Text as="b" fontSize="small">
                    {t('VET')}
                  </Text>
                </GridItem>
                <GridItem textAlign="end">
                  {!exchangeTaxResult || isTaxLoading ? (
                    <Skeleton height="20px" />
                  ) : (
                    exchangeTaxResult?.vet
                  )}
                </GridItem>
                <GridItem>
                  <Text as="b" fontSize="small">
                    {t('Status')}
                  </Text>
                </GridItem>
                <GridItem textAlign="end">
                  <Text>{t(order?.status ?? '')}</Text>
                </GridItem>
              </Grid>
            </VStack>
            {!isReadOnly && !isSubmitting && (
              <Progress
                hasStripe
                value={updateCountDown}
                colorScheme="primary"
              />
            )}
          </Flex>
        </Card>
      )}
    </>
  );
};

export default Transferences;
