import { AxiosRequestConfig, ResponseType } from 'axios';
import appConfig from '../configuration/AppConfig';
import { AuthToken } from '../types/auth';
import { getAuthTokenCookie } from '../utility/cookies/authTokenStorage';

export type ApiConfiguration = {
  baseUrl: string;
  Endpoints: ApiEndpoint[];
};

export type ApiEndpoint = {
  api: ApiNames;
  endpoint: string;
  method: ApiMethod;
  protected: boolean;
};

type ApiMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export type ApiRequestConfig = {
  endpoint: ApiNames;
  data?: unknown;
  params?: unknown;
  token?: Partial<AuthToken>;
  headers?: Record<string, string>;
  responseType?: ResponseType;
  query?: Record<string, string>;
};

export enum ApiNames {
  'LOGIN',
  'LOGIN_WITH_MFA',
  'RESET_PASSWORD',
  'RESET_PASSWORD_WITH_MFA',
  'CHANGE_PASSWORD',
  'LOGOUT',
  'REFRESH_TOKEN',
  'SIGN_UP',
  'LEAD',
  'SIGN_UP_TOKEN',
  'ME',
  'GET_ALL_BANK_ACCOUNTS',
  'GET_BANK_ACCOUNT_BY_ID',
  'CREATE_BANK_ACCOUNT',
  'UPDATE_BANK_ACCOUNT',
  'DELETE_BANK_ACCOUNT',
  'MFA_SEND_CODE',
  'MFA_VERIFY_CODE',
  'CONTACT',
  'CREATE_PAYMENT_INTENT',
  'GET_PAYMENT_INTENT_BY_USER_ID',
  'CREATE_INVOICE_CONFIG',
  'GET_INVOICE_CONFIG',
  'UPDATE_INVOICE_CONFIG',
  'DELETE_INVOICE_CONFIG',
  'GET_PAYERS',
  'CREATE_PAYER',
  'UPDATE_PAYER',
  'DELETE_PAYER',
  'GET_INVOICE_NEXT_NUMBER',
  'CREATE_INVOICE',
  'GET_INVOICES',
  'DOWNLOAD_INVOICE',
  'DELETE_INVOICE',
  'CREATE_AND_DOWNLOAD_INVOICE_PUBLIC',
  'OZ_GET_CURRENCY_PAIRS',
  'OZ_CALCULATE_TAX',
  'OZ_CALCULATE_TAX_BY_ORDER',
  'UPDATE_CONTACT_FATCA',
  'GET_DOCUMENTS',
  'UPLOAD_DOCUMENT_BY_DOCUMENT_ID',
  'SUBMIT_ONBOARDING',
  'CHECK_ONBOARDING_STEPS',
  'GET_ALL_ORDERS',
  'GET_GLOBAL_ACCOUNT',
  'SUBMIT_ORDER',
  'GET_ALL_CRYPTO_CURRENCIES',
  'GET_CRYPTO_OFFERS',
  'GET_CRYPTO_PROVIDERS',
  'GET_CRYPTO_WALLET_ADDRESS_VALIDATION',
  'CREATE_CRYPTO_ORDER',
}

export const ApiRoutes: ApiConfiguration = {
  baseUrl: appConfig.apiBaseUrl,
  Endpoints: [
    {
      method: 'POST',
      api: ApiNames.LOGIN,
      protected: false,
      endpoint: 'auth/sign-in',
    },
    {
      method: 'POST',
      api: ApiNames.REFRESH_TOKEN,
      protected: true,
      endpoint: 'auth/refresh',
    },
    {
      method: 'POST',
      api: ApiNames.LOGOUT,
      protected: true,
      endpoint: 'auth/logout',
    },
    {
      method: 'POST',
      api: ApiNames.LOGIN_WITH_MFA,
      protected: true,
      endpoint: 'auth/sign-in',
    },
    {
      method: 'GET',
      api: ApiNames.ME,
      protected: true,
      endpoint: 'users/me',
    },
    {
      method: 'POST',
      api: ApiNames.SIGN_UP,
      protected: true,
      endpoint: 'auth/sign-up',
    },
    {
      method: 'GET',
      api: ApiNames.GET_ALL_BANK_ACCOUNTS,
      protected: true,
      endpoint: 'bank-accounts',
    },
    {
      method: 'GET',
      api: ApiNames.GET_BANK_ACCOUNT_BY_ID,
      protected: true,
      endpoint: 'bank-accounts/:id',
    },
    {
      method: 'POST',
      api: ApiNames.CREATE_BANK_ACCOUNT,
      protected: true,
      endpoint: 'bank-accounts',
    },
    {
      method: 'PUT',
      api: ApiNames.UPDATE_BANK_ACCOUNT,
      protected: true,
      endpoint: 'bank-accounts/:id',
    },
    {
      method: 'DELETE',
      api: ApiNames.DELETE_BANK_ACCOUNT,
      protected: true,
      endpoint: 'bank-accounts/:id',
    },
    {
      method: 'POST',
      api: ApiNames.LEAD,
      protected: false,
      endpoint: 'auth/lead',
    },
    {
      method: 'POST',
      api: ApiNames.SIGN_UP_TOKEN,
      protected: true,
      endpoint: 'auth/sign-up/token',
    },
    {
      method: 'POST',
      api: ApiNames.MFA_SEND_CODE,
      protected: true,
      endpoint: 'auth/mfa/send-code/email',
    },
    {
      method: 'POST',
      api: ApiNames.MFA_VERIFY_CODE,
      protected: true,
      // TODO: use method from params
      endpoint: 'auth/mfa/complete/email/:id',
    },
    {
      method: 'POST',
      api: ApiNames.CONTACT,
      protected: false,
      endpoint: 'public/contact-us',
    },
    {
      method: 'POST',
      api: ApiNames.CREATE_PAYMENT_INTENT,
      protected: true,
      endpoint: 'payment-intents',
    },
    {
      method: 'GET',
      api: ApiNames.GET_PAYMENT_INTENT_BY_USER_ID,
      protected: true,
      endpoint: 'payment-intents/:id',
    },
    {
      method: 'POST',
      api: ApiNames.CREATE_INVOICE_CONFIG,
      protected: true,
      endpoint: 'invoices/configs',
    },
    {
      method: 'GET',
      api: ApiNames.GET_INVOICE_CONFIG,
      protected: true,
      endpoint: 'invoices/configs',
    },
    {
      method: 'PATCH',
      api: ApiNames.UPDATE_INVOICE_CONFIG,
      protected: true,
      endpoint: 'invoices/configs/:id',
    },
    {
      method: 'DELETE',
      api: ApiNames.DELETE_INVOICE_CONFIG,
      protected: true,
      endpoint: 'invoices/configs/:id',
    },
    {
      method: 'GET',
      api: ApiNames.GET_PAYERS,
      protected: true,
      endpoint: 'invoices/payers',
    },
    {
      method: 'POST',
      api: ApiNames.CREATE_PAYER,
      protected: true,
      endpoint: 'invoices/payers',
    },
    {
      method: 'PATCH',
      api: ApiNames.UPDATE_PAYER,
      protected: true,
      endpoint: 'invoices/payers/:id',
    },
    {
      method: 'DELETE',
      api: ApiNames.DELETE_PAYER,
      protected: true,
      endpoint: 'invoices/payers/:id',
    },
    {
      method: 'GET',
      api: ApiNames.GET_INVOICE_NEXT_NUMBER,
      protected: true,
      endpoint: 'invoices/next-invoice-number',
    },
    {
      method: 'POST',
      api: ApiNames.CREATE_INVOICE,
      protected: true,
      endpoint: 'invoices',
    },
    {
      method: 'GET',
      api: ApiNames.GET_INVOICES,
      protected: true,
      endpoint: 'invoices',
    },
    {
      method: 'GET',
      api: ApiNames.DOWNLOAD_INVOICE,
      protected: true,
      endpoint: 'invoices/:id/download',
    },
    {
      method: 'DELETE',
      api: ApiNames.DELETE_INVOICE,
      protected: true,
      endpoint: 'invoices/:id',
    },
    {
      method: 'POST',
      api: ApiNames.RESET_PASSWORD,
      protected: false,
      endpoint: 'auth/reset-password',
    },
    {
      method: 'POST',
      api: ApiNames.RESET_PASSWORD_WITH_MFA,
      protected: true,
      endpoint: 'auth/reset-password/complete',
    },
    {
      method: 'POST',
      api: ApiNames.CHANGE_PASSWORD,
      protected: true,
      endpoint: 'auth/change-password',
    },
    {
      method: 'POST',
      api: ApiNames.CREATE_AND_DOWNLOAD_INVOICE_PUBLIC,
      protected: false,
      endpoint: 'public/invoices/download',
    },
    {
      method: 'GET',
      api: ApiNames.OZ_GET_CURRENCY_PAIRS,
      protected: false,
      endpoint: 'public/quotations/currency-pairs',
    },
    {
      method: 'POST',
      api: ApiNames.OZ_CALCULATE_TAX,
      protected: false,
      endpoint: 'public/quotations/calculate-tax',
    },
    {
      method: 'POST',
      api: ApiNames.OZ_CALCULATE_TAX_BY_ORDER,
      protected: true,
      endpoint: 'orders/:id/calculate',
    },
    {
      method: 'PATCH',
      api: ApiNames.UPDATE_CONTACT_FATCA,
      protected: true,
      endpoint: 'contacts/:id/fatca',
    },
    {
      method: 'GET',
      api: ApiNames.GET_DOCUMENTS,
      protected: true,
      endpoint: 'documents',
    },
    {
      method: 'PUT',
      api: ApiNames.UPLOAD_DOCUMENT_BY_DOCUMENT_ID,
      protected: true,
      endpoint: 'documents/:id/upload',
    },
    {
      method: 'POST',
      api: ApiNames.SUBMIT_ONBOARDING,
      protected: true,
      endpoint: 'onboarding/submit-proposal',
    },
    {
      method: 'GET',
      api: ApiNames.CHECK_ONBOARDING_STEPS,
      protected: true,
      endpoint: 'onboarding/steps',
    },
    {
      method: 'GET',
      api: ApiNames.GET_ALL_ORDERS,
      protected: true,
      endpoint: 'orders',
    },
    {
      method: 'GET',
      api: ApiNames.GET_GLOBAL_ACCOUNT,
      protected: true,
      endpoint: 'bank-accounts/global/:id',
    },
    {
      method: 'POST',
      api: ApiNames.SUBMIT_ORDER,
      protected: true,
      endpoint: 'orders/execute',
    },
    {
      method: 'GET',
      api: ApiNames.GET_ALL_CRYPTO_CURRENCIES,
      protected: true,
      endpoint: 'crypto/currencies',
    },
    {
      method: 'POST',
      api: ApiNames.GET_CRYPTO_OFFERS,
      protected: true,
      endpoint: 'crypto/offers',
    },
    {
      method: 'GET',
      api: ApiNames.GET_CRYPTO_PROVIDERS,
      protected: true,
      endpoint: 'crypto/providers',
    },
    {
      method: 'GET',
      api: ApiNames.GET_CRYPTO_WALLET_ADDRESS_VALIDATION,
      protected: true,
      endpoint: 'crypto/wallets/:id/validate',
    },
    {
      method: 'POST',
      api: ApiNames.CREATE_CRYPTO_ORDER,
      protected: true,
      endpoint: 'crypto/orders',
    },
  ],
};

export const generateQueryString = (
  params: Record<string, unknown>
): string => {
  let queryString = '';
  for (const key in params) {
    if (params[key] !== undefined) {
      queryString += `${key}=${params[key]}&`;
    }
  }
  if (queryString.endsWith('&')) {
    queryString = queryString.slice(0, -1);
  }
  return queryString;
};

export const getEndpoint = (api: ApiNames, arg?: string) => {
  const apiEndpoint = ApiRoutes.Endpoints.find((x) => x.api === api);
  if (!apiEndpoint) {
    throw new Error(`Endpoint ${api} not found`);
  }
  return {
    url: `${ApiRoutes.baseUrl}${apiEndpoint.endpoint}`.replace(
      ':id',
      arg || ''
    ),
    method: apiEndpoint.method,
    isProtected: apiEndpoint.protected,
  };
};

export const getRequestConfig = ({
  endpoint,
  data,
  params,
  token,
  headers,
  responseType,
  query,
}: ApiRequestConfig): AxiosRequestConfig => {
  const { method, isProtected, url } = getEndpoint(endpoint, params as string);
  const finalToken = token?.accessToken ?? getAuthTokenCookie()?.accessToken;
  const auth = isProtected ? `Bearer ${finalToken}` : undefined;
  const queryParam = query ? `?${generateQueryString(query)}` : '';

  return {
    headers: {
      Authorization: auth,
      ...headers,
    },
    responseType,
    baseURL: ApiRoutes.baseUrl,
    url: `${url}${queryParam}`,
    data,
    method,
  };
};
