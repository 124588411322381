import { WarningIcon } from '@chakra-ui/icons';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Divider,
  Link,
  Progress,
  Tooltip,
} from '@chakra-ui/react';
import { ChakraColors } from '../../common/configuration/ChakraColors';
import usePasswordReset from './hooks/usePasswordReset';

const PasswordReset = () => {
  const {
    getFieldProps,
    touched,
    handleSubmit,
    errors,
    t,
    isLoading,
    mfaEmailSent,
    resetPasswordToken,
    handlePasswordResetSubmit,
  } = usePasswordReset();

  return (
    <div>
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent="space-around"
      >
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          gap="20px"
        >
          <img src="assets/images/logo2.png" width="350px" alt="Waygee" />
          <Divider />
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>{t('Sign in to your account')}</Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              {t('to enjoy all of our cool')}{' '}
              <Text color={'blue.400'}>{t('features')}.</Text> ✌️
            </Text>
          </Stack>
        </Flex>
        <Flex
          minH={{ base: 'unset', md: '80vh' }}
          align={'center'}
          justify={'center'}
          bg={useColorModeValue('gray.50', 'gray.800')}
        >
          <Stack spacing={3} mx={'auto'} maxW={'lg'} py={12} px={6}>
            {isLoading && (
              <Progress size="xs" isIndeterminate colorScheme="primary" />
            )}
            <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}
            >
              {mfaEmailSent && (
                <Flex flexDirection="column" gap="20px">
                  <Heading size="md" color={ChakraColors.success}>
                    {t('Check your e-mail')}!
                  </Heading>
                  <Text>{t('An email was sent with the recovery link')}</Text>
                  <Text>{t('Please check your inbox to continue')}</Text>
                </Flex>
              )}
              {!mfaEmailSent && !resetPasswordToken && (
                <Stack spacing={4}>
                  <form onSubmit={handleSubmit}>
                    <FormControl
                      id="email"
                      isInvalid={(touched.email && !!errors.email) || false}
                    >
                      <FormLabel>{t('Email address')}</FormLabel>
                      <Input type="email" {...getFieldProps('email')} />
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>
                    <Stack spacing={10}>
                      <span />
                      <Button
                        colorScheme="primary"
                        type="submit"
                        isDisabled={isLoading}
                      >
                        {t('Send e-mail')}
                      </Button>
                    </Stack>
                  </form>
                </Stack>
              )}
              {resetPasswordToken && (
                <Stack spacing={4}>
                  <form onSubmit={handlePasswordResetSubmit}>
                    <FormControl
                      id="password"
                      isInvalid={
                        (touched.password && !!errors.password) || false
                      }
                    >
                      <FormLabel>
                        {t('New password')}{' '}
                        <Tooltip
                          label={t(
                            'At least 8 characters, 1 uppercase and 1 lowercase.'
                          )}
                          fontSize="md"
                        >
                          <WarningIcon />
                        </Tooltip>
                      </FormLabel>
                      <Input type="password" {...getFieldProps('password')} />
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="dateOfBirth"
                      isInvalid={
                        (touched.dateOfBirth && !!errors.dateOfBirth) || false
                      }
                    >
                      <FormLabel>{t('Date of Birth')}</FormLabel>
                      <Input type="date" {...getFieldProps('dateOfBirth')} />
                      <FormErrorMessage>{errors.dateOfBirth}</FormErrorMessage>
                    </FormControl>
                    <Stack spacing={10}>
                      <span />
                      <Button
                        colorScheme="primary"
                        type="submit"
                        isDisabled={isLoading}
                      >
                        {t('Change password')}
                      </Button>
                    </Stack>
                  </form>
                </Stack>
              )}
            </Box>
            <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}
            >
              <Text fontSize="small">
                {t('Do not have an account yet?')}&nbsp;
                <Link href="/secure/signup">{t('Click here to get one!')}</Link>
              </Text>
            </Box>
          </Stack>
        </Flex>
      </Flex>
    </div>
  );
};

export default PasswordReset;
