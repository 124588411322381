import React, { useEffect } from 'react';

export type UseInvoiceScreenObserverProps = {
  bottomObserver: React.MutableRefObject<HTMLElement | null>;
  footerObserver: React.MutableRefObject<HTMLElement | null>;
  setIsBottomObserverVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFooterObserverVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const useInvoiceScreenObserver = ({
  bottomObserver,
  footerObserver,
  setIsBottomObserverVisible,
  setIsFooterObserverVisible,
}: UseInvoiceScreenObserverProps) => {
  useEffect(() => {
    const bottomObserverCurrent = bottomObserver.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsBottomObserverVisible(true);
        } else {
          setIsBottomObserverVisible(false);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (bottomObserverCurrent) {
      observer.observe(bottomObserverCurrent);
    }

    return () => {
      if (bottomObserverCurrent) {
        observer.unobserve(bottomObserverCurrent);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const footerObserverCurrent = footerObserver.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsFooterObserverVisible(true);
        } else {
          setIsFooterObserverVisible(false);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (footerObserverCurrent) {
      observer.observe(footerObserverCurrent);
    }

    return () => {
      if (footerObserverCurrent) {
        observer.unobserve(footerObserverCurrent);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {};
};

export default useInvoiceScreenObserver;
