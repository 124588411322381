import { useNavigate } from 'react-router-dom';
import {
  useColorModeValue,
  Heading,
  Flex,
  Divider,
  Box,
  Button,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';

const DashboardQuickActions = () => {
  const navigate = useNavigate();
  return (
    <Box
      rounded={'lg'}
      bg={useColorModeValue('white', 'gray.700')}
      boxShadow={'lg'}
      p={8}
      //   maxH="150px"
    >
      <Heading size="small" as="h1" mb="4">
        <Flex flexDir="row" gap="10px" alignItems="center">
          <MdOutlineAccountBalanceWallet />
          {t('Quick Actions')}
        </Flex>
      </Heading>
      <Divider />
      <Flex
        flexDir="row"
        gap="15px"
        minW="300px"
        justifyContent="space-between"
        padding="10px"
      >
        <Flex flexDir="row" wrap="wrap" gap="10px" maxW="340px">
          <Button
            size="sm"
            onClick={() => navigate('/secure/bank-accounts/global')}
          >
            {t('My bank accounts')}
          </Button>
          <Button
            size="sm"
            onClick={() => navigate('/secure/statement/transferences')}
          >
            {t('My transference')}
          </Button>
          <Button size="sm" onClick={() => navigate('/secure/invoice/history')}>
            {t('My invoices')}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default DashboardQuickActions;
