import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  useColorModeValue,
  Heading,
  Flex,
  Divider,
  Text,
} from '@chakra-ui/react';
import { FaRegCircleUser } from 'react-icons/fa6';
import { AuthContext } from '../../../../contexts/AuthContext/AuthContext';
import './../../Dashboard.language';

const DashboardWelcome = () => {
  const { user, orderAvailableCount } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');

  return (
    <Box
      rounded={'lg'}
      bg={useColorModeValue('white', 'gray.700')}
      boxShadow={'lg'}
      p={8}
    >
      <Heading size="small" as="h1" mb="4">
        <Flex flexDir="row" gap="10px" alignItems="center">
          <FaRegCircleUser />
          {t('Hello')} {user?.ownerIndividualContact.legalName}
        </Flex>
      </Heading>
      <Divider />
      <Flex
        flexDir="row"
        gap="15px"
        minW="300px"
        justifyContent="space-between"
        padding="10px"
      >
        <Flex flexDir="row" wrap="wrap" gap="10px" maxW="340px" minH="75px">
          <Text
            _hover={{ cursor: orderAvailableCount ? 'pointer' : undefined }}
            onClick={() => {
              if (orderAvailableCount)
                navigate('/secure/statement/transferences');
            }}
          >
            {orderAvailableCount > 0 && t('You have')}{' '}
            {orderAvailableCount > 0 && <strong>{orderAvailableCount}</strong>}{' '}
            {orderAvailableCount > 0 &&
              t(
                'available to be transferred to your local account. Click here and check it out.'
              )}
            {orderAvailableCount <= 0 &&
              t(
                'Everything is calm here, disclose your details to your payers to receive payments.'
              )}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default DashboardWelcome;
