export enum InvoiceTemplates {
  INVOICE_DEFAULT = 'DEFAULT',
}

export interface IInvoiceTemplateDefaultTemplateData {
  invoiceTitle: string;
  invoiceSubTitle: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
  payerName: string;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceDueDate?: string;
  invoiceServiceDescription: string;
  invoiceAmount: string;
  payerAddress1?: string;
  payerAddress2?: string;
  payerPhone?: string;
  payerEmail?: string;
  bankAccountDetailsLine1: string;
  bankAccountDetailsLine2: string;
  bankAccountDetailsLine3: string;
  invoiceColor: string;
}

export interface IInvoiceTemplatesDataTypes {
  [InvoiceTemplates.INVOICE_DEFAULT]: IInvoiceTemplateDefaultTemplateData;
}
