import appConfig from '../../configuration/AppConfig';
import { AuthToken } from '../../types/auth';

const authTokenKey = appConfig.authCookieKey;
const cookieIntervalCheck = appConfig.authCookieIntervalCheckInMilliseconds;
const cookieDomain = appConfig.authCookieDomain;
const cookieExpiration = appConfig.authCookieExpirationInSeconds;
const cookieRefreshTokenIntervalCheck =
  appConfig.authCookieRefreshTokenIntervalCheckInMilliseconds;
let intervalId: number | null = null;

export const setAuthToken = (authToken: AuthToken) => {
  const expirationTime = authToken?.refreshTokenExpiresAt
    ? new Date(authToken?.refreshTokenExpiresAt)?.toUTCString()
    : getExpireTime(cookieExpiration);

  const cookieValue = `${JSON.stringify({
    accessToken: authToken?.accessToken,
    refreshToken: authToken?.refreshToken,
    accessTokenExpiresAt: authToken?.accessTokenExpiresAt,
    refreshTokenExpiresAt: authToken?.refreshTokenExpiresAt,
    silent: authToken?.silent,
  })}`;
  const cookie = `${authTokenKey}=${cookieValue};expires=${expirationTime};path=/;domain=${cookieDomain}`;
  document.cookie = cookie;
};
export const removeAuthToken = () =>
  (document.cookie = `${authTokenKey}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${cookieDomain}`);

const getExpireTime = (secondsFromNow: number) => {
  const now = new Date();
  const expiredDateTime = new Date(now.getTime() + 1000 * secondsFromNow);
  return expiredDateTime.toUTCString();
};

export const getAuthTokenCookie = () => {
  const cookie = getCookieByName(authTokenKey);
  try {
    return cookie ? (JSON.parse(cookie) as AuthToken) : undefined;
  } catch (error) {
    return undefined;
  }
};

const getCookieByName = (name: string) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
};

const getAuthTokenHash = () => {
  const authTokenCookie = getAuthTokenCookie();
  let hash = '';
  if (authTokenCookie) {
    const authTokenObject = authTokenCookie as AuthToken;
    if (authTokenObject) {
      const { silent, ...rest } = authTokenObject;
      if (rest) hash = rest?.accessToken ?? '';
    }
  }
  return { hash, authTokenCookie };
};

export const addAuthCookieListener = (
  callBackLogin: (newAuthToken: AuthToken) => Promise<void>,
  callBackLogout: () => void
) => {
  if (intervalId) {
    window.clearInterval(intervalId); // If the interval already exists, destroy it
  }

  let lastCookie = getAuthTokenHash();

  const checkCookieChanges = () => {
    const currentCookie = getAuthTokenHash();
    if (currentCookie.hash !== lastCookie.hash) {
      lastCookie = currentCookie;
      if (currentCookie.authTokenCookie) {
        if (currentCookie?.authTokenCookie?.silent) {
          const { silent, ...rest } = currentCookie.authTokenCookie;
          setAuthToken(rest);
        } else {
          callBackLogin(currentCookie.authTokenCookie);
        }
      } else {
        callBackLogout();
      }
    }
  };

  intervalId = window.setInterval(() => {
    checkCookieChanges();
  }, cookieIntervalCheck);
};

export const addAuthCookieRefreshTokenListener = (
  callBackRefreshToken: (token: AuthToken) => Promise<AuthToken>
) => {
  const refreshTokenCheck = async () => {
    const token = getAuthTokenHash();
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!token.hash && token?.authTokenCookie)
      callBackRefreshToken(token.authTokenCookie)
        .then((authToken) => setAuthToken(authToken))
        .catch(() => removeAuthToken());
  };
  window.setInterval(() => {
    refreshTokenCheck();
  }, Number(cookieRefreshTokenIntervalCheck));
};
