import { useTranslation } from 'react-i18next';
import {
  Heading,
  Flex,
  Card,
  CardBody,
  Text,
  CardHeader,
  Divider,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import './../../Onboarding.language';
import {
  DocumentTypes,
  IContactDto,
  IDocumentDto,
  OnboardingSteps,
} from '@waygee/shared-types';
import { ContactFiles, Individuals } from '../..';
import { SignUpStepResult } from '../../../../common/types';
import { OnboardingDocumentsDataFileUpload } from './components/OnboardingDocumentsDataFileUpload';

export type OnboardingDocumentsDataProps = {
  onDocumentChange: (contactFiles: ContactFiles) => void;
  individuals: Individuals;
  business?: IContactDto;
  documents: IDocumentDto<DocumentTypes.TIN>[];
  onboardingStepResults: SignUpStepResult[];
};

const OnboardingDocumentsData = ({
  onDocumentChange,
  individuals,
  business,
  documents,
  onboardingStepResults,
}: OnboardingDocumentsDataProps) => {
  const { t } = useTranslation('onboarding');
  const hasMessages = onboardingStepResults.some(
    (c) => c.step === OnboardingSteps.DOCUMENTS && c.messages.length > 0
  );
  return (
    <>
      <Heading size="md">{t('Documents Upload')}</Heading>
      <Divider />
      {hasMessages && (
        <Alert status="warning">
          <AlertIcon />
          <Flex flexDir="column" w="100%">
            <AlertTitle>
              {t(
                'Our team have found some pending with your data, please check and fix that'
              )}
            </AlertTitle>

            {onboardingStepResults
              .find((c) => c.step === OnboardingSteps.DOCUMENTS)
              ?.messages.map((msg, index) => (
                <AlertDescription key={index}>- {msg}</AlertDescription>
              ))}
          </Flex>
        </Alert>
      )}
      <Flex flexDir="column" gap="20px" padding="20px">
        {Object.keys(individuals).map((individual) => {
          return (
            <Card variant="outline">
              <CardHeader>
                <Heading size="sm">
                  {t('Individual document')}:{' '}
                  {individuals[individual]?.legalName}
                </Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  {t(
                    'Please, you need to submit your individual document, such like your ID card, passport or driver license'
                  )}
                </Text>
              </CardBody>
              <CardBody>
                <OnboardingDocumentsDataFileUpload
                  name="individual"
                  multiple
                  contactId={individual}
                  onChange={onDocumentChange}
                  documents={documents}
                />
              </CardBody>
            </Card>
          );
        })}
        <Card variant="outline">
          <CardHeader>
            <Heading size="sm">
              {t('Company document')}: {business?.legalName}
            </Heading>
          </CardHeader>
          <CardBody>
            <Text>
              {t(
                'Please, you need to submit your company document, such like your company registration certificate, articles of association or memorandum of association'
              )}
            </Text>
          </CardBody>
          <CardBody>
            <OnboardingDocumentsDataFileUpload
              name="company"
              contactId={business?.id ?? ''}
              onChange={onDocumentChange}
              documents={documents}
            />
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};

export default OnboardingDocumentsData;
