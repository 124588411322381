import { IDispatcherParams } from './dispatcher.definitions';

export class Dispatcher<TResponse = void> {
  private resolvers: Array<(response: TResponse) => void> = [];

  constructor(private readonly params: IDispatcherParams<TResponse> = {}) {
    if (!this.params.dispatchEveryMs || this.params.dispatchEveryMs < 1) {
      this.params.dispatchEveryMs = 1;
    }
    this.dispatch();
  }

  async requestDispatch(): Promise<TResponse> {
    return new Promise((resolve) => {
      this.resolvers.push(resolve);
    });
  }

  private async dispatch() {
    const resolve = this.resolvers.pop();
    if (resolve) {
      const resolvedData = await Promise.resolve(this.params.resolveWith?.());
      resolve(resolvedData as TResponse);
    }
    await this.wait(this.params.dispatchEveryMs as number);
    await this.dispatch();
  }

  private wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
