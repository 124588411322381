import {
  DocumentTypes,
  IOnboardingStepConfig,
  OnboardingSteps,
} from '@waygee/shared-types';

export const ONBOARDING_DOCUMENT_TYPES = [DocumentTypes.TIN];

export const ONBOARDING_STEPS: IOnboardingStepConfig[] = [
  {
    step: OnboardingSteps.ACCOUNT_DATA,
    title: 'Account Data',
  },
  {
    step: OnboardingSteps.BANKING_DATA,
    title: 'Banking Data',
  },
  {
    step: OnboardingSteps.DOCUMENTS,
    title: 'Documents',
  },
  {
    step: OnboardingSteps.REVIEW,
    title: 'Review',
  },
];
