import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './../Onboarding.language';
import { BoxProps, FlexProps, useToast } from '@chakra-ui/react';
import { BRAZILIAN_BANKS } from '@waygee/common';
import {
  DocumentTypes,
  IBankAccountDto,
  IDocumentDto,
  OnboardingStepStatus,
  OnboardingSteps,
} from '@waygee/shared-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ApiNames, getRequestConfig } from '../../../common/apis';
import { AutoCompleteProps } from '../../../common/components/AutoComplete';
import { axiosCustomInstance } from '../../../common/network';
import { AuthContext } from '../../../contexts/AuthContext/AuthContext';
import { OnboardingBankingDataProps } from '../components/OnboardingBankingData';
import { OnboardingDocumentsDataProps } from '../components/OnboardingDocumentsData/OnboardingDocumentsData';
import { OnboardingIndividualDataProps } from '../components/OnboardingIndividualData';
import {
  OnboardingStep,
  OnboardingStepperProps,
} from '../components/OnboardingStepper/OnboardingStepper';
import {
  ContactFiles,
  Individuals,
  OnboardingIndividualDataSchema,
  OnboardingIndividualForeignAccountDataSchema,
  OnboardingSchema,
} from './../';

const useOnboarding = () => {
  const { t } = useTranslation('onboarding');
  const title = t('Onboarding');
  const subTitle = t('Fill in your personal information to start using Waygee');
  const [submitButtonLabel, setSubmitButtonLabel] = useState<string>('Next');
  const {
    user,
    onboardingStatus,
    onboardingStep,
    validateUserAccess,
    onboardingStepResults,
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bankAccount, setBankAccount] = useState<IBankAccountDto | undefined>();
  const [documents, setDocuments] = useState<IDocumentDto<DocumentTypes.TIN>[]>(
    []
  );
  const [contactFiles, setContactFiles] = useState<ContactFiles[]>([]);
  const toast = useToast();

  const individuals = useMemo(() => {
    const allIndividuals: Individuals = {};
    if (user?.ownerIndividualContact?.id)
      allIndividuals[user?.ownerIndividualContact?.id] =
        user?.ownerIndividualContact;

    user?.otherIndividualContacts?.forEach((individual) => {
      allIndividuals[individual.id] = individual;
    });
    return allIndividuals;
  }, [user?.ownerIndividualContact, user?.otherIndividualContacts]);

  const business = user?.businessContact;

  const individualValidationSchema = Yup.object<OnboardingIndividualDataSchema>(
    {
      isPep: Yup.boolean(),
      isFatca: Yup.boolean(),
      fatcaData: Yup.object<OnboardingIndividualForeignAccountDataSchema>({
        bornAmerican: Yup.boolean(),
        naturalizedAmerican: Yup.boolean(),
        partnerInAmericanCompany: Yup.boolean(),
        hasGreenCard: Yup.boolean(),
        identificationNumber: Yup.string()
          .nullable()
          .when('taxResidenceEUA', {
            is: true,
            then: (schema) =>
              schema.required(
                t('Tax Identification number required for US tax residents')
              ),
            otherwise: (schema) => schema.notRequired(),
          }),
        taxResidenceEUA: Yup.boolean(),
      }),
    }
  );

  const getIndividualsSchema = () => {
    return Object.keys(individuals).map((individualKey) => {
      return {
        [individualKey]: individualValidationSchema,
      };
    });
  };

  const getIndividualsInitialValue = useCallback(() => {
    const individualsInitialValue: Record<
      string,
      OnboardingIndividualDataSchema
    > = {};
    Object.keys(individuals).forEach((individualKey) => {
      const thisIndividual =
        user?.otherIndividualContacts?.find(
          (individual) => individual.id === individualKey
        ) ?? user?.ownerIndividualContact;
      if (thisIndividual)
        individualsInitialValue[individualKey] = {
          isPep: thisIndividual.isPep ?? false,
          isFatca: thisIndividual.isFatca ?? false,
          fatcaData: {
            bornAmerican: thisIndividual?.fatcaData?.bornAmerican ?? false,
            naturalizedAmerican:
              thisIndividual?.fatcaData?.naturalizedAmerican ?? false,
            partnerInAmericanCompany:
              thisIndividual?.fatcaData?.partnerInAmericanCompany ?? false,
            hasGreenCard: thisIndividual?.fatcaData?.hasGreenCard ?? false,
            identificationNumber:
              thisIndividual?.fatcaData?.identificationNumber,
            taxResidenceEUA:
              thisIndividual?.fatcaData?.taxResidenceEUA ?? false,
          },
        };
    });
    return individualsInitialValue;
  }, [
    individuals,
    user?.ownerIndividualContact,
    user?.otherIndividualContacts,
  ]);

  const validationSchema = Yup.object<OnboardingSchema>({
    individuals: Yup.object<Record<string, OnboardingIndividualDataSchema>>(
      ...getIndividualsSchema()
    ),
    banking: Yup.object<IBankAccountDto>({
      accountNumber: Yup.string().when('$step', {
        is: OnboardingSteps.BANKING_DATA,
        then: (schema) => schema.required(t('Account number is required')),
        otherwise: (schema) => schema.notRequired(),
      }),
      branchCode: Yup.string().when('$step', {
        is: OnboardingSteps.BANKING_DATA,
        then: (schema) => schema.required(t('Branch code is required')),
        otherwise: (schema) => schema.notRequired(),
      }),
      bankCode: Yup.string().when('$step', {
        is: OnboardingSteps.BANKING_DATA,
        then: (schema) => schema.required(t('Bank code is required')),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
  });

  const formik = useFormik<OnboardingSchema>({
    initialValues: {
      step: OnboardingSteps.ACCOUNT_DATA,
      individuals: {},
      banking: {
        accountNumber: '',
        bankCode: '',
        branchCode: '',
        accountType: 'checking',
      } as IBankAccountDto,
    },
    validationSchema,
    onSubmit: (values) => {
      console.debug(values);
    },
  });

  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setTouched,
    setValues,
    validateForm,
  } = formik;

  const onboardingSteps: OnboardingStep[] = [
    { title: t('Personal data'), description: t('Individual data') },
    { title: t('Banking data'), description: t('Banking details') },
    { title: t('Documents'), description: t('Upload physical docs') },
    { title: t('Complete'), description: t('Submit for review') },
  ];

  const boxProps: BoxProps = {
    rounded: 'lg',
    bg: 'white',
    boxShadow: 'lg',
    p: 8,
    minW: { base: '400px', md: '800px' },
  };
  const flexProps: FlexProps = {
    gap: '25px',
    margin: '20px',
    wrap: 'wrap',
  };

  const brazilianBankOptions = useMemo(
    () =>
      BRAZILIAN_BANKS.map((bank) => ({
        id: bank.code ?? '',
        name: `${bank.code} - ${bank.name}`,
      })),
    []
  );

  const autoCompleteProps: AutoCompleteProps = {
    placeholder: t('Search for a bank'),
    items: brazilianBankOptions,
    initialValue: bankAccount?.bankName ?? '',
    width: '380px',
    onSelectItem: (item) => {
      setTouched({ banking: { bankCode: true } });
      setValues({
        ...values,
        banking: {
          ...values.banking,
          bankCode: item.id.toString(),
        },
      });
    },
    onReset: () => {
      setTouched({ banking: { bankCode: true } });
      setValues({
        ...values,
        banking: {
          ...values.banking,
          bankCode: '',
        },
      });
    },
  };

  const handleBackStepClick = () => {
    switch (values.step) {
      case OnboardingSteps.ACCOUNT_DATA:
        setFieldValue('step', OnboardingSteps.REVIEW);
        break;
      case OnboardingSteps.BANKING_DATA:
        setFieldValue('step', OnboardingSteps.ACCOUNT_DATA);
        break;
      case OnboardingSteps.DOCUMENTS:
        setFieldValue('step', OnboardingSteps.BANKING_DATA);
        break;
      case OnboardingSteps.REVIEW:
        setFieldValue('step', OnboardingSteps.DOCUMENTS);
        break;
      default:
        setFieldValue('step', OnboardingSteps.ACCOUNT_DATA);
        break;
    }
  };

  const validateDocumentForm = () => {
    if (documents.length > 0) return true;
    else {
      const contactIds = new Array<string>();
      Object.keys(individuals).forEach((individualKey) => {
        contactIds.push(individualKey);
      });
      if (business?.id) contactIds.push(business?.id);

      const isValid = contactIds.every((contactId) => {
        return (
          contactFiles?.find(
            (contactFile) => contactFile?.contactId === contactId
          )?.files?.length ?? false
        );
      });

      if (!isValid) {
        toast({
          title: t('All documents are required'),
          description: t('Please, upload all the required documents'),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      return isValid;
    }
  };

  const fetchDocumentsData = async () => {
    setIsLoading(true);
    const promiseArray = new Array<Promise<void>>();
    contactFiles.forEach((contactFile) => {
      contactFile.files.forEach((file) => {
        const formData = new FormData();
        formData.append('file', file);

        promiseArray.push(
          axiosCustomInstance.request({
            ...getRequestConfig({
              endpoint: ApiNames.UPLOAD_DOCUMENT_BY_DOCUMENT_ID,
              params: documents.find(
                (d) => d.contactId === contactFile.contactId
              )?.id,
            }),
            data: formData,
          })
        );
      });
    });

    return Promise.all(promiseArray)
      .catch((error) => {
        throw error;
      })
      .finally(() => setIsLoading(false));
  };

  const fetchSubmitProposal = async () => {
    setIsLoading(true);
    const request = getRequestConfig({
      endpoint: ApiNames.SUBMIT_ONBOARDING,
    });
    return axiosCustomInstance
      .request(request)
      .finally(() => setIsLoading(false));
  };

  const fetchBankingData = async () => {
    setIsLoading(true);
    const { accountNumber, branchCode, bankCode } = values.banking;
    const request = getRequestConfig({
      endpoint: ApiNames.CREATE_BANK_ACCOUNT,
      data: {
        accountNumber,
        branchCode,
        bankCode,
        accountType: 'checking',
      },
    });
    return axiosCustomInstance
      .request(request)
      .finally(() => setIsLoading(false));
  };

  const fetchFatcaData = async () => {
    setIsLoading(true);
    const contactIds = new Array<string>();
    Object.keys(values.individuals).forEach(async (individualKey) => {
      contactIds.push(individualKey);
    });

    const promises = contactIds.map((contactId) => {
      return axiosCustomInstance
        .request(
          getRequestConfig({
            endpoint: ApiNames.UPDATE_CONTACT_FATCA,
            params: contactId,
            data: {
              ...values.individuals[contactId],
            },
          })
        )
        .catch((error) => {
          throw new Error(error.response.data.message);
        });
    });

    return Promise.all(promises)
      .catch((error) => {
        throw error;
      })
      .finally(() => setIsLoading(false));
  };

  const onDocumentChange = (contactFilesChange: ContactFiles) => {
    const contactFilesCopy = [...contactFiles];
    const contactFilesIndex = contactFilesCopy.findIndex(
      (contactFile) => contactFile.contactId === contactFilesChange.contactId
    );
    if (contactFilesIndex !== -1) {
      contactFilesCopy[contactFilesIndex] = contactFilesChange;
    } else {
      contactFilesCopy.push(contactFilesChange);
    }
    setContactFiles(contactFilesCopy);
  };

  const handleNextStepClick = async () => {
    const validation = await validateForm();

    const touchedFields: { [key: string]: boolean } = {};

    const validationKeys = Object.keys(validation);
    validationKeys.forEach((field) => {
      touchedFields[field] = true;
    });
    const isValid = validationKeys.length === 0;
    if (isValid) {
      switch (values.step) {
        case OnboardingSteps.ACCOUNT_DATA:
          fetchFatcaData()
            .then(() => {
              setFieldValue('step', OnboardingSteps.BANKING_DATA);
            })
            .catch((error) => {
              toast({
                title: t('Error saving individuals data'),
                description: `${error}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            });

          break;
        case OnboardingSteps.BANKING_DATA:
          fetchBankingData()
            .then(() => {
              setFieldValue('step', OnboardingSteps.DOCUMENTS);
            })
            .catch((error) => {
              toast({
                title: t('Error saving banking account data'),
                description: t('Please, try again later or ask for help'),
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            });
          break;
        case OnboardingSteps.DOCUMENTS:
          if (validateDocumentForm())
            fetchDocumentsData()
              .then(() => {
                setFieldValue('step', OnboardingSteps.REVIEW);
              })
              .catch(() => {
                toast({
                  title: t('Error uploading the documents'),
                  description: t('Please, try again later or ask for help'),
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
              });
          break;
        case OnboardingSteps.REVIEW:
          fetchSubmitProposal()
            .then(() => {
              setFieldValue('step', OnboardingSteps.REVIEW);
              validateUserAccess();
            })
            .catch(() => {
              toast({
                title: t('Error submitting your proposal'),
                description: t('Please, try again later or ask for help'),
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            });
          break;
        default:
          setFieldValue('step', OnboardingSteps.ACCOUNT_DATA);
          break;
      }
    } else {
      handleSubmit();
    }
  };

  const onboardingIndividualDataProps: OnboardingIndividualDataProps = {
    getFieldProps,
    errors,
    touched,
    values,
    individuals,
    onboardingStepResults,
  };

  const onboardingBankingDataProps: OnboardingBankingDataProps = {
    getFieldProps,
    errors,
    touched,
    autoCompleteProps,
    onboardingStepResults,
  };

  const onboardingDocumentsDataProps: OnboardingDocumentsDataProps = {
    individuals,
    business,
    onDocumentChange,
    documents,
    onboardingStepResults,
  };

  const getStepIndex = (step: OnboardingSteps) => {
    switch (step) {
      case OnboardingSteps.ACCOUNT_DATA:
        return 0;
      case OnboardingSteps.BANKING_DATA:
        return 1;
      case OnboardingSteps.DOCUMENTS:
        return 2;
      case OnboardingSteps.REVIEW:
        if (onboardingStatus === OnboardingStepStatus.IN_PROGRESS) return 4;
        return 3;
      default:
        return 0;
    }
  };

  const onboardingStepperProps: OnboardingStepperProps = {
    activeStep: getStepIndex(values.step),
    steps: onboardingSteps,
  };

  const loadStepButtonLabel = useCallback(() => {
    switch (values.step) {
      case OnboardingSteps.ACCOUNT_DATA:
        setSubmitButtonLabel(t('Next'));
        break;
      case OnboardingSteps.BANKING_DATA:
        setSubmitButtonLabel(t('Next'));
        break;
      case OnboardingSteps.DOCUMENTS:
        setSubmitButtonLabel(t('Next'));
        break;
      case OnboardingSteps.REVIEW:
        setSubmitButtonLabel(t('Complete'));
        break;
      default:
        setSubmitButtonLabel(t('Next'));
        break;
    }
  }, [values.step, t]);

  const loadUserBankAccounts = useCallback(() => {
    setIsLoading(true);
    axiosCustomInstance
      .request<IBankAccountDto>(
        getRequestConfig({
          endpoint: ApiNames.GET_ALL_BANK_ACCOUNTS,
        })
      )
      .then((res) => setBankAccount(res.data))
      .catch(() => setBankAccount(undefined))
      .finally(() => setIsLoading(false));
  }, []);

  const loadDocuments = useCallback(() => {
    setIsLoading(true);
    axiosCustomInstance
      .request<IDocumentDto<DocumentTypes.TIN>[]>(
        getRequestConfig({
          endpoint: ApiNames.GET_DOCUMENTS,
        })
      )
      .then((res) => setDocuments(res.data))
      .catch(() => setDocuments([]))
      .finally(() => setIsLoading(false));
  }, []);

  const loadInitialStep = useCallback(() => {
    setFieldValue('step', onboardingStep);
  }, [onboardingStep, setFieldValue]);

  useEffect(() => {
    if (bankAccount) setFieldValue('banking', bankAccount);
  }, [bankAccount, setFieldValue]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  useEffect(() => {
    loadUserBankAccounts();
  }, [loadUserBankAccounts]);

  useEffect(() => {
    setFieldValue('individuals', getIndividualsInitialValue());
  }, [getIndividualsInitialValue, individuals, setFieldValue]);

  useEffect(() => {
    loadStepButtonLabel();
  }, [loadStepButtonLabel]);

  useEffect(() => {
    loadInitialStep();
  }, [loadInitialStep]);

  console.log(values.step, onboardingStatus, onboardingStep);

  return {
    title,
    subTitle,
    onboardingStepperProps,
    step: values.step,
    onboardingStatus,
    onboardingIndividualDataProps,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    values,
    flexProps,
    boxProps,
    t,
    submitButtonLabel,
    onboardingBankingDataProps,
    handleNextStepClick,
    handleBackStepClick,
    isLoading,
    onboardingDocumentsDataProps,
  };
};

export default useOnboarding;
