import { Card } from '@chakra-ui/react';
import { ConfirmationModal } from '../../../../common/components/ConfirmationModal';
import { TableGrid } from '../../../../common/components/TableGrid';
import useInvoiceHistory from './hooks/useInvoiceHistory';

const InvoiceHistory = () => {
  const { tableGridProps, isOpen, onClose, onDeleteConfirm, id, t } =
    useInvoiceHistory();
  return (
    <>
      <ConfirmationModal
        title={t('Are you sure?')}
        description={t('Are you sure you want to delete this invoice?')}
        cancelButtonLabel={t('Cancel')}
        confirmButtonLabel={t('Delete')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onDeleteConfirm}
        id={id}
      />
      <Card padding="20px">
        <TableGrid {...tableGridProps} />
      </Card>
    </>
  );
};

export default InvoiceHistory;
