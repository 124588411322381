import { Button, Flex } from '@chakra-ui/react';
import { CryptoPaymentMethod } from '../../types';

export type BuyCryptoPaymentMethodsProps = {
  paymentMethods: CryptoPaymentMethod[];
  selectedPaymentMethod?: string;
  handleChangeSelectedPaymentMethod: (method: string) => void;
};

const BuyCryptoPaymentMethods = ({
  paymentMethods,
  selectedPaymentMethod,
  handleChangeSelectedPaymentMethod,
}: BuyCryptoPaymentMethodsProps) => {
  return (
    <Flex flexDir="row" gap="5px" justifyContent="space-around">
      {paymentMethods?.map((method) => {
        return (
          <Button
            onClick={() => handleChangeSelectedPaymentMethod(method.method)}
            variant={
              selectedPaymentMethod === method.method ? undefined : 'outline'
            }
            colorScheme="primary"
          >
            <img
              src={method.image}
              alt={method.methodName}
              width="75"
              height="31"
            />
          </Button>
        );
      })}
    </Flex>
  );
};

export default BuyCryptoPaymentMethods;
