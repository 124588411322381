import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import { IBankAccountDto } from '@waygee/shared-types';
import {
  FieldConfig,
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { AutoCompleteProps } from '../../../../../../common/components/AutoComplete';
import { LocalAccountFormFields } from './components/LocalAccountFormFields';

export type LocalAccountFormProps = {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  isSubmitDisabled: boolean;
  touched: FormikTouched<IBankAccountDto>;
  errors: FormikErrors<IBankAccountDto>;
  getFieldProps: (
    nameOrOptions: string | FieldConfig
  ) => FieldInputProps<string | number>;
  autoCompleteProps: AutoCompleteProps;
};

const LocalAccountForm = ({
  isOpen,
  onClose,
  isSubmitDisabled,
  handleSubmit,
  touched,
  errors,
  getFieldProps,
  autoCompleteProps,
}: LocalAccountFormProps) => {
  const { t } = useTranslation();
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>{t('New Bank Account')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LocalAccountFormFields
              autoCompleteProps={autoCompleteProps}
              errors={errors}
              getFieldProps={getFieldProps}
              touched={touched}
            />
          </ModalBody>
          <ModalFooter gap="20px">
            <Button onClick={onClose}>{t('Close')}</Button>
            <Button
              type="submit"
              colorScheme="green"
              disabled={isSubmitDisabled}
            >
              {t('Create')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default LocalAccountForm;
