import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from '@chakra-ui/react';
import { OnboardingSteps } from '@waygee/shared-types';
import {
  FormikTouched,
  FormikErrors,
  FieldConfig,
  FieldInputProps,
} from 'formik';
import { Individuals, OnboardingSchema } from '../../';
import { SignUpStepResult } from '../../../../common/types';

export type OnboardingIndividualDataProps = {
  touched: FormikTouched<OnboardingSchema>;
  errors: FormikErrors<OnboardingSchema>;
  values: OnboardingSchema;
  getFieldProps: (
    nameOrOptions: string | FieldConfig
  ) => FieldInputProps<string | number>;
  individuals: Individuals;
  onboardingStepResults: SignUpStepResult[];
};

const OnboardingIndividualData = ({
  touched,
  errors,
  getFieldProps,
  values,
  individuals,
  onboardingStepResults,
}: OnboardingIndividualDataProps) => {
  const { t } = useTranslation('onboarding');
  const hasMessages = onboardingStepResults.some(
    (c) => c.step === OnboardingSteps.ACCOUNT_DATA && c.messages.length > 0
  );
  return (
    <>
      <Heading size="md">{t('Individual data')}</Heading>
      <Divider />
      {hasMessages && (
        <Alert status="warning">
          <AlertIcon />
          <Flex flexDir="column" w="100%">
            <AlertTitle>
              {t(
                'Our team have found some pending with your data, please check and fix that'
              )}
            </AlertTitle>

            {onboardingStepResults
              .find((c) => c.step === OnboardingSteps.ACCOUNT_DATA)
              ?.messages.map((msg, index) => (
                <AlertDescription key={index}>- {t(msg)}</AlertDescription>
              ))}
          </Flex>
        </Alert>
      )}
      {Object.keys(individuals).map((individual, index) => (
        <Flex key={index} flexDir="column" gap="15px" marginTop="15px">
          <Flex flexWrap="wrap" maxW="450px">
            <FormControl>
              <FormLabel>{t('Name')}</FormLabel>
              <Input
                type="text"
                isDisabled={true}
                value={individuals[individual]?.legalName}
              />
            </FormControl>
          </Flex>
          <Box w={{ base: 'unset', md: '450px' }}>
            <FormControl
              id="isPep"
              isInvalid={
                (touched?.individuals?.[individual]?.isPep &&
                  !!errors?.individuals?.[individual]?.isPep) ||
                false
              }
            >
              <Flex
                flexDir="row"
                alignItems="center"
                align="center"
                textAlign="center"
              >
                <FormLabel marginTop="5px">
                  {t('Is Political Exposed Person')}
                </FormLabel>
                <Checkbox
                  isChecked={values?.individuals?.[individual]?.isPep}
                  {...getFieldProps(`individuals.${individual}.isPep`)}
                />
              </Flex>
              <FormErrorMessage>
                {errors?.individuals?.[individual]?.isPep}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id="isFatca"
              isInvalid={
                (touched?.individuals?.[individual]?.isFatca &&
                  !!errors?.individuals?.[individual]?.isFatca) ||
                false
              }
            >
              <Flex
                flexDir="row"
                alignItems="center"
                align="center"
                textAlign="center"
              >
                <FormLabel marginTop="5px">{t('Is US Person')}</FormLabel>
                <Checkbox
                  isChecked={values?.individuals?.[individual]?.isFatca}
                  {...getFieldProps(`individuals.${individual}.isFatca`)}
                />
              </Flex>
              <FormErrorMessage>
                {errors?.individuals?.[individual]?.isFatca}
              </FormErrorMessage>
            </FormControl>

            <Collapse
              in={values?.individuals?.[individual]?.isFatca}
              animateOpacity
              delay={2}
            >
              <FormControl
                id="bornAmerican"
                isInvalid={
                  (touched?.individuals?.[individual]?.fatcaData
                    ?.bornAmerican &&
                    !!errors?.individuals?.[individual]?.fatcaData
                      ?.bornAmerican) ||
                  false
                }
              >
                <Flex
                  flexDir="row"
                  alignItems="center"
                  align="center"
                  textAlign="center"
                >
                  <FormLabel marginTop="5px">{t('Born as American')}</FormLabel>
                  <Checkbox
                    isChecked={
                      values?.individuals?.[individual]?.fatcaData?.bornAmerican
                    }
                    {...getFieldProps(
                      `individuals.${individual}.fatcaData.bornAmerican`
                    )}
                  />
                </Flex>
                <FormErrorMessage>
                  {errors?.individuals?.[individual]?.fatcaData?.bornAmerican}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                id="naturalizedAmerican"
                isInvalid={
                  (touched?.individuals?.[individual]?.fatcaData
                    ?.naturalizedAmerican &&
                    !!errors?.individuals?.[individual]?.fatcaData
                      ?.naturalizedAmerican) ||
                  false
                }
              >
                <Flex
                  flexDir="row"
                  alignItems="center"
                  align="center"
                  textAlign="center"
                >
                  <FormLabel marginTop="5px">
                    {t('Naturalized American')}
                  </FormLabel>
                  <Checkbox
                    isChecked={
                      values?.individuals?.[individual]?.fatcaData
                        ?.naturalizedAmerican
                    }
                    {...getFieldProps(
                      `individuals.${individual}.fatcaData.naturalizedAmerican`
                    )}
                  />
                </Flex>
                <FormErrorMessage>
                  {
                    errors?.individuals?.[individual]?.fatcaData
                      ?.naturalizedAmerican
                  }
                </FormErrorMessage>
              </FormControl>

              <FormControl
                id="partnerInAmericanCompany"
                isInvalid={
                  (touched?.individuals?.[individual]?.fatcaData
                    ?.partnerInAmericanCompany &&
                    !!errors?.individuals?.[individual]?.fatcaData
                      ?.partnerInAmericanCompany) ||
                  false
                }
              >
                <Flex
                  flexDir="row"
                  alignItems="center"
                  align="center"
                  textAlign="center"
                >
                  <FormLabel marginTop="5px">
                    {t('Partner In American Company')}
                  </FormLabel>
                  <Checkbox
                    isChecked={
                      values?.individuals?.[individual]?.fatcaData
                        ?.partnerInAmericanCompany
                    }
                    {...getFieldProps(
                      `individuals.${individual}.fatcaData.partnerInAmericanCompany`
                    )}
                  />
                </Flex>
                <FormErrorMessage>
                  {
                    errors?.individuals?.[individual]?.fatcaData
                      ?.partnerInAmericanCompany
                  }
                </FormErrorMessage>
              </FormControl>

              <FormControl
                id="hasGreenCard"
                isInvalid={
                  (touched?.individuals?.[individual]?.fatcaData
                    ?.hasGreenCard &&
                    !!errors?.individuals?.[individual]?.fatcaData
                      ?.hasGreenCard) ||
                  false
                }
              >
                <Flex
                  flexDir="row"
                  alignItems="center"
                  align="center"
                  textAlign="center"
                >
                  <FormLabel marginTop="5px">{t('Has green card')}</FormLabel>
                  <Checkbox
                    isChecked={
                      values?.individuals?.[individual]?.fatcaData?.hasGreenCard
                    }
                    {...getFieldProps(
                      `individuals.${individual}.fatcaData.hasGreenCard`
                    )}
                  />
                </Flex>
                <FormErrorMessage>
                  {errors?.individuals?.[individual]?.fatcaData?.hasGreenCard}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                id="taxResidenceEUA"
                isInvalid={
                  (touched?.individuals?.[individual]?.fatcaData
                    ?.taxResidenceEUA &&
                    !!errors?.individuals?.[individual]?.fatcaData
                      ?.taxResidenceEUA) ||
                  false
                }
              >
                <Flex
                  flexDir="row"
                  alignItems="center"
                  align="center"
                  textAlign="center"
                >
                  <FormLabel marginTop="5px">
                    {t('Has tax residence in EUA')}
                  </FormLabel>
                  <Checkbox
                    isChecked={
                      values?.individuals?.[individual]?.fatcaData
                        ?.taxResidenceEUA
                    }
                    {...getFieldProps(
                      `individuals.${individual}.fatcaData.taxResidenceEUA`
                    )}
                  />
                </Flex>
                <FormErrorMessage>
                  {
                    errors?.individuals?.[individual]?.fatcaData
                      ?.taxResidenceEUA
                  }
                </FormErrorMessage>
              </FormControl>

              <FormControl
                id="identificationNumber"
                isInvalid={
                  (touched?.individuals?.[individual]?.fatcaData
                    ?.identificationNumber &&
                    !!errors?.individuals?.[individual]?.fatcaData
                      ?.identificationNumber) ||
                  false
                }
              >
                <FormLabel>{t('Tax Identification Number')}</FormLabel>
                <Input
                  type="text"
                  {...getFieldProps(
                    `individuals.${individual}.fatcaData.identificationNumber`
                  )}
                />
                <FormErrorMessage>
                  {
                    errors?.individuals?.[individual]?.fatcaData
                      ?.identificationNumber
                  }
                </FormErrorMessage>
              </FormControl>
            </Collapse>
          </Box>
        </Flex>
      ))}
    </>
  );
};

export default OnboardingIndividualData;
