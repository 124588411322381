import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Divider,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { OnboardingSteps } from '@waygee/shared-types';
import {
  FormikTouched,
  FormikErrors,
  FieldConfig,
  FieldInputProps,
} from 'formik';
import { OnboardingSchema } from '../../';
import { AutoCompleteProps } from '../../../../common/components/AutoComplete';
import { SignUpStepResult } from '../../../../common/types';
import { LocalAccountFormFields } from '../../../BankAccounts/components/LocalAccount/components/LocalAccountForm/components/LocalAccountFormFields';

export type OnboardingBankingDataProps = {
  touched: FormikTouched<OnboardingSchema>;
  errors: FormikErrors<OnboardingSchema>;
  getFieldProps: (
    nameOrOptions: string | FieldConfig
  ) => FieldInputProps<string | number>;
  autoCompleteProps: AutoCompleteProps;
  onboardingStepResults: SignUpStepResult[];
};

const OnboardingBankingData = ({
  errors,
  getFieldProps,
  touched,
  autoCompleteProps,
  onboardingStepResults,
}: OnboardingBankingDataProps) => {
  const { t } = useTranslation('onboarding');
  const hasMessages = onboardingStepResults.some(
    (c) => c.step === OnboardingSteps.BANKING_DATA && c.messages.length > 0
  );
  return (
    <>
      <Heading size="md">{t('Banking data')}</Heading>
      <Divider />
      {hasMessages && (
        <Alert status="warning">
          <AlertIcon />
          <Flex flexDir="column" w="100%">
            <AlertTitle>
              {t(
                'Our team have found some pending with your data, please check and fix that'
              )}
            </AlertTitle>

            {onboardingStepResults
              .find((c) => c.step === OnboardingSteps.BANKING_DATA)
              ?.messages.map((msg, index) => (
                <AlertDescription key={index}>- {msg}</AlertDescription>
              ))}
          </Flex>
        </Alert>
      )}
      <Flex flexDir="column" gap="10px">
        <LocalAccountFormFields
          autoCompleteProps={autoCompleteProps}
          errors={errors.banking ? errors.banking : {}}
          getFieldProps={getFieldProps}
          touched={touched.banking ? touched.banking : {}}
          fieldStartName="banking."
        />
      </Flex>
    </>
  );
};

export default OnboardingBankingData;
