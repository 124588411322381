import { AxiosResponse } from 'axios';

export const handleDownloadResponse = (
  response: AxiosResponse,
  fileName: string,
  type = 'application/pdf'
) => {
  const blob = new Blob([new Uint8Array(response.data)], {
    type,
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  if (link.parentNode) link.parentNode.removeChild(link);
};
