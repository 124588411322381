import {
  ContactTypes,
  DocumentTypes,
  IDocumentConfig,
} from '@waygee/shared-types';

export const DOCUMENTS_CONFIG: Record<DocumentTypes, IDocumentConfig> = {
  [DocumentTypes.TIN]: {
    type: DocumentTypes.TIN,
    name: 'Tax Identification Number',
    description: 'Tax Identification Number, e.g. CPF, CNPJ, SSN, etc.',
    isMultiple: false,
    isUnique: true,
    accountTypes: [ContactTypes.INDIVIDUAL, ContactTypes.BUSINESS],
    hasFile: true,
    isFileRequired: true,
  },
};
