import {
  Box,
  Flex,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useMediaQuery,
} from '@chakra-ui/react';

export type OnboardingStep = {
  title: string;
  description: string;
};
export type OnboardingStepperProps = {
  steps: OnboardingStep[];
  activeStep: number;
};

const OnboardingStepper = ({ activeStep, steps }: OnboardingStepperProps) => {
  const [showStepper] = useMediaQuery('(min-width: 900px)');
  return showStepper ? (
    <Flex>
      <Stepper index={activeStep} colorScheme="primary">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    </Flex>
  ) : null;
};

export default OnboardingStepper;
