import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  Link,
  Text,
  Flex,
  FormControl,
  HStack,
  PinInput,
  PinInputField,
  Center,
  FormErrorMessage,
  Button,
} from '@chakra-ui/react';
import { FormikErrors, FormikTouched } from 'formik';
import './Mfa.language';

export type MfaFormProps = {
  disclaimer?: string;
  handleMfaCodeValidation: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  handleResendMfaCode: () => void;
  countdown: number;
  isCounting: boolean;
  isLoading: boolean;
  setFieldValue: (field: string, value: string) => void;
  touched: FormikTouched<{
    mfaCode: string;
  }>;
  errors: FormikErrors<{
    mfaCode: string;
  }>;
};

const MfaForm = ({
  handleMfaCodeValidation,
  handleResendMfaCode,
  countdown,
  isCounting,
  isLoading,
  setFieldValue,
  touched,
  errors,
  disclaimer,
}: MfaFormProps) => {
  const { t } = useTranslation('mfa');
  return (
    <Stack spacing={4}>
      {' '}
      <form onSubmit={handleMfaCodeValidation}>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          gap="20px"
        >
          <Text>
            {disclaimer ??
              t(
                'Please verify your e-mail with the 6 digits code to validade your access'
              )}
          </Text>
          <FormControl
            id="mfaCode"
            isInvalid={(touched.mfaCode && !!errors.mfaCode) || false}
          >
            <Flex flexDir="column" justifyContent="center" alignItems="center">
              <HStack>
                <PinInput
                  onChange={(mfa) => setFieldValue('mfaCode', mfa)}
                  isInvalid={(touched.mfaCode && !!errors.mfaCode) || false}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Flex>
            <Center>
              <FormErrorMessage>
                {touched.mfaCode && errors.mfaCode}
              </FormErrorMessage>
            </Center>
          </FormControl>
          <Button colorScheme="green" type="submit" isDisabled={isLoading}>
            {t('Validate')}
          </Button>
          <Link
            fontSize="small"
            onClick={isCounting ? undefined : handleResendMfaCode}
            cursor={isCounting ? 'not-allowed' : 'pointer'}
          >
            {isCounting
              ? `${t('Wait')} ${countdown} ${t('seconds to resend the code')}`
              : t('Resend e-mail')}
          </Link>
        </Flex>
      </form>
    </Stack>
  );
};

export default MfaForm;
