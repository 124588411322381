import { DateTimeHelper, Dispatcher } from '@waygee/common';
import axios from 'axios';
import appConfig from '../configuration/AppConfig';
import {
  getAuthTokenCookie,
  removeAuthToken,
  setAuthToken,
} from '../utility/cookies/authTokenStorage';
import { fetchRefreshToken } from './fetchRefreshToken';
import { RequestLock } from './utils/RequestLock';

const TIME_OUT = 120000;

const validateAccessToken = async () => {
  const authToken = getAuthTokenCookie();
  if (authToken?.accessTokenExpiresAt) {
    const diffInMinutes = DateTimeHelper.getDifferenceInMinutes(
      authToken.accessTokenExpiresAt.toString()
    );
    const isAccessTokenLessThen1MinuteToExpire = diffInMinutes < 1;
    if (isAccessTokenLessThen1MinuteToExpire) {
      try {
        await RequestLock.lock();
        const newToken = await fetchRefreshToken();
        setAuthToken({ ...newToken, silent: true });
        return newToken;
      } catch (error) {
        removeAuthToken();
        return undefined;
      } finally {
        RequestLock.unlock();
      }
    }
  }
  RequestLock.unlock();
  return authToken;
};

const axiosCustomInstance = axios.create({
  timeout: TIME_OUT,
});

axiosCustomInstance.interceptors.response.use(
  (response) => {
    RequestLock.requestDone();
    return response;
  },
  (error) => {
    RequestLock.requestDone();
    return Promise.reject(error);
  }
);

const dispatcher = new Dispatcher({
  resolveWith: async () => {
    return validateAccessToken();
  },
});

const checkRequest = () => dispatcher.requestDispatch();

axiosCustomInstance.interceptors.request.use(async (config) => {
  await RequestLock.waitForUnlock();

  const tokenToUse = await checkRequest();

  if (tokenToUse) {
    config.headers.Authorization = `Bearer ${tokenToUse.accessToken}`;
  }
  sessionStorage.setItem(
    appConfig.lasRequestDateSessionId,
    DateTimeHelper.getNowAsLocal().toISOString()
  );
  RequestLock.newRequest();
  return config;
});

axiosCustomInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const newToken = await checkRequest();

      axiosCustomInstance.defaults.headers.common['Authorization'] =
        'Bearer ' + newToken;

      return axiosCustomInstance(originalRequest);
    } else if (error?.response?.status === 401) {
      removeAuthToken();
    }
    return Promise.reject(error);
  }
);

export default axiosCustomInstance;
