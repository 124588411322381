import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  CloseButton,
  Flex,
  Button,
} from '@chakra-ui/react';
import './../LayoutWrapper.language';
import { OnboardingStepStatus } from '@waygee/shared-types';
import { AuthContext } from '../../../../contexts/AuthContext/AuthContext';

const SignUpCheck = () => {
  const [show, setShow] = useState<boolean>(false);
  const { t } = useTranslation('layout-wrapper');
  const navigate = useNavigate();
  const { isLoggedIn, onboardingStatus, onboardingStepResults } =
    useContext(AuthContext);

  const hasAnyMessages = onboardingStepResults.some(
    (c) => c.messages.length > 0
  );

  const onClose = () => setShow(false);
  const onButtonClick = () => {
    onClose();
    navigate('/secure/onboarding');
  };

  useEffect(() => {
    const isOnboardingPending =
      onboardingStatus === OnboardingStepStatus.PENDING ||
      onboardingStatus === OnboardingStepStatus.IN_PROGRESS;
    setShow(isLoggedIn ? isOnboardingPending : false);
  }, [isLoggedIn, onboardingStatus]);
  return show ? (
    <Flex w="100%">
      <Alert status="warning" w="100%">
        <AlertIcon />
        <Flex flexDir="column" w="100%">
          <AlertTitle>{t('Registration pending to be completed')}</AlertTitle>
          <AlertDescription>
            {onboardingStatus === OnboardingStepStatus.PENDING &&
              !hasAnyMessages &&
              t(
                'Finish your registration to start using Waygee. In order to execute exchange operations, we need a few more data from you.'
              )}
            {onboardingStatus === OnboardingStepStatus.PENDING &&
              hasAnyMessages &&
              t(
                'We need a few more data from you to finish your registration. Please, click on the button to complete your registration.'
              )}
            {onboardingStatus === OnboardingStepStatus.IN_PROGRESS &&
              t(
                'Your registration is being reviewed. We will notify you when it is ready.'
              )}
          </AlertDescription>
          <Flex alignSelf="flex-start" marginTop="15px">
            <Button colorScheme="green" onClick={onButtonClick}>
              {onboardingStatus === OnboardingStepStatus.PENDING &&
                t('Complete registration')}
              {onboardingStatus === OnboardingStepStatus.IN_PROGRESS &&
                t('View the progress')}
            </Button>
          </Flex>
        </Flex>
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={onClose}
        />
      </Alert>
    </Flex>
  ) : null;
};

export default SignUpCheck;
