import { DateTime, DateTimeUnit } from 'luxon';

export class DateTimeHelper {
  /**
   * Format a date to a string in UTC timezone
   * Reference to accepted formats: https://moment.github.io/luxon/#/formatting?id=table-of-tokens
   */
  static format(date: Date, format: string, timezone?: string) {
    let dt = DateTime.fromJSDate(date);
    if (timezone) dt = dt.setZone(timezone);
    return dt.toFormat(format);
  }

  /**
   * Format date in local timezone
   * Reference to accepted formats: https://moment.github.io/luxon/#/formatting?id=table-of-tokens
   */
  static formatLocal(date: Date, format: string) {
    if (typeof date === 'string')
      return DateTime.fromISO(date).toLocal().toFormat(format);
    return DateTime.fromJSDate(date).toLocal().toFormat(format);
  }

  /*
   * Get the difference in minutes between a date and the current date
   */
  static getDifferenceInMinutes = (dateToCheck: string): number => {
    // Parse the dateToCheck to a DateTime object
    const date = DateTime.fromISO(dateToCheck);

    // Get the current date
    const currentDate = DateTime.local();

    // Get the difference in minutes
    const diffInMinutes = date.diff(currentDate, 'minutes').minutes;

    return diffInMinutes;
  };

  /**
   * Format date in UTC timezone
   * Reference to accepted formats: https://moment.github.io/luxon/#/formatting?id=table-of-tokens
   */
  static formatUtc(date: Date, format: string) {
    if (typeof date === 'string')
      return DateTime.fromISO(date).toUTC().toFormat(format);
    return DateTime.fromJSDate(date).toUTC().toFormat(format);
  }

  static fromUnixTimestamp(timestamp: number) {
    return DateTime.fromSeconds(timestamp).toJSDate();
  }

  static fromUnixTimestampWithoutSeconds(timestamp: number) {
    return DateTime.fromMillis(timestamp * 1000).toJSDate();
  }

  static toUnixTimestamp(date: Date) {
    return DateTime.fromJSDate(date).toSeconds();
  }

  /**
   * Format date in local timezone
   * Reference to accepted formats: https://moment.github.io/luxon/#/formatting?id=table-of-tokens
   */
  static parse(date: string, format: string) {
    return DateTime.fromFormat(date, format).toJSDate();
  }

  static formatBackend(date: Date) {
    return DateTime.fromJSDate(date).toUTC().toISO();
  }

  static parseBackend(date: string) {
    return DateTime.fromISO(date).toLocal().toJSDate();
  }

  static isSame(date1: Date, date2: Date, unit: DateTimeUnit) {
    return DateTime.fromJSDate(date1).hasSame(DateTime.fromJSDate(date2), unit);
  }

  static getNowAsLocal = () => DateTime.local().toJSDate();

  /*
   * Format seconds to 00:00 format
   */
  static formatSecondsToMinutesRemaining = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
}
